import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ContractPlanService {

	baseUrl: string;

	constructor(private http:HttpClient) 
	{ 
		this.baseUrl = environment.apiUrl+'api/';
	}
	
	getAssets(contract,serviceGroupType): Observable <any[]>
	{
		return this.http.post<any[]>(this.baseUrl+'assets/getByContract',{contract: contract, serviceGroupType: serviceGroupType, 'token':sessionStorage.getItem('token')});       
	}

	getAssetInfo(assetId)
	{
		return this.http.post<any[]>(this.baseUrl+'assets/getInfo',{assetId: assetId, 'token':sessionStorage.getItem('token')});   
	}

	getPlans(contract,serviceGroupType)
	{
		return this.http.post<any[]>(this.baseUrl+'contracts/getPlans',{contract: contract, serviceGroupType: serviceGroupType, 'token':sessionStorage.getItem('token')});   
	}

	getPlanSvg(blueprint)
	{
		 
		return this.http.post<any[]>(this.baseUrl+'contracts/getPlanSvg',{blueprint: blueprint, 'token':sessionStorage.getItem('token')});
	}

	
	getPlanInfo(blueprint)
	{
		return this.http.post<any[]>(this.baseUrl+'contracts/getPlanInfo',{blueprint: blueprint, 'token':sessionStorage.getItem('token')});   
	}


	getPlanPdfUrl(blueprint)
	{
		return this.http.post<any[]>(this.baseUrl+'plan/getPdfUrl',{blueprint: blueprint, 'token':sessionStorage.getItem('token')});   
	}

	savePlanPdf(pdf,blueprint)
	{
		return this.http.post<any[]>(this.baseUrl+'plan/putPdf',{blueprint:blueprint,pdf: pdf, 'token':sessionStorage.getItem('token')});   
	}
}
