import { Component, OnInit } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import { JarwisService } from 'src/app/services/jarwis.service';
import { SnotifyService } from 'ng-snotify';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public error = [];
  public oldPasswordError = null;

  public form = {
    user_id: null,
    old_password: null,
    user_password: null,
    user_password_confirmation: null
  };
  
  datosUsuario = null;

  constructor(
    private userData: UserDataService,
    private Jarwis: JarwisService,
    private router: Router,
    private notify: SnotifyService
    ) { }

  ngOnInit() {
    this.datosUsuario = this.userData.get();
    console.log(this.datosUsuario);
    this.form.user_id = this.datosUsuario.user_id;
  }

  onSubmit(){
    this.oldPasswordError = null;
    this.notify.info('Espere un momento...', {timeout: 3000});
    this.Jarwis.modificaPassword(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(data){
    this.notify.success(data.data);
    this.router.navigateByUrl('/home');
  }

  handleError(error){
    this.notify.error('Hay errores en el formulario');

    if(error.error.errors){
      this.error = error.error.errors;
    } else{
      this.oldPasswordError = error.error.error;
    }
  }
}
