import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'Search',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.css']
})


export class SearchInputComponent implements OnInit {

    @Input('title') title = 'Buscar datos en tabla';
    @Output('search') searchEvent = new EventEmitter;

    

    constructor() { }

    ngOnInit() {
    }

    search(event)
    {
        let data = {
            search : event.target.value
        }
        this.searchEvent.emit(data);
    }


}
