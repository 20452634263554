import { Injector } from '@angular/core';

// import { BaseControlComponent } from '../components/common/controls/base-control/base-control.component';
// import { }


export let AppInjector: Injector;
export function setAppInjector(injector: Injector) {
    if (AppInjector) {
        // Should not happen
        console.error('Programming error: AppInjector was already set');
    }
    else {
        AppInjector = injector;
    }
}


// export class AppInjector
// {
//     private static injector: Injector;

//     static setInjector(injector: Injector) {

//         AppInjector.injector = injector;

//     }

//     getControl(){
//         return new BaseControlComponent();
//     }

//     // static getInjector(): Injector {

//     //     return AppInjector.injector;

//     // }

// }       