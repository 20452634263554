import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-tracking-marker',
  templateUrl: './tracking-marker.component.html',
  styleUrls: ['./tracking-marker.component.css']
})
export class TrackingMarkerComponent implements OnInit {

    constructor() {}

    ngOnInit() {}

    private marker;
    private data : any = null;
    private label :string = 'Vehiculo';
    private trackingLine = null;
    private trackingDecorator = null;
    private defaultIcon = null;
    private defaultColor = null;
    private onfocusColor = null;
    private map; 

    defaultPolylineOptions;
    onfocusPolylineOptions;
    defaultDecoratorPatterns;
    onfocusDecoratorPatterns;

    onfocus = false;
    focusTrigger = null;

    icon;

    public build(icon, color , onfocusColor)
    {
        this.defaultIcon = icon;
        this.defaultColor = color;
        this.onfocusColor = onfocusColor;

        this.initOptions();
        this.initMarker();
        this.initTrackingLine();
        this.setPopup();
    }

    initOptions()
    {
        this.defaultPolylineOptions = { opacity:0.5, smoothFactor:2, color: this.defaultColor, weight:3 }
        this.onfocusPolylineOptions = { opacity:0.5, smoothFactor:2, color: this.onfocusColor, weight:6 }
        this.defaultDecoratorPatterns = [{ offset: 5, repeat: 50, symbol: new L.Symbol.ArrowHead({ polygon: true, pixelSize: 8, pathOptions: { color:this.defaultColor, fillOpacity: 0.5, opacity: 0.5, weight: 0 } }) }];
        this.onfocusDecoratorPatterns = [{ offset: 5, repeat: 50, symbol: new L.Symbol.ArrowHead({ polygon: true, pixelSize: 15, pathOptions: { color:this.onfocusColor, fillOpacity: 1, opacity: 0.5, weight: 0 } }) }];

        this.icon = L.divIcon({
            html: `<i style="color:${this.defaultColor};font-size:25px;" class="fas fa-${this.defaultIcon}"></i>`
            // icon : 'fa-home'
            , iconSize    : [20, 20]
            , className   : 'done'
          });
    }

    
    initMarker()
    {
        this.marker = L.marker(
            this.data.lastTrack.position
            , { icon: this.icon }
        );
    }

    initTrackingLine()
    {
        this.trackingLine = L.polyline(this.data.trackings, this.defaultPolylineOptions );
        this.trackingDecorator = L.polylineDecorator([this.trackingLine],{ patterns: this.defaultDecoratorPatterns  });
    }

    addTo(map: L.Map)
    {
        this.map = map;
        this.bindEvents();
        this.marker.addTo(map);
        this.trackingLine.addTo(map);
        this.trackingDecorator.addTo(map);
    }

    bindEvents()
    {
        var _this = this;

        this.marker.on('mouseover',function(){ _this.focus('marker'); });
        this.marker.on('mouseout',function(){ _this.unfocus('marker'); });

        if( !this.isTrackingLineEmpty() ){
            this.trackingLine.on('click', function(){ _this.centerMapOnMe() });
            this.trackingDecorator.on('click', function(){ _this.centerMapOnMe() });

            this.trackingLine.on('mouseover',function(){ _this.focus('line'); });
            this.trackingLine.on('mouseout',function(){ _this.unfocus('line'); });
            this.trackingDecorator.on('mouseover',function(){ _this.focus('decorator'); });        
            this.trackingDecorator.on('mouseout',function(){ _this.unfocus('decorator'); });
        }
    }

    isFocused(){
       return this.onfocus == true;
    }

    focus(trigger)
    {
        if( ! this.isFocused() ){
            this.onfocus = true;
            this.focusTrigger = trigger;
            this.focusMarker();
            if( !this.isTrackingLineEmpty() )   this.focusTrackingLine()
        }
    }

    focusMarker()
    {
        this.marker.valueOf()._icon.style.filter = 'grayscale(50%)';  //altera el color del icono
        this.marker.valueOf()._icon.style.transform += ' scale(1.3)'; //incrementa el tamaño del icono
    }

    unfocusMarker()
    {
        this.marker.valueOf()._icon.style.filter = 'hue-rotate(360deg)'; //restaura el color original del icono        
        var newStyleTransform = this.marker.valueOf()._icon.style.transform.replace(' scale(1.3)',''); //elimina el escalado de imagen
        this.marker.valueOf()._icon.style.transform = newStyleTransform;
    }

    unfocus(trigger)
    {
        if( trigger == this.focusTrigger ){
            this.unfocusMarker();
            if( !this.isTrackingLineEmpty() )   this.unfocusTrackingLine()
            this.onfocus = false;
            this.focusTrigger = null;
        }
    }

    focusTrackingLine()
    {
        this.trackingLine.setStyle( this.onfocusPolylineOptions );
        this.trackingDecorator.setPatterns( this.onfocusDecoratorPatterns );
    }

    unfocusTrackingLine()
    {
        this.trackingLine.setStyle(this.defaultPolylineOptions); 
        this.trackingDecorator.setPatterns( this.defaultDecoratorPatterns );
    }

    setPopup()
    { this.marker.bindPopup( this.getPopup() ); }


    setData(data,label)
    { this.data = data; this.label = label; }

    getPopup()
    {
        return `<div>${ this.data.type }: <strong>${ this.data.name }</strong></div>
        <div>Hora Ultima Ubicacion: ${ this.data.lastTrack.time }</div>`; 
    }

    centerMapOnMe()
    {
        let map = this.marker._map;
        if( !this.isTrackingLineEmpty() ){
            map.fitBounds(this.trackingLine.getBounds());
        }else{
            const position = this.marker.getLatLng();
            map.setView(position,15);
        }
        this.marker.openPopup();
    }

    getVehicle()
    { return this.data; }

    remove()
    {
        this.map = null;
        this.marker.remove();
        this.trackingLine.remove();
        this.trackingDecorator.remove();
    }

    isTrackingLineEmpty()
    {
        return this.data.trackings.length == 0;
    }

    updatePosition(position)
    {
        this.marker.setLatLng(position);
        this.trackingLine.addLatLng(position);
        if (this.map!=null) {
            this.marker.update();
        }
    }

    checkId(id)
    {
        return this.data.id == id;
    }

}
