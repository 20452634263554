import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapPopUpService {

  constructor() { }
  makeAssetPopup(data: any): string {
    return `<div>Ciudad: ${ data.name }</div>
    <div>Region: ${ data.state }</div>
    <div>Poblacion: ${ data.population }</div>`;
  }
}
