import { Deserializable } from "./deserializable.model";

export class Control implements Deserializable{
    id :number;
    label :string;
    tag :string;
    value :string | boolean | number;
    type :string;
    options :any;


    constructor( data = {} ){
        this.deserialize(data);    
    }

    deserialize(input :any){
        Object.assign(this, input);
        this.options = this.parseOptionsJSON();
        if(this.type=='AGGREGATOR'){
            // console.log('CONTROL AGGREGATOR' , this.value );
        }
        // console.log('constructing CONTROL', this);
        return this;
    }

    parseOptionsJSON() :void 
    {
        if(typeof this.options == 'string'){
            if( this.options.trim() != "" ){
                this.options = eval('(' + this.options + ')')
            }
        }
        return this.options;
    }
   
}