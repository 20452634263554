import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from './../../services/print.service';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.css']
})
export class PrintLayoutComponent implements OnInit {

  private html : String;
  public currentDate : String;
  constructor(route : ActivatedRoute ,private printService: PrintService) {
    this.currentDate = '04/11/2019';
    this.html = route.snapshot.params['printParams'];
    //this.html = "test-text2";
   }

  ngOnInit() {
    //this.printService.onDataReady();
  }

}
