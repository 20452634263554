import { Component, OnInit, Output , EventEmitter} from '@angular/core';
import { JarwisService } from 'src/app/services/jarwis.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoriesService } from "../../../services/repositories.service";
import { DocumentosEmpresasService } from "../../../services/documentos-empresas.service";
import { ClientsOptionsService } from '../../../services/clients-options.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SnotifyService } from 'ng-snotify';
import * as moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css'],
  styles: [JSON.parse(localStorage.getItem("style")).img.toString(),JSON.parse(localStorage.getItem("style")).menu.toString() , JSON.parse(localStorage.getItem("style")).bootstrap.toString()],
})
export class BuscadorComponent implements OnInit {

  public contratos;
  public contratosTodos;
  public link;
  public form = {
    'cust_id': null,
    'filtro': '',
    'user_id': '',
  };
  public pagina: number = 1;
  public porPagina: number = 10;
  public tab=1;
  public ruta=this.route.url;
  public cargando=3;
  //paginacion
  public aux=[""];
  public aux2;
  public pagact=1;
  public paginas=0;  
  public elementos=3;
  public selector;
  public disp=0;
  public selectoraux;
  public sup=5;
  public inf=0;
  public elemact;
  //modulo reporte
  public fbusq=0;
  public btype=0;
  public cont_id="-1";
  public modulo="";
  public cust_id="-1";
  public id;
  public TODOS="";
  public type="TODOS";
  public compid="";
  public doc;
  public numero="";
  public wcodigo=-1;
  public wname=null;
  public flagwork=0;
  public show="Seleccione Fecha";
  public show2="Seleccione Fecha";
  public calact=0;
  public calact2=0;
  public subtype=null;
  public subtypeflag=0;
  public subid;
  public selectedDate;
  public selectedDate2;
  public clie_id=JSON.parse(localStorage.getItem('userData')).clie_id;

  //
  public repos_table;
  public repos_table_id;
  public reload=[];
  //
  public timezone;
  public customer_id_valida;


  constructor(private Jarwis: JarwisService,
    private activatedRoute: ActivatedRoute,
    private userData: UserDataService,
    private valPermiso: ValidaPermisosService,
    private route:Router,
    private datos:RepositoriesService,
    private datosdocEmp:DocumentosEmpresasService,
    private http:HttpClient,
    private clie_opt:ClientsOptionsService,
    private notify: SnotifyService
    ) {
    this.ruta=this.route.url;  
    this.form.user_id = this.userData.get().user_id;
    this.activatedRoute.params.subscribe(
      params => {this.contratos = null; this.obtenerContratos(params.cust_id)}
    );

    // reporte
    moment().locale('es');    
    this.modulo=this.route.url.split('/')[(this.route.url.split('/').length)-2];
    this.cont_id=this.route.url.split('/')[4];
    this.repos_table = this.route.url.split('/')[(this.route.url.split('/').length)-2];
    console.log("tabla : " + this.repos_table);
    console.log("tabla id : " + this.repos_table_id);
    

    if(this.cont_id==undefined && this.modulo!="reporte"){
      this.cust_id=this.modulo;
      this.modulo="reporte";
      this.cont_id="-2";
      console.log(this.cont_id);
      console.log(this.modulo);
    }
    this.datos.getType(this.modulo).subscribe(dato=>{
      this.id=dato.rety_name;
      
      var index = this.id.map(function(d) { return d['rety_name']; }).indexOf('Todos');
      this.id[index].rety_name="Todos";
      this.TODOS=this.id[index].rety_id;
      this.type=this.TODOS;
      console.log(dato);
      console.log(this.id);
      var index = this.id.map(function(d) { return d['rety_name']; }).indexOf('Todos');
      this.TODOS=this.id[index].rety_id;
      this.type=this.TODOS;
      this.compid=dato.comp_id[0].comp_id;
      this.pagact=1;
      this.fbusq=1;
      this.btype=1;
      this.disp=1;      
    },
    error=>{console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});

    })
   }

   change(type){
    console.log(type);
    this.flagwork=0;
    this.subtype=undefined;
    this.subtypeflag=0;
    this.datos.getSubType(type).subscribe(data=>{
      console.log(data);
      this.subid=data;
      this.subtypeflag=1;
      
    });  

  }

  fecha(){
    console.log(this.selectedDate);
  }

  tipo(arg,arg2){
    let auxn=this.wname;   
    console.log("arg" + arg);
    console.log("arg2" + arg2);
    
    if(false){
      this.notify.warning("Ingrese Subtipo de documento");
    }
    else{
      if(this.selectedDate===undefined || this.selectedDate2===undefined){
        this.tipo2(arg,arg2,"1900-01-01 00:00:00","2100-01-01 00:00:00");
      }
      else{        
        this.tipo2(arg,arg2,this.selectedDate,this.selectedDate2);
      }
    }    
  }
   tipo2(arg,arg2,t1=null,t2=null){
    
    if(arg==this.TODOS){
      this.fbusq=0;
      this.pagact=1;
      this.datosdocEmp.get_emp(this.repos_table,this.repos_table_id,this.compid,t1,t2,this.timezone).subscribe(dato=>{

        this.doc=dato;
        this.disp=1;
        this.numero="";
        console.log(this.doc);
        this.paginacion(dato);
        this.fbusq=1;
      },error=>{console.log(error);  
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
        this.disp=1;
        this.fbusq=1;
        this.numero="";
      })
    }else{
      this.fbusq=0;
      this.pagact=1;
      
      this.datosdocEmp.getListFilter(this.repos_table,this.repos_table_id,arg,arg2,this.compid,t1,t2,this.timezone).subscribe(dato=>{
        
        this.doc=dato;
        this.disp=1;
        this.numero="";
        this.wname=null;
        this.wcodigo=-1;
        this.paginacion(dato);
        this.fbusq=1;
        this.flagwork=1;
        console.log(this.doc);
      },error=>{  console.log(error);  
        this.wname=null;
        this.wcodigo=-1;
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
        this.disp=1;
        this.fbusq=1;
        this.numero="";
        this.flagwork=1;
      })
    }
  }

  tshow(arg){
    if(arg==1){

      this.show=this.selectedDate;
      this.calact=1;
      this.calact2=1;
    }
    if(arg==2){
      this.show2=this.selectedDate2;
      this.calact=1;
      this.calact2=1;
    }
    if(arg==0){
      this.show="Seleccione Fecha";
      this.show2="Seleccione Fecha";
      this.calact=1;
      this.calact2=1;
    }
  }
  checktype(){
    return this.type;
  }


  changeCode(code){
    this.wcodigo=code;
  }

  changeName(name){ this.wname=name; }

  changeTab(arg){ 
    this.tab=arg; 
  }

  checkRouter(){
    if(this.ruta==this.route.url){
      return true;
    }
    else{      
      this.ruta=this.route.url;
      return false;
    }
  }
  /**fin reportes*/


  obtenerContratos(cust_id){
    this.form.cust_id = cust_id;
    this.link = '../' + cust_id + '/contrato/';
    this.Jarwis.obtenerContratos(this.form).subscribe(
      data => this.asignarContratos(data)
    );
    this.customer_id_valida=this.route.url.split('/')[(this.route.url.split('/').length)-1];

    //si selecciono otro holding debera buscar los documentos del customer/holding selecciondo
    if(this.cust_id != this.customer_id_valida){
      this.clie_opt.get_options(this.clie_id).subscribe(data=>{
        this.timezone = data['app_timezone'];   

        this.datosdocEmp.get_emp(this.repos_table,this.customer_id_valida,this.compid,"1900-01-01 00:00:00","2100-01-01 00:00:00",this.timezone).subscribe(data=>{
        this.doc=data;
        this.fbusq=1;
        this.btype=1;
        this.disp=1;
        console.log(this.doc);
        this.paginacion(data);
        },
        error=>{console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
        })
        
      },
      error=>{console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
      }) 
    }
  }

  asignarContratos(data){
    this.contratosTodos = data.data;
    this.contratos = this.contratosTodos;
  } 

  /*
  botonFiltrar(){
    var filtro = this.form.filtro;
    this.contratos = this.contratosTodos.filter(function (el) {
      return (
        el.cont_name.toLowerCase().includes(filtro.toLowerCase()) || 
        el.cont_address.toLowerCase().includes(filtro.toLowerCase()) || 
        el.cont_code.toLowerCase().includes(filtro.toLowerCase())
      );
    });
  }
*/
  filtrar(event){
    var filtro = event;
    this.contratos = this.contratosTodos.filter(function (el) {
      return (
        el.cont_name.toLowerCase().includes(filtro.toLowerCase()) || 
        el.cont_address.toLowerCase().includes(filtro.toLowerCase()) || 
        el.cont_code.toLowerCase().includes(filtro.toLowerCase())
      );
    });
  }

  validaPermisos(permiso){
    return this.valPermiso.validaPermisos(permiso);
  }
  guardaNombre(comp_id, cont_id, nombre_cust, nombre_cont){
    localStorage.setItem('comp_id', comp_id);
    localStorage.setItem('cont_id', cont_id);
    localStorage.setItem('cont_nom',nombre_cont);
    localStorage.setItem('cust_nom',nombre_cust);
  }

  /*metodos de reportes*/
  upload(){
    this.cargando=3;
    this.disp=2;   
    
  }

  paginacion(arg){
    
    this.aux2=(arg.length/this.elementos);
    
    this.aux=this.aux2.toString().split(".");
    
    if(this.aux[1]===undefined){
      this.paginas=Number(this.aux[0]);

    }
    else{
      this.paginas=Number(this.aux[0])+1;
    }
    this.elemact=arg.slice(this.elementos*(this.pagact-1),(this.pagact*this.elementos));
    
    this.selectoraux=Array.from({length: Number(this.paginas)}, (v, k) => k+1);
    this.selector=this.selectoraux.slice(this.inf,this.sup);
  }
  dev(){
    this.paginacion(this.doc);
    if(this.sup>this.selectoraux[this.selectoraux.length-1]){
      
      this.inf=this.selectoraux[this.selectoraux.length-1]-5;
      this.sup=this.selectoraux[this.selectoraux.length-1];

    }
    if(this.inf<0){
      
      this.inf=0;
      this.sup=5;
    }
    
    this.sum(0);
  }
  pag(arg){

    this.disp=0;
    this.pagact=Number(arg); 
    this.inf=this.pagact-3;
    this.sup=this.pagact+2;
    if(this.sup>this.selectoraux[this.selectoraux.length-1]){
      this.inf=this.selectoraux[this.selectoraux.length-1]-5;
      this.sup=this.selectoraux[this.selectoraux.length-1];

    }
    if(this.inf<0){
      this.inf=0;
      this.sup=5;
    }
    
    this.paginacion(this.doc);
    this.disp=1;
  }
  sum(arg){
    
    this.disp=0;
    this.pagact=this.pagact+arg;
    
    if(this.pagact<1){
      this.pagact=1;
    }
    if(this.pagact>this.selectoraux[this.selectoraux.length-1]){
      this.pagact=this.selectoraux[this.selectoraux.length-1];
    }
    
    if(this.selector[this.selector.length-1]!=this.selectoraux[this.selectoraux.length-1] && arg==1 && this.pagact==this.selector[3]){
      this.inf=this.inf+arg;
      this.sup=this.sup+arg;

    }
    if((this.selector[0]!=1 && arg==-1) && this.pagact==this.selector[1]){

      this.inf=this.inf+arg;
      this.sup=this.sup+arg;
    }

    this.paginacion(this.doc);    
    this.disp=1;

  }

  eliminarRepositories(repos_id,repos_route){
    console.log("repositories id eliminar: "+  repos_id );
    this.paginacion(this.reload);  
    
    this.datos.delete_repositories(repos_id,repos_route).subscribe(data=>{
      this.reload_component();
    },error=>{console.log(error)});
    this.fbusq=0;
    this.pagact=1;     
      

      
  }

  desactivarRepositories(repos_id){
    console.log("repositories id desactivar: "+  repos_id);
    this.paginacion(this.reload);  

    this.datos.disable_repositories(repos_id).subscribe(data=>{
      this.reload_component();
    },error=>{console.log(error)});
    this.fbusq=0;
      this.pagact=1;
      
    
  }

  reload_component(){
    this.datosdocEmp.get_emp(this.repos_table,this.repos_table_id,this.compid,"1900-01-01 00:00:00","2100-01-01 00:00:00",this.timezone).subscribe(dato=>{

        this.doc=dato;
        this.disp=1;
        this.numero="";
        console.log(this.doc);
        this.paginacion(dato);
        this.fbusq=1;
      },error=>{console.log(error);  
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
        this.disp=1;
        this.fbusq=1;
        this.numero="";
      })
  }

  ngOnInit() {    

  }

}
