import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

// create interface for data in sendGuideFile(data)
export interface MassUpGuideData {
  CONTRATO_CODIGO: string;
  NOMBRE_ARCHIVO: string;
  LINEA_NEGOCIO: string;
  SUBTIPO_DOCUMENTO: string;
}

@Injectable({
  providedIn: 'root'
})

export class MassUploadService {

  baseUrl: string;


  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl + 'api/';
  }
  // listado de servicios

  // 1. obtener los repositories con su cliente, compañia, contrtato, etc asociados
  getDemoData(): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'massUpload/getData', { 'token': sessionStorage.getItem('token') });
  }

  sendGuideFile(data: MassUpGuideData, userId : number): Observable<any[]> {
    const dataWithId = {
      data,
      userId
    }
    console.log('dataWithId', dataWithId);
    return this.http.post<any[]>(this.baseUrl + 'massUpload/validate', dataWithId);
  }



  executeMassUpload(form: FormData): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'massUpload/executeMassUpload', form);
  }

  
}




