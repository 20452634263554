export class Task {
    anio : string;
    mes: string;
    novedades: string;
    riesgo: string;
    accion: string;

    comentario: string;

    consumo: string;
    captura:string;


    construct(){
        this.anio = '';
        this.mes = '';
        this.novedades = '';
        this.riesgo = '';
        this.accion = '';
        this.comentario = '';
        this.consumo = '';
        this.captura = ''
    }
}