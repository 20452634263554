import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppInjector } from 'src/app/services/app-injector.service';
import { BsModalService } from 'ngx-bootstrap';
import { SearchableTableComponent } from '../../common/searchable-table/searchable-table.component';
import { ContractPlanService } from 'src/app/services/contract-plan.service';
import { AssetService } from 'src/app/services/asset.service';


@Component({
  selector: 'AssetPlanModal',
  templateUrl: './asset-modal.component.html',
  styleUrls: ['./asset-modal.component.css']
})
export class AssetModalComponent implements OnInit
{
	modalService :BsModalService;
	assetService :AssetService;

	modal = null;
	data :any;
	info = [];
	loading = true;
	stage = 'asset';
	tuvDetail = [];
	
	public modalConfig = {
		backdrop: true , //Fondo oscuro
		ignoreBackdropClick: false, //para que no se cierre al clickear fuera del formulario
		class: 'modal-lg',
		keyboard: true //para que no se cierre al apretar escape
	};

	@ViewChild('modalBody') modalBody : ElementRef;

	@ViewChild('deratTable',{read: SearchableTableComponent}) deratTable : SearchableTableComponent;
	@ViewChild('tuvTable',{read: SearchableTableComponent}) tuvTable : SearchableTableComponent;




	constructor()
	{
		this.modalService = AppInjector.get(BsModalService);
		this.assetService = AppInjector.get(AssetService);
		this.setDefault();
	}

	setDefault()
	{
		this.data = {
			info: [],
			desratizacion: [],
			tuv: []
		};
	}

	// this.template;

	ngOnInit() 
	{
		// console.log('!!! init AssetModal',this);
	}

	buildInfo() :void
	{
		var assetCode = this.data.info.asset_code.split('-');
		var info = [];
		info.push({ title: 'Numero Dispositivo', value: assetCode[0] });  
		info.push({ title: 'Codigo Punto de Servicio', value: assetCode[1] });  
		info.push({ title: 'Ubicación', value: assetCode[2] });  
		info.push({ title: 'Tipo Trampa', value: assetCode[3] });  
		info.push({ title: 'Punto de Servicio', value: this.data.info.contractLocation });  
		info.push({ title: 'Grupo', value: this.data.info.group });  
		info.push({ title: 'Categoría', value: this.data.info.category });  
		info.push({ title: 'Localizacion', value: this.data.info.location });  
		info.push({ title: 'Estado', value: this.data.info.status });  
		this.info = info;
	}

	setData(data) : void
	{
		this.loading = false;

		this.data = data;
		this.buildInfo();
		
		let _this = this;

		// console.log('!!! SET DATA()',this.deratTable);
		// this.deratTable.getDataTable().setHeaders(['Fecha','Estado']);
		// this.deratTable.setDataSource(data['derat']);

		// this.tuvTable.getDataTable().setHeaders(['Fecha','Estado','Opciones']);
		// this.tuvTable.setDataSource(data['tuv']);
		this.loading = false;
	}

	show() : void
	{
		this.setDefault();
		this.loading = true;
		this.modal = this.modalService.show(this.modalBody,this.modalConfig);
	}
	
	close() : void
	{
		this.modal.hide()
		this.stage = 'asset';
	}



	tuvId = null;

	openTuvModal(id)
	{
		this.loading = true;
		this.stage = 'tuv';
		this.tuvId = id;
		// console.log(this,id);

		this.assetService.getTuvDetail(id).subscribe(
			data => {
				this.tuvDetail = data['tuvDetail'];
				this.loading = false;
			}, error => {
				console.log('Error al obtener detalle tuv del articulo '+id)
			}
		);
	}

	back()
	{
		this.stage = 'asset'
	}
}
