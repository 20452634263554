import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RecommendationService {


  constructor(private http:HttpClient) { 
    this.baseUrl = environment.apiUrl;
  }
  private baseUrl: string;

  


  getServices(clieId):Observable <any[]>{
    console.log('getting Services');
    var options = typeof localStorage.getItem('options') == null ? [] : JSON.parse(localStorage.getItem('options'));
    return this.http.post<any[]>(this.baseUrl+'api/recommendation/getServices',{clieId: clieId,'token':localStorage.getItem('token'), forms : options['forms_recomendations']});       
  }

  getFilters(clieId,segtyId):Observable <any[]>{
    console.log('getting Services');
    var options = typeof localStorage.getItem('options') == null ? [] : JSON.parse(localStorage.getItem('options'));
    return this.http.post<any[]>(this.baseUrl+'api/recommendation/getFilters',{clieId: clieId,segtyId:segtyId,'token':localStorage.getItem('token'), forms : options['forms_recomendations']});       
  }

  //obtiene estructura de inputs y valores (si es que se le proporciona id) de una recomendacion.
  getFilteredRecommendation(filter,segtyId,clieId):Observable <any[]>{
    console.log('getting filtered contracts');
    return this.http.post<any[]>(this.baseUrl+'api/recommendation/getFilteredRecommendations',{segtyId: segtyId, clieId: clieId, filter: filter,'token':localStorage.getItem('token')});       
  }

  getContractRecommendations(filter,clieId,segtyId,contractId,page): Observable <any[]>{
    console.log('getting RiskFactorSchema');
    var options = typeof localStorage.getItem('options') == null ? [] : JSON.parse(localStorage.getItem('options'));
    return this.http.post<any[]>(this.baseUrl+'api/recommendation/getContractRecommendations',{filter: filter, clieId: clieId,segtyId:segtyId,contId: contractId,'token':localStorage.getItem('token'),forms : options['forms_recomendations']});       
  }

  exportContractRecommendations(filter,clieId,segtyId,contractId,page): Observable <any[]>{
    console.log('getting RiskFactorSchema');
    var options = typeof localStorage.getItem('options') == null ? [] : JSON.parse(localStorage.getItem('options'));
    return this.http.post<any[]>(this.baseUrl+'api/recommendation/export',{filter: filter, clieId: clieId, forms : options['forms_recomendations'], segtyId: segtyId, contId: contractId,'token':localStorage.getItem('token')});       
  }


  //obtiene estructura de inputs y valores (si es que se le proporciona id) de una recomendacion.
  getRecommendation(formId,recommendationId):Observable <any[]>{
    console.log('getting single recommendation data');
    return this.http.post<any[]>(this.baseUrl+'api/recommendation/get',{formId: formId,recommendationId:recommendationId,'token':localStorage.getItem('token')});       
  }

  //obtiene estructura de inputs y valores (si es que se le proporciona id) de una recomendacion.
  saveRecommendation(form:FormData):Observable <any[]>{
    console.log('saving recommendation');
    // const headers = new HttpHeaders();
    // headers.append('Content-Type', 'multipart/form-data');
    // headers.append('Accept', 'application/json');
    // headers.append('token',localStorage.getItem('token'));
    // headers.append('Token',localStorage.getItem('token'));
    form.append('token',localStorage.getItem('token'));
    return this.http.post<any[]>(this.baseUrl+'api/recommendation/save',form/* {formId: formId,recommendationId:recommendationId,data:JSON.stringify(data),images:images,'token':localStorage.getItem('token')} */);       
  }
 

  
}
