import { Directive, Input, TemplateRef, ViewContainerRef, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appReload]'
})
export class ReloadDirective implements OnChanges {

  // forma del video. quizas cambiar esto mas adelante.
  // @Input() set appReload(condition: boolean) {}
  @Input() appReload !: number;


  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,) {
    this.viewContainerRef.createEmbeddedView(templateRef);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['appReload'] && changes['appReload'].previousValue !== undefined) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }



}
