import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { BaseControlComponent } from './../base-control/base-control.component';
import { ControlWrapperComponent } from '../control-wrapper/control-wrapper.component';
import { MatInput, MatAutocompleteTrigger } from '@angular/material';
import {map, startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})

/**
 * Componente de Input con Autocomplete
 */
export class SearchControl extends BaseControlComponent implements OnInit {

    constructor() 
    {
        super();
    }

    @Input('clearBtn') showClearBtn :boolean = true;

    filteredOptions: Observable<string[]>;
    input = new FormControl();
    // options: string[] = ['One', 'Two', 'Three'];

    ngOnInit() {
        this.refreshAutocompleteValues();
    }

    onKeyup(event)
    {
        this.data.value = event.target.value;
        this.changeEvent.emit({event:event,control:this.data});
    }

    onClear(event)
    {
        this.data.value = '';
        this.refreshAutocompleteValues();
        this.changeEvent.emit({event:event,control:this.data});
    }

    optionSelected(event)
    {
        this.data.value = event.option.value;
        this.changeEvent.emit({event:event,control:this.data});
    }
  
    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.data.options.data.filter(option => option.toLowerCase().includes(filterValue));
    }

    refreshAutocompleteValues()
    {
        this.filteredOptions = this.input.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    }
  
}
