import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ElementRef,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  HostListener
} from "@angular/core";
import { area, line, curveLinear } from "d3-shape";
import { scaleBand, scaleLinear, scalePoint, scaleTime } from "d3-scale"
import { concat } from "rxjs";
import {
  NgxChartsModule,
  BaseChartComponent,
  LineComponent,
  LineSeriesComponent,
  calculateViewDimensions,
  ViewDimensions,
  ColorHelper
} from "@swimlane/ngx-charts";
import { ComboSeriesHorizontalComponent } from "./combo-series-horizontal.component";
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: "grouped-stacked-horizontal-combo",
  templateUrl: "./grouped-stacked-horizontal-combo.component.html",
  styleUrls: ["./grouped-stacked-horizontal-combo.component.css"],
  animations: [
        trigger('animationState', [
            transition(':leave', [
                style({
                    opacity: 1,
                    transform: '*'
                }),
                animate(500, style({ opacity: 0, transform: 'scale(0)' }))
            ])
        ])
    ]
})
export class GroupedStackedHorizontalComboComponent extends BaseChartComponent
  implements OnChanges {
  @Input() ChartSeries: [];
  @Input() view;
  @Input() curve: any = curveLinear;
  @Input() legend = false;
  @Input() legendTitle: string = "Legend";
  @Input() legendPosition: string = "right";
  @Input() xAxis;
  @Input() yAxis;
  @Input() showXAxisLabel;
  @Input() showYAxisLabel;
  @Input() showRightYAxisLabel;
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() yAxisLabelRight;
  @Input() tooltipDisabled: boolean = false;
  @Input() gradient: boolean;
  @Input() showGridLines: boolean = true;
  @Input() activeEntries: any[] = [];
  @Input() schemeType: string = 'ordinal';
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() yRightAxisTickFormatting: any;
  @Input() roundDomains: boolean = false;
  @Input() colorSchemeLine: any[];
  @Input() autoScale;
  @Input() lineChart: any;
  @Input() yLeftAxisScaleFactor: any;
  @Input() yRightAxisScaleFactor: any;
  @Input() rangeFillOpacity: number;
  @Input() animations: boolean = false;
  @Input() noBarWhenZero: boolean = true;
  @Input() barPadding = 5;
  @Input() groupPadding = 10;
  @Input() showDataLabel:boolean = false;
  @Input() xScaleMax = 1;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  dims: ViewDimensions;
  xScale: any;
  yScale: any;
  xDomain: any;
  yDomain: any;
  transform: string;
  colors: ColorHelper;
  colorsLine: ColorHelper;
  margin: any[] = [10, 20, 10, 20];
  xAxisHeight: number = 0;
  yAxisWidth: number = 0;
  legendOptions: any;
  scaleType = "linear";
  xScaleLine;
  yScaleLine;
  xDomainLine;
  yDomainLine;
  seriesDomain;
  scaledAxis;
  combinedSeries;
  xSet;
  filteredDomain;
  hoveredVertical;
  yOrientLeft = "left";
  yOrientRight = "right";
  legendSpacing = 0;
  bandwidth;

  groupDomain;
  groupScale;
  valueScale;
  valuesDomain;
  innerScale;
  innerDomain;
  optionDomain;
  barHeight;
  //END VARIABLES



    groupTransform = function (group) {
        return "translate(0, " + this.groupScale(group.name) + ")";
    };
    

    

  update() {
    super.update();
    this.dims = calculateViewDimensions({
      width: this.showDataLabel==true ? this.width-110 : this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    if (!this.yAxis) {
      this.legendSpacing = 0;
    } else if (this.showYAxisLabel && this.yAxis) {
      this.legendSpacing = 100;
    } else {
      this.legendSpacing = 40;
    }

    this.valuesDomain = this.getValueDomain();
    this.bandwidth = this.valuesDomain[1] - this.valuesDomain[0];
    this.optionDomain = this.getOptionDomain();
    this.groupDomain = this.getGroupDomain();
    this.innerDomain = this.getInnerDomain();
    this.barHeight = this.getBarHeight();
    this.xScale = this.getXScale();
    this.yScale = this.getYScale();

    this.valueScale = this.getValueScale();
    this.seriesDomain = this.getSeriesDomain();
    this.groupScale = this.getGroupScale();
    this.innerScale = this.getInnerScale();
    this.setColors();
    this.legendOptions = this.getLegendOptions();
    this.transform = "translate(" + this.dims.xOffset + " , " + this.margin[0] + ")";
  }

  deactivateAll() {
    for (const entry of this.activeEntries) {
      this.deactivate.emit({ value: entry, entries: [] });
    }
    this.activeEntries = [];
  }

  @HostListener("mouseleave")


  hideCircles(): void {
    this.hoveredVertical = null;
    this.deactivateAll();
  }

  /**
   * Calcula el alto disponible para un barGroup
   * @param none    
   * @return int - alto
   */
  getBarHeight(){
    let whiteSpace = this.groupDomain.length>0 ? this.groupPadding * this.groupDomain.length : 0;    
    return (this.dims.height - whiteSpace ) / this.groupDomain.length;
  }


  updateHoveredVertical(item): void {
    this.hoveredVertical = item.value;
    this.deactivateAll();
  }

  updateDomain(domain): void {
    // this.filteredDomain = domain;
    // this.xDomainLine = this.filteredDomain;
    // this.xScaleLine = this.getXScaleLine(this.xDomainLine, this.dims.width);
  }

  getSeriesDomain(): any[] {
    this.combinedSeries = this.results.slice(0);
    return this.combinedSeries.map(d => d.name);
  }

  isDate(value): boolean {
    if (value instanceof Date) {
      return true;
    }
    return false;
  }

  getScaleType(values): string {
    let date = true;
    let num = true;

    for (const value of values) {
      if (!this.isDate(value)) {
        date = false;
      }

      if (typeof value !== "number") {
        num = false;
      }
    }

    if (date) return "time";
    if (num) return "linear";
    return "ordinal";
  }

  getXScale(): any {
    this.xDomain = this.getXDomain();
    const spacing =
      this.xDomain.length / (this.dims.width / this.barPadding + 1);
    return scaleBand()
      .range([0, this.dims.width])
      .paddingInner(spacing)
      .domain(this.xDomain);
  }

  getYScale(): any {
    // stacked
    var spacing = this.groupDomain.length / (this.dims.height / this.barPadding + 1);

    return scaleBand()
        .rangeRound([0, this.dims.height])
        .paddingInner(spacing)
        .domain(this.groupDomain);
  }


  getXDomain(): any[] {
    return this.results.map(d => d.name);
  }

  onClick(data) {
    this.select.emit(data);
  }

  setColors(): void {
    let domain;
    domain = this.optionDomain;
    this.colors = new ColorHelper(
      this.scheme,
      this.schemeType,
      domain,
      this.customColors
    );
  }


  /**
   * Funcion determina todos nombres de las barras para ser mostrados en el Legend
   * @param none    
   * @return array<string> - nombres de barras
   */
  getOptionDomain(){
    var domain = [];
    this.results.map((group)=>{
      group.series.map((stack)=>{
          stack.series.map((chunk)=>{
              if(!domain.includes(chunk.name)) domain.push(chunk.name);
          });
      });
    });
    return domain;
  }



  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === "ordinal") {
      opts.domain = this.optionDomain;;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.optionDomain;;
      opts.colors = this.colors.scale;
    }
    return opts;
  }

  updateLineWidth(width): void {
    this.bandwidth = width;
  }

  updateYAxisWidth({ width }): void {
    this.yAxisWidth = width + 20;
    this.update();
  }

  updateXAxisHeight({ height }): void {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(item) {
    const idx = this.activeEntries.findIndex(d => {
      return (
        d.name === item.name &&
        d.value === item.value &&
        d.series === item.series
      );
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries.push(item);
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(item) {
    const idx = this.activeEntries.findIndex(d => {
      return (
        d.name === item.name &&
        d.value === item.value &&
        d.series === item.series
      );
    });

    this.activeEntries.splice(idx, 1);

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }

  /**
   * Funcion que indica la posicion en el eye Y dentro de un barGroup
   * **Se diseña para ser pasada como parametro y llamada en otros componentes**
   * @param string - nombre del barGroup
   * @return int - posicion en eje Y
   */
    getGroupScale = function()  {
        var spacing = this.groupDomain.length / (this.dims.height / this.groupPadding + 1);
        return scaleBand()
            .rangeRound([0, this.dims.height])
            .paddingInner(spacing)
            .paddingOuter(spacing / 2)
            .domain(this.groupDomain);
    };

    /**
   * Funcion determina todos nombres de los barGroup
   * **Se diseña para ser pasada como parametro y llamada en otros componentes**
   * @param none    
   * @return array<string> - nombres de group 
   */
    getGroupDomain() {
        var domain = [];
        for (var _i = 0, _a = this.results; _i < _a.length; _i++) {
            var group = _a[_i];
            if (!domain.includes(group.name)) {
                domain.push(group.name);
            }
        }
        return domain;
    };

    
 /**
   * Determina la posicion (en pixeles) en el eje x dado un valor 
   * **Se diseña para ser pasada como parametro y llamada en otros componentes**
   * @param int - valor de x
   * @return int - posicion en eje x
   */
    getValueScale = function () {
        var scale = scaleLinear()
            .range([0, this.dims.width])
            .domain(this.valuesDomain);
        return this.roundDomains ? scale.nice() : scale;
    };


  /**
   * Determina los valores maximos y minimos (dominio) a alcanzar dentro del eje X.
   * **Se diseña para ser pasada como parametro y llamada en otros componentes**
   * @param none
   * @return array<int>(2) - minimo y maximo
   */
    getValueDomain = function () {
        var domain = [];
        //itera grupos
        for (var _i = 0, _a = this.results; _i < _a.length; _i++) {
            var group = _a[_i];
            //itera stack
            for (var _b = 0, _c = group.series; _b < _c.length; _b++) {
                var d = _c[_b];

                //suma Stack
                var sum = 0;
                d.series.map((v)=>{
                    sum += parseInt(v.value);
                });
                if (!domain.includes(sum)) {
                    domain.push(sum);
                }
            }
        }
        var min = Math.min.apply(Math, [0].concat(domain));
        var max = this.xScaleMax ? Math.max.apply(Math, [this.xScaleMax].concat(domain)) : Math.max.apply(Math, [0].concat(domain));
        return [min, max];
    };

  /**
   * Funcion que indica la posicion en el eye Y dentro de un barGroup
   * **Se diseña para ser pasada como parametro y llamada en otros componentes**
   * @param string - nombre del barStack
   * @return int - posicion en eje Y
   */
    getInnerScale = function () {
        var height = this.groupScale.bandwidth();
        var spacing = this.innerDomain.length / (height / this.barPadding + 1);
        return scaleBand()
            .rangeRound([0, height])
            .paddingInner(spacing)
            .domain(this.innerDomain);
    };
    

   
    /**
   * Funcion determina todos nombres de los barStack
   * **Se diseña para ser pasada como parametro y llamada en otros componentes**
   * @param none    
   * @return array<string> - nombres de stack 
   */
    getInnerDomain = function () {
        var domain = [];
        //itera grupos
        for (var _i = 0, _a = this.results; _i < _a.length; _i++) {
            var group = _a[_i];
            //itera stacks
            for (var _b = 0, _c = group.series; _b < _c.length; _b++) {
                var d = _c[_b];
                if (!domain.includes(d.name)) {
                    domain.push(d.name);
                }
            }
        }
        return domain;
    };
    
    
}
