import { Component, OnInit, AfterViewInit, ViewChild, Input, HostListener, ChangeDetectorRef, ViewContainerRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AssetsMapService } from './../../services/assets-map.service';
import { SnotifyService } from 'ng-snotify';
import { MapComponent } from './../leaflet/map/map.component';
import { AssetMarkerComponent } from './../leaflet/asset-marker/asset-marker.component';
import { AssetPanelComponent } from './../leaflet/asset-panel/asset-panel.component';
import { TrackingPanelComponent } from './../leaflet/tracking-panel/tracking-panel.component';
import { TrackingMarkerComponent } from '../leaflet/tracking-marker/tracking-marker.component';

import * as L from 'leaflet';
import { BsModalService } from 'ngx-bootstrap';
import { ServiceModalComponent } from '../service-modal/service-modal.component';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { SearchableTableComponent } from '../common/searchable-table/searchable-table.component';
import { Control } from 'src/app/models/control.model';
import { interval, Subscription } from 'rxjs';
// import { ControlFactoryService } from 'src/app/services/control-factory.service';


@Component({
    selector: 'app-assets-map',
    templateUrl: './assets-map.component.html',
    styleUrls: ['./assets-map.component.css']
})
export class AssetsMapComponent implements OnInit, OnDestroy {
    
    @ViewChild( 'map',{read:MapComponent} ) map :MapComponent;
    
    constructor( private assetMapService :AssetsMapService, 
                private notify : SnotifyService, 
                private modalService : BsModalService,
                private detector :ChangeDetectorRef
                /* ,
                private controlFactory : ControlFactoryService */ ) {}

    @ViewChild('assetPanel', {read:AssetPanelComponent}) assetPanel :AssetPanelComponent;
    @ViewChild('trackingPanel', {read:TrackingPanelComponent}) trackingPanel :TrackingPanelComponent;
    @ViewChild('serviceModal', {read:ServiceModalComponent}) serviceModal :ServiceModalComponent;
    // @ViewChild('controlsTemplate', {read:ViewContainerRef}) controlsTemplate :ViewContainerRef;

    @Output('assetsLoaded') assetsLoadedEvent = new EventEmitter(); 

    timerSubscription: Subscription = null;
    assetMarkers :AssetMarkerComponent[] = [];
    workersTrackingMarkers :TrackingMarkerComponent[] = [];
    vehiclesTrackingMarkers :TrackingMarkerComponent[] = [];
    geofences = [];
    showVehiclesTracking = false;
    showWorkersTracking = true;
    assetsProperlyLoaded = false;
    vehiclesTrackingProperlyLoaded = false;
    workersTrackingProperlyLoaded = false;


    public modalConfig = {
        backdrop: true , //Fondo oscuro 
        ignoreBackdropClick: true, //para que no se cierre al clickear fuera del formulario 
        class: 'modal-lg',
        keyboard: false //para que no se cierre al apretar escape 
      };

    // Captura el evento lanzado al hacer click sobre el boton de informacion de un servicio
    // dentro del popup de un AssetMarkerComponent
    // @HostListener('serviceInfoRequested',['$event']) onServiceInfoRequested(event){
    //     this.serviceModal.show(event.serviceAssetId);
    // }


    ngOnInit()
    {
        this.initMap();
        var _this = this;
        // setTimeout(function(){ _this.enableLiveTracking(); },20000)
    }

    ngOnDestroy()
    {
        this.disableLiveTracking();
    }

    enableLiveTracking()
    {
        this.notify.info('Se inicia el seguimiento en vivo');
        const source = interval(3000);
        this.timerSubscription = source.subscribe(val => {
            if (this.vehiclesTrackingProperlyLoaded && this.workersTrackingProperlyLoaded){
                this.updateTracking()
            }
            if (this.assetsProperlyLoaded) {
                this.assetPanel.refreshMarkers();
            }
        });
    }

    disableLiveTracking()
    {
        if (this.timerSubscription!=null) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
            this.notify.info('Se detuvo el seguimiento en vivo')
        }
    }

    toggleLiveTraking()
    {
        if (this.timerSubscription!=null) {
            this.enableLiveTracking();
        } else {
            this.disableLiveTracking();
        }
    }

    
    onModalClose(){
        var _this = this;
        setTimeout(function(){
            _this.map.showSidebar();
        },1000);
    }

    openMapModal(event)
    {
        this.map.showModal(event);
    }

    updateMapModal(event)
    {
        this.map.modal.close();
        this.map.showModal(event);
    }


    
    addAssetPanel(){
        this.assetPanel.setMap(this.map);
        this.map.addPanel({
            id       : 'assets',
            tab      : '<i class="fas fa-map-marker"></i>',
            pane     : this.assetPanel.getDOM(),
            title    : 'Articulos',
            position : 'top'
        });
    }

    addTrackingPanel(){
        // this.trackingPanel.setMap(this.map);
        this.map.addPanel({
            id       : 'tracking',
            tab      : '<i class="fas fa-wrench"></i>',
            pane     : this.trackingPanel.getDOM(),
            title    : 'Seguimiento de Rutas',
            position : 'top'
        });
    }

    delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    initMap()
    {
        this.assetMapService.getAssets().subscribe(
            data=>{
                this.assetsLoadedEvent.emit(data);
                this.clearAssets();
                this.createAssetsMarkers(data);
                this.assetsProperlyLoaded = true;
            },error=>{
                console.log('error');
                this.notify.warning(error);
            }
        );

        this.assetMapService.getVehiclesTracks().subscribe(
            data => {
                this.clearTrackings();
                this.createTrackingMarkers(data,'vehicles');
                this.vehiclesTrackingProperlyLoaded = true;
            }
            , error => {
                console.log('error ',error);
                this.notify.warning(error);
            }
        )

        this.assetMapService.getWorkersTracks().subscribe(
            data => {
                this.clearTrackings();
                this.createTrackingMarkers(data,'workers');
                this.map.setMarkers(this.workersTrackingMarkers);
                this.updateTrackingPanel();
                this.workersTrackingProperlyLoaded = true;
            }
            , error => {
                console.log('error ',error);
                this.notify.warning(error);
            }
        )

        // this.assetMapService.getProperties().subscribe(
        //     data => {
        //         this.clearGeofences();

        //         var map = this.map.map;
        //         var _this = this;
        //         $.each( data['properties'], function(index,property:any){
        //             var polygon = L.polygon(property.geofence, {color: 'red',fill:true})
        //                             .addTo(map)
        //                             .bindPopup(`<div>
        //                                             <div>Propiedad : ${property.name}</div>
        //                                             <div>Descripcion : ${property.description}</div>
        //                                         </div>`);
        //             _this.geofences.push(polygon); 
        //         });
        //     }
        //     , error => {
        //         console.log('error ',error);
        //         this.notify.warning(error);
        //     }
        // );
        
    }

    getWorkersTracking()
    {
        this.assetMapService.getWorkersTracks().subscribe(
            data => {
                this.clearTrackings();
                this.createTrackingMarkers(data['workers'],'user');
            }
            , error => {
                console.log('error ',error);
                this.notify.warning(error);
            }
        )
    }

    getVehiclesTracking()
    {

    }


    initSidebar()
    {
        this.map.initSideBar();
        this.addAssetPanel();
        this.addTrackingPanel();
        // this.map.toggleFullScreen();
        var _this = this;

        this.map.addStateButton({
            states : [{
                stateName: 'keep-tracking',
                icon: 'fa fa-play',
                title: 'Activar Seguimiento en Tiempo Real',
                onClick: function(btn){
                    _this.enableLiveTracking()
                    btn.state('stop-tracking');
                }
            } , {
                stateName: 'stop-tracking',
                icon: 'fa fa-pause',
                title: 'Pausar Seguimiento en Tiempo Real',
                onClick: function(btn){
                    _this.disableLiveTracking()
                    btn.state('keep-tracking');
                }
            }]
        });


    }


    selectedAsset(event){
        alert(event);
    }

    updateAssetPanel(){
        this.assetPanel.setMarkers(this.assetMarkers);
        this.assetPanel.initFilters();
    }

    updateTrackingPanel(){
        this.trackingPanel.clearMarkers();
        var allMarkers = [];
        if (this.showVehiclesTracking) {
            this.vehiclesTrackingMarkers.forEach(marker=> allMarkers.push(marker));
        }
        if (this.showWorkersTracking) {
            this.workersTrackingMarkers.forEach(marker=> allMarkers.push(marker));
        }
        this.map.setMarkers(allMarkers);
        this.trackingPanel.setMarkers(allMarkers);
    }


    createAssetsMarkers(data){
        for (let i = 0 ; i < data['assets'].length; i++) {
            var asset = data['assets'][i];
            // var marker = this.factory.resolveComponentFactory( AssetMarkerComponent ).create();
            var marker = new AssetMarkerComponent( this.assetMapService, this.serviceModal );
            marker.setAsset(asset);
            marker.build();
            this.assetMarkers.push(marker);
        }
        this.map.setMarkers(this.assetMarkers);
        this.updateAssetPanel();
    }

    createTrackingMarkers(data,type){
        // this.clearTrackings();
        if (type=='workers') {
            var icon = 'user';
            var color = 'blue';
        } else {
            var icon = 'truck';
            var color = 'red';
        }
        for (let i = 0 ; i < data[type].length; i++) {
            var vehicle = data[type][i];
            var marker = new TrackingMarkerComponent();
            const title = type =='workers' ? 'Técnico' : 'Vehiculo';
            marker.setData(vehicle,title);
            marker.build(icon,color,'gray');
            if (type=="workers") {
                this.workersTrackingMarkers.push(marker);
            } else {
                this.vehiclesTrackingMarkers.push(marker);
            }
        }
        this.updateTrackingPanel();
    }


    clearAssets(){
        this.assetMarkers = [];
        this.assetPanel.clearMarkers();
    }

    clearTrackings(){
        // this.workersTrackingMarkers = [];
        // this.vehiclesTrackingMarkers = [];

        this.trackingPanel.clearMarkers();
    }

    clearGeofences(){
        for( let gf of this.geofences ){
            gf.remove();
        }
        this.geofences = [];
    }

    toggleWorkersTracking()
    {
        if (this.showWorkersTracking==true) {
            this.showWorkersTracking = false;
            // this.workersTrackingMarkers.forEach (marker => {
            //     marker.remove();
            // })
        } else{
            this.showWorkersTracking = true;
            // this.map.setMarkers(this.workersTrackingMarkers);
        }
        this.updateTrackingPanel();
    }

    toggleVehiclesTracking()
    {
        if (this.showVehiclesTracking==true) {
            this.showVehiclesTracking = false;
        } else{
            this.showVehiclesTracking = true;
        }
        this.updateTrackingPanel();
    }

    updateTracking()
    {
        // console.log('esto se ejecuta cada 3 segundos');
        this.assetMapService.getUpdatedTrackings().subscribe(
            data => {
                data['vehicles'].forEach( tracking => {
                    var marker :TrackingMarkerComponent = this.findVehicleMarker(tracking.id);
                    marker.updatePosition(tracking.position);
                });

                data['workers'].forEach( tracking => {
                    var marker : TrackingMarkerComponent = this.findWorkerMarker(tracking.id);
                    marker.updatePosition(tracking.position);
                });
            }
        );
    }

    findWorkerMarker(id) :TrackingMarkerComponent {
        var marker :TrackingMarkerComponent[] = this.workersTrackingMarkers.filter( marker => { return marker.checkId(id);  })
        return marker[0];
    }

    findVehicleMarker(id) :TrackingMarkerComponent {
        var marker :TrackingMarkerComponent[] = this.vehiclesTrackingMarkers.filter( marker => { return marker.checkId(id);  })
        return marker[0];
    }


    
    arrayToObject(someArray)
    { return Object.assign({}, someArray); }

}
