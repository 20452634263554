import { Component, OnInit, Input, Output, HostBinding, ViewChild } from '@angular/core';
import { EventEmitter } from 'events';
import { ChartInterface } from 'src/app/interfaces/chart-interface';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {

  public title = "";
  public dataSource : any = null;

  public index : number;
  public selfRef: LineChartComponent;
  public isLoading = true;
  public style : any;
  public type : string;
  public chartStyles = {
    'dispositivos' : {
      view : [1000, 240]
      , scheme :  {
        domain: [ '#E51807','#3211AB','#9BBB59']
        // domain: ['#3211AB','#F02E04','#F9FF1C','#1F8303','#871292','#00AFE8','#EA8707','#539802']
      }
      , xAxis : true
      , yAxis : true
      , showXAxisLabel : false
      , showYAxisLabel : false
      , yAxisLabel : ''
      , xAxisLabel : ''
      , legend :  false
      , legendTitle : ''
      // , yAxisLabel : ''
      // , barPadding : 20
      // , xScaleMax : 100
    },
    'tuv' : {
      view : [1000, 240]
      , scheme :  {
        domain: [ '#E51807','#3211AB','#9BBB59','#7859EB','#FB886F','#F7FB7B','#78D35F','#C98ECF','#7FD9F7','#F2B25F','#B2EA70']
        // domain: ['#3211AB','#F02E04','#F9FF1C','#1F8303','#871292','#00AFE8','#EA8707','#539802']
      }
      , xAxis : true
      , yAxis : true
      , showXAxisLabel : false
      , showYAxisLabel : false
      , yAxisLabel : 'Cantidad'
      , xAxisLabel : 'Mes'
      , legend :  true
      , legendTitle : 'Años'
      // , yAxisLabel : ''
      // , barPadding : 20
      // , xScaleMax : 100
    }
  };
 
  @HostBinding('class.col-md-4') isColMd4 : boolean;
  @HostBinding('class.col-lg-4') isColLg4 : boolean;
  @HostBinding('class.col-lg-6') isColLg6 : boolean;
  @HostBinding('class.col-md-6') isColMd6 : boolean;
  @HostBinding('class.col-md-8') isColMd8 : boolean;
  @HostBinding('class.col-lg-8') isColLg8 : boolean;
  @HostBinding('class.col-lg-12') isColLg12 : boolean;
  @HostBinding('class.col-md-12') isColMd12 : boolean;



  //interface for Parent-Child interaction
  public parentReference : ChartInterface;
  isDataValid = true;


  @ViewChild('chart') chart:LineChartComponent;

  constructor() { 
    this.isColMd4 = false;
    this.isColLg4 = false;
    this.isColMd6 = false;
    this.isColLg6 = false;
    this.isColMd8 = false;
    this.isColLg8 = false;
    this.isColMd12 = false;
    this.isColLg12 = false;
  }

  // @Input('type') type : string; 
  // @Output() onLoad = new EventEmitter();


  ngOnInit() {
  }

  yAxisTickFormatting = (value: number) => {
    if (Math.floor(value) !== value) {
      return '';
    }
    return value;
  }

  setClasses(classes){
    if( !Array.isArray(classes) ) return false;
    if(classes.includes('col-lg-4')) this.isColLg4 = true;
    if(classes.includes('col-md-4')) this.isColMd4 = true;
    if(classes.includes('col-lg-6')) this.isColLg6 = true;
    if(classes.includes('col-md-6')) this.isColMd6 = true;
    if(classes.includes('col-lg-8')) this.isColLg8 = true;
    if(classes.includes('col-md-8')) this.isColMd8 = true;
    if(classes.includes('col-lg-12')) this.isColLg12 = true;
    if(classes.includes('col-md-12')) this.isColMd12 = true;
  }

  setDataSource(dataSource){
    this.isLoading = false;
    this.dataSource = dataSource;
    this.validateData();
  }

  // refresh(){
  //   this.chart.update();
  // }

  setType(type){
    this.type = type;
    this.style = this.chartStyles[type];
  }

  setCustomStyle(style){
    this.style = style; 
  }



  validateData(){
    var data = this.dataSource;
    var pointCount = 0;
    data.map((lines)=>{
      lines.series.map((point)=>{
          if(point.value>0) pointCount++;
      });
    });
    this.isDataValid = pointCount>0;
  }
}
