import { Component, OnInit, Renderer2, Input, Output, TemplateRef, EventEmitter } from '@angular/core';
import { NotaVentaService } from '../../services/nota-venta.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
//
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { listLocales } from 'ngx-bootstrap/chronos';

import { ActivatedRoute, Router } from "@angular/router";
import { SnotifyService } from 'ng-snotify';

import { ViewChild } from '@angular/core';
import * as $ from 'jquery';
import * as moment from 'moment';

@Component({
  selector: 'app-nota-venta',
  templateUrl: './nota-venta.component.html',
  styleUrls: ['./nota-venta.component.css'],
 // styles: [JSON.parse(sessionStorage.getItem("style")).img.toString(),JSON.parse(sessionStorage.getItem("style")).menu.toString() , JSON.parse(sessionStorage.getItem("style")).bootstrap.toString()],
})
export class NotaVentaComponent implements OnInit {
  
  public modalSubidaArchivo: BsModalRef;
  public modalVerArchivo: BsModalRef;
  public config = {
    backdrop: true , //Fondo oscuro 
    ignoreBackdropClick: true, //para que no se cierre al clickear fuera del formulario 
    class: 'modal-lg',
    keyboard: false //para que no se cierre al apretar escape 
  };
	public elemact;
	public doc;
  public comp_id;
  public loading = 0;
  public loadingUpload = -1;
  public loadingData = 0;

  //paginacion
  public pagina: number = 1;
  public porPagina: number = 5;  

  //paginacionModal
  public paginaModal: number = 1;
  public porPaginaModal: number = 5; 
  public elemactModal;

  // filtros
  public clie_id=JSON.parse(sessionStorage.getItem('userData')).clie_id;
  public status=""; // considerar que por ahora sera el estado de la ruta o tarea
  public rety_name="";
  public repositories_type;

  // reportes
  public tipoReport;

  //obtener contractos y linea de negocio 
  public cont_id="";

  //subida de archivo
  @ViewChild('myFile') public myFile;
  @ViewChild('barras') public progreso;
  fileToUpload: File = null;
  public id;
  public btype=0;  
  public report_type;
  public nombre;
  public subiendo=0;
  public ok=0;
  public dataFile:FormData= new FormData();
  public sub_type_id;
  public flagwork=0;
  public subtype=null;
  public subtypeflag=0;
  public sub_type_selected;

  //work_order_items
  public woit_id; 
  //listado de archivos por nota de venta

  //fecha  
  public fechaFilterINI; 
  public fechaFilterFIN;   

  public locale = 'es';
  data_fecha: Date[];
  data_fecha_bill: Date[];  
  public segty_id=sessionStorage.getItem('segty');
  public modulo="";

  //descripcion del archivo
  public description;
  public cust_id;
 
  elements: any = [];
  headElements = ['id', 'cliente', 'contrato', 'servicio', 'asignacion', 'realizacion', 'estado'];
  
  //para validar el mensaje de sin archivos o sin datos, ademas del cargando datos  
  public elementsData = -1;

  //acivities
  public asgt_id = sessionStorage.getItem("asgt_id");  
  public acti_id = sessionStorage.getItem("activities");  

  constructor(
    private datos:NotaVentaService, 
    private http:HttpClient,
    private renderer:Renderer2,
    private localeService: BsLocaleService,
    private route:Router,
    private notify: SnotifyService,
    private modalService: BsModalService) {    
    
   /*this.route.navigateByUrl(this.route.url);*/
   moment().locale('es');   
   defineLocale('es', esLocale); 
   this.cont_id=this.route.url.split('/')[4];
   this.cust_id=this.route.url.split('/')[2];

    //obtener el modulo
    this.modulo=this.route.url.split('/')[(this.route.url.split('/').length)-1];
    console.log(this.modulo); 
    console.log('asgt_id : '+this.asgt_id); 
    
    this.localeService.use(this.locale);   

    this.elements.length = 0;
    this.loadingData = 0;
    this.elementsData = -1;

  	this.datos.get_nv(this.acti_id).subscribe(
  		data=>{
  			this.doc=data;
        this.loadingData = 1;        
        if(this.doc.length === 0){
              this.elementsData = 0;
        }else{
           this.elementsData = 1;
        }

        this.paginacion(data);

  		},error => {
          console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
          this.elements.length = 0;
          this.loadingData = 0;
          this.elementsData = -1;
    })  

    this.datos.getTypeReport().subscribe(
        data=>{
          this.repositories_type=data.rety_name;
          this.comp_id=data.comp_id[0].comp_id;                 
      },error => {
          console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});          
    })   
  }

  openModalSubidaArchivo(id,template: TemplateRef<any>) {
    this.woit_id=id;
    console.log('dsa : ' + this.woit_id);
    this.loading=0;     
    this.list_report_type(id);
    this.modalSubidaArchivo = this.modalService.show(template, this.config);
  }

  openModalVerArchivo(id,template: TemplateRef<any>) {
    this.woit_id=id;
    this.loading=0;     
    this.get_docs_by_nv(id)
    this.modalVerArchivo = this.modalService.show(template, this.config);
  }

  closeModalSubidaArchivos(){            

    this.elements.length = 0;
    this.loadingData = 0;
    this.elementsData = 1;

    this.datos.get_nv(this.acti_id).subscribe(
      data=>{
        this.doc=data;        
        this.loadingData = 1;

        if(this.doc.length === 0){
           this.elementsData = 0;
        }else{
           this.elementsData = -1;
        } 

        this.paginacion(data);

      },error => {
          console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
          this.elements.length = 0;
          this.loadingData = 0;
          this.elementsData = -1;
    }) 

    this.modalSubidaArchivo.hide();
  }

  closeModalVerArchivos(){ 

    this.elements.length = 0;
    this.loadingData = 0;  
    this.elementsData = -1;

    this.datos.get_nv(this.acti_id).subscribe(
      data=>{
        this.doc=data;        
        this.loadingData = 1;

        if(this.doc.length === 0){
           this.elementsData = 0;
        }else{
           this.elementsData = -1;
        }

        this.paginacion(data);

      },error => {
          console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
          this.elements.length = 0;
          this.loadingData = 0;
          this.elementsData = -1;
    })   
    this.modalVerArchivo.hide();
  }

  // para que obtenga el valor de la linea de negocio cuando se selecciona una y despues otra.
  obtener_nv_by_linea_negocio(){
    // valida si el dato guardado en el localstorage es distinto del actual, si no recarga la tabla    
    if(sessionStorage.getItem("segty")!=this.segty_id){

      this.segty_id=sessionStorage.getItem("segty");

      this.elements.length = 0;
      this.loadingData = 0;
      this.elementsData = -1;

      this.datos.get_nv(this.acti_id).subscribe(
      data=>{
        this.doc=data;        
        this.loadingData = 1;

        if(this.doc.length === 0){
              this.elementsData = 0;
        }else{
           this.elementsData = -1;
        }

        this.paginacion(data); 

      },error => {
          console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});        
          this.elements.length = 0;
          this.loadingData = 0;
          this.elementsData = -1;
    })   
      return false;
    }else{      
      return true;
    }
  }

  onValueChangeWoit_datetime(value: Date[]): void {
    this.data_fecha = value;
    this.fechaFilterINI = moment(this.data_fecha[0]).format('YYYY-MM-DD');
    this.fechaFilterFIN = moment(this.data_fecha[1]).format('YYYY-MM-DD');    
  }

  onChangeFilter(){    

    if(this.fechaFilterINI === "Invalid date"){this.fechaFilterINI =""; }    
    if(this.fechaFilterFIN === "Invalid date"){this.fechaFilterFIN ="";}

    this.elements.length = 0;
    this.loadingData = 0;
    this.elementsData = -1;

    this.datos.nv_filter(this.acti_id,this.status,this.rety_name,this.fechaFilterINI,this.fechaFilterFIN).subscribe(
      data=>{
        this.doc=data;   
        this.loadingData = 1;   

        if(this.doc.length === 0){
              this.elementsData = 0;
        }else{
             this.elementsData = -1;
        } 

        this.paginacion(data);
      },error => {
          console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
          this.elements.length = 0;
          this.loadingData = 0;
          this.elementsData = -1;
    }) 
  }

// paginacion
  paginacion(arg){        
    
    for (var _i = 0; _i < arg.length; _i++) {
      var num =  arg[_i];
      this.elements.push({ id: arg[_i]['woit_id'], cliente:arg[_i]['cust_name'], contrato: arg[_i]['cont_name'], servicio: arg[_i]['segr_name'], asignacion: arg[_i]['fecha_ruta_tarea'], realizacion: arg[_i]['woit_datetime'], estado: arg[_i]['status'] });
      //console.log(num);
    }      
  }  
  // fin paginacion

  // relleno del select
  list_report_type(id){    
    this.ok=0;
    this.datos.getTypeReport().subscribe(
      data=>{
        this.tipoReport=data.rety_name;
        this.comp_id=data.comp_id[0].comp_id;
        this.loading=1;
        this.woit_id=id;
      },error => {
          console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});          
          this.loading = 0;         
    }) 
  }  

  //subida de archivos al s3
    handleFileInput(files: FileList) {
    
    this.nombre=((files.item(0).name).split("."))[0];
    for (var i = 0; i < files.length; i++) {
      this.dataFile.append(i.toString(), files.item(i), files[i].name);
    }
     
    this.fileToUpload = files.item(0);
  }


  uploadFileToActivity() {

    this.loadingUpload = 0;
    this.dataFile.append('comp_id',this.comp_id);
    this.dataFile.append('subtype',this.subtype);
    this.dataFile.append('clie_id',this.clie_id);
    this.dataFile.append('cont_id',this.cont_id);
    this.dataFile.append('cust_id',this.cust_id);
    this.dataFile.append('woit_id',this.woit_id);
    this.dataFile.append('asgt_id',this.asgt_id);
    this.dataFile.append('desc',this.description);
    
    this.dataFile.append('token',sessionStorage.getItem('token'));
    this.subiendo=1;
    this.ok=0;
    
    // se sube al s3
    this.datos.postFile(this.dataFile).subscribe(data => {     
          this.loadingUpload = 1;     
          this.subiendo=0;
          this.ok=1;

    }, error => {
        console.log(error);
        this.loadingUpload = 1; 
        this.subiendo=0;
        this.ok=2;
    });
        
  }

  // llamado a todos los documentos de la nota de venta por tipo
  get_docs_by_nv(id){
    this.woit_id=id;
    this.loading=0; 
    console.log('woit_id : ' + this.woit_id);
    
    this.datos.get_files_s3(this.woit_id,this.segty_id).subscribe(
          data=>{
            //this.paginacionModal(data);   
            this.elemactModal = data                  
            this.loading=1;
            console.log(data);

    },error => {
          console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});        
          this.loading = 0;
    }) 
    
  }   

  change(type){
    console.log(type);
    this.flagwork=0;
    this.subtype=undefined;
    this.subtypeflag=0;
    this.datos.getSubType(type).subscribe(data=>{
      this.subtypeflag=2;// no quiero que se mueste el sub tipo de repositories
      this.sub_type_id=data;
      this.subtype=data[0]['resuty_id'];

    },error => {
          console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});        
          this.subtypeflag = 0;
    }) 

  }

  ngOnInit() {}

}
