import { Component, OnInit, Renderer2, Input, Output, TemplateRef, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SnotifyService } from 'ng-snotify';

export interface Mes {
  value: number;
  nombre: string;
}

export interface Anio {
  value: number;
  nombre: number;
}

export interface contratos {
  value: number;
  cont_name: string;
  cont_code: string;
}

@Component({
  selector: 'app-cadena-auditoria-externa',
  templateUrl: './cadena-auditoria-externa.component.html',
  styleUrls: ['./cadena-auditoria-externa.component.css']
})


export class CadenaAuditoriaExternaComponent implements OnInit {

	month: Mes[] = [
    {value: undefined, nombre: '--'},
    {value: 1, nombre: 'Enero'},
    {value: 2, nombre: 'Febrero'},
    {value: 3, nombre: 'Marzo'},
    {value: 4, nombre: 'Abril'},
    {value: 5, nombre: 'Mayo'},
    {value: 6, nombre: 'Junio'},
    {value: 7, nombre: 'Julio'},
    {value: 8, nombre: 'Agosto'},
    {value: 9, nombre: 'Septiembre'},
    {value: 10, nombre: 'Octubre'},
    {value: 11, nombre: 'Noviembre'},
    {value: 12, nombre: 'Diciembre'},
  ];

  year: Anio[] = [
    {value: undefined, nombre: undefined},
    {value: 2016, nombre: 2016},
    {value: 2017, nombre: 2017},
    {value: 2018, nombre: 2018},
    {value: 2019, nombre: 2019},
    {value: 2020, nombre: 2020},
    {value: 2021, nombre: 2021},
    {value: 2022, nombre: 2022},
    {value: 2023, nombre: 2023},
    {value: 2024, nombre: 2024},
    {value: 2025, nombre: 2025},
  ];

  viewAudi: any[] = [500, 350];
  viewDistribucion: any[] = [1030, 400];  
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  barMaxWidth = 15;
  yAxisLabel = 'y';
  timeline = true;
  legendTitle = 'Año';
  legendPosition ="below";  
  yAxisLabelDist = "%";

  colorScheme = {
    domain: ['#3211AB']
  };
  //
  colorSchemeDistribucion = {
    domain: ['#7859EB','#FB886F','#F7FB7B','#78D35F','#C98ECF','#7FD9F7','#F2B25F','#B2EA70']
    // domain: ['#3211AB','#F02E04','#F9FF1C','#1F8303','#871292','#00AFE8','#EA8707','#539802']
  };

  public user_id = JSON.parse(sessionStorage.getItem('userData')).user_id;
  public clie_id = JSON.parse(sessionStorage.getItem('userData')).clie_id;
  public dataSourceCompromiso: any[] = [];
  public dataSourceCompromisoAnio: any[] = [];
  public dataSourceDistribucion: any[] = [];
  public shows_dashboards_compromiso = 0;
  public shows_dashboards_distribucion = 0;
   // filtro fecha y año
  public selectMonth = '';
  public selectYear = '';

  constructor(private valPermiso: ValidaPermisosService,
    private dashboard: DashboardService,
    private http:HttpClient,
    private route:Router,
    private notify: SnotifyService
    ) { 

// AUDITORIA EXTERNA
  	/*this.dashboard.get_dashboard_auditoria_total_month(this.user_id).subscribe(
        dato=>{                     
         
          this.dataSourceCompromiso = dato['datos_by_month'];          
          this.shows_dashboards_compromiso = 1; 
                             
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    });

    this.dashboard.get_dashboard_auditoria_total_anio(this.user_id).subscribe(
        dato=>{                     
         
          this.dataSourceCompromisoAnio = dato['datos_by_anio'];
          // this.shows_dashboards_compromiso = 1; 
                             
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    });

    this.dashboard.get_dashboard_auditoria_distribucion(this.user_id).subscribe(
        dato=>{                     
         
          this.dataSourceDistribucion = dato['distribucion'];
          this.shows_dashboards_distribucion = 1; 
                             
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    });*/
    // FIN AUDITORIA EXTERNA

  }

// AUDITORIA EXTERNA
 /* openCloseCollapseGraphiAnio(){
    // escondo los divs 
    $('#collapseGraficosMes').removeClass("show");
    $('#collapseGraficosDistriMes').removeClass("show");

    $('.botonCollapseGraphicAnio').addClass("active");
    $('.botonCollapseGraphicMes').removeClass("active");
    $('.botonCollapseGraphicDistriMes').removeClass("active");
  }*/

 /* openCloseCollapseGraphiMes(){
    // escondo los divs 
    $('#collapseGraficosAnio').removeClass("show");
    $('#collapseGraficosDistriMes').removeClass("show");

    $('.botonCollapseGraphicMes').addClass("active");
    $('.botonCollapseGraphicAnio').removeClass("active");
    $('.botonCollapseGraphicDistriMes').removeClass("active");
  }

  openCloseCollapseGraphicDistMes(){
    // escondo los divs 
    $('#collapseGraficosAnio').removeClass("show");
    $('#collapseGraficosMes').removeClass("show");

    $('.botonCollapseGraphicDistriMes').addClass("active");
    $('.botonCollapseGraphicAnio').removeClass("active");
    $('.botonCollapseGraphicMes').removeClass("active");

  }*/

 /*onChangeFilterMes(month){
    this.selectMonth = month;

      this.dashboard.flt_total_month(this.user_id,this.selectYear,month).subscribe(
        data=>{

        this.dataSourceCompromiso = data['datos_by_month'];          
        this.shows_dashboards_compromiso = 1; 

      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
      }) 

     this.dashboard.flt_distribucion(this.user_id,this.selectYear,month).subscribe(
        data=>{

        this.dataSourceDistribucion = data['distribucion'];
        this.shows_dashboards_distribucion = 1; 

      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
      }) 

  } 

  onChangeFilterYear(year){
    this.selectYear = year;

    this.dashboard.flt_total_anio(this.user_id,year).subscribe(
        data=>{

        this.dataSourceCompromisoAnio = data['datos_by_anio'];

    },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  

    this.dashboard.flt_total_month(this.user_id,this.selectYear,this.selectMonth).subscribe(
        data=>{

        this.dataSourceCompromiso = data['datos_by_month'];          
        this.shows_dashboards_compromiso = 1; 

    },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    }) 

    this.dashboard.flt_distribucion(this.user_id,this.selectYear,this.selectMonth).subscribe(
        data=>{

        this.dataSourceDistribucion = data['distribucion'];
        this.shows_dashboards_distribucion = 1; 

    },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    }) 

  } */

  /*filter(){
    this.shows_dashboards_compromiso=0;
    this.shows_dashboards_distribucion=0;

     


    if(this.selectYear != ''){

         
    }

  }*/
  // FIN AUDITORIA EXTERNA


  ngOnInit() {
  }

}
