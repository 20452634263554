import { Component, OnInit, Renderer2, Input, Output, TemplateRef, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { VisitasService } from '../../services/visitas.service';
import { NotaVentaService } from '../../services/nota-venta.service';
import { ClientsOptionsService } from '../../services/clients-options.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { PageEvent } from '@angular/material/paginator';
//
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { listLocales } from 'ngx-bootstrap/chronos';
import { SnotifyService } from 'ng-snotify';

import { ActivatedRoute, Router } from "@angular/router";
import * as $ from 'jquery';
import * as moment from 'moment';

export interface Visits{
  acti_id: number;
  acti_assign: Date;
  fecha_realizacion: Date;
  servicio: string;
  acti_status: string;
}


@Component({
  selector: 'app-visitas',
  templateUrl: './visitas.component.html',
  styleUrls: ['./visitas.component.css']
})

export class VisitasComponent implements OnInit {

  public modalSubidaArchivo: BsModalRef;
  public modalVerArchivo: BsModalRef;
  public config = {
    backdrop: true , //Fondo oscuro 
    ignoreBackdropClick: true, //para que no se cierre al clickear fuera del formulario 
    class: 'modal-lg',
    keyboard: false //para que no se cierre al apretar escape 
  };  
  public elemact;
  public doc;
  public comp_id;
  public loading = 0;
  public loadingUpload = -1;
  public loadingData = 0;
 
  //paginacionModal
  public paginaModal: number = 1;
  public porPaginaModal: number = 5; 
  public elemactModal;

  // filtros
  public clie_id=JSON.parse(localStorage.getItem('userData')).clie_id;
  public user_id = JSON.parse(localStorage.getItem('userData')).user_id;
  public rety_name="";
  public repositories_type;

  // reportes
  public tipoReport;

  //obtener contractos y linea de negocio 
  public cont_id="";

  //subida de archivo
  fileToUpload: File = null;
  public id;
  public btype=0;  
  public report_type;
  public nombre;
  public subiendo=0;
  public ok=0;
  public dataFile:FormData= new FormData();
  public sub_type_id;
  public flagwork=0;
  public subtype=null;
  public subtypeflag=0;
  public sub_type_selected;
  public asgt_id;
  public acti_status;

  //fecha  
  public fechaFilterINI; 
  public fechaFilterFIN;   

  public locale = 'es';
  data_fecha: Date[];
  data_fecha_bill: Date[];  
  public segty_id=localStorage.getItem('segty');
  public modulo="";

  //descripcion del archivo
  public description;
  public cust_id;
 
 //para validar el mensaje de sin archivos o sin datos, ademas del cargando datos  
 // -1 -> estado inicial / 1 -> con datos / 2 o 0 -> estado de sin datos 
  public elementsData = -1; 

  //nota venta
  public view_component_nv=-1;
  public URL;
  public timezone;
  public cont_name;
  public fecha_visita='';// busca las visitas a partir de esta fecha en adelante

  public prof_opt='';

  elements: any = [];
  //headElements = ['ID','Asignacion', 'Realizacion',' Servicios', 'Estado', 'asgt_id'];
  displayedColumns: string[] = ['acti_id','acti_assign', 'fecha_realizacion', 'tipo_visita', 'servicio' ,'acti_status', 'acciones'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource;
  pageSizeOptions: number[] = [5, 10, 20];
  public sorting;
  
  
  constructor(
  	private valPermiso: ValidaPermisosService,
  	private datos:VisitasService, 
  	private datosNV:NotaVentaService,
  	private clie_opt:ClientsOptionsService,
    private http:HttpClient,
    private renderer:Renderer2,
    private localeService: BsLocaleService,
    private route:Router,
    private notify: SnotifyService,
    private modalService: BsModalService) {

	  	/*this.route.navigateByUrl(this.route.url);*/
	   moment().locale('es');   
	   defineLocale('es', esLocale); 
	   this.cont_name=localStorage.getItem('cont_nom');
	   this.cont_id=this.route.url.split('/')[4];
	   this.cust_id=this.route.url.split('/')[2];
	   this.URL=this.route.url.replace('visita','visita_nv');
	   //this.URL=this.route.url;
	   	
	    //obtener el modulo
	    this.modulo=this.route.url.split('/')[(this.route.url.split('/').length)-1];

	    this.localeService.use(this.locale);   

	    this.elements.length = 0;
	    this.loadingData = 0;
	    this.elementsData = -1;

	    this.clie_opt.get_options(this.clie_id).subscribe(
	      data=>{
	      	this.timezone = data['app_timezone']; 	

	      	this.datos.get_profiles_options(this.user_id).subscribe(
	  		data=>{
	  			
	  			if(data == undefined || data == null){ 
	  				this.prof_opt=''; 
	  			}else{
	  				this.prof_opt = data['initial_date_viewing_visits'];
	  			}
	  			//console.log(this.prof_opt);

	  			this.datos.get_activities(this.clie_id,this.cont_id,this.segty_id,this.timezone,this.prof_opt).subscribe(
			  		data=>{
			  			this.doc=data;
			       		this.loadingData = 1;        
			        if(this.doc.length === 0){
			              this.elementsData = 0;
			        }else{
			           this.elementsData = 1;
			        }

			        this.paginacion(data);

			  		},error=>{
			  			console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
						this.elements.length = 0;
			    		this.loadingData = 0;
			    		this.elementsData = -1;
					})

			},error=>{
				console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
				this.elements.length = 0;
			    this.loadingData = 0;
			    this.elementsData = -1;
			})

	    },error=>{
	    	console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
			this.elements.length = 0;
	    	this.loadingData = 0;
	    	this.elementsData = -1;
		})	      	

    }

    openModalSubidaArchivo(id,template: TemplateRef<any>) {
	    this.asgt_id=id;
	    this.loading=0;     
	    this.list_report_type(id);
	    this.modalSubidaArchivo = this.modalService.show(template, this.config);
  	}

  	openModalVerArchivo(id,template: TemplateRef<any>) {
	    this.asgt_id=id;
	    this.loading=0;     
	    this.get_docs_by_visit(id)
	    this.modalVerArchivo = this.modalService.show(template, this.config);	    
  	}

 	closeModalSubidaArchivos(){            

	    this.elements.length = 0;
	    this.elementsData = 1;
	    this.description=''; 
	    //this.uploadFile.nativeElement.value = "";
	    $('#filename').val('');
	    this.modalSubidaArchivo.hide();
  	}

  	closeModalVerArchivos(){ 

	    this.elements.length = 0; 
	    this.elementsData = -1;    
	    this.modalVerArchivo.hide();
 	}

 	 // para que obtenga el valor de la linea de negocio cuando se selecciona una y despues otra.
  	obtener_acti_by_linea_negocio(){
	    // valida si el dato guardado en el localstorage es distinto del actual, si no recarga la tabla    
	    if(localStorage.getItem("segty")!=this.segty_id){

	      this.segty_id=localStorage.getItem("segty");

	      this.elements.length = 0;
	      this.loadingData = 0;
	      this.elementsData = -1;

	      this.datos.get_activities(this.clie_id,this.cont_id,this.segty_id,this.timezone,this.prof_opt).subscribe(
	      data=>{
	        this.doc=data;        
	        this.loadingData = 1;

	        if(this.doc.length === 0){
	              this.elementsData = 0;
	        }else{
	           this.elementsData = -1;
	        }

	        this.paginacion(data); 
	        // this.dataSource.data = this.elements as Visits[];

	      },error=>{console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
			this.elements.length = 0;
	    	this.loadingData = 0;
	    	this.elementsData = -1;
		})   
	      return false;

	    }else{      
	      return true;
	    }
  	}

  	onValueChangeDatetime(value: Date[]): void {
	    this.data_fecha = value;
	    this.fechaFilterINI='';
	    this.fechaFilterFIN='';
	    this.fechaFilterINI = moment(this.data_fecha[0]).format('YYYY-MM-DD');
	    this.fechaFilterFIN = moment(this.data_fecha[1]).format('YYYY-MM-DD');    
  	}

  	onChangeFilter(){    

	    if(this.fechaFilterINI === "Invalid date"){this.fechaFilterINI =""; }    
	    if(this.fechaFilterFIN === "Invalid date"){this.fechaFilterFIN ="";}
	    
	    if(this.fechaFilterINI > this.prof_opt){
	    	console.log('elija otra fecha');
	    }

	    this.elements.length = 0;
	    this.loadingData = 0;
	    this.elementsData = -1;

	    this.datos.acti_filter(this.clie_id,this.acti_status,this.fechaFilterINI,this.fechaFilterFIN,this.cont_id,this.segty_id,this.timezone,this.prof_opt).subscribe(
	      data=>{
	        this.doc=data;
	        this.elements.length = 0;//
	        this.loadingData = 1;

	        if(this.doc.length === 0){
	              this.elementsData = 0;
	        }else{
	           this.elementsData = -1;
	        }  
	        this.paginacion(data);

	      },error=>{console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
			this.elements.length = 0;
	    	this.loadingData = 0;
	    	this.elementsData = -1;
		})  
  	}

	// paginacion
  	paginacion(arg){        

    
    this.dataSource = new MatTableDataSource(arg);
    this.dataSource.sort = this.sort;
  	this.dataSource.paginator = this.paginator;

  	this.dataSource.sortingDataAccessor = (item, property) => {
		  switch (property) {
		    case 'acti_assign': return new Date(item.acti_assign);
		    case 'fecha_realizacion': return new Date(item.fecha_realizacion);		   
		    default: return item[property];
		  }
		};
  	
	    /*for (var _i = 0; _i < arg.length; _i++) {
	      var num =  arg[_i];
	      this.elements.push({ ID: arg[_i]['acti_id'], Asignacion: arg[_i]['acti_assign'], Realizacion: arg[_i]['fecha_realizacion'], Servicios:arg[_i]['segr_name'], Estado: arg[_i]['acti_status'], asgt_id: arg[_i]['asgt_id'] });
	      //console.log(num);
	    }    */
	   
  	}  
  	// fin paginacion

 	 // relleno del select
  	list_report_type(id){    
	    this.ok=0;
	    this.datos.getTypeReport().subscribe(
	      data=>{
	        this.tipoReport=data.rety_name;
	        this.comp_id=data.comp_id[0].comp_id;
	        this.loading=1;
	        this.asgt_id=id;

	      },error=>{console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
			this.loading=2;
		})
  	}

  	// llamado a todos los documentos de la nota de venta por tipo

  	//subida de archivos al s3
    handleFileInput(files: FileList) {
    
	    this.nombre=((files.item(0).name).split("."))[0];
	    for (var i = 0; i < files.length; i++) {
	      this.dataFile.append(i.toString(), files.item(i), files[i].name);
	    }
	     
	    this.fileToUpload = files.item(0);
 	}


  	uploadFileToActivity() {

	    this.loadingUpload = 0;
	    this.dataFile.append('comp_id',this.comp_id);
	    this.dataFile.append('subtype',this.subtype);
	    this.dataFile.append('clie_id',this.clie_id);
	    this.dataFile.append('cont_id',this.cont_id);
	    this.dataFile.append('cust_id',this.cust_id);
	    this.dataFile.append('woit_id', '');
	    this.dataFile.append('asgt_id',this.asgt_id);
	    this.dataFile.append('desc',this.description);

	    this.dataFile.append('token',localStorage.getItem('token'));
	    this.subiendo=1;
	    this.ok=0;
	    //this.renderer.setProperty(this.progreso.nativeElement, 'style', 'width:55%;');
	    // se sube al s3
	    this.datos.postFile(this.dataFile).subscribe(data => {
	      //this.renderer.setProperty(this.progreso.nativeElement, 'style', 'width:100%;');
	          this.loadingUpload = 1;     
	          this.subiendo=0;
	          this.ok=1;

	    }, error => {
	      console.log(error);
	      this.loadingUpload = 1; 
	      this.subiendo=0;
	      this.ok=2;
	    });
        
  	}

  	// llamado a todos los documentos de la visita por tipo
  	get_docs_by_visit(id){
	    this.asgt_id=id;
	    this.loading=0; 
	    
	    this.datos.get_visits_s3(this.asgt_id).subscribe(
	          data=>{
	            //this.paginacionModal(data);   
	            this.elemactModal = data                  
	            this.loading=1;

	    },error=>{console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
			this.loading=2;
		})
    
  	}   

  	change(type){
	    console.log(type);
	    this.flagwork=0;
	    this.subtype=undefined;
	    this.subtypeflag=0;
	    this.datos.getSubType(type).subscribe(data=>{
	      this.subtypeflag=2;// no quiero que se mueste el sub tipo de repositories
	      this.sub_type_id=data;
	      this.subtype=data[0]['resuty_id'];

	    },error=>{console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
			this.subtypeflag=2;
		});   
  	}

  	viewNV(acti_id,asgt_id){
  		//this.view_component_nv = 1;
  		localStorage.setItem("activities",acti_id);
  		localStorage.setItem("asgt_id",asgt_id);
  	}

  	validaPermisos(permiso){
    	return this.valPermiso.validaPermisos(permiso);
 	}

  	//
  	@ViewChild(MatSort) set content(content: ElementRef) {
	  this.sorting = content;
	  if (this.sorting){
	     this.dataSource.sort = this.sorting;

	  }
	}

 
  	ngOnInit() {  		

  	}




}
