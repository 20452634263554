import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewChecked, EventEmitter, Output, TemplateRef, Input } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap';
import { FormsService } from 'src/app/services/forms.service';
import { SnotifyService } from 'ng-snotify';
import { Control } from 'src/app/models/control.model';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { MapComponent } from '../leaflet/map/map.component';

@Component({
  selector: 'ServiceModal',
  templateUrl: './service-modal.component.html',
  styleUrls: ['./service-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceModalComponent implements OnInit
{
    public modalConfig = {
      backdrop: true , //Fondo oscuro 
      ignoreBackdropClick: true, //para que no se cierre al clickear fuera del formulario 
      class: 'modal-lg',
      keyboard: false, //para que no se cierre al apretar escape 
    //   scrollable: true,
    //   centered: true
    };

    public id;
    public controls;
    public isLoaded = false;

    @Input() map:MapComponent;

    @Output('loading') loadingEvent = new EventEmitter;
    @Output('ready') readyEvent = new EventEmitter;

    @ViewChild('autoShownModal'/* , { static: false } */) modal: ModalDirective;

    @ViewChild('template') template :ElementRef;


    constructor(
        private detector     :ChangeDetectorRef, 
        private formService  :FormsService,
        private notify       :SnotifyService,
        private selfReference: ElementRef )
    {
    }


    ngOnInit()
    {}
    
    show(id)
    {
        this.id = id;
        this.isLoaded = false;
        // this.modal.show();
        // this.showEvent.emit();
        this.refresh();

        console.log("HTML!!! => ",HTML,this.template,this.selfReference)
        var HTML = this.getSelfHTML()//$("#da-modal").html();
        this.loadingEvent.emit({title:'Servicio: '+this.id,content: HTML});
        // var modal = this.map.showModal({
        //     title: 'SERVICIO :'+this.id,
        //     content: HTML 
        // });

        this.formService.getFormByServiceAssetId(this.id).subscribe(
            data => {
                this.controls = data['controls'];
                this.isLoaded = true;
                this.refresh();
                console.log('abriendo modal')
                
                let HTML = this.getSelfHTML()//$("#da-modal").html();
                this.readyEvent.emit({title:'Servicio: '+this.id,content: HTML});

                // modal.content(HTML);
                // this.map.updateModalContent(HTML);

            }, error => {
                this.notify.error( 'Hubo un error al buscar el servicio ' + error )
                this.close();
            }
        )
    }

    refresh()
    {
        console.log('refreshing service modal...');
        this.detector.detectChanges();
    }


    close()
    {
        this.modal.hide();
    }

    getSelfHTML()
    {
        $("#service-modal").show();
        return document.getElementById('service-modal')
        // return this.selfReference.nativeElement.innerHTML.replace('style="display:none;"','');
    }
}


// <!-- <ng-template #template>
//     <div class="modal-header">
//         <h4 class="modal-title">Informacion del Servicio: {{id}}</h4>
//         <button type="button" class="close" aria-label="Close" (click)="close()">
//             <span aria-hidden="true">&times;</span>
//         </button>
//     </div>
//     <div class="modal-body">


//     </div>

// </ng-template> -->


// <!-- *ngIf="isModalShown==true" -->
// <div  [config]="modalConfig"  bsModal #autoShownModal="bs-modal"
//      class="modal fade" tabindex="-1" role="dialog">
//     <div class="modal-dialog modal-lg">
//         <div class="modal-content">

//             <div class="modal-header">

//                 <h4 class="modal-title">Informacion del Servicio: {{id}}</h4>
//                 <button type="button" class="close" aria-label="Close" (click)="close()">
//                     <span aria-hidden="true">&times;</span>
//                 </button>
                
//             </div>
//             <div class="modal-body">

//                 <div *ngIf="isLoaded==false" class="d-flex justify-content-center">
//                     <button mdbBtn type="button" disabled style="padding-top: 15%; padding-bottom: 15%; background:none; color: inherit; border: none;">
//                     <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//                     Cargando...
//                     </button>
//                 </div>

//                 <ng-container *ngIf="isLoaded==true">
//                     <!-- <h4>Formulario de Articulos</h4> -->
//                     <div class="row" *ngFor="let control of controls; let i = index">
//                         <div class="col-lg-4">{{ control.name }}</div>
//                         <div class="col-lg-8" *ngIf="control.type=='CAMERA'">
//                             CAMERA
//                         </div>
            
//                         <!-- Se muestran las distintas respuestas para el agregador -->
//                         <div class="col-lg-8" *ngIf="control.type=='AGGREGATOR'">
//                             <div class="card" *ngFor="let answer of control.value; let n = index">
//                                 <div class="card-header">
//                                     <a  class="btn btn-link"
//                                     aria-controls="collapseExample"
//                                     role="button"
//                                     data-toggle="collapse"
//                                     href="#service-aggregator-{{ i }}-{{ n }}">
//                                     <i class="fas fa-chevron-down" aria-hidden="true"></i>
//                                     {{ n }}
//                                 </a>
//                                 </div>
//                                 <div id="service-aggregator-{{ i }}-{{ n }}" class="collapse card-body">
//                                     <div 
//                                     class="row" 
//                                     *ngFor="let subcontrol of answer">
//                                         <div class="col-lg-4">{{ subcontrol.name }} :</div>
//                                         <div class="col-lg-8">{{ subcontrol.value }}</div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
            
//                         <!-- Control normal con solo texto -->
//                         <div class="col-lg-8" *ngIf="  !['AGGREGATOR','CAMERA'].includes(control.type) ">
//                             {{ control.value }}
//                         </div>
//                     </div>
//                 </ng-container>

//             </div>

//             <!-- <div class="modal-footer" *ngIf="isLoaded==true">
//                 <button type="button" class="btn btn-warning" (click)="close()">Cancelar</button>
//             </div> -->

//         </div> <!-- modal-content -->
//     </div> <!-- modal-dialog -->
// </div>