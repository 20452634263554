import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  baseUrl: string;

  constructor(private http:HttpClient) { 
  	this.baseUrl = environment.apiUrl;
  }

  getTasksPermission(): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'api/getDashboardTasksPermission',{'token':sessionStorage.getItem('token')});       
  }

  get_dashboard_group(user_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_dashboard_group',{user_id: user_id,'token':sessionStorage.getItem('token')});       
  }

  // PRESENCIA DE PLAGAS
  get_graphic_data(user_id,type): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_graphic_data',{user_id:user_id,type:type,'token':sessionStorage.getItem('token')});       
  }  

  get_contracts_profile(user_id,dashboardStyle): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_contracts_profile',{user_id: user_id,dashboardStyle:dashboardStyle,'token':sessionStorage.getItem('token')});       
  }

  get_graphic_filters(type,filters): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_graphic_data_filters',{type:type,filters:filters,'token':sessionStorage.getItem('token')});       
  }

  get_graphic_data_riesgo(user_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_graphic_data_riesgo',{user_id:user_id,'token':sessionStorage.getItem('token')});       
  }

  get_riesgo_filters(filters): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'riesgo_filters',{filters:filters,'token':sessionStorage.getItem('token')});       
  }

  //AUDITORIA EXTERNA

  get_dashboard_auditoria_total_month(user_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_dashboard_auditoria_total_month',{user_id:user_id,'token':sessionStorage.getItem('token')});       
  }

  get_dashboard_auditoria_total_anio(user_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_dashboard_auditoria_total_anio',{user_id:user_id,'token':sessionStorage.getItem('token')});       
  }

  get_dashboard_auditoria_distribucion(user_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_dashboard_auditoria_distribucion',{user_id:user_id,'token':sessionStorage.getItem('token')});       
  }

  // filtros auditoria

  flt_total_month(filters): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'flt_total_month',{filters:filters,'token':sessionStorage.getItem('token')});       
  }

  flt_total_anio(filters): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'flt_total_anio',{filters:filters,'token':sessionStorage.getItem('token')});       
  }

  flt_distribucion(filters): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'flt_distribucion',{filters:filters,'token':sessionStorage.getItem('token')});       
  }

  //ACTIVIDAD ROEDORES
  get_dashboard_act_roedores(user_id,ubicacion): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_dashboard_act_roedores',{user_id:user_id,ubicacion:ubicacion,'token':sessionStorage.getItem('token')});       
  }

  get_dashboard_captura(user_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_dashboard_acti_roedores_captura',{user_id:user_id,'token':sessionStorage.getItem('token')});       
  }

  get_dashboard_consumo(user_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_dashboard_acti_roedores_consumo',{user_id:user_id,'token':sessionStorage.getItem('token')});       
  }
        // FILTROS ACTIVIDAD ROEDORES
  flt_dashboard_act_roedores(user_id,ubicacion,year,month,cont_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'flt_dashboard_act_roedores',{user_id:user_id,ubicacion:ubicacion,year:year,month:month,cont_id:cont_id,'token':sessionStorage.getItem('token')});       
  }

  flt_dashboard_captura(user_id,year,month,cont_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'flt_dashboard_acti_roedores_captura',{user_id:user_id,year:year,month:month,cont_id:cont_id,'token':sessionStorage.getItem('token')});       
  }

  flt_dashboard_consumo(user_id,year,month,cont_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'flt_dashboard_acti_roedores_consumo',{user_id:user_id,year:year,month:month,cont_id:cont_id,'token':sessionStorage.getItem('token')});       
  }

  // SATURACION TUV
  get_tuv_semanal(user_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'tuv_semanal',{user_id:user_id,'token':sessionStorage.getItem('token')});       
  }               

  get_tuv_mensual(user_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'tuv_mensual',{user_id:user_id,'token':sessionStorage.getItem('token')});       
  }
  
  get_tuv_semanal_plaga(user_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'tuv_semanal_plaga',{user_id:user_id,'token':sessionStorage.getItem('token')});       
  }

      // FILTROS tuv

  flt_tuv_semanal(user_id,year,month,cont_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'flt_tuv_semanal',{user_id:user_id,year:year,month:month,cont_id:cont_id,'token':sessionStorage.getItem('token')});       
  }

  flt_tuv_mensual(user_id,year,month,cont_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'flt_tuv_mensual',{user_id:user_id,year:year,month:month,cont_id:cont_id,'token':sessionStorage.getItem('token')});       
  }

  flt_tuv_semanal_plaga(user_id,year,month,cont_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'flt_tuv_semanal_plaga',{user_id:user_id,year:year,month:month,cont_id:cont_id,'token':sessionStorage.getItem('token')});       
  }

  addCommentDashboard(dashboard_name,comentario): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'addCommentDashboard',{dashboard_name:dashboard_name,comentario:comentario,'token':sessionStorage.getItem('token')});       
  }

//listar comentario
  listCommentDashboard(dashboard_tag,user_id,year,month,cont_id): Observable <any[]>{
     console.log('tag para comentarios...............'+dashboard_tag+' cont_id: '+cont_id+' user_id: '+user_id+' month: '+month+' year: '+year+'')
     return this.http.post<any[]>(this.baseUrl+'listCommentDashboard',{dashboard_tag:dashboard_tag,user_id:user_id,year:year,month:month,cont_id:cont_id,'token':sessionStorage.getItem('token')});       
  }


  // EDITAR COMENTARIO DE LOS DASHBOARD
  editCommentActiRoeMesZona(user_id,acrosemezo_id,consumo,captura,novedad,factor_riesgo,accion_rentokil): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'editCommentActiRoeMesZona',{user_id:user_id,acrosemezo_id:acrosemezo_id,consumo:consumo,captura:captura,novedad:novedad,factor_riesgo:factor_riesgo,accion_rentokil:accion_rentokil,'token':sessionStorage.getItem('token')});       
  }

  editCommentCompAnualCapturaMes(user_id,id,comentario): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'editCommentCompAnualCapturaMes',{user_id:user_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
  }

  editCommentCompAnualConsumoMes(user_id,id,comentario): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'editCommentCompAnualConsumoMes',{user_id:user_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
  }

  editCommentTUVSemanaPlaga(user_id,id,novedad,factor_riesgo,accion_rentokil): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'editCommentTUVSemanaPlaga',{user_id:user_id,id:id,novedad:novedad,factor_riesgo:factor_riesgo,accion_rentokil:accion_rentokil,'token':sessionStorage.getItem('token')});       
  }

  editCommentTUVSemanal(user_id,id,comentario): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'editCommentTUVSemanal',{user_id:user_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
  }

  editCommentTUVMensual(user_id,id,comentario): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'editCommentTUVMensual',{user_id:user_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
  }

  listAllCommentTask(cont_id,task_page): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'listAllCommentTask',{cont_id:cont_id,task_page:task_page,'token':sessionStorage.getItem('token'),'showTasksMonthsAgo': sessionStorage.getItem('showTasksMonthsAgo')});       
  }
  // COMPLETAR TAREAS
  completeTaskActiRoeMesZona(user_id,daphagrata_id,acrosemezo_id,consumo,captura,novedad,factor_riesgo,accion_rentokil): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'completeTaskEditActiRoeMesZona',{user_id:user_id,daphagrata_id:daphagrata_id,acrosemezo_id:acrosemezo_id,consumo:consumo,captura:captura,novedad:novedad,factor_riesgo:factor_riesgo,accion_rentokil:accion_rentokil,'token':sessionStorage.getItem('token')});       
  }

  completeTaskCompAnualCapturaMes(user_id,daphagrata_id,id,comentario): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'completeTaskEditCompAnualCapturaMes',{user_id:user_id,daphagrata_id:daphagrata_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
  }

  completeTaskCompAnualConsumoMes(user_id,daphagrata_id,id,comentario): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'completeTaskEditCompAnualConsumoMes',{user_id:user_id,daphagrata_id:daphagrata_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
  }

  completeTaskTUVSemanaPlaga(user_id,daphagrata_id,id,novedad,factor_riesgo,accion_rentokil): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'completeTaskEditTUVSemanaPlaga',{user_id:user_id,daphagrata_id:daphagrata_id,id:id,novedad:novedad,factor_riesgo:factor_riesgo,accion_rentokil:accion_rentokil,'token':sessionStorage.getItem('token')});       
  }

  completeTaskRTUSemanaPlaga(user_id,daphagrata_id,id,comentario): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'completeTaskEditRTUSemanaPlaga',{user_id:user_id,daphagrata_id:daphagrata_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
  }
  completeTaskMSTSemanaPlaga(user_id,daphagrata_id,id,comentario): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'completeTaskEditMSTSemanaPlaga',{user_id:user_id,daphagrata_id:daphagrata_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
  }

  completeTaskTUVSemanal(user_id,daphagrata_id,id,comentario): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'completeTaskEditTUVSemanal',{user_id:user_id,daphagrata_id:daphagrata_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
  }

   completeTaskRTUSemanal(user_id,daphagrata_id,id,comentario): Observable <any[]>{
      return this.http.post<any[]>(this.baseUrl+'completeTaskEditRTUSemanal',{user_id:user_id,daphagrata_id:daphagrata_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
   }

   completeTaskMSTSemanal(user_id,daphagrata_id,id,comentario): Observable <any[]>{
      return this.http.post<any[]>(this.baseUrl+'completeTaskEditMSTSemanal',{user_id:user_id,daphagrata_id:daphagrata_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
   }

  completeTaskTUVMensual(user_id,daphagrata_id,id,comentario): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'completeTaskEditTUVMensual',{user_id:user_id,daphagrata_id:daphagrata_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
  }

  completeTaskRTUMensual(user_id,daphagrata_id,id,comentario): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'completeTaskEditRTUMensual',{user_id:user_id,daphagrata_id:daphagrata_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
   }
   completeTaskMSTMensual(user_id,daphagrata_id,id,comentario): Observable <any[]>{
      return this.http.post<any[]>(this.baseUrl+'completeTaskEditMSTMensual',{user_id:user_id,daphagrata_id:daphagrata_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
   }


  listGraphicTittle(): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'listGraphicTittle',{'token':sessionStorage.getItem('token')});       
  }

  getSpeciesGroups(filters): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'getSpeciesGroups',{filters:filters, 'token':sessionStorage.getItem('token')});       
  }

  getOtherSpeciesGraphicData(filters): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'getOtherSpeciesGraphicData',{filters:filters, 'token':sessionStorage.getItem('token')});       
  }

  getTuvSaturationBySpeciesGroup(years,months,contracts): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'getTuvSaturationBySpeciesGroup',{years: years, months: months, contracts: contracts, 'token':sessionStorage.getItem('token')});       
  }

  getContractName(contId): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'getContractName',{contId: contId, 'token':sessionStorage.getItem('token')});       
  }

  getProperties(): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'api/getProperties',{relatedTo:['CONTRACTS'], 'token':sessionStorage.getItem('token')});       
  }

  //FILTROS Feromonas
  getFeromonasMstBySpeciesGroup(years,months,contracts): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'getFeromonasMstBySpeciesGroup',{years: years, months: months, contracts: contracts,'token':sessionStorage.getItem('token')});       
 }
 
 flt_feromona_mst_semanal(user_id,year,month,cont_id): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'flt_dashboard_feromonas_mst_semanal',{user_id:user_id,year:year,month:month,cont_id:cont_id,'token':sessionStorage.getItem('token')});       
 }

 flt_feromona_mst_mensual(user_id,year,month,cont_id): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'flt_dashboard_feromonas_mst_mensual',{user_id:user_id,year:year,month:month,cont_id:cont_id,'token':sessionStorage.getItem('token')});       
 }

 getFeromonasRtuBySpeciesGroup(years,months,contracts): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'getFeromonasRtuBySpeciesGroup',{years: years, months: months, contracts: contracts,'token':sessionStorage.getItem('token')});       
 }
 
 flt_feromona_rtu_semanal(user_id,year,month,cont_id): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'flt_dashboard_feromonas_rtu_semanal',{user_id:user_id,year:year,month:month,cont_id:cont_id,'token':sessionStorage.getItem('token')});       
 }

 flt_feromona_rtu_mensual(user_id,year,month,cont_id): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'flt_dashboard_feromonas_rtu_mensual',{user_id:user_id,year:year,month:month,cont_id:cont_id,'token':sessionStorage.getItem('token')});       
 }

 editCommentRTUSemanaPlaga(user_id,id,comentario): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'editCommentRTUSemanaPlaga',{user_id:user_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
 }

 editCommentRTUSemanal(user_id,id,comentario): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'editCommentRTUSemanal',{user_id:user_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
}

editCommentRTUMensual(user_id,id,comentario): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'editCommentRTUMensual',{user_id:user_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
}

 editCommentMSTSemanaPlaga(user_id,id,comentario): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'editCommentMSTSemanaPlaga',{user_id:user_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
 }

 editCommentMSTSemanal(user_id,id,comentario): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'editCommentMSTSemanal',{user_id:user_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
}

editCommentMSTMensual(user_id,id,comentario): Observable <any[]>{
   return this.http.post<any[]>(this.baseUrl+'editCommentMSTMensual',{user_id:user_id,id:id,comentario:comentario,'token':sessionStorage.getItem('token')});       
}

}
