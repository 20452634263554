import { Component, OnInit } from '@angular/core';
import { JarwisService } from 'src/app/services/jarwis.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.css']
})
export class RequestResetComponent implements OnInit {

  public error = null;
  public form= {
    email: null
  };

  constructor(
    private Jarwis: JarwisService, 
    private notify: SnotifyService
    ) { }

  ngOnInit() {
  }

  onSubmit(){
    this.notify.info('Espere un momento...', {timeout: 10000});
    this.Jarwis.sendPasswordResetLink(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(res){
    this.notify.success(res.data, {timeout: 5000});
    this.form.email = null;
  }

  handleError(error){
    this.error = null;
    if(error.error.error){
      this.error = error.error.error;
    }else{
      this.error = error.error.errors.email;
    }
    this.notify.error(this.error, {timeout: 5000});
  }

}
