import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

// servicio para llamar al observable con el segtyId
import { ReloadComponentsService } from '../../services/reload-components.service';


@Component({
  selector: 'app-plugins',
  templateUrl: './plugins.component.html',
  styleUrls: ['./plugins.component.css']
})
export class PluginsComponent implements OnInit {
	plugin="";
  number: number; // usando esta variable funciona, pero pinchando un boton.

  numberServ = this.reloadService.updatedSegtyID$;
  	
  constructor(private route: ActivatedRoute, private router:Router, private reloadService: ReloadComponentsService) {
    this.reloadService.updatedSegtyID$.subscribe(
      data => {
        console.log(`PluginsComponent on callback from reloadService.updated.suscribe: ${data}`);
        this.number = Math.random();
      }
    );
  }



  // make funtcion that listens to changes on numberServ and console.log it on every change
  // wathcer(){
  //   this.reloadService.updatedSegtyID$.subscribe(
  //     data => {
  //       console.log(`PluginsComponent on callback from reloadService.updated.suscribe: ${data}`);
  //       this.number = Math.random();
  //     }
  //   );
  // }


  comparar(Actual){
  	// console.log('Actual a comparar');
    // console.log(Actual);
  	if(Actual==this.plugin){

  		return true;

  	}
  	else{
  		return false;
  	}


  }

  updateNumber(): void {
    console.log('updateNumber()')
    this.number = Math.random();
  }


  ngOnInit() {

  		this.route.paramMap.subscribe(params => {
    	  this.plugin = params.get("modulo");    
        console.log('this.plugin');
        console.log(this.plugin);
  		})
  }

}
