import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ControlWrapper',
  templateUrl: './control-wrapper.component.html',
  styleUrls: ['./control-wrapper.component.css']
})
export class ControlWrapperComponent /* implements OnInit */ {
  
  @Input() title :string = null;
  @Input() classes :string; 
  @Input() hint :string = null; 
  @Input() icon :string = null;

  constructor() { }

  // ngOnInit() {

  // }

}
