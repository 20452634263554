import { Component, OnInit, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { RecommendationService } from '../../services/recommendation.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { RecommendationFormComponent } from './recommendation-form/recommendation-form.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SnotifyService } from 'ng-snotify';
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { getMonth } from 'ngx-bootstrap';
import { DatepickerComponent } from './../controls/datepicker/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import * as XLSX from 'xlsx';

import { saveAs } from 'file-saver/';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.css']
})
export class RecommendationComponent implements OnInit {



  //configuracion del modal
  public modalConfig = {
    backdrop: true, //Fondo oscuro 
    ignoreBackdropClick: true, //para que no se cierre al clickear fuera del formulario 
    class: 'modal-lg',
    keyboard: false //para que no se cierre al apretar escape 
  };
  public services = null;
  public activeTab = null;
  public contractId = null;
  public formId = null;
  public recommendations = null;
  public recommendationsHeaders = [];
  public loading = false;
  public recommendationsTitle = '';
  public recommendationsTable;
  public contractsTable = null;
  public displayedColumns = [];
  public displayedColumnsContracts = ['code', 'name', 'nOpen', 'nClosed', 'nAll', 'options'];

  public showTable = false;
  public pageSizeOptions: number[] = [15, 25, 50, 75];
  public page = 1;
  public nRecom;
  public recomListSize;
  public filterSource = {
    clients: []
    , contracts: []
    , status: []

  };
  public contractsTableSource = [];
  public showContracts = false;
  public showContractsTable = false;
  public selectClient: any;
  public selectContract: any;
  public selectStatus: any;
  public selectedContracts: any;
  public selectPriority: any;
  public selectDates = {
    detection: ''
    , verification: ''
  };
  public contractsDataSource = [];
  public segtyId;
  public recommendationFormData: any;
  public recommendationFormModal = null;


  public headerLabels = [];
  //tabla recomendaciones
  @ViewChild('tableSort', { read: MatSort }) tableSort: MatSort;
  //paginador recomendaciones
  @ViewChild('taskPaginator', { read: MatPaginator }) taskPaginator: MatPaginator;
  //tabla contratos
  @ViewChild('contractsSort', { read: MatSort }) contractsSort: MatSort;
  //paginador contratos
  @ViewChild('contractsPaginator', { read: MatPaginator }) contractsPaginator: MatPaginator;
  @ViewChild('recommendationForm') recommendationForm: RecommendationFormComponent;

  public clieId = JSON.parse(localStorage.getItem('userData')).clie_id;

  public ignoredColumns = ['related_id', 'related_table', 'cont_id'];
  public initialDate = null;
  constructor(
    private rfService: RecommendationService,
    private modalService: BsModalService,
    private validationService: ValidaPermisosService,
    private notify: SnotifyService,
  ) {
    console.log('recommendation starting');
  }

  ngOnInit() {

    console.log('recommendation starting');

    this.rfService.getServices(this.clieId).subscribe(
      data => {
        this.services = JSON.parse(data['services']);
      }, error => {
        console.log(error);
        this.notify.warning("No se pudo obtener los servicios. Intentelo nuevamente", { timeout: 5000, });
      })
  }

  changeTab(i, segtyId) {
    this.activeTab = i;

    this.cleanFilters();
    localStorage.setItem('segtyId', segtyId);
    this.loadContracts(segtyId);
    // this.getFilters()
  }

  cleanFilters() {
    this.selectClient = '';
    this.selectContract = '';
    this.selectStatus = '';
    this.selectPriority = '';
    this.selectDates = {
      detection: ''
      , verification: ''
    };
  }


  events: string[] = [];

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }



  setRecommendationsTitle(contId) {
    var _this = this;
    $.each(this.contractsDataSource[this.segtyId]['contracts'], function (i, c) {
      if (c.id == contId) {
        _this.recommendationsTitle = "Factores de Riesgo > " + c.name;
      }
    });
  }


  setRecommendationsHeaders(data) {
    this.recommendationsHeaders = ['N°', 'fecha', 'ultima_revision', 'técnico'];
    this.displayedColumns = ['comentarios', 'N°', 'fecha', 'ultima_revision', 'técnico'];
    var headers = [];
    var _this = this;

    var ignoreList = [
      // 'foda_id', 'user_id', 'devi_id', 'foan_id', 'asst_id', 'foda_created_datetime'
      // , 'foda_submitted_datetime', 'foda_location', 'foda_emails', 'foda_status', 'foda_guardar'
      // , 'related_id', 'related_table', 'cont_id', 'nComments','foan_folio','serv_asset_id'
      // ,'foda_last_user_id','foda_last_user_datetime', 'rut_cliente', 'nombre_cliente'
      // , 'codigo_ubicacion', 'codigo_contrato', 'ultimo_comentario', 'foto_comentario'
      'foda_serv_camera', 'foda_serv_gallery_image', 'foda_serv_save', 'foda_serv_linea_negocio'
    ];

    $.each(data, function (tag, title) {
      let header = String(tag);
      if (!ignoreList.includes(header)) {
        _this.recommendationsHeaders.push(header);
        _this.displayedColumns.push(header);
      }
    });
    this.recommendationsHeaders.push('dias_abierto');
    this.displayedColumns.push('dias_abierto');

    // this.displayedColumns.push('comentarios');
  }

  prevPage() {
    this.loadRecommendation(this.contractId, this.page - 1);
  }

  nextPage() {
    this.loadRecommendation(this.contractId, this.page + 1);
  }


  loadContracts(segtyId) {
    this.showTable = false;
    this.loading = true;
    this.segtyId = segtyId;
    // if (this.contractsTable != null) {
    //   this.filters()
    //   return false;
    // }
    this.showContracts = false;
    this.showContractsTable = false;

    // console.log(this.contractsDataSource);
    this.rfService.getFilters(this.clieId, segtyId).subscribe(
      data => {
        this.filterSource.clients = data['clients'];
        this.filterSource.contracts = data['contracts'];
        this.filterSource.status = [{ id: 'Abierto', name: 'Abierto' }, { id: 'Cerrado', name: 'Cerrado' }];
        this.loading = false;
        this.showContracts = true;
        this.showContractsTable = true;
        this.initialDate = data['initialDate'];
        setTimeout(() => {
          let isNewTable = true;
          this.contractsDataSource[segtyId] = data;
          if (isNewTable) {
            this.contractsTable = new MatTableDataSource(data['contracts']);
            this.contractsTable.sort = this.contractsSort;
            this.contractsTable.paginator = this.contractsPaginator;
          }

        }, 500);


      }, error => {
        console.log(error);
        this.notify.warning('No se pudo obtener filtros. Intentelo mas tarde')
      }
    )
  }

  onChangeFilterPriority(event) {
    this.selectPriority = event;
    this.filters();
  }

  onChangeFilterClient(event) {
    this.selectClient = event;
    this.filters();
  }

  onChangeFilterContract(event) {
    this.selectContract = event;
    this.filters();
  }

  onChangeFilterStatus(event) {
    this.selectStatus = event;
    this.filters();
  }


  detectionDateChange(event) {
    this.selectDates['detection'] = (event.value.toLocaleDateString().split('-')).reverse().join('-');
    this.filters();
  }

  verificationDateChange(event) {
    this.selectDates['verification'] = (event.value.toLocaleDateString().split('-')).reverse().join('-');
    this.filters();
  }


  filters() {
    var filters = this.getFilters();
    this.loading = true;
    this.showContractsTable = false;
    this.showContracts = true;

    this.rfService.getFilteredRecommendation(filters, this.segtyId, this.clieId).subscribe(
      data => {
        this.contractsTable.data = data['contracts'];
        this.filterSource.contracts = data['contractsFilter'];
        this.selectedContracts = this.selectContract;
        this.loading = false;
        this.showContractsTable = true;
      }, error => {
        console.log(error);
        this.notify.warning('No se pudo obtener Factores de Riesgo. Intentelo mas tarde')
      }
    );
  }




  refreshRecommendations() {
    this.loadRecommendation();
  }



  loadRecommendation(contract = null, page = null) {
    $(".btn-recom-prev .btn-recom-next").prop('disabled', true);
    this.showContracts = false;
    this.showContractsTable = false;
    this.showTable = false;
    this.contractId = contract != null ? contract.id : this.contractId;
    this.formId = contract != null ? contract.form_id : this.formId;
    this.page = page != null ? page : this.page;
    this.loading = true;
    this.setRecommendationsTitle(this.contractId);

    var filters = this.getFilters();

    this.rfService.getContractRecommendations(filters, this.clieId, this.segtyId, this.contractId, page).subscribe(
      data => {
        this.nRecom = data['nRecom'];
        this.recomListSize = data['recomListSize'];
        this.handleHardPagination(page);

        this.setRecommendationsHeaders(data['labels']);
        this.loading = false;
        this.showTable = true;
        this.headerLabels = data['labels'];
        data['recommendations'].forEach((v, k) => {

          if (data['recommendations'][k]["foda_serv_estado"] == "Abierto") {
            data['recommendations'][k]['dias_abierto'] = calcularDias(data['recommendations'][k]['fecha'])
          }
          if (data['recommendations'][k]["foda_serv_estado"] == "Cerrado") {
            data['recommendations'][k]['dias_abierto'] = calcularDias(data['recommendations'][k]['fecha'], data['recommendations'][k]['foda_last_user_datetime'])
          }
          if (data['recommendations'][k]['foda_last_user_datetime']) {
            data['recommendations'][k]['ultima_revision'] = (data['recommendations'][k]['foda_last_user_datetime']).split(' ')[0]
          }
          data['recommendations'][k]['N°'] = k + 1
        });
        console.log(data)
        setTimeout(() => {
          this.recommendationsTable = new MatTableDataSource(data['recommendations']);
          this.recommendationsTable.sort = this.tableSort;
          this.recommendationsTable.paginator = this.taskPaginator;


          this.recommendationsTable.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'fecha': return new Date(item.fecha);
              default: return item[property];
            }
          };
          $(".btn-recom-prev .btn-recom-next").prop('disabled', false);
        }, 500);
      }, error => {
        this.loading = false;
        this.showContracts = true;
        this.notify.warning("No se pudo obtener la informacion. Intentelo nuevamente.", { timeout: 5000, });
      })
  }


  getFilters() {
    if (this.selectClient === undefined) { this.selectClient = ''; }
    if (this.selectContract === undefined) { this.selectContract = ''; }
    if (this.selectStatus === undefined) { this.selectStatus = ''; }
    if (this.selectPriority === undefined) { this.selectPriority = ''; }

    return {
      priority: this.selectPriority
      , clients: this.selectClient.length == 0
        ? this.filterSource.clients.map(c => c.id)
        : this.selectClient
      , contracts: this.selectContract
      , status: this.selectStatus
      , dates: this.selectDates
    };
  }

  handleHardPagination(page) {
    if (page == 1) {
      $(".btn-recom-prev").hide();
      $(".btn-recom-next").show();
    } else if (page * parseInt(this.recomListSize) > parseInt(this.nRecom)) {
      $(".btn-recom-prev").show();
      $(".btn-recom-next").hide();
    } else {
      $(".btn-recom-prev").show();
      $(".btn-recom-next").show();
    }

  }

  checkPermission(permiso) {
    return this.validationService.validaPermisos(permiso);
  }

  objectHasAttribute(object, attribute) {
    return attribute in object;
  }

  editRecommendation(recId = null) {
    $(".btn-create-reco").prop('disabled', true);
    $(".btn-edit-reco").prop('disabled', true);
    this.recommendationForm.loadForm({
      contractId: this.contractId,
      recommendationId: recId,
      formId: this.formId
    });
    setTimeout(() => {
      $(".btn-create-reco").prop('disabled', false);
      $(".btn-edit-reco").prop('disabled', false);
    }, 7000);
  }


  exportContract(contract = null, page = null) {
    this.contractId = contract != null ? contract.id : null;
    this.formId = contract != null ? contract.form_id : this.formId;
    this.page = page != null ? page : this.page;

    var filters = this.getFilters();

    Swal.fire({
      title: "¡ Exportando Excel !",
      html: '<button _ngcontent-c4="" disabled="" mdbbtn="" style=background: none; color: inherit; border: none" type="button" class="btn"><span _ngcontent-c4="" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span> Cargando... </button>',
      showConfirmButton: false,
      allowOutsideClick: false
    });
    
    this.rfService.exportContractRecommendations(filters, this.clieId, this.segtyId, this.contractId, page).subscribe(
      data => {
        if ( data['status'] && data['recommendations'] ) {
          
          const worksheet = XLSX.utils.json_to_sheet(data['recommendations']);
          $.each(worksheet, function(cell, item) {
            if (item.v != null && item.v.toString().indexOf('http') == 0)
              worksheet[cell] = {f: '=HYPERLINK("' + item.v.toString() + '","[Imagen]")'};
          });
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Recommendations");

          XLSX.writeFile(workbook, `recommendations_${new Date().getTime()}.xlsx`, { compression: true });
        } else {
          this.notify.error(data['error'], { timeout: 10000, pauseOnHover: true });
        }
        Swal.close();
      }, error => {
        console.log(error);
        this.notify.warning(error);
        Swal.close();
      }
    )
  }

  initialDateFilter = (d: Date): boolean => {
    if (!this.initialDate) return true;
    let year = d.getFullYear();
    let month = ("0" + (d.getMonth() + 1)).slice(-2)
    let day = ("0" + d.getDate()).slice(-2);
    let date = `${year}-${month}-${day}`;
    return date >= this.initialDate;
  }


}
function calcularDias(fecha, fecha_2 = null) {
  var fecha1 = new Date(fecha);
  var fecha2 = null;
  if (fecha_2) {
    fecha2 = new Date(fecha_2);
  }
  else {
    fecha2 = new Date();
  }
  var differencia = fecha1.getTime() - fecha2.getTime();
  var dias = Math.abs(Math.ceil(differencia / (1000 * 3600 * 24)));
  console.log(dias)
  return dias
}