import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VisitasService {

	baseUrl: string;

  constructor(private http:HttpClient) { 
  	this.baseUrl = environment.apiUrl;
  }

  	get_activities(clie_id,cont_id,segty_id,timezone,acti_create): Observable <any[]>{
     	return this.http.post<any[]>(this.baseUrl+'get_activities',{clie_id: clie_id,cont_id:cont_id,segty_id:segty_id,timezone:timezone,acti_create:acti_create,'token':localStorage.getItem('token')});       
    }

    acti_filter(clie_id,acti_status,fechaINI,fechaFIN,cont_id,segty_id,timezone,acti_create): Observable <any[]>{
   		return this.http.post<any[]>(this.baseUrl+'actiFilters',{clie_id:clie_id,acti_status:acti_status,fechaINI:fechaINI,fechaFIN:fechaFIN,cont_id:cont_id,segty_id:segty_id,timezone:timezone,acti_create:acti_create,'token':localStorage.getItem('token')});
  	}

   	get_visits_s3(id): Observable<any>{
    	return this.http.post<any>(this.baseUrl+"get_visits_s3",{id:id,'token':localStorage.getItem('token')});
  	}

  	getTypeReport(): Observable<any>{
	    return this.http.post<any>(this.baseUrl+"list_rety",{clie_id: JSON.parse(localStorage.getItem('userData')).clie_id,'token':localStorage.getItem('token')},
	    {
	      headers: new HttpHeaders({
	        
	      })
	    });
  	}

  	getSubType(rety_id): Observable<any>{
	    return this.http.post<any>(this.baseUrl+"reporte_subtype",{'rety_id':rety_id,'token':localStorage.getItem('token')},
	    {
	      headers: new HttpHeaders({
	        
	        'Content-type': 'application/json',
	        'Accept': 'application/json',

	      })
	    });
  	}

  	// subida de archivos s3
 	postUrl(arg,arg1,arg2,arg3,arg5=null): Observable<any>{
	    return this.http.post<any>(this.baseUrl+"archivo_U",{'url':arg,'name':arg1,'type':arg2,'compid':arg3,'token':localStorage.getItem('token'),'work_code':arg5},
	    {
	      headers: new HttpHeaders({
	        
	      })
	    });
  	}

  	postFile(fileToUpload: FormData): Observable<any> {
	    const endpoint = this.baseUrl+'archivo';
	    const formData: FormData = new FormData();
	    console.log(formData);
	    
	    return this.http.post<any>(endpoint,fileToUpload,
	    {
	      headers: new HttpHeaders({
	        

	      })
	    });
  	}  

  	get_profiles_options(user_id): Observable <any[]>{
    	return this.http.post<any[]>(this.baseUrl+'get_profiles_options',{user_id: user_id,'token':localStorage.getItem('token')});       
 	}
}
