import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { JarwisService } from 'src/app/services/jarwis.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { ActivatedRoute, Router } from "@angular/router";
import { ReloadComponentsService } from '../../services/reload-components.service';
/*import * as $ from 'jquery';*/

import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-menu-horizontal',
  templateUrl: './menu-horizontal.component.html',
  styles:[JSON.parse(sessionStorage.getItem("style")).menuhori.toString()]
})
export class MenuHorizontalComponent implements OnInit {

  public Menus;
  public Submenus;
  public URL;
  public nombre;
  public imagenes = [];
  public segty_id=-1;
  public aux=0;
  public flag="";

  constructor(
    private Jarwis: JarwisService,
    private datosUsuario: UserDataService,
    private valPermiso: ValidaPermisosService,
    private reloadService: ReloadComponentsService,
    private router: Router
    ) {    
      this.URL=this.router.url;
      this.nombre=sessionStorage.getItem('cust_nom');
      let value=this.router.url.split('/')[4];
      let datos=(this).datosUsuario.get();
      datos=Object.assign({cont:value.toString()},datos);
      this.cargaImagenes();
      this.Jarwis.obtenerSubmenuLineaNegocio(datos).subscribe(
        data => this.salvaDatosSubMenu(data)
      );
      this.Jarwis.obtenerDatosLineaNegocio(datos).subscribe(
        data => this.salvaDatosMenu(data)
      );
    }

  ngOnInit() {
    // alert(`URL: ${this.URL}`); 
   }



  // funcion para mostrar el componente con la tabla qlia!
  displayTableOfDocs(e){
    alert('Aqui mostrar componente de la tabla!!')
  }

  chargeSegtyId(){
    console.log(`cargando segtyId en localStorage y en reloadService! ${this.segty_id}`);
    this.reloadService.setSegtyID$(this.segty_id);
    sessionStorage.setItem("segtyId", this.segty_id.toString());
  }




  cargaImagenes(){
    if(JSON.parse(sessionStorage.getItem('style')).imagenesCarrusel == undefined){
      this.imagenes = [];
    }else{
      this.imagenes = JSON.parse(JSON.parse(sessionStorage.getItem('style')).imagenesCarrusel.replace(/'/gi, "\""));
    }
  }
   cambiaImagen(){
    this.cargaImagenes();
  }
 validaPermisos(permiso){
    return this.valPermiso.validaPermisos(permiso);
  }
   
  salvaDatosMenu(data){    
    this.Menus = data.data;
  } 

  salvaDatosSubMenu(data){
    this.Submenus = data.data;
  }

  obtenerSubmenus(segty_id){ 
    return (this.Submenus.filter(smenu => smenu.segty_id == segty_id));
  }
  
  autorizado(permiso){
    return this.valPermiso.validaPermisos(permiso);
  }
  comprobar(){
    
    if(this.router.url.split('/').length!=6){
      return true;
    }
    else{
      return false;
    }

  }

// me guarda el id de la la linea de negocio en el localstorage para poder utilizarla en el componente de nota de ventas.
  get_segty(arg,arg2){
    console.log(`URL ${this.URL}`); // {{URL}}{{submenu.modu_route}}
    sessionStorage.setItem("segty",arg);    
    console.log("linea de negocio id : " +arg);
    this.flag=arg2;    
  }

  changeService(arg){
    this.flag="";
    this.segty_id=arg;
    
  }


  checkPermission(action) {
    const userPerms = JSON.parse(sessionStorage.getItem('perms'));
    let hasPerm = false;
    userPerms.forEach(perm => {
      if (perm.perm_key === action) {
        hasPerm = true;
      }
    });
    return hasPerm;
  }

  formatModuName(name)
  {
    if( name.includes('Contrato') ){
      name = name.replace('Contrato','Punto de Servicio');
    }
    if( name.includes('Contratos') ){
      name = name.replace('Contratos','Puntos de Servicio');
    }
    return name;
  }
}
