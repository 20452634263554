import { Injectable } from '@angular/core';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class ValidaPermisosService {

  private permisos;

  constructor(private userData: UserDataService) { }

  validaPermisos(permiso){
    this.permisos = this.userData.getPermisos();

    if (this.permisos.length == 0 || this.permisos.length == undefined) {
      return false;
    } else if(this.permisos.filter(perm => perm.perm_key == permiso).length > 0){
      return true;
    }
    return false;
  }
}
