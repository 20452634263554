import { Component, OnInit, ViewContainerRef, EventEmitter, ViewChild, Output, AfterViewInit } from '@angular/core';
import { AppInjector } from 'src/app/services/app-injector.service';
import { Control } from 'src/app/models/control.model';
import { ControlFactoryService } from 'src/app/services/control-factory.service';
/*
    * Componente con Panel de filtros para articulos.
    * Filtros:
    * - Contrato
    * - Propiedad
    * - Cliente
    * - Codigo de Articulo
*/ 
@Component({
    selector: 'AssetsFilter',
    templateUrl: './assets-filter.component.html',
    styleUrls: ['./assets-filter.component.css']
})
export class AssetsFilterComponent implements AfterViewInit {
    controls = {
        contracts : null,
        properties: null,
        clients: null,
        code: null,
        startDate: null,
        endDate: null
    };
    controlFactory: ControlFactoryService;

    constructor() {
        this.controlFactory = AppInjector.get(ControlFactoryService);
    }

    ngAfterViewInit() {
        // this.initFilters();
    }

    @ViewChild('filtersContainer', {read:ViewContainerRef} ) filtersContainer :ViewContainerRef;
    @Output('onChange') onChangeEvent = new EventEmitter();

    initFilters (assets)
    {
        this.filtersContainer.clear();
        let options = this.getFilterOptions(assets);
        let controls = {
            clients: new Control({
                    id: 1
                    , label: "Cliente"
                    , tag: "client"
                    , value: ""
                    , type: "MULTISELECT"
                    , options: {data: options.clients}
                }),
            contracts: new Control({
                    id: 1
                    , label: "Contrato"
                    , tag: "contract"
                    , value: ""
                    , type: "MULTISELECT"
                    , options: {data: options.contracts}
                }),
            properties: new Control({ 
                    id: 1
                    , label: "Propiedad"
                    , tag: "property"
                    , value: ""
                    , type: "MULTISELECT"
                    , options: {data: options.properties}
                }),
            code: new Control({ 
                id: 1
                , label: "Codigo de Articulo"
                , tag: "code"
                , value: ""
                , type: "SEARCH"
                , options: {data: options.codes}
            }),
            startDate: new Control({ 
                id: 1
                , label: "Desde"
                , tag: "startDate"
                , value: ""
                , type: "DATE"
                , options: ''
            }),
            endDate: new Control({ 
                id: 1
                , label: "Hasta"
                , tag: "startDate"
                , value: ""
                , type: "DATE"
                , options: ''
            })
        };
        this.clearControls();
        for (let [key,filter] of Object.entries(controls)){
            var control = this.controlFactory.insertControl(this.filtersContainer,filter);
            control.addClass("col-lg-2");
            this.bindFilterEvents(control);
            this.controls[key] = control;
        }
    }

    getFilterOptions(assets)
    {
        var filters = { contracts: [], properties: [], clients: [], codes: [] };
        assets.forEach( asset => {
            if (!filters.contracts.includes(asset.contractLocation) && asset.contractLocation !== null) {
                filters.contracts.push(asset.contractLocation);
            }
            if (!filters.properties.includes(asset.property) && asset.property !== null) {
                filters.properties.push(asset.property);
            }
            if (!filters.clients.includes(asset.client) && asset.client !== null) {
                filters.clients.push(asset.client);
            }
            if (!filters.codes.includes(asset.name) && asset.name !== null) {
                filters.codes.push(asset.name);
            }
        })
        return filters;
    }


    bindFilterEvents(control)
    {
        control.changeEvent.subscribe(event => {
            let data = {
                contracts: this.controls.contracts.data.value.split(',').filter(e=> e!==''),
                clients: this.controls.clients.data.value.split(',').filter(e=> e!==''),
                properties: this.controls.properties.data.value.split(',').filter(e=> e!==''),
                code: this.controls.code.data.value.trim(),
                startDate: this.controls.startDate.data.value,
                endDate: this.controls.endDate.data.value
            }
            this.onChangeEvent.emit(data);
        });
    }

    clearControls ()
    {
        this.controls = {
            contracts : null,
            properties: null,
            clients: null,
            code: null,
            startDate: null,
            endDate: null
        };
    }
}
