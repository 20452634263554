import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/services/token.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { UserDataService } from 'src/app/services/user-data.service';
import { SnotifyService } from 'ng-snotify';
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';

@Component({
  selector: 'app-header',
  template: unescape(JSON.parse(sessionStorage.getItem("style")).headerhtml.toString()).toString(),
  styles:[JSON.parse(sessionStorage.getItem("style")).header,JSON.parse(sessionStorage.getItem("style")).img]
})
export class HeaderComponent implements OnInit {

  datosUsuario = null;
  a:string;
  public permisos;

  constructor(
    private Token:TokenService,
    private Auth: AuthService,
    private router: Router,
    private userData: UserDataService,
    private notify: SnotifyService,
    private valPermiso: ValidaPermisosService
  ) { }

  ngOnInit() {
    this.datosUsuario = this.userData.get();
    
  }

  autorizado(permiso){
    return this.valPermiso.validaPermisos(permiso);
  }

  cerrarSesion(event: MouseEvent){
    event.preventDefault();
    this.notify.confirm('¿Está seguro de cerrar la sesión?', {
      timeout: 10000,
      buttons:[
        {
          text: 'Si',
          action: toaster=>{
            sessionStorage.setItem('auth','0');
            this.notify.success('Sesion cerrada Correctamente');
            this.Token.remove(),
            this.userData.remove(),
            this.userData.removePermisos(),
            this.Auth.changeAuthStatus(false),
            this.router.navigateByUrl('/login'),
            this.notify.remove(toaster.id)
          }
        },
        {
          text: 'No'
        }
      ]
    });
  }

}
