import { Component, OnInit, Input } from '@angular/core';
//import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-carrusel',
  templateUrl: './carrusel.component.html',
  styleUrls: ['./carrusel.component.css']
})
export class CarruselComponent implements OnInit {

  @Input() public imagenes;
  public baseUrl;
  constructor() { 
    this.baseUrl = window.location.origin; // Obtiene la URL base del host local
  }

  ngOnInit() {
  }

}

