import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor() { }

  handle(data){
    this.set(data);
  }

  set(data){
    localStorage.setItem('userData', JSON.stringify(data));
  }

  get(){
    return JSON.parse(localStorage.getItem('userData'));
  }
  
  remove(){
    localStorage.removeItem('userData');
  }

  handlePermisos(data){
    this.setPermisos(data);
  }

  handleClientOptions(options) {
    //data ya viene como string
    localStorage.setItem('clieOptions', options);
  }

  getClientOptions() {
    try {
      return JSON.parse(localStorage.getItem("clieOptions"));
    } catch (e) {
      console.warn('No se pudo obtener opciones de cliente',e);
      return {};
    }
  }

  setPermisos(data){
    localStorage.setItem('perms', JSON.stringify(data));
  }

  getPermisos(){
    return JSON.parse(localStorage.getItem('perms'));
  }
  
  removePermisos(){
    localStorage.removeItem('perms');
  }
}
