import { Component, OnInit, Renderer2 } from "@angular/core";
import { JarwisService } from "src/app/services/jarwis.service";
import { TokenService } from "src/app/services/token.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { UserDataService } from "src/app/services/user-data.service";
import { SnotifyService } from "ng-snotify";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  styles: [JSON.parse(localStorage.getItem("style")).bootstrap.toString()],
})
export class LoginComponent implements OnInit {
  public form = {
    user_username: null,
    user_password: null,
  };

  public cookieAlertHtml: string = "";
  public cookieValue: boolean | null =
    this.getLocalStorageItem("setup_cookie_value");
  public showCookieAlert: boolean = this.cookieValue === null ? true : false;
  public error: string | null = null;

  // Bandera para evitar la generación múltiple de botones
  private buttonsGenerated: boolean = false;

  constructor(
    private Jarwis: JarwisService,
    private Token: TokenService,
    private router: Router,
    private Auth: AuthService,
    private userData: UserDataService,
    private notify: SnotifyService,
    private renderer: Renderer2
  ) {
    if (this.cookieValue === null) {
      this.initializeCookieAlert();
    }
  }

  ver() {
    return localStorage.getItem("auth") === "1";
  }

  ngOnInit() {
    this.setupCookieAlert();
  }

  onSubmit() {
    this.Jarwis.login(this.form).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError(error)
    );
  }

  private getLocalStorageItem(key: string): any {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  private setLocalStorageItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private initializeCookieAlert() {
    const style = this.getLocalStorageItem("style");
    if (style) {
      this.cookieAlertHtml = decodeURIComponent(style.cookie_alert || "");
      this.loadDynamicStyle(style.cookies_alert_css || "");

      const updateContainerStyle = () => {
        const containerCookie = document.querySelector("#containerCookie");
        const containerBgCookie = document.querySelector("#bgOpacityForCookie");
        if (containerCookie) {
          setTimeout(() => {
            this.renderer.setStyle(containerCookie, "display", "block");
            this.renderer.setStyle(containerBgCookie, "display", "block");
            this.generateButtons();
          }, 1000);
          return;
        } else {
          setTimeout(updateContainerStyle, 2000);
        }
      };

      if (document.readyState === "loading") {
        this.renderer.listen(
          "document",
          "DOMContentLoaded",
          updateContainerStyle
        );
      } else {
        updateContainerStyle();
      }
    }
  }

  private loadDynamicStyle(css: string) {
    const div = this.renderer.createElement("div");
    this.renderer.setProperty(div, "innerHTML", `&shy;<style>${css}</style>`);
    this.renderer.appendChild(document.body, div);
  }

  private setupCookieAlert() {
    if (!this.cookieValue) {
      this.deleteCookies();
    }
  }

  private handleResponse(data: any) {
    localStorage.setItem("auth", "1");
    this.Token.handle(data.access_token);
    this.userData.handle(data.user);
    this.userData.handlePermisos(data.permisos.original.data);
    this.userData.handleClientOptions(data.clieOptions);
    this.Auth.changeAuthStatus(true);

    this.router.navigateByUrl("/home");
    this.notify.success(`Bienvenido ${data.user.user_name}`, { timeout: 5000 });
  }

  private handleError(error: any) {
    this.error = error.error.error;
    this.notify.error(`${error.error.error}`, { timeout: 6000 });
  }

  private deleteCookies() {
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    });
  }

  private generateButtons() {
    // Verificar si los botones ya fueron generados
    if (this.buttonsGenerated) return;

    // Asignar Bandera de Botones para no crear Múltiples
    this.buttonsGenerated = true;

    const buttonContainer = this.createButtonContainer();

    const container = document.querySelector(".boxCookie");
    if (container) {
      this.renderer.appendChild(container, buttonContainer);
    } else {
      // Si el contenedor no existe, esperemos hasta que el DOM esté listo
      this.renderer.listen("document", "DOMContentLoaded", () => {
        const container = document.querySelector(".boxCookie");
        if (container) {
          this.renderer.appendChild(container, buttonContainer);
        }
      });
    }
  }

  private createButtonContainer() {
    const buttonContainer = this.renderer.createElement("div");
    this.renderer.addClass(buttonContainer, "formCookie");

    const declineButton = this.createButton(
      "Rechazar uso de cookies",
      () => this.onCookieButtonClick(false),
      "decline"
    );
    const acceptButton = this.createButton(
      "Aceptar Cookies",
      () => this.onCookieButtonClick(true),
      "accept"
    );

    const buttonGroupDecline = this.createButtonGroup(declineButton);
    const buttonGroupAccept = this.createButtonGroup(acceptButton);

    this.renderer.appendChild(buttonContainer, buttonGroupDecline);
    this.renderer.appendChild(buttonContainer, buttonGroupAccept);

    return buttonContainer;
  }

  private createButton(
    text: string,
    clickHandler: () => void,
    buttonClass: string
  ) {
    const button = this.renderer.createElement("button");
    this.renderer.addClass(button, "buttons");
    this.renderer.addClass(button, buttonClass);
    this.renderer.setProperty(button, "textContent", text);
    this.renderer.listen(button, "click", clickHandler);
    return button;
  }

  private createButtonGroup(button: HTMLElement) {
    const buttonGroup = this.renderer.createElement("div");
    this.renderer.addClass(buttonGroup, "form-group");
    this.renderer.appendChild(buttonGroup, button);
    return buttonGroup;
  }

  onCookieButtonClick(cookieValue: boolean) {
    this.setLocalStorageItem("setup_cookie_value", cookieValue);
    this.showCookieAlert = false;
    if (!cookieValue) {
      this.deleteCookies();
    }
    this.setCookieAlertDisplay("none");
  }

  private setCookieAlertDisplay(display: string) {
    const containerCookie = document.querySelector(".containerCookie");
    if (containerCookie) {
      this.renderer.setStyle(containerCookie, "display", display);
    }

    const bgOpacityCookie = document.querySelector("#bgOpacityForCookie");
    if (bgOpacityCookie) {
      this.renderer.setStyle(bgOpacityCookie, "display", display);
    }
  }
}
