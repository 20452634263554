import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';
import {report} from "./report";
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class RepositoriesService {

	baseUrl: string;	

	constructor(private http:HttpClient) {

		this.baseUrl = environment.apiUrl;
		//http://localhost/Backend/backend/public/ --> pruebas locales
		//http://api.movilform.com/
	}

	prueba(url):Observable<any[]>{

		return this.http.get<any[]>(url,{headers: new HttpHeaders({
				
				'Content-type': 'application/json',
				'Accept': 'application/json',

			})});

	}

	getAllDoc(modulo,arg,t1=null,t2=null,cont_id="-1",cust_id="-1",timezone): Observable<any[]>{

		
		return this.http.post<any[]>(this.baseUrl+"reporte_all",{'modulo':modulo ,'cust_id':cust_id,'cont_id':cont_id,'comp':arg,'t1':t1,'t2':t2,timezone:timezone,'token':sessionStorage.getItem('token')},
		{
			headers: new HttpHeaders({
				
				'Content-type': 'application/json',
				'Accept': 'application/json',

			})
		});

	}

	postUrl(arg,arg1,arg2,arg3,arg5=null): Observable<any>{

		return this.http.post<any>(this.baseUrl+"archivo_U",{'url':arg,'name':arg1,'type':arg2,'compid':arg3,'token':sessionStorage.getItem('token'),'work_code':arg5},
		{
			headers: new HttpHeaders({
				
			})
		});


	}
	postDesc(arg,arg2):Observable<any>{

		return this.http.post<any>(this.baseUrl+"archivo_D",{'id':arg2,'token':sessionStorage.getItem('token'),'desc':arg},
		{
			headers: new HttpHeaders({
				
			})
		});

	}


	postFile(fileToUpload: FormData): Observable<any> {
		const endpoint = this.baseUrl+'archivo';
		//http://localhost/Backend/backend/public/ 
		//https://clientsiteapi.movilform.com/archivo
		const formData: FormData = new FormData();
		console.log(formData);
		
		return this.http.post<any>(endpoint,fileToUpload,
		{
			headers: new HttpHeaders({
				

			})
		});

	}

	setId(): Observable<any[]>{
		return this.http.get<any[]>(this.baseUrl+"sesion");
	}
	getId(): Observable<report[]>{

		return this.http.get<report[]>(this.baseUrl+"reporte");

	}
	getList(modulo,tipo,subtipo,comp,t1,t2,cont_id="-1",code=null,name=null,cust_id="-1",timezone): Observable<any>{
		console.log(cont_id);
		return this.http.post<any>(this.baseUrl+"reporte_G",{'modulo':modulo,'cust_id':cust_id,'cont_id':cont_id,'type':tipo,'subtype':subtipo, 'comp':comp,'t1':t1,'t2':t2,'token':sessionStorage.getItem('token'),'work_code':code,'work_name':name,timezone:timezone},
		{
			headers: new HttpHeaders({
				
				'Content-type': 'application/json',
				'Accept': 'application/json',

			})
		});

	}
	getType(modulo): Observable<any>{
		let a=this.http.post<any>(this.baseUrl+"reporte_T",{'modulo':modulo,clie_id: JSON.parse(sessionStorage.getItem('userData')).clie_id,'token':sessionStorage.getItem('token')},
		{
			headers: new HttpHeaders({
				
				'Content-type': 'application/json',
				'Accept': 'application/json',

			})
		});
		return a;
	}
	getSubType(rety_id): Observable<any>{


		return this.http.post<any>(this.baseUrl+"reporte_subtype",{'rety_id':rety_id,'token':sessionStorage.getItem('token')},
		{
			headers: new HttpHeaders({
				
				'Content-type': 'application/json',
				'Accept': 'application/json',

			})
		});
	}

	postContid(cont_id,id): Observable<any>{

		return this.http.post<any>(this.baseUrl+"archivo_C",{'id':id ,'cont_id': cont_id,'token':sessionStorage.getItem('token')},
		{
			headers: new HttpHeaders({
				
				'Content-type': 'application/json',
				'Accept': 'application/json',

			})
		});

	}

	delete_repositories(repos_id,repos_route): Observable<any>{
		return this.http.post<any>(this.baseUrl+"delete_repositories",{repos_id:repos_id, repos_route:repos_route, 'token':sessionStorage.getItem('token')},
		{
			headers: new HttpHeaders({
				
				'Content-type': 'application/json',
				'Accept': 'application/json',

			})
		});

	}

	disable_repositories(repos_id): Observable<any>{
		return this.http.post<any>(this.baseUrl+"disable_repositories",{repos_id:repos_id,'token':sessionStorage.getItem('token')},
		{
			headers: new HttpHeaders({
				
				'Content-type': 'application/json',
				'Accept': 'application/json',

			})
		});

	}
	


	
}
