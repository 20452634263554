import { Injectable } from '@angular/core';
import { SaturacionTuvComponent } from '../components/dashboard_html/rentokil/saturacion-tuv/saturacion-tuv.component';
import { TaskModalComponent } from '../components/dashboard_html/task-modal/task-modal.component';

@Injectable({
  providedIn: 'root'
})
export class TaskModalService {

  private parent : SaturacionTuvComponent;
  private child : TaskModalComponent;
  constructor(parent) {
    this.parent =  parent;
  }

  setChild(child){
    this.child = child;
  }

  complete(data){
    console.log('service complete => ',data);
    if( this.child.taskType=="TuvSemanalPlaga" ){
      this.parent.completarTaskTUVSemanaPlaga(data);
    }else if(this.child.taskType=="TuvSemanal") {
      this.parent.completeTaskTUVSemanal(data);
    }else if(this.child.taskType=="TuvMensual") {
      this.parent.completeTaskTUVMensual(data);
    }else if(this.child.taskType=="RoedoresActividadDispositivos") {
      this.parent.completeTaskActiRoeMesZona(data);
    }else if(this.child.taskType=="RoedoresConsumo") {
      this.parent.completeTaskConsumoMes(data);
    }else if(this.child.taskType=="RoedoresCaptura") {
      this.parent.completeTaskCapturaMes(data);
    }else if(this.child.taskType=="RtuSemanalPlaga"){
      this.parent.completarTaskRTUSemanaPlaga(data);
    }else if(this.child.taskType=="RtuSemanal"){
      this.parent.completeTaskRTUSemanal(data);
    }else if(this.child.taskType=="RtuMensual"){
      this.parent.completeTaskRTUMensual(data);
    }else if(this.child.taskType=="MstSemanalPlaga"){
      this.parent.completarTaskMSTSemanaPlaga(data);
    }else if(this.child.taskType=="MstSemanal"){
      this.parent.completeTaskMSTSemanal(data);
    }else if(this.child.taskType=="MstMensual"){
      this.parent.completeTaskMSTMensual(data);
    }
  }

  edit(data){
    console.log('service edit => ',data,this.child.taskType);
    if( this.child.taskType=="TuvSemanalPlaga" ){
      this.parent.editarComentarioTUVSemanaPlaga(data);
    }else if(this.child.taskType=="TuvSemanal") {
      this.parent.editarComentarioTUVSemanal(data);
    }else if(this.child.taskType=="TuvMensual") {
      this.parent.editarComentarioTUVMensual(data);
    }else if(this.child.taskType=="RoedoresActividadDispositivos") {
      this.parent.editarComentarioActiRoeMesZona(data);
    }else if(this.child.taskType=="RoedoresConsumo") {
      this.parent.editarComentarioConsumoMes(data);
    }else if(this.child.taskType=="RoedoresCaptura") {
      this.parent.editarComentarioCapturaMes(data);
    }else if(this.child.taskType=="RtuSemanalPlaga"){
      this.parent.editarComentarioRTUSemanaPlaga(data);
    }else if(this.child.taskType=="RtuSemanal"){
      this.parent.editarComentarioRTUSemanal(data);
    }else if(this.child.taskType=="RtuMensual"){
      this.parent.editarComentarioRTUMensual(data);
    }else if(this.child.taskType=="MstSemanalPlaga"){
      this.parent.editarComentarioMSTSemanaPlaga(data);
    }else if(this.child.taskType=="MstSemanal"){
      this.parent.editarComentarioMSTSemanal(data);
    }else if(this.child.taskType=="MstMensual"){
      this.parent.editarComentarioMSTMensual(data);
    }

    
  }

  formError(){
    this.child.saving = false;
  }
}
