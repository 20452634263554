import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { FormControl } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { isArray } from 'util';

@Component({
    selector: 'dashboard-filter',
    templateUrl: './dashboard-filter.component.html',
    styleUrls: ['./dashboard-filter.component.css']
})

export class DashboardFilterComponent implements OnInit 
{
    @Input() multiMonth:string = 'false';
    @Input() multiYear:string = 'false';
    @Input() multiContract:string = 'false';
    @Input() multiProperty:string = 'false';
    @Input() hideMonth = false;
    @Input() hideYear = false;
    @Input() hideProperty = true;
    @Input() stickyYear = false;
    @Input() stickyYearNotificationText = 'Se establecio el año actual por defecto. Para filtrar por año movil desmarque primero los meses seleccionados';
    @Input() noButtons = false;
    @Input() propertyLabel :string = 'Propiedad';

    @Output() onChange = new EventEmitter();
    @Output() onContractSearch = new EventEmitter();
    @Output('aplyFilter') aplyFilterEvent = new EventEmitter();
    @Output('removeFilter') removeFilterEvent = new EventEmitter();

    @HostBinding('style.width') width = "100%";
    // selectedContract=null;
    data;
    allContracts;
    selectedYears = [];
    selectedMonths = [];
    selectedContracts = [];
    yearControl;
    yearOptions;
    monthOptions;
    monthControl;
    contractControl;
    contractOptions;
    contractSearch = '';
    lastSearch = {
        'months' : [],
        'years' : [],
        'contracts' : [],
        'properties' : []
    }
    defaultSearch = {
        'months' : [],
        'years' : [],
        'contracts' : [],
        'properties' : []
    }
    propertyControl: any;
    selectedProperties: any;
    


    constructor(private notify:SnotifyService)
    {
        var currentYear = new Date().getFullYear();
        this.yearOptions = [];
        for( let year=2015; year <= (currentYear+5); year++ ){
            this.yearOptions.push({id:year,value:year});
        }
        this.data = {
            'month' :  [
                {id: 1, value: 'Enero'},
                {id: 2, value: 'Febrero'},
                {id: 3, value: 'Marzo'},
                {id: 4, value: 'Abril'},
                {id: 5, value: 'Mayo'},
                {id: 6, value: 'Junio'},
                {id: 7, value: 'Julio'},
                {id: 8, value: 'Agosto'},
                {id: 9, value: 'Septiembre'},
                {id: 10, value: 'Octubre'},
                {id: 11, value: 'Noviembre'},
                {id: 12, value: 'Diciembre'},
              ]
            , 'year' : this.yearOptions
            , 'contract' : []
            , 'property' : []
        };

        this.setDefaultYears( this.multiYear ? [] : '' )
        this.setDefaultMonths( this.multiMonth ? [] : '' )
        this.setDefaultContract( this.multiContract ? [] : '' )
        this.setDefaultProperty( this.multiProperty ? [] : '' )

        this.yearControl = new FormControl();
        this.monthControl = new FormControl();
        this.contractControl = new FormControl();
        this.propertyControl = new FormControl();
    }

    setData(type:string,data)
    {
        if(type in this.data){
            this.data[type] = data;
            if(type=='contract') this.allContracts = data;
        }
    }

    
    //# Funciones para setear Seleccion
    setSelectedContract(contracts)
    {
        if( this.isMultiple('contract') ){
            this.contractControl.setValue(contracts);
        }
        this.selectedContracts = contracts;
    }

    setSelectedProperty(properties)
    {
        if( this.isMultiple('property') ){
            this.propertyControl.setValue(properties);
        }
        this.selectedProperties = properties;
    }


    setSelectedMonths(months)
    {
        if( this.isMultiple('month') ){
            this.monthControl.setValue(months);
        }
        this.selectedMonths = months; 
    }


    setSelectedYears(years)
    {
        if( this.isMultiple('year') ){
            this.yearControl.setValue(years);
        }
        this.selectedYears = years;
    }
    //#

    //# Funciones para controlar el comportamiento inicial del filtro
    setDefaultContract(contracts)
    {
        this.setSelectedContract(contracts);
        this.lastSearch.contracts = contracts;
        this.defaultSearch.contracts = contracts;
    }

    setDefaultProperty(properties)
    {
        this.setSelectedProperty(properties);
        this.lastSearch.contracts = properties;
        this.defaultSearch.contracts = properties;
    }

    setDefaultMonths(months)
    {
        this.setSelectedMonths(months) 
        this.lastSearch.months = months;
        this.defaultSearch.months = months;
    }

    setDefaultYears(years)
    {
        this.setSelectedYears(years);
        this.lastSearch.years = years;
        this.defaultSearch.years = years;    
    }
    //#

    

    onMonthChange(event)
    {
        this.selectedMonths = event.value;
        if ( this.stickyYear && this.onlyMonthSelected() ){
            this.setCurrentYear();
        }
        this.emit('month');
    }

    onYearChange(event)
    {
        this.selectedYears = event.value; 
        if ( this.stickyYear && this.onlyMonthSelected() ){
            this.setCurrentYear();
            this.notify.info(this.stickyYearNotificationText, {timeout:6000} );
        }
        this.emit('year');
    }

    onContractChange(event)
    {
        this.selectedContracts = event.value;
        this.emit('contract'); 
    }

    onPropertyChange(event)
    {
        this.selectedProperties = event.value;
        this.data['contract'] = this.getContractsFilteredByProperty();
        var _this = this;
        let keepSelected = this.selectedContracts.filter(function(el){
            let finded = false;
            for(let contract of _this.data['contract']){
                if(contract.id==el){
                    finded = true;
                }
            }
            return finded;
        });
        this.setSelectedContract(keepSelected);
        this.emit('property'); 
    }

    getContractsFilteredByProperty()
    {
        let properties = Array.isArray(this.selectedProperties) ? this.selectedProperties : [this.selectedProperties];
        var relatedContracts :any= this.getContractsFromProperties(properties);
        let filteredContracts = relatedContracts.length>0
                        ? this.allContracts.filter(function(el){ return relatedContracts.includes(el.id); })
                        : this.allContracts;
        return filteredContracts;
    }


    getContractsFromProperties(selectedProperties){
        var contracts = [];
        for(let selectedProperty of selectedProperties){
            for( let property of this.data['property']){
                if(selectedProperty==property.id){
                    for(let relatedContract of property.contracts){
                        if (!contracts.includes(relatedContract)) {
                            contracts.push(parseInt(relatedContract));
                        }
                    }
                }
            }
        }
        return contracts;
    }


    onlyMonthSelected()
    {
        return this.selectedMonths.length>0 && this.selectedYears.length==0;
    }


    setCurrentYear()
    {
        let currentYear = new Date().getFullYear();
        this.setSelectedYears([currentYear]);
    }

    emit(trigger)
    {
        var data = {
            filters : {
                'months' : this.selectedMonths,
                'years' : this.selectedYears,
                'contracts' : this.selectedContracts,
                'properties' : this.selectedProperties,
            },
            eventTrigger : trigger
        }
        let changes = {
            'months' : this.monthsHasChange(),
            'years' : this.yearsHasChange(),
            'contracts' : this.contractsHasChange(),
            'property' : this.propertiesHasChange()
        }

        this.onChange.emit(data);
    }

    ngOnInit()
    {}
    

    searchContracts(event)
    {
        event.stopPropagation();
        var search = event.target.value;
        this.filterContracts(search);

        this.onContractSearch.emit(search);
    }


    checkInvalidSearch()
    {
        var search = this.contractSearch.trim();
        if(search.trim()!='')
        {
            let matches = this.allContracts.filter(function (el) {
                return (
                    el.value.toLowerCase().includes(search.trim().toLowerCase()) || 
                    el.code.toLowerCase().includes(search.trim().toLowerCase())
                );
            })
            if(matches.length==0)
            {
                this.freeContractSearch();
            }
        }
    }


    freeContractSearch()
    {
        this.filterContracts('');
        this.onContractSearch.emit('');
    }


    filterContracts(search)
    {
        this.contractSearch = search;
        if(search == ''){
            this.data['contract'] = this.allContracts;
        }
        let contracts = this.getContractsFilteredByProperty();
        this.data['contract'] = contracts.filter(function (el) {
            return (
            el.value.toLowerCase().includes(search.trim().toLowerCase()) || 
            el.code.toLowerCase().includes(search.trim().toLowerCase())
            );
        });
    }


    isMultiple(type:string)
    {
        if(type=='month') return this.multiMonth=='true';
        if(type=='year') return this.multiYear=='true';
        if(type=='contract') return this.multiContract=='true';
        if(type=='property') return this.multiProperty=='true';
        return false;
    }


    onAplyFilterClick()
    {
        var data = {
            filters : {
                'months' : this.selectedMonths,
                'years' : this.selectedYears,
                'contracts' : this.selectedContracts,
                'properties' : this.selectedProperties,
            },
            changes : {
                'months' : this.monthsHasChange(),
                'years' : this.yearsHasChange(),
                'contracts' : this.contractsHasChange(),
                'property' : this.propertiesHasChange()
            }        
        }

        this.updateLastSearch();

        this.aplyFilterEvent.emit(data);

    }

    updateLastSearch(){
        this.lastSearch = {
            'months' : this.selectedMonths,
            'years' : this.selectedYears,
            'contracts' : this.selectedContracts,
            'properties' : this.selectedProperties,
        };
    }


    cleanFilters()
    {
        this.selectedContracts = this.defaultSearch.contracts;
        if( this.isMultiple('contract') )    this.contractControl.setValue(this.defaultSearch.contracts);

        if( this.isMultiple('month') )   this.monthControl.setValue(this.defaultSearch.months);
        this.selectedMonths = this.defaultSearch.months;

        if( this.isMultiple('year') )    this.yearControl.setValue(this.defaultSearch.years);
        this.selectedYears = this.defaultSearch.years;

        if( this.isMultiple('property') )    this.propertyControl.setValue(this.defaultSearch.properties);
        this.selectedProperties = this.defaultSearch.properties;
        
        this.updateLastSearch();        
    }


    onRemoveFilterClick()
    {
        this.cleanFilters();
        var data = {
            filters : {
                'months' : this.selectedMonths,
                'years' : this.selectedYears,
                'contracts' : this.selectedContracts,
                'properties' : this.selectedProperties
            },
            eventTrigger : 'aplyFilter'
        }

        this.removeFilterEvent.emit(data);
    }

    //indica si cualquiera de los filtros ha cambiado desde la ultima busqueda
    hasChange( compareWith = 'last')
    {
        let years =  this.hideYear ? false : this.yearsHasChange(compareWith);
        let months =  this.hideMonth ? false : this.monthsHasChange(compareWith);
        return this.contractsHasChange(compareWith) || years || months;
    }

    contractsHasChange( compareWith = 'last' )
    {
        let comparison = compareWith=='default' ? this.defaultSearch.contracts : this.lastSearch.contracts;
        return this.selectedContracts != comparison;
    }

    propertiesHasChange( compareWith = 'last' )
    {
        let comparison = compareWith=='default' ? this.defaultSearch.properties : this.lastSearch.properties;
        return this.selectedProperties != comparison;
    }

    yearsHasChange( compareWith = 'last' )
    {
        let comparison = compareWith=='default' ? this.defaultSearch.years : this.lastSearch.years;
        return  this.isMultiple('year')
                ? !this.isEquivalent( this.selectedYears,comparison )
                : this.selectedYears != comparison;
    }

    monthsHasChange( compareWith = 'last' )
    {
        let comparison = compareWith=='default' ? this.defaultSearch.months : this.lastSearch.months;
        return  this.isMultiple('month')
                ? !this.isEquivalent( this.selectedMonths,comparison )
                : this.selectedMonths != comparison;
    }



    //Solo para comparar objetos
    isEquivalent(a, b) {
        // Create arrays of property names
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);
    
        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length != bProps.length) {
            return false;
        }
    
        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];
    
            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
    
        // If we made it this far, objects
        // are considered equivalent
        return true;
    }



    //Element Display functions
    calculateDateDivDisplay()
    {
        if (this.hideMonth==false && this.hideYear==false) {
            return 'row col-lg-4 col-md-12 fltrs';
        }else if (this.isShown('month') && !this.isShown('year')) {
            return 'row col-lg-3 col-md-12 fltrs';
        }else if (!this.isShown('month') && this.isShown('year')) {
            return 'row col-lg-2 col-md-12 fltrs';
        }else{
            return '';
        }
    }

    calculateContractsDivDisplay()
    {
        if (this.hideProperty) {
            return 'row col-lg-4 col-md-12 fltrs';
        } else {
            return 'row col-lg-5 col-md-12 fltrs';
        }
    }

    calculateContractFieldDisplay()
    {
        let p:any = this.hideProperty;
        if (p==true || p=='true') {
            return 'col-md-12 col-xs-12 col-lg-12';
        } else {
            return 'col-md-6 col-xs-12 col-lg-6';
        }
    }

    isShown(type){
        if(type=="month"){
            let month:any = this.hideMonth;
            return month == false || month == 'false';
        }
        if(type=="year"){
            let year:any = this.hideYear;
            return year == false || year == 'false';
        }
        if(type=="property"){
            let property:any = this.hideProperty;
            return property == false || property == 'false';
        }
    }

}


