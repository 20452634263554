import { Component, OnInit, ViewChild } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { MassUploadService } from '../../services/mass-upload.service';
import readXlsxFile from 'read-excel-file';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import * as XLSX from 'xlsx';

export interface MassUploadSchema {
  CONTRATO_CODIGO: string;
  NOMBRE_ARCHIVO: string;
  LINEA_NEGOCIO: string;
  SUBTIPO_DOCUMENTO: string;
  bucketNotFound: boolean;
  contractNotFound: boolean;
}

@Component({
  selector: 'app-mass-upload',
  templateUrl: './mass-upload.component.html',
  styleUrls: ['./mass-upload.component.css']
})
export class MassUploadComponent implements OnInit {

  private displayedColumns: string[] = ['CONTRATO_CODIGO', 'NOMBRE_ARCHIVO', 'LINEA_NEGOCIO', 'SUBTIPO_DOCUMENTO'];
  private clientId: number = JSON.parse(localStorage.getItem('userData')).clie_id;
  private loadingValidateData = false;
  private massUpGuideData: any = [];
  private excelSchema = { // TODO: asignar el valor de la columna de acuerdo al modulo seleccionado
    'CONTRATO_CODIGO': {
      prop: 'CONTRATO_CODIGO',
      type: String,
    },
    'NOMBRE_ARCHIVO': {
      prop: 'NOMBRE_ARCHIVO',
      type: String,
    },
    'LINEA_NEGOCIO': {
      prop: 'LINEA_NEGOCIO',
      type: String,
    },
    'SUBTIPO_DOCUMENTO': {
      prop: 'SUBTIPO_DOCUMENTO',
      type: String,
    },
    'bucketNotFound': {
      prop: 'bucketNotFound',
      type: Boolean,
    },
    'contractNotFound': {
      prop: 'contractNotFound',
      type: Boolean,
    }
  };
  private file;
  private userId = JSON.parse(localStorage.getItem('userData')).user_id;

  private bucketNotFoundFiles = [];
  private contractNotFoundFiles = [];
  private succeded = false;

  dataSource = new MatTableDataSource<MassUploadSchema>(this.massUpGuideData);

  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(
    private massUpServ: MassUploadService,
    private notify: SnotifyService
  ) {
  }

  ngOnInit() {
  }

  /**
   * Funcion para validar el archivo guía.
   * @param event 
   */
  async validateExcelFile(event) {
    try {

      this.succeded = false;
      const file = (event.target as HTMLInputElement).files[0];
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.notify.warning('El archivo debe ser formato excel', 'Error');
        return;
      }
      let validateExact = true;
      const schema = this.excelSchema;
      const validExact = await readXlsxFile(file, {schema, transformData(data) {
          console.log(data);
          console.log(data[0])
          if(data[0].length !== 4) {
            validateExact = false;
          }
          return data;
        }
      })
      if(!validateExact) {
        this.notify.warning('Revise el formato y los datos del archivo', 'Error');
        return;
      }
      const data2 = await readXlsxFile(file, { schema });
      const rows = data2.rows;
      const errs = data2.errors;
      if (errs.length > 0 || rows.length <= 0) {
        this.notify.warning('Revise el formato y los datos del archivo', 'Error');
        return;
      }
      this.massUpGuideData = rows;
      this.file = file;
      this.dataSource = new MatTableDataSource<MassUploadSchema>(this.massUpGuideData);
      this.dataSource.paginator = this.paginator;
    } catch (error) {
      this.notify.warning('Revise el formato y los datos del archivo', 'Error');
      console.log('error!');
      console.log(error);
    }
  };




  /**
   * Funcion para limpiar el arcvhivo guía cargado.
   */
  clearFile() {
    if (this.file) {
      this.file = null;
      if (this.massUpGuideData.length > 0) {
        this.massUpGuideData = [];
        this.dataSource = new MatTableDataSource<MassUploadSchema>(this.massUpGuideData);
        this.dataSource.paginator = this.paginator;
      }
    } else {
      this.notify.warning('No hay archivo cargado', 'Error');
    }
  };


  async confirmUpload() {
    this.loadingValidateData = true;

    if (!this.file || this.file === '') {
      this.notify.error('Debe indicar el archivo guía', 'Error');
      return;
    }
    console.log('this.massUpGuideData');
    console.log(this.massUpGuideData);

    this.massUpServ.sendGuideFile(this.massUpGuideData, this.userId).subscribe(
      (res: any) => {
        console.log('res');
        this.loadingValidateData = false;
        console.log('res');
        console.log(res);



        if (res.filesNotFound.length > 0 || res.contractsNotFound.length > 0 || res.wrongBuisnessLine.length > 0 || res.wrongSubTypes.length > 0) { // TODO: validar otros posibles errores.

          this.bucketNotFoundFiles = res.filesNotFound;
          this.contractNotFoundFiles = res.contractsNotFound;

          if (res.filesNotFound.length > 0) this.showErrorsFilesNotFound(res.filesNotFound);
          if (res.contractsNotFound.length > 0) this.showErrorsContractNotFound(res.contractsNotFound);
          if (res.wrongBuisnessLine.length > 0) this.showBuisnessLinesErrors(res.wrongBuisnessLine);
          if (res.wrongSubTypes.length > 0) this.showDocumentTypesErrors(res.wrongSubTypes);
          this.notify.confirm('Los siguientes archivos presentaron errores', 'Error', {
            buttons: [
              {
                text: 'Confirmar',
                action: toster => {
                  this.loadingValidateData = true;
                  this.notify.remove(toster.id);
                  console.log('se confirma la carga de los archivos');
                  const formData = new FormData();
                  formData.append('guideFile', this.file);
                  formData.append('clientId', this.clientId.toString());
                  // formData.append('compId', ????);
                  formData.append('data', JSON.stringify(res.originalData));
                  this.massUpServ.executeMassUpload(formData).subscribe(
                    (res: any) => {
                      this.loadingValidateData = false;
                      console.log('res');
                      console.log(res);
                      if (res.succededFiles.length <= 0) {
                        this.notify.error('Ningun archivo se subio, por favor revisar formato y datos excel', 'Error', { timeout: 5000 });
                        this.createExcelErrorFile(res.failedFiles);
                      }
                      if (res.succededFiles.length > 0 && res.failedFiles.length > 0) {
                        this.notify.warning('Algunos archivos no se subieron, por favor revisar formato y datos excel', 'Error');
                        this.createExcelErrorFile(res.failedFiles);
                      }
                      if (res.succededFiles.length > 0 && res.failedFiles.length <= 0) {
                        this.notify.success('Se cargaron los archivos correctamente', 'Éxito');
                      }
                      return;
                    },
                    (err) => {
                      this.loadingValidateData = false;
                      this.notify.error('Error al cargar los archivos', 'Error');
                      console.log('err');
                      console.log(err);
                      return;
                    }
                  );
                },
                bold: false
              },
              {
                text: 'Cancelar',
                action: toster => {
                  this.notify.remove(toster.id);
                  console.log('se cancela la carga de los archivos');
                },
                bold: false
              }
            ]
          });
        }

        // en caso de que no hayan errores en la validacion de los datos del archivo guía, se procede a la carga de los archivos
        if (res.filesNotFound.length <= 0 && res.contractsNotFound.length <= 0 && res.wrongBuisnessLine.length <= 0 && res.wrongSubTypes.length <= 0) {
          this.notify.success('Se valido correctamente el archivo guía', 'Éxito');
          this.notify.confirm('¿Desea continuar con la carga de los archivos?', 'Confirmar', {
            buttons: [
              {
                text: 'Confirmar',
                action: toster => {
                  this.loadingValidateData = true;
                  this.notify.remove(toster.id);
                  console.log('se confirma la carga de los archivos');
                  const formData = new FormData();
                  formData.append('guideFile', this.file);
                  formData.append('clientId', this.clientId.toString());
                  // formData.append('compId', ????);
                  formData.append('data', JSON.stringify(res.originalData));
                  this.massUpServ.executeMassUpload(formData).subscribe(
                    (res: any) => {
                      this.loadingValidateData = false;
                      console.log('res');
                      console.log(res);

                      if (res.succededFiles.length <= 0) {
                        this.notify.error('Ningun archivo se subio, por favor revisar formato y datos excel', 'Error', { timeout: 5000 });
                        this.createExcelErrorFile(res.failedFiles);
                      }
                      if (res.succededFiles.length > 0 && res.failedFiles.length > 0) {
                        this.notify.warning('Algunos archivos no se subieron, por favor revisar formato y datos excel', 'Error');
                        this.createExcelErrorFile(res.failedFiles);
                      }
                      if (res.succededFiles.length > 0 && res.failedFiles.length <= 0) {
                        this.notify.success('Se cargaron los archivos correctamente', 'Éxito');
                        this.dataSource = new MatTableDataSource([]);
                        this.succeded = true;
                      }
                      return;
                    },
                    (err) => {
                      this.loadingValidateData = false;
                      this.notify.error('Error al cargar los archivos', 'Error');
                      console.log('err');
                      console.log(err);
                      return;
                    }
                  );
                },
                bold: false
              },
              {
                text: 'Cancelar',
                action: toster => {
                  this.notify.remove(toster.id);
                  console.log('se cancela la carga de los archivos');
                },
                bold: false
              }
            ]
          });
        }

        // consular en caso de que no haya errores, proceder a la carga de los archivos o igual preguntar si se desea continuar
      },
      err => {
        this.loadingValidateData = false;
        console.log('err');
        console.log(err);
        this.notify.error('Error al validar los datos del archivo guía', 'Error');
        return;
      }
    );
  }




  /**
   * Funcion para mostrar un cargador
   * este cargador se muestra cuando la varuable loadingValidateData es true
   */
  showLoader() {
    if (this.loadingValidateData) {
      return true;
    } else {
      return false;
    }
  }



  convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }


  createExcelErrorFile(failedFiles) {
    console.log('failedFiles');
    console.log(failedFiles);
    failedFiles.forEach(file => {
      if (file.LINEA_NEGOCIO_ID == null) {
        file.error ? file.error = file.error + ', No se encontro linea de negocio' : file.error = 'No se encontro linea de negocio';
      }
      if (file.SUBTIPO_DOCUMENTO_ID == null) {
        file.error ? file.error = file.error + ', No se encontro subtipo de documento' : file.error = 'No se encontro subtipo de documento';
      }
      delete file.CONTRATO_ID;
      delete file.SUBTIPO_DOCUMENTO_ID;
      delete file.LINEA_NEGOCIO_ID;
    });
    const datetime = new Date();
    const date = datetime.getDate();
    const month = datetime.getMonth() + 1;
    const year = datetime.getFullYear();
    const hour = datetime.getHours();
    const minutes = datetime.getMinutes();
    const seconds = datetime.getSeconds();
    const dateStr = date + '/' + month + '/' + year + '- ' + hour + ':' + minutes + ':' + seconds;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(failedFiles);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `errores-carga-masiva-${dateStr}.xlsx`);
  }




  checkRow(row): Boolean {
    try {
      // comparar si row.CONTRATO_CODIGO esta en this.bucketNotFoundFiles
      // si esta en el bucketNotFoundFiles, retornar true
      if (this.bucketNotFoundFiles.includes(row.CONTRATO_CODIGO)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }


  /**
   * Funcion para mostrar en la tabla los archivos que no fueron encontrados en el bucket.
   */
  showErrorsFilesNotFound(files) {
    try {
      files.forEach(file => {
        const row = $(`tr:contains(${file})`);
        row.css('background-color', 'orange');
        row.attr('title', 'El archivo no fue encontrado en el bucket');
        row.attr('data-toggle', 'tooltip');
        row.attr('data-placement', 'bottom');
      });
    } catch (error) {
      console.log('error in showErrors');
      console.log(error);
    }
  }
  /**
   * Funcion para mostrar los errores de los contratos no encontrados.
   * @param files 
   */
  showErrorsContractNotFound(files) {
    try {
      files.forEach(file => {
        const row = $(`tr:contains(${file})`);
        const title = row.attr('title');
        if (title) {
          row.css('background-color', 'red');
          row.attr('title', title + ', El contrato no fue encontrado');
        } else {
          row.css('background-color', 'red');
          row.attr('title', 'El contrato no fue encontrado');
          row.attr('data-toggle', 'tooltip');
          row.attr('data-placement', 'bottom');
        }
      });
    } catch (error) {
      console.log('error in showErrors');
      console.log(error);
    }
  }


  showBuisnessLinesErrors(files) {
    try {
      files.forEach(file => {
        const row = $(`tr:contains(${file})`);
        const title = row.attr('title');
        if (title) {
          row.attr('title', title + ', No se encontro linea de negocio');
          row.css('background-color', 'red');
        } else {
          row.attr('title', 'No se encontro linea de negocio');
          row.css('background-color', 'red');
          row.attr('data-toggle', 'tooltip');
          row.attr('data-placement', 'bottom');
        }
      });
    } catch (error) {
      console.log('error in showErrors');
      console.log(error);
    }
  }


  showDocumentTypesErrors(files) {
    try {
      files.forEach(file => {
        const row = $(`tr:contains(${file})`);
        const title = row.attr('title');
        if (title) {
          row.attr('title', title + ', No se encontro sup tipo de documento');
          row.css('background-color', 'red');
        } else {
          row.attr('title', 'No se encontro sup tipo de documento');
          row.css('background-color', 'red');
        }
      });
    } catch (error) {
      console.log('error in doctypes');
      console.log(error);
    }
  }


  showSuccessMesageOnTable() {
    // vaciar la tabla y mostrar un mensaje de exito
    // this.tableData = [];
    this.massUpGuideData = [
      {
        CONTRATO_CODIGO: 'Carga finalizada de manera exitosa',
        CONTRATO_ID: '',
        LINEA_NEGOCIO_ID: '',
        LINEA_NEGOCIO_NOMBRE: '',
        SUBTIPO_DOCUMENTO_ID: '',
        SUBTIPO_DOCUMENTO_NOMBRE: '',
        error: ''
      }
    ];
    this.dataSource = new MatTableDataSource(this.massUpGuideData);
    // set bg color of the row to green
    const row = $(`tr:contains(Carga exitosa)`);
    row.css('background-color', 'green');
  }


  openDocs() {
    const url = 'https://cmtelecomunicaciones.atlassian.net/wiki/spaces/MC/pages/2715189249/Carga+Masiva+Documentos+Contratos+Portal+Clientes';
    window.open(url, '_blank');
  }

  pageChanged(event) {
    console.log('pageChanged');
    console.log(event);
  }

}