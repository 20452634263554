import { Component, OnInit, ViewChild, AfterViewInit, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { GroupedStackedHorizontalComboComponent } from '../combo-charts/grouped-stacked-horizontal-combo/grouped-stacked-horizontal-combo.component';
import { ChartInterface } from 'src/app/interfaces/chart-interface';

@Component({
  selector: 'stackgroup-horizontal-chart',
  templateUrl: './stackgroup-horizontal-chart.component.html',
  styleUrls: ['./stackgroup-horizontal-chart.component.css'],
})
export class StackgroupHorizontalChartComponent implements AfterViewInit {

    private tooltip;

    @ViewChild('chart') chart:GroupedStackedHorizontalComboComponent;


    public title = "";
    public dataSource : any = [];
  
    public index : number;
    public fingerprint : string;
    public selfRef: StackgroupHorizontalChartComponent;
    public isLoading = true;
    public style : any;
    public chartStyles = {
      'tuv' : {
        view : [900,400],
        scheme: {domain: ['#7859EB','#FB886F','#F7FB7B','#78D35F','#C98ECF','#7FD9F7','#F2B25F','#B2EA70']},
        legend: true,
        legendTitle : 'Clase Especie',
        legendPosition : 'right',
        showXAxis : true,
        showYAxis : true,
        yAxis : true,
        xAxis: true,
        gradient : false,
        showLegend: true,
        showXAxisLabel : false,
        xAxisLabel : 'Cantidad',
        showYAxisLabel : false,
        yAxisLabel : 'Dispositivos',
        showGridLines : true,
        groupPadding : 15,
        animations: true,
        barPadding : 7,
        showDataLabel : true,
        xScaleMax : 5
      }
    };
    public type : string;
   
    @HostBinding('class.col-md-4') isColMd4 : boolean;
    @HostBinding('class.col-lg-4') isColLg4 : boolean;
    @HostBinding('class.col-md-6') isColMd6 : boolean;
    @HostBinding('class.col-lg-6') isColLg6 : boolean;
    @HostBinding('class.col-md-8') isColMd8 : boolean;
    @HostBinding('class.col-lg-8') isColLg8 : boolean;
    @HostBinding('class.col-md-12') isColMd12 : boolean;
    @HostBinding('class.col-lg-12') isColLg12 : boolean;
  
  
    public parentReference : ChartInterface;
  
    showRightYAxisLabel: boolean = true;
    yAxisLabelRight: string = 'Utilization';

    isDataValid = true;

        

    ngAfterViewInit() {
        this.chart.update();
    }

    xAxisTickFormatting = (value: number) => {
      if (Math.floor(value) !== value) {
        return '';
      }
      return value;
    }
  
    constructor(private detector:ChangeDetectorRef) {
    
      this.fingerprint = Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);

    }
  
    ngOnInit() {

    }
  
    setClasses(classes){
      if( !Array.isArray(classes) ) return false;
      if(classes.includes('col-lg-4')) this.isColLg4 = true;
      if(classes.includes('col-md-4')) this.isColMd4 = true;
      if(classes.includes('col-lg-6')) this.isColLg6 = true;
      if(classes.includes('col-md-6')) this.isColMd6 = true;
      if(classes.includes('col-lg-8')) this.isColLg8 = true;
      if(classes.includes('col-md-8')) this.isColMd8 = true;
      if(classes.includes('col-lg-12')) this.isColLg12 = true;
      if(classes.includes('col-md-12')) this.isColMd12 = true;
    }
  
    setDataSource(dataSource){
        this.isLoading = false;
        this.dataSource = dataSource;
        this.chart.update();
        this.validateData();
    }
  
    setType(type){
      this.type = type;
      this.style = this.chartStyles[type];
    }
  
    setCustomStyle(style){
      this.style = style; 
    }
    
    validateData(){
      var data = this.dataSource;
      var barCount = 0;
      data.map((group)=>{
        group.series.map((stack)=>{
          stack.series.map((bar)=>{
            if(bar.value>0) barCount++;
          })
        });
      });
      this.isDataValid = barCount>0;
    }
}
