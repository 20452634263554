import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import {RepositoriesService} from "../../services/repositories.service";
import { ViewChild } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styles: [JSON.parse(sessionStorage.getItem("style")).img.toString(),JSON.parse(sessionStorage.getItem("style")).menu.toString() , JSON.parse(sessionStorage.getItem("style")).bootstrap.toString()],

})

export class FileUploadComponent implements OnInit {
  fileToUpload: File = null;
  @ViewChild('myInput') public myInputVariable;
  @ViewChild('barra') public progreso;
  public aux=""
  public id;
  public subid;
  public btype=0;
  public type='';
  public wcodigo=null;
  public wname=null;
  public subtype;
  public nombre;
  public compid;
  public subiendo=0;
  public ok=0;
  public index;
  public URL;
  public cont_id="-1";
  public cust_id="-1";
  public modulo="";
  public subtypeflag=0;
  public desc;
  public data:FormData= new FormData();
  public clie_id =JSON.parse(sessionStorage.getItem('userData')).clie_id;

  //bandera para que si es de tipo documentos de empresa --> customers
  public docEmp="false"; 
  public docEmpTable="";
  public docEmpId="";
 

  @Output() reload_compoment_report = new EventEmitter();
  @Output() reload_compoment_docEmp = new EventEmitter();


  constructor(private route:Router,private datos:RepositoriesService,private renderer: Renderer2,private notify: SnotifyService) { 

    this.modulo=this.route.url.split('/')[(this.route.url.split('/').length)-1];
    if(!/^([0-9])*$/.test(this.modulo)){
      //si no es numero no hace nada --> lo que importa validar es que si el modulo es un numero deberia guardarse documentos asociados al customers   
    }else{
      console.log(this.modulo);
      this.docEmpTable = this.route.url.split('/')[(this.route.url.split('/').length)-2];
      this.modulo="reporte";
      this.docEmp="true";
      this.docEmpId=this.route.url.split('/')[(this.route.url.split('/').length)-1];// es el id del customers --> /customer/443

    }

    this.datos.getType(this.modulo).subscribe(dato=>{
      console.log(dato);
      this.btype=1;
      this.id=dato.rety_name;
      this.index = this.id.findIndex(obj => obj.rety_name=="TODOS");
      this.id.splice(this.index,1);
      this.index = this.id.findIndex(obj => obj.rety_name=="Documentos Trabajador");
      if(this.index!=-1){
        this.id.splice(this.index,1);
      }     
      
      this.compid=dato.comp_id[0].comp_id;
      
    }, error=>{this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});})

  }

  reset() {
    this.myInputVariable.nativeElement.value = "";
    
  }

  handleFileInput(files: FileList) {
  	
  	this.nombre=((files.item(0).name).split("."))[0];
  	for (var i = 0; i < files.length; i++) {
      this.data.append(i.toString(), files.item(i), files[i].name);
    }
    
    this.fileToUpload = files.item(0);
    
    

  }
  change(type){
    console.log(type);
    this.subtypeflag=0;
    this.datos.getSubType(type).subscribe(data=>{
      this.subtypeflag=1;
      this.subid=data;
    });
    

  }

  checktype(){
      return this.type;


    }
  change_name(name){

    this.wname=name;
    console.log(this.wname);


  }

  change_code(code){

    this.wcodigo=code;
    console.log(this.wcodigo);


  }

  uploadFileToActivity() {

    this.data.append('comp_id',this.compid);
    this.data.append('subtype',this.subtype);
    this.data.append('clie_id',this.clie_id);
    this.data.append('docEmp',this.docEmp);
    this.data.append('docEmpTable',this.docEmpTable);
    this.data.append('docEmpId',this.docEmpId);    
    this.data.append('token',sessionStorage.getItem('token'));
    this.data.append('woit_id', ''); //lo declaro vacio porque no es necesario este datos, solo se usa para la subida de archivo de la nota de venta.
    this.data.append('asgt_id', ''); //lo declaro vacio porque no es necesario este datos, solo se usa para la subida de archivo de las visitas.
    
    if(this.subtype==undefined){
      this.notify.warning("Seleccione subtipo de documento");
    }    
    else{
      this.cont_id=this.route.url.split('/')[4];
      this.cust_id=this.route.url.split('/')[2];
      console.log(this.cust_id);
      console.log(this.cont_id);
      if(this.cust_id == 'reporte'){ this.data.append('cust_id',''); }else{ this.data.append('cust_id',this.cust_id); }
      if(this.cont_id == undefined){ this.data.append('cont_id',''); }else{ this.data.append('cont_id',this.cont_id); }
      this.data.append('desc',this.desc);
      this.subiendo=1;
      this.ok=0;
      this.renderer.setProperty(this.progreso.nativeElement, 'style', 'width:50%;');
      this.datos.postFile(this.data).subscribe(data => {
          this.renderer.setProperty(this.progreso.nativeElement, 'style', 'width:100%;');
          this.reload_compoment_report.emit(); // recarga el componente para que muestre el listado denuevo
          this.reload_compoment_docEmp.emit();
          this.subiendo=0;
          this.ok=1;
      }, error => {
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
        this.renderer.setProperty(this.progreso.nativeElement, 'style', 'width:0%;');
        this.subiendo=0;
        this.ok=2;
      });
    }
    
    
  }

  uploadFileData(){



  }

  ngOnInit() {

  }

}

