import { Component, OnInit, Renderer2, Input, Output, TemplateRef, EventEmitter, ViewChild, ElementRef,
        ComponentRef, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SnotifyService } from 'ng-snotify';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { VerticalChartComponent } from '../../../dashboard/charts/vertical-chart/vertical-chart.component';
import { DataTableComponent } from '../../../dashboard/data-table/data-table.component';
import { ChartInterface } from 'src/app/interfaces/chart-interface';
import { HorizontalChartComponent } from '../../../dashboard/charts/horizontal-chart/horizontal-chart.component';
import { TableAndChartHandlerComponent } from 'src/app/components/dashboard/table-and-chart-handler/table-and-chart-handler.component';

import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';
import { MatSelect } from '@angular/material';
// import * as $ from 'jquery';
import * as moment from 'moment'; 
import { DashboardFilterComponent } from '../saturacion-tuv/dashboard-filter/dashboard-filter.component';

export interface Mes {
  value: number;
  nombre: string;
}

export interface Anio {
  value: number;
  nombre: number;
}

export interface contratos {
  value: number;
  cont_name: string;
  cont_code: string;
}


@Component({
  selector: 'app-reporte-presencia-plagas',
  templateUrl: './reporte-presencia-plagas.component.html',
  styleUrls: ['./reporte-presencia-plagas.component.css']
})


export class ReportePresenciaPlagasComponent extends TableAndChartHandlerComponent implements OnInit, ChartInterface {

  public user_id = JSON.parse(sessionStorage.getItem('userData')).user_id;
  public clie_id = JSON.parse(sessionStorage.getItem('userData')).clie_id;

  public maxPlagueValue = 1;
  public plagueCharts = [];

  public dataSourceCompromiso: any[] = [];
  public dataSourceCompromisoAnio: any[] = [];
  public dataSourceDistribucion: any[] = [];
  public shows_dashboards_compromiso = 0;
  public shows_dashboards_distribucion = 0; 
  public filters;

  //table var
  displayedColumnsPlague: string[] = ['cantidad_avistamientos','año','mes', 'contrato'];
  displayedColumns: string[] = ['cantidad','anio','mes', 'cust_name'];
  displayTableCust: string[] = ['name','cont_name','value'];


  @ViewChild('riskLevelChartContainer', { read: ViewContainerRef }) riskLevelChartContainer : ViewContainerRef;
  @ViewChild('plagueChartContainer', { read: ViewContainerRef }) plagueChartContainer : ViewContainerRef;
  @ViewChild('plagueTableContainer', { read: ViewContainerRef }) plagueTableContainer : ViewContainerRef;
  @ViewChild('auditContainer', { read: ViewContainerRef }) auditContainer : ViewContainerRef;
  @ViewChild('filterPanel', { read: DashboardFilterComponent }) filterPanel : DashboardFilterComponent;


  public componentIndex = 0;
  private speciesGroups = [];
  private noSpeciesGroups = false;
  private noOtherSpecies = false;
  
  public maxPlaguePresence = 0;
  public plagueChartReferences = [];
  public plagueLoaded = {
    others: false,
    species: {}
  };

  public titles = {
    risk : 'Nivel de Riesgo',
    plague: 'Cargando...'
  }
  showRiskLevelCustomLegend:boolean = false;
  
  constructor(
    private dashboard: DashboardService,
    private notify: SnotifyService,
    public cfResolver : ComponentFactoryResolver
    ) 
  { 
    super(cfResolver);
    this.dashboard.get_contracts_profile(this.user_id,'reporte-presencia-plagas').subscribe(
        data=>{                 
          this.setContractData(data);
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  

  
  }// FIN CONSTRUCTOR


  ngOnInit() 
  {
    this.filters = { months:[] , years: [], contracts: [], properties:[] };
    this.loadProperties();
    this.aplyFilters();
  }


  setContractData(contracts)
  {
    this.filterPanel.setData('contract', this.parseContracts(contracts) );
  }

  
  parseContracts(contracts)
  {
    var contractsList = [];
    for( let contract of contracts){
      contractsList.push({ id: contract.value, value: contract.cont_name, code: contract.cont_code });
    }
    return contractsList;
  }


  isArrayEmpty( toCheck )
  {
    if(Array.isArray(toCheck)){
      if( toCheck.length>0 ){
        return false;
      }
    } 
    return true;
  }


  aplyFilters()
  {
    this.clearReferences();

    this.chartReferences = [];
    this.tableReferences = [];

    this.loadPlagueElements();
    this.loadAuditElements();
    
  }
  

  graphicPresenciaPlagas(){   $('.app_menu').css({"max-height":"1650px"}); }
  tablePresenciaPlagas(){     $('.app_menu').css({"max-height":"1250px"}); }
  graphicAuditoriaExterna(){  $('.app_menu').css({"max-height":"1850px"}); }


  registerPlagueSpecies(data)
  {
    data.forEach(speciesGroupName => {
      this.plagueLoaded.species[speciesGroupName] = false;
    });
  }

  loadProperties()
  {
    this.dashboard.getProperties().subscribe(
      data => {
        this.filterPanel.setData('property', data['properties'] );
      }
    );
  }
  
  loadPlagueElements()
  {
    this.showRiskLevelCustomLegend = false;
    this.noSpeciesGroups = false;
    this.maxPlaguePresence = 0;
    //creacion grafico Nivel de riesgo plaga
    var riskLevelChart :HorizontalChartComponent = this.createHorizontalChart(this.riskLevelChartContainer,'plaga','Cargando...',['col-lg-12']);
    this.dashboard.get_riesgo_filters(this.filters).subscribe(
      data=>{
        this.showRiskLevelCustomLegend = true;
        riskLevelChart.style.legend = false;
        riskLevelChart.title = data['title'];
        riskLevelChart.setDataSource(data['graficos_riesgo']); 
      }
      , error=>{ console.log(error); this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,}); })

    //Creacion tabla y graficos de plaga
    this.dashboard.getSpeciesGroups(this.filters).subscribe(
      data => {
        this.titles.plague = data['title'];
        this.speciesGroups = data['speciesGroups'];
        this.registerPlagueSpecies(this.speciesGroups);
        if( data.length==0 ) this.noSpeciesGroups = true;
        this.speciesGroups.forEach(speciesGroupName => {

          var chart = this.createVerticalChart(this.plagueChartContainer,'plaga','Locales con presencia de '+speciesGroupName,["col-lg-6","col-md-6"],this.plagueChartReferences);
          var table = this.createTable(this.plagueTableContainer,'Presencia '+speciesGroupName,['n°_notificacion','año','mes','punto_visita'],["col-lg-6",'col-md-6']);
          
          this.dashboard.get_graphic_filters(speciesGroupName,this.filters).subscribe(
            data => {
              
              chart.setDataSource(data['ngxcharts']);
              table.setDataSource(data['dataTable']);
              this.plagueCharts.push(chart);  
              this.setMaxPlagueValue(data['maxValue']);
              table.fontSize = 10;
              table.setColumnSizes({'punto_visita':50, 'año':12, 'n°_notificacion':23});
            }, error => { this.notify.warning(`Error al cargar informacion de presencia "${speciesGroupName}"`); }
          );
          return false;
        });
      }, error => {
        this.notify.error('Se produjo un error al cargar datos. Intentelo nuevamente.',{timeout:5000});
      } 
    );
    this.loadOtherSpecies();
  }


  loadOtherSpecies()
  {
    this.noOtherSpecies = false;
    this.dashboard.getOtherSpeciesGraphicData(this.filters).subscribe(
      data => {
        if( data['dataTable'].length>0 ){
          let chart = this.createVerticalChart(this.plagueChartContainer,'plaga','Presencia Otras Especies',["col-lg-6","col-md-6"],this.plagueChartReferences );
          let table = this.createTable(this.plagueTableContainer,'Presencia Otras Especies',['n°_notificacion','año','mes','punto_visita'],["col-lg-6",'col-md-6']);
          chart.setDataSource(data['ngxcharts']);
          table.setDataSource(data['dataTable']);
          this.plagueCharts.push(chart);
          this.setMaxPlagueValue(data['maxValue']);
          table.fontSize = 10;
          table.setColumnSizes({'punto_visita':50, 'año':12, 'n°_notificacion':23});
        }else this.noOtherSpecies = true;
      }, error => {
        console.log('ERROR: ',error);
      });
  }


  setMaxPlagueValue(newValue)
  {
    if( newValue > this.maxPlagueValue ) this.maxPlagueValue = newValue;
    
    for( var i = 0; i<this.plagueCharts.length; i++){
      let component = this.plagueCharts[i];
      component.style.yScaleMax = this.maxPlagueValue;
    }
  }


  loadAuditElements()
  {
    var container = this.auditContainer;
    var anuallyAverageChart = this.createVerticalChart(container,'auditoria','Cargando...',["col-lg-6","col-md-6"]);
    var monthlyAverageChart = this.createVerticalChart(container,'auditoria','Cargando...',["col-lg-6","col-md-6"]);
    var distributionChart = this.createHorizontalChart(container,'distribucion','Cargando...',['col-lg-12']);
    var table = this.createTable(container,'Detalle nota auditorías',['razon_social','ubicacion','total'],['col-lg-12']);
    table.columnSizes = { 'total' : 10, 'razon_social' : 30 };

    anuallyAverageChart.style.view[1] = 305;

    let dateLabel = this.buildDateLabel();
    anuallyAverageChart.setCustomTag(dateLabel);
    table.setCustomTag(dateLabel);



    this.dashboard.flt_total_anio(this.filters).subscribe(
      data=>{
        anuallyAverageChart.title = data['title'];
        anuallyAverageChart.setDataSource(data['datos_by_anio']); 
        table.setDataSource(data['datos_by_anio_Table']);
      }, error=>{
          console.log(error);
          this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
    });
    
    this.dashboard.flt_distribucion(this.filters).subscribe(
      data => { 
        distributionChart.title = data['title'];
        distributionChart.setDataSource(data['distribucion']); 
      }
      , error => { console.log(error); this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});}
    );  

    this.dashboard.flt_total_month(this.filters).subscribe(
      data=>{ 
        monthlyAverageChart.title = data['title'];
        monthlyAverageChart.setDataSource(data['datos_by_month']);
      }
      , error=>{ console.log(error); this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,}); }
    ); 
  }


  clearReferences()
  {
    this.riskLevelChartContainer.clear();
    this.plagueChartContainer.clear();
    this.plagueTableContainer.clear();
    this.auditContainer.clear();
  }


  onChangeFilter(event)
  {
    this.filters = event.filters;
    this.aplyFilters();
  }


  buildDateLabel()
  {
    var yearsString = '';
    for (var [n,year] of Object.entries(this.filters.years)) {
      yearsString+= year;
      if( parseInt(n) < (this.filters.years.length - 1) ){
        yearsString += ' - ';
      }
    }
    if( this.filters.years.length == 0 ){
      yearsString += new Date().getFullYear();
    }
    
    if( this.filters.years.lenth < 2){
      yearsString = "Año: "+yearsString;
    }else{
      yearsString = "Años: "+yearsString;
    }

    return [yearsString];
  }


  getRiskChartCustomLegend()
  {
    return `<div class="col-lg-6" style="padding:5px;background-color:#d6d8d9">
                <div class="row pl-4">
                    <div style="height:15px;width:15px;background-color:green;margin:5px 5px 0px 0px;"></div>
                    <div>Sin presencia de plagas de interés</div>
                </div>
                <div class="row pl-4">
                    <div style="height:15px;width:15px;background-color:yellow;margin:5px 5px 0px 0px;"></div>
                    <div>Presencia de plaga de interés con bajo impacto sanitario</div>
                </div>
                <div class="row pl-4">
                    <div style="height:15px;width:15px;background-color:red;margin:5px 5px 0px 0px;"></div>
                    <div>Presencia de plaga de interés con alto impacto sanitario</div>
                </div>
            </div>
            <p>(*): Si tiene dudas, favor contactarse con su encargado de cuent</p>`;
  }

}


