import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ContractDocsService {

  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl + 'api/';
  }

  // obtener los documentos asociados a un contrato.
  getDocumentsByContractId(contractId, segtyId, docsPerms): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'contracts/getDocs', { contId: contractId, segtyId, docsPerms, 'token': sessionStorage.getItem('token') });
  }

  updateDocumentName(documentId, newName, enable, reposActualName, reposRoute, userId,resutyId): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'contracts/updateRepo', { reposId: documentId, newName, enable, reposActualName, reposRoute, userId,resutyId, 'token': sessionStorage.getItem('token') });
  }


  deleteDocument(documentId): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'contracts/deleteDoc', { id: documentId, 'token': sessionStorage.getItem('token') });
  }



  uploadDocument(form: FormData): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'contracts/uploadDoc', form);
  }

  overrideDocumentS3(form: FormData): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'contracts/overrideDocS3', form);
  }


  getDocumentTypes(compId): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'contracts/getDocTypes', { compId, 'token': sessionStorage.getItem('token') });
  }

  getDocumentSubTypes(docTypeId): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + 'contracts/getDocSubTypes', { retyId: docTypeId });
  }



}
