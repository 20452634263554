
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { MAT_DATE_LOCALE, MatDatepicker } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from './date-formater';
import { BaseControlComponent } from '../base-control/base-control.component';
import { Component, OnInit, Input, EventEmitter, Output, HostBinding, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AppInjector } from 'src/app/services/app-injector.service';
import { Moment } from 'moment';
import { DateFormatService } from 'src/app/services/date-format.service';


@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.css'],
    providers: [
        {provide: DateAdapter, useClass: AppDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
      ]
})
export class DateControl extends BaseControlComponent implements OnInit
{
    @Input() placeholder :string  = 'fecha';
    @Input() initialDate :string ;//= (new Date().toLocaleDateString().split('-')).reverse().join('-');
    @Input() disableInput :boolean = true;
    @Input() disabled :boolean = false;
    @Input() innerClass = '';

    @Output('change') changeEvent = new EventEmitter();
    @Output('toggle') toggleEvent = new EventEmitter();
    @HostBinding('style.width') width = 'auto';

    @ViewChild('dateCtrl') dateCtrl :ElementRef;

    @ViewChild('picker',{read:MatDatepicker}) picker: MatDatepicker<Moment>;

    // changeDetector :ChangeDetectorRef;


    constructor(private dateAdapter:DateAdapter<any>,private changeDetector :ChangeDetectorRef,private formatter : DateFormatService)
    {
        super();
        this.dateAdapter.setLocale('es-CH');
        // this.changeDetector = AppInjector.get(ChangeDetectorRef);
    }

    ngOnInit()
    {
    }

    onChange(event)
    {
        let date = event.value instanceof Date 
                    ? this.formatter.format(event.value)
                    : '';
        this.updateValue(date);
        this.changeEvent.emit({event : date,control:this.data});
    }

    onToggle(event)
    {
        this.toggleEvent.emit({event : event,control:this.data});
    }

    clear()
    {
        this.data.value = '';
        this.innerValue = '';
        this.resetPicker();
    }

    resetPicker()
    {
        this.picker.select(undefined);
    }


}


