import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-grafico-barra',
  templateUrl: './grafico-barra.component.html',
  styleUrls: ['./grafico-barra.component.css']
})
export class GraficoBarraComponent implements OnInit {

  @Input() public datos;
  data: Object;

  constructor() { }

  ngOnInit() {
    this.data = {
      "chart": {
        "caption": this.datos.titulo,
        "subCaption": this.datos.subtitulo,
        "showBorder": this.datos.bordes,
        "bgColor": this.datos.colorFondo,
        "numberPrefix" : this.datos.prefijo,
        "showValues": this.datos.mostrarValor,
        "showPercentInTooltip": this.datos.mostrarPorcentaje,
        "yaxisname": this.datos.labelLateral,
        "xaxisname": this.datos.labelBajo,
        "decimals": this.datos.decimales,
        "theme": "fusion",
        "exportEnabled": this.datos.exportar,
        "plottooltext": this.datos.textoToolTip,
        "palettecolors": this.datos.colorBarra,
      },
      "data": this.datos.datos
    };
  }

}
