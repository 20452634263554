import { Component, OnInit,ViewContainerRef, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormControlDirective } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Task } from './task.model';
import { TaskModalService } from 'src/app/services/task-modal.service';

@Component({
  selector: 'app-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/*
  Esta clase aplica solo para comentarios sobre el grafico "Saturacion TUV Semanal por Plaga"
  ya que es el unico, hasta el momento, que requiere de inyeccion dinamica de componentes 
  (ViewContainerRef).
*/
export class TaskModalComponent implements OnInit,AfterViewInit {
  public saving = false; 
  private _d :any;

  //se utiliza esta clase para manejar los datos del formulario
  private task: Task;

  @Output() complete = new EventEmitter();
  @Output() edit = new EventEmitter();

  @ViewChild("container", {read: ViewContainerRef}) container: ViewContainerRef;
  @ViewChild("bodyContainer", {read: ElementRef}) bodyContainer: ElementRef;

  public chartRefSubject: BehaviorSubject<ViewContainerRef> = new BehaviorSubject<ViewContainerRef>(null);
  public bodyRefSubject: BehaviorSubject<ElementRef> = new BehaviorSubject<ElementRef>(null);
  
  public completeForm: FormGroup
  public type: string;
  private service : TaskModalService;
  public loading = true;
  public taskType;

  public totalConsumption = 0;
  public totalCapture = 0;

  constructor(
    public bsModalRef: BsModalRef,
    public vcRef: ViewContainerRef,
    private fBuilder : FormBuilder,
    private detector: ChangeDetectorRef
  ) { 
    // this.type = 'complete';
    this.task = new Task();
    // this.task.año = '2019';
    // this.task.mes = 'Noviembre';
    // this.completeForm = new FormGroup({
    //   'año' : new FormControl(this.task.año,[Validators.required]), 
    //   'mes' : new FormControl(this.task.mes,[Validators.required]), 
    //   'novedades' : new FormControl(this.task.novedades,[Validators.required,Validators.maxLength(350)]), 
    //   'riesgo' : new FormControl(this.task.riesgo,[Validators.required,Validators.maxLength(250)]), 
    //   'accion' : new FormControl(this.task.accion,[Validators.required,Validators.maxLength(400)]), 
    // })
  }

  setTask(task){
    this.task = task;
    this.detector.detectChanges();
  }


  ngOnInit() {
    if(this.type=="complete"){

    }
  }

  
  setData(data){
    this._d = data;
  }

  setBody(body : ElementRef){
    this.bodyContainer = body;
  }

  setCallback(){

  }

  setTaskService(service : TaskModalService){
    service.setChild(this);
    this.service = service;
  }

  submit(form){
    console.log('este tipo envio...............................')
    console.log(this.taskType);
    this.saving = true;
    this.task.anio = form.controls.año.value;
    this.task.mes = form.controls.mes.value;
    if(['TuvSemanalPlaga','RoedoresActividadDispositivos'].includes(this.taskType)){
      this.task.novedades = form.controls.novedades.value;
      this.task.riesgo = form.controls.riesgo.value;
      this.task.accion = form.controls.accion.value;
      if('RoedoresActividadDispositivos' == this.taskType){
        this.task.consumo = form.controls.consumo.value;
        this.task.captura = form.controls.captura.value;
      }
    }else if(['TuvSemanal','TuvMensual','RoedoresCaptura','RoedoresConsumo','RtuSemanalPlaga','RtuSemanal','RtuMensual','MstSemanalPlaga','MstSemanal','MstMensual'].includes(this.taskType)){
      this.task.comentario = form.controls.comentario.value;
    }

    if(this.type=='complete'){
      this.service.complete(this.task);
    }else{
      this.service.edit(this.task);
    }
  }

  ngAfterViewInit() {
    this.chartRefSubject.next(this.container);
    this.refresh();
  }

  refresh(){
    this.detector.detectChanges();
  }

  onCancelClicked() {
    this.bsModalRef.hide()
  }

  changeField(){
  }

  
  updateCount(event){
    var elm = event.target;
    var restante = elm.maxLength - elm.value.length;
    if(restante>0 && restante != elm.maxLength)   $("#"+elm.id).css("border-left", "5px solid #42A948");
    else $("#"+elm.id).css("border-left", "5px solid #a94442");
    $('.task-'+elm.id).text(restante);
  }

  setType(type:string){
    this.type = type;
    this.refresh();
  }

  setTaskType(taskType){
    this.taskType = taskType;
    this.refresh();
  }



  //funcion que actualiza valores de captura y consumo de dispositivos
  //al ingresar comentarios para facilitar la operacion del usuario
  updateDeviceActivityValues(data,type)
  {
    this.totalConsumption += data.totalConsumption;
    this.totalCapture += data.totalCapture;

    if( this.type=='complete'){
      this.task.consumo = this.totalConsumption.toString();
      this.task.captura = this.totalCapture.toString();
    }
  }

}
