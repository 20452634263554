import { Component, Input, EventEmitter, Output, HostBinding } from '@angular/core';
import { AppInjector } from 'src/app/services/app-injector.service';
import { Control } from 'src/app/models/control.model';

@Component({
  selector: 'app-base-control',
  templateUrl: './base-control.component.html',
  styleUrls: ['./base-control.component.css']
})
export class BaseControlComponent 
{    
    @Input() data : Control = null;
    hint :string = null;
    icon :string = null;
    placeholder :string = '';
    innerValue;
    defaultValue;
    disabled:boolean;

    // @Input() classes :string = ''; 
    @Input() mode : 'edit' | 'show';

    @Output('change') changeEvent = new EventEmitter;

    @HostBinding('class') classes: string = ''; 
    @HostBinding('style.display') display: string = 'inline-block'; 
    // @HostBinding('style.width.%') width :number = 100;

    
    constructor() 
    {
        // const myService = AppInjector.get(MyService);
    }



    getValue()
    {
        return this.data.value;
    }

    
    setValue(value)
    {
        this.data.value = value;
    }

    
    getData()
    {
        return this.data;
    }

    
    setData(data)
    {
        this.data = data;
        this.setDefaultValue(data.value);
        this.toDefault();
    }

    
    onChange(event)
    {
        this.updateValue(event.value);
        let eventData = {
            event : event,
            value: this.data.value,
            control: this.data
        }
        this.changeEvent.emit(eventData);
    }

    getInnerValue()
    {
        return this.innerValue;
    }

    updateValue(value)
    {
        this.innerValue = value;
        this.data.value = value;
    }

    enable()
    {
        this.disabled = false;
    }


    disable(){
        this.disabled = true;
    }


    setDefaultValue(value)
    {
        this.defaultValue = value; 
    }

    toDefault()
    {
        this.updateValue(this.defaultValue);
    }

    clear()
    {
        this.data.value = '';
        this.innerValue = '';
    }

    setDisplay(newDisplay)
    {
        this.display = newDisplay;
    }

    addClass(newClass :string){
        this.classes += newClass;
    }

}
