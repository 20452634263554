import { Component, OnInit } from '@angular/core';
import { BaseControlComponent } from '../base-control/base-control.component';

@Component({
	selector: 'checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss']
})
export class CheckboxControl extends BaseControlComponent implements OnInit
{

	constructor()
	{
		super();
	}

	ngOnInit()
	{}


	onChange(event){
		this.updateValue(event.checked);
		let eventData = {
            event : event,
            value: this.data.value,
            control: this.data
        }
        this.changeEvent.emit(eventData);
	}

}
