import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AssetMarkerComponent } from 'src/app/components/leaflet/asset-marker/asset-marker.component';


@Injectable({
  providedIn: 'root'
})
export class AssetsMapService {


  baseUrl: string;

  constructor(private http:HttpClient) { 
  	this.baseUrl = environment.apiUrl+'api/';
  }

  getAssets(): Observable <any[]>{
    return this.http.post<any[]>(this.baseUrl+'assets/get',{'token':sessionStorage.getItem('token')});
  }

  getWorkersTracks(): Observable <any[]>{
    return this.http.post<any[]>(this.baseUrl+'workers/tracks/get',{'token':sessionStorage.getItem('token')});
  }

  getVehiclesTracks(): Observable <any[]>{
    return this.http.post<any[]>(this.baseUrl+'vehicles/tracks/get',{'token':sessionStorage.getItem('token')});
  }


  getProperties(): Observable <any[]>{
    return this.http.post<any[]>(this.baseUrl+'properties/get',{'token':sessionStorage.getItem('token')});
  }

  getAssetServices(assetId): Observable <any[]>{
    return this.http.post<any[]>(this.baseUrl+'assets/getServices',{id: assetId, 'token':sessionStorage.getItem('token')});
  }

  getAllAssetServices(assets): Observable <any[]>{
    return this.http.post<any[]>(this.baseUrl+'assets/services/all',{assets: assets, 'token':sessionStorage.getItem('token')});
  }

  exportAssetServices(services,filters): Observable <any[]>{
    return this.http.post<any[]>(this.baseUrl+'assets/services/export',{services: services, filters:filters, 'token':sessionStorage.getItem('token')});
  }

  getUpdatedTrackings(): Observable <any[]>{
    return this.http.post<any[]>(this.baseUrl+'trackings/refresh',{'token':sessionStorage.getItem('token')});
  }

  getUpdatedAssets(markers : AssetMarkerComponent[], date :string = ''): Observable <any[]>{
    var assets = [];
    markers.forEach(m => { assets.push(m.getAsset().id); })
    console.log('AssetsMapService::getUpdatedAssets():assets',assets)
    return this.http.post<any[]>(this.baseUrl+'assets/refresh',{assets: assets, date: date, 'token': sessionStorage.getItem('token')});
  }


}
