import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-grafico-pastel',
  templateUrl: './grafico-pastel.component.html',
  styleUrls: ['./grafico-pastel.component.css']
})
export class GraficoPastelComponent implements OnInit {

  @Input() public datos;
  data: Object;

  constructor() {   }

  ngOnInit() {
    this.data = {
      "chart": {
        "caption": this.datos.titulo,
        "subCaption" : this.datos.subtitulo,
        "showBorder": this.datos.bordes,
        "bgColor": this.datos.colorFondo,
        "showValues": this.datos.mostrarValor,
        "showPercentInTooltip": this.datos.mostrarPorcentaje,
        "numberPrefix" : this.datos.prefijo,
        "enableMultiSlicing": "1",
        "theme": "fusion",
        "exportEnabled": this.datos.exportar
      },
      "data": this.datos.datos
    };
  }

}
