import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';
import { DataTableComponent } from '../../dashboard/data-table/data-table.component';


@Component({
    selector: 'LeafletTrackingPanel',
    templateUrl: './tracking-panel.component.html',
    styleUrls: ['./tracking-panel.component.css']
})
export class TrackingPanelComponent implements AfterViewInit {

    markers = [];

    @Output() selectedMarker = new EventEmitter();
    @ViewChild('table', {read:DataTableComponent} ) table :DataTableComponent;



    constructor() { }

    ngAfterViewInit() 
    {
        this.table.isHoverEnabled = true;
        this.table.customRowTitle = 'Click para centrar el mapa en este Elemento';
    }

    getDOM()
    {
        return document.getElementById('trackingPanel');
    }

    onSelectedAsset(event)
    {
        this.selectedMarker.emit(event);
    }

    setMarkers(markers)
    {
        this.markers = markers;
        let rows = this.markersToTableData(this.markers);
        this.updateTable(rows);
    }

    markersToTableData(markers)
    {
        var rows = [];
        for(let marker of markers){
            let a = marker.getVehicle();
            rows.push({
                'seguimiento'          : a.name
                , 'ultimo_servicio' : a.lastServiceDate
            });
        }
        return rows;
    }

    updateTable(tableData)
    {
        this.table.showAllColumns();
        this.table.setDataSource(tableData);
    }
    
    onMarkerRowClicked(event)
    {
        let marker = this.findMarkerByName( event.row.seguimiento ); 
        marker.centerMapOnMe();
    }

    findMarkerByName(seekedName)
    {
        var seekedMarker = null;
        for(let marker of this.markers){
            let a = marker.getVehicle();
            if( a.name == seekedName ){
                seekedMarker = marker;
                break;    
            }
        }
        return seekedMarker;
    }


    clearMarkers()
    {
        for(let marker of this.markers){
            marker.remove();
        }
        this.markers = [];
    }
}
