import { Component, OnInit, Renderer2, Input, Output, TemplateRef, EventEmitter, ViewChild, ElementRef, ComponentFactoryResolver, ViewContainerRef, AfterViewInit, NgZone, AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SnotifyService } from 'ng-snotify';
import { PrintService } from 'src/app/services/print.service';
import { DataTableComponent } from './../../dashboard/data-table/data-table.component';
import { HorizontalChartComponent } from '../../dashboard/charts/horizontal-chart/horizontal-chart.component';
import { LineChartComponent } from '../../dashboard/charts/line-chart/line-chart.component';
import { StackgroupHorizontalChartComponent } from '../../dashboard/charts/stackgroup-horizontal-chart/stackgroup-horizontal-chart.component';
import { GroupedVerticalChartComponent } from '../../dashboard/charts/grouped-vertical-chart/grouped-vertical-chart.component';

@Component({
    selector: 'print-dashboard',
    templateUrl: './printDashboard.component.html',
    styleUrls: ['./printDashboard.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintDashboardComponent implements OnInit, AfterViewInit, AfterViewChecked {

    private graphicVars: any;
    private gType;

    private error;

    public contractName;

    //TUV
    private dataSourceTUVSemanalPlagaMosca;
    private dataSourceTUVSemanalPlagaPolilla;
    private dataSourceTUVSemanalPlagaAvispas;
    private dataSourceTUVSemanalPlagaMosquitos;
    private dataSourceTUVSemanalPlagaPolillaGrano;
    public dataSourceTUVSemanal;
    public dataSourceTUVMensual;
    public listCommentTUVSemanaPlaga;
    public listCommentTUVSemanal;
    public listCommentTUVMensual;

    public dataSourceMSTSemanal;
    public dataSourceMSTMensual;
    public dataSourceRTUSemanal;
    public dataSourceRTUMensual;

    public listCommentMSTSemanaPlaga;
    public listCommentMSTSemanal;
    public listCommentMSTMensual;

    public listCommentRTUSemanaPlaga;
    public listCommentRTUSemanal;
    public listCommentRTUMensual;

    //ACTIVIDAD ROEDORES
    public dataSourceExterior;
    public dataSourceEstructural;
    public dataSourceInterior;
    public dataSourceCaptura;
    public dataSourceConsumo;
    public listCommentActiRoeMesZona;
    public listCommentCompAnualCapturaMes;
    public listCommentCompAnualConsumoMes;

    public gName;

    legendaNivelRiesgo = '';

    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = true;
    showXAxisLabel = true;
    showYAxisLabel = true;
    barMaxWidth = 15;
    captureLegend = 'Año';
    consumptionLegend = 'Año';

    viewTUV: any[] = [610, 340];
    viewTUVMensual: any[] = [900, 340];
    viewTUVSemPlaga: any[] = [1020, 220];
    viewActiRoedores: any[] = [850, 240];

    @ViewChild('templateActiRoeMesZona') templateActiRoeMesZona: ElementRef;
    @ViewChild('templateCompAnualCapturaMes') templateCompAnualCapturaMes: ElementRef;
    @ViewChild('templateCompAnualConsumoMes') templateCompAnualConsumoMes: ElementRef;
    @ViewChild('templateTUVSemanaPlaga') templateTUVSemanaPlaga: ElementRef;
    @ViewChild('templateTUVMensual') templateTUVMensual: ElementRef;
    @ViewChild('templateTUVSemanal') templateTUVSemanal: ElementRef;

    @ViewChild('tuvContainer', { read: ViewContainerRef }) tuvContainer: ViewContainerRef;
    @ViewChild('consumptionTableContainer', { read: ViewContainerRef }) consumptionTableContainer: ViewContainerRef;
    @ViewChild('captureTableContainer', { read: ViewContainerRef }) captureTableContainer: ViewContainerRef;
    @ViewChild('weeklyTuvContainer', { read: ViewContainerRef }) weeklyTuvContainer: ViewContainerRef;
    @ViewChild('monthlyTuvContainer', { read: ViewContainerRef }) monthlyTuvContainer: ViewContainerRef;

    @ViewChild('mstContainer', { read: ViewContainerRef }) mstContainer: ViewContainerRef;
    @ViewChild('weeklyMstContainer', { read: ViewContainerRef }) weeklyMstContainer: ViewContainerRef;
    @ViewChild('monthlyMstContainer', { read: ViewContainerRef }) monthlyMstContainer: ViewContainerRef;

    @ViewChild('rtuContainer', { read: ViewContainerRef }) rtuContainer: ViewContainerRef;
    @ViewChild('weeklyRtuContainer', { read: ViewContainerRef }) weeklyRtuContainer: ViewContainerRef;
    @ViewChild('monthlyRtuContainer', { read: ViewContainerRef }) monthlyRtuContainer: ViewContainerRef;


    @ViewChild('templateRTUSemanaPlaga') templateRTUSemanaPlaga: ElementRef;
    @ViewChild('templateRTUMensual') templateRTUMensual: ElementRef;
    @ViewChild('templateRTUSemanal') templateRTUSemanal: ElementRef;

    @ViewChild('templateMSTSemanaPlaga') templateMSTSemanaPlaga: ElementRef;
    @ViewChild('templateMSTMensual') templateMSTMensual: ElementRef;
    @ViewChild('templateMSTSemanal') templateMSTSemanal: ElementRef;

    private colorSchemeTUV = {
        domain: ['#3211AB']
    };
    private colorSchemeSemanalPlagas = {
        domain: ['#8064A2', '#9BBB59', '#C0504D', '#CF7C89', '#4F81BD']
        // domain: ['#3211AB','#F02E04','#F9FF1C','#1F8303','#871292','#00AFE8','#EA8707','#539802']
    };
    private colorSchemeLineChart = {
        domain: ['#E51807', '#3211AB']
        // domain: ['#3211AB','#F02E04','#F9FF1C','#1F8303','#871292','#00AFE8','#EA8707','#539802']
    };

    private colorSchemeActiRoedores = {
        domain: ['#8064A2', '#9BBB59', '#C0504D', '#CF7C89', '#4F81BD']
        // domain: ['#3211AB','#F02E04','#F9FF1C','#1F8303','#871292','#00AFE8','#EA8707','#539802']
    };

    public rdy = {
        comment: false
        , data: false
    };

    _time;

    get time() { return this._time; }

    constructor(
        private valPermiso: ValidaPermisosService,
        private dashboard: DashboardService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private printService: PrintService,
        private cfResolver: ComponentFactoryResolver,
        public zone: NgZone,
        public rednderer: Renderer2,
        public cDetector: ChangeDetectorRef
    ) {
        console.log('Print Dashboard COnstruct');

        this.error = 0;
        this.graphicVars = JSON.parse(this.route.snapshot.params['printParams']);
        console.log(this.graphicVars);
        this.gType = this.graphicVars.graphic;

        this._time = Date.now();
        zone.runOutsideAngular(() => {
            setInterval(() => {
                this._time = Date.now();
            }, 1);
        });
    }



    print(time = 3500) {
        this.cDetector.detectChanges();
        setTimeout(() => {
            // console.log(this.dataSourceInterior,this.gType,this.gName);
            console.log('timeout');
            this.printService.onDataReady();
        }, time);
    }



    ngAfterViewInit() {
        console.log('AfterViewInit => ', this.tuvContainer, this._time);
        this.draw();
    }

    ngAfterViewChecked() { }
    ngOnInit() { }


    private getMonthName(month){
        switch(month){
            case 1: return 'Enero';
            case 2:return 'Febrero'; 
            case 3: return 'Marzo';
            case 4: return 'Abril';
            case 5: return 'Mayo';
            case 6: return 'Junio';
            case 7: return 'Julio';
            case 8: return 'Agosto';
            case 9: return 'Septiembre';
            case 10: return 'Octubre';
            case 11: return 'Noviembre';
            case 12: return 'Diciembre';
            default : return '';
        }
    }

    getString(type){
        var string = '';
        if(type=='year') var toIterate = this.graphicVars.years; 
        if(type=='month') var toIterate = this.graphicVars.months; 
        console.log(toIterate);
        if( Array.isArray(toIterate) ){
            console.log('isArray')
            if(toIterate.length==0){
                string = 'Sin Selección';
            }else{
                var length = toIterate.length;
                for(let i=0; i<length;i++){
                    console.log('YEARSSSSS',toIterate[i]);
                    string += type=='month' 
                                ? this.getMonthName(toIterate[i]) 
                                : toIterate[i];
                    if(i<length-1) string += ', ';
                };
            }
        }else{
            console.log('isnt array');
            string = toIterate;
        }
        return string;
    }

    draw() {
        console.log('drawing', this.gType, this.contractName);
        let userId = this.graphicVars.userId;
        let years = this.graphicVars.years;
        let months = this.graphicVars.months;
        var contId = this.graphicVars.contIds;

        console.log('printDashboardComponent graphicVars => ', this.graphicVars);
        console.log('printDashboardComponent contId => ', contId);
        this.dashboard.getContractName(contId).subscribe(
            data => {
                this.contractName = data['name'];
            }, error => {
                console.log('print Dashboard, getting contract name error ', error);
            }
        )

        if (this.gType == "TuvMensual") {
            this.loadMonthlyTuv(userId, years, months, contId);
        } else if (this.gType == "TuvSemanal") {
            this.loadWeeklyTuv(userId, years, months, contId);
        } else if (this.gType == "TuvSemanalPlagas") {
            this.loadPlagueTuv(userId, years, months, contId);
        } else if (this.gType == "RoedoresActividadDispositivos") {
            this.loadRoedoresActividadDispositivo(userId, years, months, contId);
        } else if (this.gType == "RoedoresCaptura") {
            this.loadRoedoresCaptura(userId, years, months, contId);
        } else if (this.gType == "RoedoresConsumo") {
            this.loadRoedoresConsumo(userId, years, months, contId);
        }else if(this.gType=="MstSemanalPlagas"){
            this.loadPlagueMst(userId, years, months, contId);
        }else if(this.gType=="MstSemanal"){
            this.loadWeeklyMst(userId, years, months, contId);
        }else if(this.gType=="MstMensual"){
            this.loadMonthlyMst(userId, years, months, contId);
        }else if(this.gType=="RtuSemanalPlagas"){
            this.loadPlagueRtu(userId, years, months, contId);
        }else if(this.gType=="RtuSemanal"){
            this.loadWeeklyRtu(userId, years, months, contId);
        }else if(this.gType=="RtuMensual"){
            this.loadMonthlyRtu(userId, years, months, contId);
        } else {
            this.error = 1;
        }
        if (this.error == 1) this.print()
    }


    ngAfterContentInit() {
        console.log('afterContentInit => ', this.tuvContainer, this._time);
    }


    private loadRoedoresActividadDispositivo(userId, years, months, contId) {
        let cres = 0;
        this.gName = "Actividad en dispositivos de roedores segun zona y mes";
       
       
        this.dashboard.flt_dashboard_act_roedores(userId, 'interior', years, months, contId).subscribe(
            dato => {
                console.log(dato['actRoedores']);
                this.dataSourceInterior = dato['actRoedores'];
                cres++;
                if(cres==4){
                    this.print(5000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            });

        this.dashboard.flt_dashboard_act_roedores(userId, 'estructural', years, months, contId).subscribe(
            dato => {
                this.dataSourceEstructural = dato['actRoedores'];
                cres++;
                if(cres ==4){
                    this.print(5000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            });

        this.dashboard.flt_dashboard_act_roedores(userId, 'exterior', years, months, contId).subscribe(
            dato => {
                this.dataSourceExterior = dato['actRoedores'];
                cres++
                if(cres==4){
                    this.print(5000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            });
           
        if (this.error == 1) return false;
         
        this.dashboard.listCommentDashboard('gra-roedor-actividad-mes-zona', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentActiRoeMesZona = dato['datos'];
                cres++;
                if(cres==4){
                    this.print(5000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }

    private loadRoedoresCaptura(userId, years, months, contId) {
        var table = this.createTable(this.captureTableContainer, '', true, ['col-lg-12']);
        table.dontPaginate();
        table.columnReferences = { 'año': 'print-year-legend-capture' };
        this.gName = "Comparativa anual de captura en dispotivos según mes";
        let cres = 0;
        this.dashboard.flt_dashboard_captura(userId, years, months, contId).subscribe(
            dato => {
                table.setDataSource(dato['table']);
                this.dataSourceCaptura = dato['captura'];
                setTimeout(() => {
                    console.log('aplying table colors');
                    $(".print-year-legend-capture-0").html("<div style=\"margin-top:5px; border-radius:3px; float:left; width:15px; height:15px;background-color:#f00;\"></div>&nbsp;" + $(".print-year-legend-capture-0").html());
                    $(".print-year-legend-capture-1").html("<div style=\"margin-top:5px; border-radius:3px; float:left; width:15px; height:15px;background-color:#00f;\"></div>&nbsp;" + $(".print-year-legend-capture-1").html());
                }, 1500);
                cres++;
                if(cres==2){
                    this.print(1000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            });

        if (this.error == 1) return false;

        this.dashboard.listCommentDashboard('gra-roedor-captura-mes', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentCompAnualCapturaMes = dato['datos'];
                cres++;
                if(cres==2){
                    this.print();
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }

    private loadRoedoresConsumo(userId, years, months, contId) {
        var table = this.createTable(this.consumptionTableContainer, '', true, ['col-lg-12']);
        table.dontPaginate();
        let cres = 0;
        table.columnReferences = { 'año': 'print-year-legend-consumption' };
        this.gName = "Comparativa anual de consumo en dispositivos según mes";
        this.dashboard.flt_dashboard_consumo(userId, years, months, contId).subscribe(
            dato => {
                this.dataSourceConsumo = dato['consumo'];
                table.setDataSource(dato['table']);
                setTimeout(() => {
                    console.log('aplying table colors');
                    $(".print-year-legend-consumption-0").html("<div style=\"margin-top:5px; border-radius:3px; float:left; width:15px; height:15px;background-color:#f00;\"></div>&nbsp;" + $(".print-year-legend-consumption-0").html());
                    $(".print-year-legend-consumption-1").html("<div style=\"margin-top:5px; border-radius:3px; float:left; width:15px; height:15px;background-color:#00f;\"></div>&nbsp;" + $(".print-year-legend-consumption-1").html());
                }, 1500);
                cres++;
                if(cres==2){
                    this.print(2000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            });

        if (this.error == 1) return false;

        this.dashboard.listCommentDashboard('gra-roedor-consumo-mes', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentCompAnualConsumoMes = dato['datos'];
                cres++;
                if(cres==2){
                    this.print();
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }


    loadPlagueTuv(userId, years, months, contId) {
        this.gName = "Cantidad de Saturacion TUV por tipo de Trampa y Grupo de Especie";
       
        // var chart = this.createComboChartGroupStackHorizontal(this.tuvContainer, 'tuv', '', ['col-lg-12'])
        let cres = 0;
        this.dashboard.getTuvSaturationBySpeciesGroup(years, months, contId).subscribe(
            data => {
                console.log('dentro del subscribe');
                // var chartData = [];

                $.each(data['chart'],(month :any,chartData)=>{
                    var chart = this.createComboChartGroupStackHorizontal(this.tuvContainer,'tuv',month,['col-lg-12']);
                    chart.style.xScaleMax = data['maxValue'];
                    chart.style.view[0] = 900; 
                    chart.style.view[1] = months.length>0 ? (data['nBars']*17)+50 : 240;
                    chart.setDataSource(chartData);
                });


                $.each(data['tables'], (month: any, tableData) => {

                    // chartData.push({ 'name': month, 'series': tableData });
                    var table = this.createTable(this.tuvContainer, month, true, ['col-lg-12']);
                    table.fontSize = 10;
                    table.dontPaginate();
                    table.displayAllColumns = true;
                    table.setDataSource(tableData);

                    // this.tuvContainer.nativeElement.insertAdjacentHTML("<p style='page-break-before: always'>ESTO ES UNA PRUEBA</p>");
                })
                // chart.style.view[1] = (data['nBars'] * 12) + 240;
                // chart.setDataSource(data['chart']);
                // console.log(data['chart']);
                cres++;
                if(cres==2){
                    this.print(1000);  
                }
            }, error => {

            }
        );

        this.dashboard.listCommentDashboard('gra-tuv-saturacion-semana-plaga', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentTUVSemanaPlaga = dato['datos'];
                cres++;
                if(cres==2){
                    this.print(1000);
                }
                // this.ready('comment');     
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }

    loadWeeklyTuv(userId, years, months, contId) {
        this.gName = 'Promedio de saturación TUV semanal';
        console.log('dentro del grafico');
        let cres = 0;
        var chart = this.createGroupedVerticalChart(this.weeklyTuvContainer, 'tuv', '', ['col-lg-12']);
        this.dashboard.flt_tuv_semanal(userId, years, months, contId).subscribe(
            data => {
                chart.setDataSource(data['ngx-chart']);
                // this.dataSourceTUVSemanal  = data['TUVsemanal'];     
                console.log('cargada la data', data);
                cres++;
                if(cres==2){
                    this.print(1000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })

        this.dashboard.listCommentDashboard('gra-tuv-promedio-saturacion-semanal', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentTUVSemanal = dato['datos'];
                cres++;
                if(cres==2){
                    this.print(1000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }



    loadMonthlyTuv(userId, years, months, contId) {
        this.gName = "Promedio mensual de saturación TUV";
        var chart = this.createLineChart(this.monthlyTuvContainer, 'tuv', '', ['col-lg-12']);
        let cres = 0;
        this.dashboard.flt_tuv_mensual(userId, years, months, contId).subscribe(
            data => {
                chart.setDataSource(data['ngx-chart']);
                this.dataSourceTUVMensual = data['TUVmensual'];
                cres++;
                if(cres=2){
                    this.print(1000);
                }
                console.log('cargada la data');
            }, error => {
                console.log(error);
                this.error = 1;
            })
        this.dashboard.listCommentDashboard('gra-tuv-promedio-saturacion-mensual', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentTUVMensual = dato['datos'];
                console.log(dato['datos'], this.listCommentTUVMensual);
                cres++;
                if(cres==2){
                    this.print(1000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }

    //create Chart Functions
    createComboChartGroupStackHorizontal(container: ViewContainerRef, type: string = '', title: string = '', classes: any[] = []) {
        let chartFactory = this.cfResolver.resolveComponentFactory(StackgroupHorizontalChartComponent);
        let chartReference = container.createComponent(chartFactory);
        let currentChart = chartReference.instance;
        currentChart.selfRef = currentChart;
        currentChart.isLoading = true;
        currentChart.title = title;
        currentChart.setType(type);
        currentChart.setClasses(classes);
        console.log('end CreateLineChart function');
        return currentChart;
    }

    createGroupedVerticalChart(container: ViewContainerRef, type: string = '', title: string = '', classes: any[] = []) {
        let chartFactory = this.cfResolver.resolveComponentFactory(GroupedVerticalChartComponent);
        let chartReference = container.createComponent(chartFactory);
        let currentChart = chartReference.instance;
        currentChart.selfRef = currentChart;
        currentChart.isLoading = true;
        currentChart.title = title;
        currentChart.setType(type);
        currentChart.setClasses(classes);
        console.log('end CreateLineChart function');
        return currentChart;
    }

    createLineChart(container: ViewContainerRef, type: string = '', title: string = '', classes: any[] = []) {
        let chartFactory = this.cfResolver.resolveComponentFactory(LineChartComponent);
        let chartReference = container.createComponent(chartFactory);
        let currentChart = chartReference.instance;
        currentChart.selfRef = currentChart;
        currentChart.isLoading = true;
        currentChart.title = title;
        currentChart.setType(type);
        currentChart.setClasses(classes);
        console.log('end CreateLineChart function');
        return currentChart;
    }

    createTable(container: ViewContainerRef, title: string = '', columnsToDisplay: string[] | boolean, classes: any[] = []) {
        let tableFactory = this.cfResolver.resolveComponentFactory(DataTableComponent);
        let tableReference = container.createComponent(tableFactory);
        let currentTable = tableReference.instance;
        currentTable.selfRef = currentTable;
        currentTable.isLoading = true;
        currentTable.title = title;
        if (typeof columnsToDisplay == 'boolean') currentTable.displayAllColumns = columnsToDisplay;
        else currentTable.setHeaders(columnsToDisplay);
        currentTable.setClasses(classes);
        // currentTable.isColLg6 = true;
        // this.renderer.addClass(currentTable,'col-lg-6 col-md-12');
        return currentTable;
    }

    createHorizontalChart(container: ViewContainerRef, type: string = '', title: string = '', classes: string[] = []) {
        let chartFactory = this.cfResolver.resolveComponentFactory(HorizontalChartComponent);
        let chartReference = container.createComponent(chartFactory);
        let currentChart = chartReference.instance;
        currentChart.selfRef = currentChart;
        currentChart.setType(type);
        currentChart.title = title;
        currentChart.setClasses(classes);
        return currentChart;
    }

    ready(type: string) {
        this.rdy[type] = true;
        if (this.rdy.comment == true && this.rdy.data == true) {
            this.print(5000);
        }
    }

    loadPlagueMst(userId, years, months, contId) {
        this.gName = "Cantidad Feromonas MST según semana y tipo de plaga prevalente";
        let cres = 0;
        // var chart = this.createComboChartGroupStackHorizontal(this.mstContainer, 'tuv', '', ['col-lg-12'])
        this.dashboard.getFeromonasMstBySpeciesGroup(years, months, contId).subscribe(
            data => {
                console.log('dentro del subscribe');
                // var chartData = [];
                $.each(data['chart'],(month :any,chartData)=>{
                    var chart = this.createComboChartGroupStackHorizontal(this.mstContainer,'tuv',month,['col-lg-12']);
                    chart.style.xScaleMax = data['maxValue'];
                    chart.style.view[0] = 900; 
                    chart.style.view[1] = months.length>0 ? (data['nBars']*17)+50 : 240;
                    chart.setDataSource(chartData);
                });

                $.each(data['tables'], (month: any, tableData) => {

                    // chartData.push({ 'name': month, 'series': tableData });
                    var table = this.createTable(this.mstContainer, month, true, ['col-lg-12']);
                    table.fontSize = 10;
                    table.dontPaginate();
                    table.displayAllColumns = true;
                    table.setDataSource(tableData);

                    // this.mstContainer.nativeElement.insertAdjacentHTML("<p style='page-break-before: always'>ESTO ES UNA PRUEBA</p>");
                })
                // chart.style.view[1] = (data['nBars'] * 12) + 240;
                // chart.setDataSource(data['chart']);
                // console.log(data['chart']);
                cres++;
                if(cres==2){
                    this.print(1000);
                }
            }, error => {

            }
        );

        this.dashboard.listCommentDashboard('gra-feromona-mst-plaga-trampa-semanal', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentMSTSemanaPlaga = dato['datos'];
                cres++;
                if(cres==2){
                    this.print(1000);
                }
                // this.ready('comment');     
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }

    loadWeeklyMst(userId, years, months, contId) {
        this.gName = 'Cantidad de capturas en Feromona MST semanal';
        
        let cres = 0;
        var chart = this.createGroupedVerticalChart(this.weeklyMstContainer, 'tuv', '', ['col-lg-12']);
        this.dashboard.flt_feromona_mst_semanal(userId, years, months, contId).subscribe(
            data => {
                chart.setDataSource(data['ngx-chart']);    
                console.log('cargada la data', data);
                cres++;
                if(cres==2){
                    this.print(1000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })

        this.dashboard.listCommentDashboard('gra-feromona-mst-promedio-plaga-semanal', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentMSTSemanal = dato['datos'];
                cres++;
                if(cres==2){
                    this.print(1000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }

    loadMonthlyMst(userId, years, months, contId) {
        this.gName = "Cantidad de captura en Feromona MST mensual";
        
        var chart = this.createLineChart(this.monthlyMstContainer, 'tuv', '', ['col-lg-12']);
        let cres = 0;
        this.dashboard.flt_feromona_mst_mensual(userId, years, months, contId).subscribe(
            data => {
                chart.setDataSource(data['ngx-chart']);
                this.dataSourceMSTMensual = data['MSTmensual'];
                console.log('cargada la data');
                cres++;
                if(cres==2){
                    this.print(1000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })
        this.dashboard.listCommentDashboard('gra-feromona-mst-promedio-plaga-mensual', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentMSTMensual = dato['datos'];
                console.log(dato['datos'], this.listCommentMSTMensual);
                cres++;
                if(cres==2){
                    this.print(1000);
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }

    loadPlagueRtu(userId, years, months, contId) {
        this.gName = "Cantidad Feromonas RTU según semana y tipo de plaga prevalente";
        // var chart = this.createComboChartGroupStackHorizontal(this.rtuContainer, 'tuv', '', ['col-lg-12'])
        let cres =0;
        this.dashboard.getFeromonasRtuBySpeciesGroup(years, months, contId).subscribe(
            data => {
                console.log('dentro del subscribe');
                // var chartData = [];
                $.each(data['chart'],(month :any,chartData)=>{
                    var chart = this.createComboChartGroupStackHorizontal(this.rtuContainer,'tuv',month,['col-lg-12']);
                    chart.style.xScaleMax = data['maxValue'];
                    chart.style.view[0] = 900; 
                    chart.style.view[1] = months.length>0 ? (data['nBars']*17)+50 : 240;
                    chart.setDataSource(chartData);
                });

                $.each(data['tables'], (month: any, tableData) => {

                    // chartData.push({ 'name': month, 'series': tableData });
                    var table = this.createTable(this.rtuContainer, month, true, ['col-lg-12']);
                    table.fontSize = 10;
                    table.dontPaginate();
                    table.displayAllColumns = true;
                    table.setDataSource(tableData);
                    // this.rtuContainer.nativeElement.insertAdjacentHTML("<p style='page-break-before: always'>ESTO ES UNA PRUEBA</p>");
                })
                // chart.style.view[1] = (data['nBars'] * 12) + 240;
                // chart.setDataSource(data['chart']);
                // console.log(data['chart']);
                cres++;
                if(cres==2){
                    this.print(1000);  
                }
            }, error => {

            }
        );

        this.dashboard.listCommentDashboard('gra-feromona-rtu-plaga-trampa-semanal', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentRTUSemanaPlaga = dato['datos'];
                cres++;
                if(cres==2){
                    this.print(1000);  
                }
                // this.ready('comment');     
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }

   
    loadWeeklyRtu(userId, years, months, contId) {
        this.gName = 'Cantidad de capturas en Feromona RTU semanal';
        
        var chart = this.createGroupedVerticalChart(this.weeklyRtuContainer, 'tuv', '', ['col-lg-12']);
        let cres = 0;
        this.dashboard.flt_feromona_rtu_semanal(userId, years, months, contId).subscribe(
            data => {
                chart.setDataSource(data['ngx-chart']);
                // this.dataSourceRTUSemanal  = data['RTUsemanal'];     
                console.log('cargada la data', data);
                cres++;
                if(cres==2){
                    this.print(1000);  
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })

        this.dashboard.listCommentDashboard('gra-feromona-rtu-promedio-plaga-semanal', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentRTUSemanal = dato['datos'];
                cres++;
                if(cres==2){
                    this.print(1000);  
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }

    loadMonthlyRtu(userId, years, months, contId) {
        this.gName = "Cantidad de captura en Feromona RTU mensual";
        
        var chart = this.createLineChart(this.monthlyRtuContainer, 'tuv', '', ['col-lg-12']);
        let cres = 0;
        this.dashboard.flt_feromona_rtu_mensual(userId, years, months, contId).subscribe(
            data => {
                chart.setDataSource(data['ngx-chart']);
                this.dataSourceRTUMensual = data['RTUmensual'];
               
                cres++;
                if(cres==2){
                    this.print(1000);  
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })
        this.dashboard.listCommentDashboard('gra-feromona-rtu-promedio-plaga-mensual', userId, years, months, contId).subscribe(
            dato => {
                this.listCommentRTUMensual = dato['datos'];
                console.log(dato['datos'], this.listCommentRTUMensual);
                cres++;
                if(cres==2){
                    this.print(1000);  
                }
            }, error => {
                console.log(error);
                this.error = 1;
            })
    }
}
