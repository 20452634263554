import { Component, OnInit, Renderer2, Input, Output, TemplateRef, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SnotifyService } from 'ng-snotify';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public user_id = JSON.parse(localStorage.getItem('userData')).user_id;
  public clie_id = JSON.parse(localStorage.getItem('userData')).clie_id;
  public dashboard_group;
  public dagro_style;
  public loadingSelectDagro = 0;
  public type = 'seleccione';
  public cont_id = 25059;
  public shows_dashboards = 0;
  public viewFile;
  public viewContent;
  public viewStyleDash = 0;

  dataSource: Object;
  dataSourceRoedores: Object;
  dataSourceCucarachas: Object;
  //dataSourceAves: Object;
  dataSourceGato: Object;
  dataSourceMoscas: Object;  

  constructor(
    private valPermiso: ValidaPermisosService,
    private dashboard: DashboardService,
    private http:HttpClient,
    private route:Router,
    private notify: SnotifyService
  ){
    console.log('constructor dashboard');
     this.dashboard.get_dashboard_group(this.user_id).subscribe(
        data=>{
          this.dashboard_group = data;
          this.loadingSelectDagro = 1;
                         
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  
     
  }

  ngOnInit() {
    $('.app_menu').css({"max-height":"500px"});
  }

  change_dashboard_group(dagro){
    this.dagro_style = dagro[1];
    console.log(this.dagro_style);
    this.viewStyleDash = this.dagro_style;
    $('.app_menu').css({"max-height":"1650px"});//cambio el maximo de alto del menu lateral (custmers-options)
  }

  validaPermisos(permiso){
    return this.valPermiso.validaPermisos(permiso);
  }
}
