import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class PermisosService implements CanActivate {

  private permisos;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> |
    Promise<boolean> {
      console.log('permisos');
      this.permisos={};
      this.permisos = this.userData.getPermisos();
    let roles = route.data["permiso"];
    if (this.permisos.length == 0 || this.permisos.length == undefined) {
      this.router.navigate(['/home']);
      return false;
    } else if(this.permisos.filter(permiso => permiso.perm_key == roles).length > 0){
      return true;
    }
    this.router.navigate(['/home']);
    return false;
  }

  constructor(
    private userData: UserDataService,
    private router: Router
    ) { }
}
