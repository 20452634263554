import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';
import { SnotifyService } from 'ng-snotify';
@Injectable({
  providedIn: 'root'
})
export class AfterLoginService implements CanActivate {

  canActivate(reoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> |
  Promise<boolean> {
    console.log('after login');
    this.Token.verify().subscribe(data=>{
      
      localStorage.setItem('auth','1');
      
    },
    error=>{
      console.log(error);
      if(error.status==401){
        
        localStorage.setItem('auth','0');
        this.notify.warning("Por favor inicie sesion",{timeout: 5000,});
        this.router.navigate(['/login']);
      }
    });

    if(this.Token.loggedIn()){
      console.log('logged In');
      return true;
    } else{
      
      //redirecciona a login si no está logeado y quiere ingresar a pagina que requiere estar logeado
      if(localStorage.getItem('auth')!='1'){
        console.log("login1");
        
      }
     
        return false;

      
      
    }


  }

  constructor(
    private Token: TokenService,
    private router: Router,
    private notify: SnotifyService
  ) { }
}
