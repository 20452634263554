import { Component, OnInit, Renderer2, Input, Output, TemplateRef, EventEmitter, ViewChild, ElementRef,ComponentFactoryResolver, ViewContainerRef, AfterViewInit, ChangeDetectorRef, HostListener   } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SnotifyService } from 'ng-snotify';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { FormBuilder, FormGroup,FormControl,Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material';
import { PrintService } from './../../../../services/print.service'
import { DataTableComponent } from './../../../dashboard/data-table/data-table.component';
import { HorizontalChartComponent } from '../../../dashboard/charts/horizontal-chart/horizontal-chart.component';
import { LineChartComponent } from '../../../dashboard/charts/line-chart/line-chart.component';
import { TaskModalComponent } from './../../task-modal/task-modal.component';
import { Task } from './../../task-modal/task.model';
import { TaskModalService } from 'src/app/services/task-modal.service';
import * as moment from 'moment';
import { TableAndChartHandlerComponent } from 'src/app/components/dashboard/table-and-chart-handler/table-and-chart-handler.component';
// import { runInThisContext } from 'vm';
import { GroupedVerticalChartComponent } from 'src/app/components/dashboard/charts/grouped-vertical-chart/grouped-vertical-chart.component';
import { StackgroupHorizontalChartComponent } from 'src/app/components/dashboard/charts/stackgroup-horizontal-chart/stackgroup-horizontal-chart.component';
import { DashboardFilterComponent } from 'src/app/components/dashboard_html/rentokil/saturacion-tuv/dashboard-filter/dashboard-filter.component';
import { StackedVerticalChartComponent } from 'src/app/components/dashboard/charts/stacked-vertical-chart/stacked-vertical-chart.component';


export interface Mes {
  value: number;
  nombre: string;
}

export interface Anio {
  value: number;
  nombre: number;
}

export interface contratos {
  value: number;
  cont_name: string;
  cont_code: string;
}

export interface graphic {
  value: number;
  daphagra_title: string;
}

@Component({
  selector: 'app-saturacion-tuv',
  templateUrl: './saturacion-tuv.component.html',
  styleUrls: ['./saturacion-tuv.component.css']
})
export class SaturacionTuvComponent extends TableAndChartHandlerComponent implements OnInit,AfterViewInit {

  public user_id = JSON.parse(sessionStorage.getItem('userData')).user_id;
  public clie_id = JSON.parse(sessionStorage.getItem('userData')).clie_id;
  

  month: Mes[] = [
    {value: 1, nombre: 'Enero'},
    {value: 2, nombre: 'Febrero'},
    {value: 3, nombre: 'Marzo'},
    {value: 4, nombre: 'Abril'},
    {value: 5, nombre: 'Mayo'},
    {value: 6, nombre: 'Junio'},
    {value: 7, nombre: 'Julio'},
    {value: 8, nombre: 'Agosto'},
    {value: 9, nombre: 'Septiembre'},
    {value: 10, nombre: 'Octubre'},
    {value: 11, nombre: 'Noviembre'},
    {value: 12, nombre: 'Diciembre'},
  ];

  year: Anio[] = [
    {value: 2017, nombre: 2017},
    {value: 2018, nombre: 2018},
    {value: 2019, nombre: 2019},
    {value: 2020, nombre: 2020},
    {value: 2021, nombre: 2021},
    {value: 2022, nombre: 2022},
    {value: 2023, nombre: 2023},
    {value: 2024, nombre: 2024},
    {value: 2025, nombre: 2025},
  ];


  public taskPermission = {
    allowed: false,
    initialDate: 1,
    finalDate: 30,
    showTasksMonthsAgo:12
  };

  
  public dataContract:contratos[]=[];
  public contratosTodos=[];
  public dataGraphic:graphic[]=[];



  private tmService: TaskModalService;



  barMaxWidth = 15;


  //ACTIVIDAD ROEDORES

  public shows_dashboards_interior = 0;
  public shows_dashboards_estructural = 0;
  public shows_dashboards_exterior = 0;
  //Modal Añadir comentario
  public modalCommentDash: BsModalRef;
  public description;
  public dashboard_name;
  //Ver comentarios
  public modalViewCommentDash: BsModalRef;  
  public pagina: number = 1;
  public porPagina: number = 4;   // cantidad de filas a mostrar en la paginacion
  public porPaginaListadoTarea: number = 20;
  public paginaListadoTarea: number = 1;
  public editComment = false;
  //Edicion de comentario
  public descriptionEdicion;
  public anio;
  public mes;
  public fecha;
  public id_table_to_edit;
  public daphagrata_id; //
  //edicion comentario actividad roedores

  // edicion de comentario captura segun mes
  public coancadime_commentary;
  // edicion de comentario consumo segun mes
  public coancodime_commentary;
  //TUV mensual
  public daprsametu_commentary;
  //TUV semanal
  public daprsasetu_commentary;
  // listado de comentario actividad roedores
  public listCommentActiRoeMesZona;
  public listCommentCompAnualCapturaMes;
  public listCommentCompAnualConsumoMes;
  public loadingCommentActiRoeMesZona = 0;
  public loadingCommentCompAnualCapturaMes = 0;
  public loadingCommentCompAnualConsumoMes = 0;
  public loadingTuvModal = false;
  // listado de comentario tUV
  public listCommentTUVSemanaPlaga;
  public listCommentTUVSemanal;
  public listCommentTUVMensual;
  public loadingCommentTUVSemanaPlaga = 0;
  public loadingCommentTUVSemanal = 0;
  public loadingCommentTUVMensual = 0;

  public listCommentFeromonaRtuMensual; 
  public loadingCommentFeromonaRtuMensual = 0;
  public countFeromonaRtuMensual = 0;  

  public listCommentFeromonaRtuSemanal; 
  public loadingCommentFeromonaRtuSemanal = 0;
  public countFeromonaRtuSemanal = 0;  

  public listCommentFeromonaRtuPlaga; 
  public loadingCommentFeromonaRtuPlaga = 0;
  public countFeromonaRtuPlaga = 0;  

  public listCommentFeromonaMstMensual; 
  public loadingCommentFeromonaMstMensual = 0;
  public countFeromonaMstMensual = 0;  

  public listCommentFeromonaMstSemanal; 
  public loadingCommentFeromonaMstSemanal = 0;
  public countFeromonaMstSemanal = 0;  

  public listCommentFeromonaMstPlaga; 
  public loadingCommentFeromonaMstPlaga = 0;
  public countFeromonaMstPlaga = 0;  

  //
  public listAllTask;
  public loadingAllTask = 0;
  public completeTaskComment = false;
  displayedColumns: string[] = ['anio','mes', 'fecha','cont_name', 'grafico', 'acciones'];
  pageSizeOptions: number[] = [15, 25, 50, 75];
  @ViewChild('allTaskSort', {read: MatSort}) allTaskSort: MatSort;
  @ViewChild('paginatorAllTask', {read: MatPaginator}) paginatorAllTask: MatPaginator;
  public taskPage = 1;


  //visualizacion graficos / tablas
  public viewGraphic = true;
  public loadingEdit = 0;
  public cant_pending_task = 0;
  public cant_pending_task_all =0;
  public task_list_size = 0;
  public shows_task_table =0;

  //loagin grafico modal template
  public loadingGraphicModal = 0;
  public loadingTuv;
  public loadingDeviceActivity = { perimetroExterior: false, perimetroEstructural: false, interior: false };

  //configuracion del modal
  public config = {
    backdrop: true , //Fondo oscuro 
    ignoreBackdropClick: true, //para que no se cierre al clickear fuera del formulario 
    class: 'modal-lg',
    keyboard: false //para que no se cierre al apretar escape 
  };


  //count listado done comentario
  public countActiRoeMesZona = 0;
  public countCompAnualCapturaMes = 0;
  public countCompAnualConsumoMes = 0;
  public countTUVSemanaPlaga = 0;
  public countTUVMensual = 0;
  public countTUVSemanal = 0;
  

  public contractsListText : string = 'Ninguno'


  public selectedContract;
  public taskOptions;
  //
  public deviceCharts = [];
  public maxDeviceValue = 0;
  
  public titles = {
    weeklyTuv : 'Cargando...',
    monthlyTuv : 'Cargando...',
    tuvSaturationByPlague : 'Cargando...',
    deviceActivity: 'Cargando...',
    capture : 'Cargando...',
    consumption : 'Cargando...',
    feromonasMSTByPlague:'Cargando...',
    feromonasMSTweekly:'Cargando...',
    feromonasMSTmonthly:'Cargando...',
    feromonasRTUByPlague:'Cargando...',
    feromonasRTUweekly:'Cargando...',
    feromonasRTUmonthly:'Cargando...'
  }

  @ViewChild('testRef') testRef: ElementRef;
  
  @ViewChild('captureContainer', {read: ViewContainerRef}) captureContainer: ViewContainerRef;
  @ViewChild('consumptionContainer', {read: ViewContainerRef}) public consumptionContainer: ViewContainerRef;
  
  
  @ViewChild('tuvSaturationChartContainer', {read: ViewContainerRef}) tuvSaturationChartContainer: ViewContainerRef;
  @ViewChild('tuvContainerModal', {read: ViewContainerRef}) tuvContainerModal: ViewContainerRef;
  @ViewChild('tuvMonthlySaturationContainer', {read: ViewContainerRef}) tuvMonthlySaturationContainer: ViewContainerRef;
  @ViewChild('tuvWeeklySaturationContainer', {read: ViewContainerRef}) tuvWeeklySaturationContainer: ViewContainerRef;
  @ViewChild('deviceActivityContainer', {read: ViewContainerRef}) deviceActivityContainer: ViewContainerRef;
  
  @ViewChild('FeromonasMstSaturationChartContainer', {read: ViewContainerRef}) FeromonasMstSaturationChartContainer: ViewContainerRef;
  @ViewChild('FeromonaMstWeeklySaturationContainer', {read: ViewContainerRef}) FeromonaMstWeeklySaturationContainer: ViewContainerRef;
  @ViewChild('FeromonaMstMonthlySaturationContainer', {read: ViewContainerRef}) FeromonaMstMonthlySaturationContainer: ViewContainerRef;
  @ViewChild('FeromonasRtuSaturationChartContainer', {read: ViewContainerRef}) FeromonasRtuSaturationChartContainer: ViewContainerRef;
  @ViewChild('FeromonaRtuWeeklySaturationContainer', {read: ViewContainerRef}) FeromonaRtuWeeklySaturationContainer: ViewContainerRef;
  @ViewChild('FeromonaRtuMonthlySaturationContainer', {read: ViewContainerRef}) FeromonaRtuMonthlySaturationContainer: ViewContainerRef;
  //filters
  @ViewChild('weeklyTuvFilter') weeklyTuvFilter: DashboardFilterComponent;
  @ViewChild('monthlyTuvFilter') monthlyTuvFilter: DashboardFilterComponent;
  @ViewChild('deviceActivityFilter') deviceActivityFilter: DashboardFilterComponent;
  @ViewChild('plagueTuvFilter') plagueTuvFilter: DashboardFilterComponent;
  @ViewChild('captureFilter') captureFilter: DashboardFilterComponent;
  @ViewChild('consumptionFilter') consumptionFilter: DashboardFilterComponent;
  @ViewChild('tasksFilter') tasksFilter: DashboardFilterComponent;

  @ViewChild('weeklyMstFilter') weeklyMstFilter: DashboardFilterComponent;
  @ViewChild('monthlyMstFilter') monthlyMstFilter: DashboardFilterComponent;
  @ViewChild('plagueMstFilter') plagueMstFilter: DashboardFilterComponent;
  
  @ViewChild('weeklyRtuFilter') weeklyRtuFilter: DashboardFilterComponent;
  @ViewChild('monthlyRtuFilter') monthlyRtuFilter: DashboardFilterComponent;
  @ViewChild('plagueRtuFilter') plagueRtuFilter: DashboardFilterComponent;

  chartFilters = {
    'weeklyTuv' : {'years':[],'months':[],'contracts':[]}
    , 'monthlyTuv' : {'years':[],'months':[],'contracts':[]}
    , 'plagueTuv' : {'years':[],'months':[],'contracts':[]}
    , 'deviceActivity' : {'years':[],'months':[],'contracts':[]}
    , 'capture' : {'years':[],'months':[],'contracts':[]}
    , 'consumption' : {'years':[],'months':[],'contracts':[]}
    ,'weeklyRtu' : {'years':[],'months':[],'contracts':[]}
    , 'monthlyRtu' : {'years':[],'months':[],'contracts':[]}
    , 'plagueRtu' : {'years':[],'months':[],'contracts':[]}
    ,'weeklyMst' : {'years':[],'months':[],'contracts':[]}
    , 'monthlyMst' : {'years':[],'months':[],'contracts':[]}
    , 'plagueMst' : {'years':[],'months':[],'contracts':[]}
  };

  constructor(
    private valPermiso: ValidaPermisosService,
    private dashboard: DashboardService,
    private http:HttpClient,
    private route:Router,
    private notify: SnotifyService,
    private modalService: BsModalService,
    public printService : PrintService,
    public cfResolver : ComponentFactoryResolver,
    public cdRef : ChangeDetectorRef ,
    public formBuilder : FormBuilder,
    public taskContainer: ViewContainerRef
    
    ) { 
      super(cfResolver);
    }// FIN DEL CONSTRUCTOR

  nextTaskPage(){
    this.loadTasks(this.taskPage+1);
  }

  prevTaskPage(){
    this.loadTasks(this.taskPage-1);
  }



  loadTasks(taskPage){
    this.shows_task_table = 0;
    $(".btn-task").prop('disabled',true);
    this.taskPage = taskPage;
    var t = this.taskOptions;
    this.dashboard.listAllCommentTask(t.contracts,this.taskPage).subscribe(
      dato=>{   
        
        //this.listAllTask = dato;   
        
        if(taskPage == 1 ){
          $(".btn-tasks-prev").hide();
          $(".btn-tasks-next").show();
        }else if( taskPage * parseInt(dato['taskListSize']) > parseInt(dato['nTasks']) ){
          $(".btn-tasks-prev").show();
          $(".btn-tasks-next").hide();
        }else{
          $(".btn-tasks-prev").show();
          $(".btn-tasks-next").show();
        }
        
        this.cant_pending_task = dato['count'];
        this.cant_pending_task_all = dato['nTasks'];
        this.task_list_size = dato['taskListSize'];
        this.listAllTask = new MatTableDataSource(dato['datos']);
        
        this.listAllTask.sort = this.allTaskSort;
        this.listAllTask.paginator = this.paginatorAllTask;
          this.shows_task_table = 1;
          $(".btn-task").prop('disabled',false);    
        this.listAllTask.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'fecha': return new Date(item.fecha);     
            default: return item[property];
          }
        }; 

        this.loadingAllTask = 1;     
        if(this.listAllTask.length == 0){ this.listAllTask.length = 0; this.loadingAllTask = 2;  }     
        
                      
    },error=>{
      console.log(error);
      this.loadingAllTask = 3;
      this.notify.warning("Error al cargar tareas:\nConexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })   
  }



  openTask(){
    this.viewGraphic = false;
    $('.charts-container').hide(1000,function(){
      $('.tasks-container').show(1000);
    });
    $('#btnGraphic').removeClass('active');
    // $('.btnTask').addClass('active');
    if( typeof this.listAllTask === "undefined" ){
      this.loadTasks(1); 
    }
  }

  openGraphic(){
    this.viewGraphic = true;
    $('.tasks-container').hide(1000,function(){
      $('.charts-container').show(1000);
    });
    $('#btnTask').removeClass('active');
    // $('#btnGraphic').addClass('active');
  }

  graphicActiRoedores(){
    $('.app_menu').css({"max-height":"3000px"});
  }

  graphicTUV(){
    $('.app_menu').css({"max-height":"2700px"});
  }

  graphicActiFeromonasMst(){
    $('.app_menu').css({"max-height":"3000px"});
  }
  graphicActiFeromonasRtu(){
    $('.app_menu').css({"max-height":"3000px"});
  }

  showLoading(){
    this.loadingDeviceActivity = { perimetroExterior: true, perimetroEstructural: true, interior: true };
  }


  aplyFiltersToAllCharts(){


    this.clearReferences();
    
    this.loadCaptureChart();
    this.loadConsumptionChart();
    this.loadTuvSaturationByPlagueChart();
    // this.showLoading();
    console.log('cargando el componente...................................')
    this.loadWeeklyTuvChart();
    this.loadMonthlyTuvChart();
    this.loadDeviceActivityChart();

    this.loadFeromonasMstByPlagueChart();
    this.loadWeeklyFeromonaMstChart();
    this.loadMonthlyFeromonaMstChart();
    
    this.loadFeromonasRtuByPlagueChart();
    this.loadWeeklyFeromonaRtuChart();
    this.loadMonthlyFeromonaRtuChart();
  }//filtros


  

  guardarComentario(){
      this.dashboard.addCommentDashboard(this.dashboard_name,this.description).subscribe(
        dato=>{                     
          console.log('id_comment  :  '+ dato);                         
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
      }) 
      this.dashboard_name = '';
      this.description = '';
  }


  editCommentActiRoeMesZona(anio,mes,fecha,acrosemezo_id,consumo,captura,novedades,facto_riesgo,accion,cont_id,template: TemplateRef<any>){
    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);
    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    task.riesgo = facto_riesgo;
    task.accion = accion;
    task.novedades = novedades;
    task.captura = captura;
    task.consumo = consumo;
    var filter = {
      years : anio
      , months : [this.getNumberMonth(mes)]
      , contracts : cont_id
    }
    this.modalViewCommentDash.content.setType("edit");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskType('RoedoresActividadDispositivos');
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.modalViewCommentDash.content.loading = false;
      this.taskContainer = ref;
      if (this.taskContainer) {
        
        this.loadDeviceActivityChart(this.taskContainer,filter);

      }
    })

    this.id_table_to_edit = acrosemezo_id;
  }

  editCommentCapturaMes(anio,mes,fecha,coancadime_id,comentario,cont_id,template: TemplateRef<any>){    
    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);
    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    task.comentario = comentario;
    var filter = {
      years : anio
      , months : [this.getNumberMonth(mes)]
      , contracts : cont_id
    }
    this.modalViewCommentDash.content.setType("edit");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskType('RoedoresCaptura');
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.modalViewCommentDash.content.loading = false;
      this.taskContainer = ref;
      if (this.taskContainer) {
        
        this.loadCaptureChart(this.taskContainer,filter);

      }
    })

    this.id_table_to_edit = coancadime_id;
  }

  editCommentConsumoMes(anio,mes,fecha,coancodime_id,comentario,cont_id,template: TemplateRef<any>){    
    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);
    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    task.comentario = comentario;
    var filter = {
      years : anio
      , months : [this.getNumberMonth(mes)]
      , contracts : cont_id
    }
    this.modalViewCommentDash.content.setType("edit");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskType('RoedoresConsumo');
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.modalViewCommentDash.content.loading = false;
      this.taskContainer = ref;
      if (this.taskContainer) {
        this.loadConsumptionChart(this.taskContainer,filter);
      }
    })

    this.id_table_to_edit = coancodime_id;
  }

  editCommentTUVSemanaPlaga(anio,mes,id,novedades,facto_riesgo,accion,cont_id,template: TemplateRef<any>){
      this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);

      this.tmService = new TaskModalService(this);
      let task = new Task();
      task.anio = anio;
      task.mes = mes;
      task.riesgo = facto_riesgo;
      task.accion = accion;
      task.novedades = novedades;
      var filter = {
        years : anio
        , months : [this.getNumberMonth(mes)]
        , contracts : cont_id
      }
      this.modalViewCommentDash.content.setType("edit");
      this.modalViewCommentDash.content.setTask(task);
      this.modalViewCommentDash.content.setTaskType('TuvSemanalPlaga');
      this.modalViewCommentDash.content.setTaskService(this.tmService);
      this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
        this.modalViewCommentDash.content.loading = false;
        this.taskContainer = ref;
        if (this.taskContainer) {
          
          this.loadTuvSaturationByPlagueChart(this.taskContainer,filter);

        }
      })

      this.id_table_to_edit = id;
  }

  editCommentTUVSemanal(anio,mes,id,comentario,cont_id,template: TemplateRef<any>){
    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);

    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    task.comentario = comentario;
    var filter = {
      years : anio
      , months : [this.getNumberMonth(mes)]
      , contracts : cont_id
    }
    this.modalViewCommentDash.content.setType("edit");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskType('TuvSemanal');
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.modalViewCommentDash.content.loading = false;
      this.taskContainer = ref;
      if (this.taskContainer) {
        this.loadWeeklyTuvChart(this.taskContainer,filter);
      }
    })
    this.id_table_to_edit = id;

  }

  editCommentTUVMensual(anio,mes,id,comentario,cont_id,template: TemplateRef<any>){
    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);
    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    task.comentario = comentario;
    var filter = {
      years : [anio]
      , months : [this.getNumberMonth(mes)]
      , contracts : cont_id
    }
    this.modalViewCommentDash.content.setType("edit");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskType('TuvMensual');
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.modalViewCommentDash.content.loading = false;
      this.taskContainer = ref;
      if (this.taskContainer) {
        this.loadMonthlyTuvChart(this.taskContainer,filter);
      }
    })
    this.id_table_to_edit = id;
  }


  // Actividad Roedores
  editarComentarioActiRoeMesZona(task){
    if(this.validateFormActiRoeMesZona(task) == true){

      this.dashboard.editCommentActiRoeMesZona(this.user_id,this.id_table_to_edit,task.consumo,task.captura,task.novedades,task.riesgo,task.accion).subscribe(
          dato=>{ 
            
            this.loadDeviceActivityTasks();
            this.modalViewCommentDash.hide();

        },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      }) 
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
    }

  }

  editarComentarioCapturaMes(task){
    if(this.validateFormTaskCapturaMes(task) == true) { 
      this.dashboard.editCommentCompAnualCapturaMes(this.user_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 

            this.loadCaptureTasks();    
            this.modalViewCommentDash.hide();
                               
      },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      }) 
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
    }

  }

  editarComentarioConsumoMes(task){
    if(this.validateFormTaskConsumoMes(task) == true){
      this.dashboard.editCommentCompAnualConsumoMes(this.user_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 

            this.loadConsumptionTasks();
            this.modalViewCommentDash.hide();
                               
      },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,}); 
      })
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
    }

  }

  // TUV

  editarComentarioTUVSemanaPlaga(task: Task){    
    if(this.validateFormTaskTUVSemanaPlaga(task) == true){
      this.dashboard.editCommentTUVSemanaPlaga(this.user_id,this.id_table_to_edit,task.novedades,task.riesgo,task.accion).subscribe(
          dato=>{ 
            //actualiza tareas
            this.loadTuvSaturationByPlagueTasks();  
            this.modalViewCommentDash.hide();
     
        },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      }) 
    }else{
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.tmService.formError();
    }

  }

  editarComentarioTUVSemanal(task){
    if(this.validateFormTaskTUVSemanal(task) == true){
      this.dashboard.editCommentTUVSemanal(this.user_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 
  
            this.loadWeeklyTuvTasks();          
            this.modalViewCommentDash.hide();
                     
        },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      })
    }else{
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.tmService.formError();
    } 
  }

  editarComentarioTUVMensual(task){
    if(this.validateFormTaskTUVMensual(task) == true){
      this.dashboard.editCommentTUVMensual(this.user_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{
            this.loadMonthlyTuvTasks();          
            this.modalViewCommentDash.hide();
                     
        },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      }) 
    }else{
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.tmService.formError();
    }

  }



  completeTask(id,anio,mes,table,related_id,cont_id,tag){
    console.log('este tag......'+tag)
    this.anio = anio;
    this.mes = mes;
    this.daphagrata_id = id;
    this.id_table_to_edit = related_id;
    this.completeTaskComment = true;

    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);

    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    var filter = {
      years : anio
      , months : [this.getNumberMonth(mes)]
      , contracts : this.taskOptions.contracts
    }
    this.modalViewCommentDash.content.setType("complete");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.taskContainer = ref;
      if (this.taskContainer) {


        switch (tag) {
          case "gra-roedor-actividad-mes-zona":
            this.modalViewCommentDash.content.setTaskType("RoedoresActividadDispositivos");
            this.loadDeviceActivityChart(this.taskContainer,filter);
            break;

          case "gra-roedor-captura-mes":
            this.modalViewCommentDash.content.setTaskType("RoedoresCaptura");
            this.loadCaptureChart(this.taskContainer,filter);
            break;

          case "gra-roedor-consumo-mes":
            this.modalViewCommentDash.content.setTaskType("RoedoresConsumo");
            console.log('enviando filtros l800 '+filter)
            this.loadConsumptionChart(this.taskContainer,filter);
            break;

          case "gra-tuv-saturacion-semana-plaga":
            this.modalViewCommentDash.content.setTaskType("TuvSemanalPlaga");
            this.loadTuvSaturationByPlagueChart(this.taskContainer,filter);
            break;

          case "gra-tuv-promedio-saturacion-semanal":
            this.modalViewCommentDash.content.setTaskType("TuvSemanal");
            this.loadWeeklyTuvChart(this.taskContainer,filter);
            break;

          case "gra-tuv-promedio-saturacion-mensual":
            this.modalViewCommentDash.content.setTaskType("TuvMensual");
            this.loadMonthlyTuvChart(this.taskContainer,filter);
            break;


          
          case "gra-feromona-mst-plaga-trampa-semanal":
            this.modalViewCommentDash.content.setTaskType("MstSemanalPlaga");
            this.loadFeromonasMstByPlagueChart(this.taskContainer,filter);
            break;

          case "gra-feromona-mst-promedio-plaga-semanal":
            this.modalViewCommentDash.content.setTaskType("MstSemanal");
            this.loadWeeklyFeromonaMstChart(this.taskContainer,filter);
            break;

          case "gra-feromona-mst-promedio-plaga-mensual":
            this.modalViewCommentDash.content.setTaskType("MstMensual");
            this.loadMonthlyFeromonaMstChart(this.taskContainer,filter);
            break;

          case "gra-feromona-rtu-plaga-trampa-semanal":
            this.modalViewCommentDash.content.setTaskType("RtuSemanalPlaga");
            this.loadFeromonasRtuByPlagueChart(this.taskContainer,filter);
            break;

          case "gra-feromona-rtu-promedio-plaga-semanal":
            this.modalViewCommentDash.content.setTaskType("RtuSemanal");
            this.loadWeeklyFeromonaRtuChart(this.taskContainer,filter);
            break;

          case "gra-feromona-rtu-promedio-plaga-mensual":
            this.modalViewCommentDash.content.setTaskType("RtuMensual");
            this.loadMonthlyFeromonaRtuChart(this.taskContainer,filter);
            break;

          
          default:
            this.loadingGraphicModal = 0;    
            alert('No existe templete ' + tag +' por favor crear templete correspondiente');
            break;
        }    
      }
    })
  }



  //completar tarea actividad roedores

  completeTaskActiRoeMesZona(task){
    this.loadingEdit = 1;
    this.loadingAllTask = 0; 

    if(this.validateFormActiRoeMesZona(task) == true){

      this.dashboard.completeTaskActiRoeMesZona(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.consumo,task.captura,task.novedades,task.riesgo,task.accion).subscribe(
        dato=>{ 

          this.loadTasks(1);
          this.loadDeviceActivityTasks();
          this.modalViewCommentDash.hide();

        },error=>{
          console.log(error);
          this.loadingGraphicModal = 0; 
          this.modalViewCommentDash.hide();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
        }) 

    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }

    

  }

  validateFormActiRoeMesZona(task){
    if(task.consumo === ''|| task.consumo === undefined || task.consumo==null 
      && task.captura === '' || task.captura === undefined || task.captura==null 
      && task.novedades === '' || task.novedades === undefined || task.novedades==null 
      && task.riesgo === '' || task.riesgo === undefined || task.riesgo==null
      && task.accion ==='' || task.accion === undefined || task.accion==null){
      return false;
    }else{
      return true;
    }
  }

  completeTaskCapturaMes(task){

    this.loadingEdit = 1;
    this.loadingAllTask = 0;  
    if(this.validateFormTaskCapturaMes(task) == true) { 
      this.dashboard.completeTaskCompAnualCapturaMes(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{

            this.loadTasks(1);
            this.loadCaptureTasks();
            this.modalViewCommentDash.hide();
                               
      },error=>{
          this.loadingGraphicModal = 0; 
          console.log(error);
          this.modalViewCommentDash.hide();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      })

    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }

  }

  validateFormTaskCapturaMes(task){
    if(task.comentario === ''|| task.comentario === undefined || task.comentario==null){
      return false;
    }else{
      return true;
    }
  }

  completeTaskConsumoMes(task){
    this.loadingEdit = 1;
    this.loadingAllTask = 0;

    if(this.validateFormTaskConsumoMes(task) == true){
      this.dashboard.completeTaskCompAnualConsumoMes(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 
            this.loadTasks(1);
            this.loadConsumptionTasks();
            this.modalViewCommentDash.hide();
      },error=>{
          console.log(error);
          this.loadingGraphicModal = 0; 
          this.modalViewCommentDash.hide();     
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,}); 
      })
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }

  }

  validateFormTaskConsumoMes(task){
    if(task.comentario === ''|| task.comentario === undefined || task.comentario==null){
      return false;
    }else{
      return true;
    }
  }

  // completar tarea TUV

  completarTaskTUVSemanaPlaga(task){

    this.loadingEdit = 1;
    this.loadingAllTask = 0;  

    if(this.validateFormTaskTUVSemanaPlaga(task) == true){
      this.dashboard.completeTaskTUVSemanaPlaga(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.novedades,task.riesgo,task.accion).subscribe(
          dato=>{ 
            this.loadTasks(1);
            this.loadTuvSaturationByPlagueTasks();
            this.modalViewCommentDash.hide();
                               
        },error=>{
          console.log(error);
          this.loadingGraphicModal = 0; 
          this.modalViewCommentDash.hide();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      })
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }
  }

  
  completarTaskRTUSemanaPlaga(task){

    this.loadingEdit = 1;
    this.loadingAllTask = 0;  

    if(this.validateFormTaskRTUSemanaPlaga(task) == true){
      this.dashboard.completeTaskRTUSemanaPlaga(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 
            this.loadTasks(1);
            this.loadFeromonaRtuByPlagueTasks();
            this.modalViewCommentDash.hide();
                               
        },error=>{
          console.log(error);
          this.loadingGraphicModal = 0; 
          this.modalViewCommentDash.hide();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      })
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }
  }

    
  completarTaskMSTSemanaPlaga(task){

    this.loadingEdit = 1;
    this.loadingAllTask = 0;  

    if(this.validateFormTaskMSTSemanaPlaga(task) == true){
      this.dashboard.completeTaskMSTSemanaPlaga(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 
            this.loadTasks(1);
            this.loadFeromonaMstByPlagueTasks();
            this.modalViewCommentDash.hide();
                               
        },error=>{
          console.log(error);
          this.loadingGraphicModal = 0; 
          this.modalViewCommentDash.hide();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      })
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }
  }

  validateFormTaskTUVSemanaPlaga(task){
    if(task.novedades === '' || task.novedades === undefined || task.novedades==null
      && task.riesgo || task.riesgo === undefined || task.riesgo==null
      && task.accion === '' || task.accion === undefined || task.accion==null){
      return false;
    }else{
      return true;
    }
  }

  completeTaskTUVSemanal(task){
    this.loadingEdit = 1;
    this.loadingAllTask = 0;   
    if(this.validateFormTaskTUVSemanal(task) == true){
      this.dashboard.completeTaskTUVSemanal(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 
            this.loadTasks(1);
            this.loadWeeklyTuvTasks();
            this.modalViewCommentDash.hide();               
                               
        },error=>{
          console.log(error);
          this.loadingGraphicModal = 0; 
          this.modalViewCommentDash.hide();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      }) 
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }

  }

  completeTaskRTUSemanal(task){
    this.loadingEdit = 1;
    this.loadingAllTask = 0;   
    if(this.validateFormTaskRTUSemanal(task) == true){
      this.dashboard.completeTaskRTUSemanal(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 
            this.loadTasks(1);
            this.loadWeeklyFeromonaRtuTasks();
            this.modalViewCommentDash.hide();               
                               
        },error=>{
          console.log(error);
          this.loadingGraphicModal = 0; 
          this.modalViewCommentDash.hide();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      }) 
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }

  }

  completeTaskMSTSemanal(task){
    this.loadingEdit = 1;
    this.loadingAllTask = 0;   
    if(this.validateFormTaskMSTSemanal(task) == true){
      this.dashboard.completeTaskMSTSemanal(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 
            this.loadTasks(1);
            this.loadWeeklyFeromonaMstTasks();
            this.modalViewCommentDash.hide();               
                               
        },error=>{
          console.log(error);
          this.loadingGraphicModal = 0; 
          this.modalViewCommentDash.hide();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      }) 
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }

  }

  validateFormTaskTUVSemanal(task){
    if(task.comentario === '' || task.comentario === undefined || task.comentario==null){
      return false;
    }else{
      return true;
    }
  }

  completeTaskTUVMensual(task){
    this.loadingEdit = 1;
    this.loadingAllTask = 0; 
    if(this.validateFormTaskTUVMensual(task) == true){
      this.dashboard.completeTaskTUVMensual(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 

            this.loadTasks(1);
            this.loadMonthlyTuvTasks();
            this.modalViewCommentDash.hide();                          
                               
        },error=>{
          console.log(error);
          this.loadingGraphicModal = 0; 
          this.modalViewCommentDash.hide();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      })
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }

  }

  completeTaskRTUMensual(task){
    this.loadingEdit = 1;
    this.loadingAllTask = 0; 
    if(this.validateFormTaskRTUMensual(task) == true){
      this.dashboard.completeTaskRTUMensual(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 

            this.loadTasks(1);
            this.loadMonthlyFeromonaRtuTasks();
            this.modalViewCommentDash.hide();                          
                               
        },error=>{
          console.log(error);
          this.loadingGraphicModal = 0; 
          this.modalViewCommentDash.hide();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      })
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }

  }
  completeTaskMSTMensual(task){
    this.loadingEdit = 1;
    this.loadingAllTask = 0; 
    if(this.validateFormTaskMSTMensual(task) == true){
      this.dashboard.completeTaskMSTMensual(this.user_id,this.daphagrata_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 

            this.loadTasks(1);
            this.loadMonthlyFeromonaMstTasks();
            this.modalViewCommentDash.hide();                          
                               
        },error=>{
          console.log(error);
          this.loadingGraphicModal = 0; 
          this.modalViewCommentDash.hide();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      })
    }else{
      this.tmService.formError();
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.loadingEdit = 0
    }

  }

  validateFormTaskTUVMensual(task){
    if(task.comentario === '' || task.comentario === undefined || task.comentario==null){
      return false;
    }else{
      return true;
    }
  }

  searchTableTask(filterValue) {
    this.listAllTask.filter = filterValue.trim().toLowerCase();
  }

  validaPermisos(permiso){
      return this.valPermiso.validaPermisos(permiso);
  }


  

  getNumberMonth(mes){
    if(mes == 'Enero'){ return '01'}
    if(mes == 'Febrero'){ return '02'}
    if(mes == 'Marzo'){ return '03'}
    if(mes == 'Abril'){ return '04'}
    if(mes == 'Mayo'){ return '05'}
    if(mes == 'Junio'){ return '06'}
    if(mes == 'Julio'){ return '07'}
    if(mes == 'Agosto'){ return '08'}
    if(mes == 'Septiembre'){ return '09'}
    if(mes == 'Octubre'){ return '10'}
    if(mes == 'Noviembre'){ return '11'}
    if(mes == 'Diciembre'){ return '12'}
  }




  ngAfterViewInit(){
    $('.app_menu').css({"max-height":"2700px"});

    this.dashboard.getTasksPermission().subscribe(
      data => {
        this.taskPermission = data['taskPermission'];
        sessionStorage.setItem("showTasksMonthsAgo",data['taskPermission']["showTasksMonthsAgo"]);
      } 
    )
    
    this.dashboard.get_contracts_profile(this.user_id,'saturacion-tuv').subscribe(
      data=>{

          this.dataContract  = data;    
          this.contratosTodos = data;
          this.selectedContract = data[0].value;

          this.setFilterContracts(this.plagueTuvFilter,this.dataContract);
          this.setFilterContracts(this.weeklyTuvFilter,this.dataContract);
          this.setFilterContracts(this.monthlyTuvFilter,this.dataContract);
          this.setFilterContracts(this.deviceActivityFilter,this.dataContract);
          this.setFilterContracts(this.captureFilter,this.dataContract);
          this.setFilterContracts(this.consumptionFilter,this.dataContract);
          this.setFilterContracts(this.tasksFilter,this.dataContract);

          this.setFilterContracts(this.plagueMstFilter,this.dataContract);
          this.setFilterContracts(this.weeklyMstFilter,this.dataContract);
          this.setFilterContracts(this.monthlyMstFilter,this.dataContract);
          
          this.setFilterContracts(this.plagueRtuFilter,this.dataContract);
          this.setFilterContracts(this.weeklyRtuFilter,this.dataContract);
          this.setFilterContracts(this.monthlyRtuFilter,this.dataContract);

          var currentYear = new Date().getFullYear();
          this.plagueTuvFilter.setDefaultYears(currentYear);
          this.weeklyTuvFilter.setDefaultYears(currentYear);
          this.captureFilter.setDefaultYears(currentYear);
          this.deviceActivityFilter.setDefaultYears(currentYear);
          this.monthlyTuvFilter.setDefaultYears([currentYear-1,currentYear]);
          this.tasksFilter.setDefaultYears([currentYear-1,currentYear]);
          
          this.plagueMstFilter.setDefaultYears(currentYear);
          this.plagueRtuFilter.setDefaultYears(currentYear);
          
          this.weeklyMstFilter.setDefaultYears(currentYear);
          this.weeklyRtuFilter.setDefaultYears(currentYear);

          this.monthlyMstFilter.setDefaultYears([currentYear-1,currentYear]);
          this.monthlyRtuFilter.setDefaultYears([currentYear-1,currentYear]);
          this.consumptionFilter.setDefaultYears([currentYear-1,currentYear]);
          //this.monthlyMstFilter.setDefaultYears(currentYear);
          
          var currentMonth = (new Date().getMonth()) +1;
          this.plagueTuvFilter.setDefaultMonths([currentMonth]);

          this.plagueMstFilter.setDefaultMonths([currentMonth]);
          this.plagueRtuFilter.setDefaultMonths([currentMonth]);

         
          this.tasksFilter.setDefaultContract( data[0].value );
          this.updateContracts(data[0].value, 'SET_DEFAULT');

          this.aplyFiltersToAllCharts();

          this.taskOptions = {
            years : []
            , months : []
            , contracts: [data[0].value]
          };
          this.loadTasks(1);
          
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  


    this.dashboard.listGraphicTittle().subscribe(
        data=>{
          this.dataGraphic  = data;
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  



  }



  parseContracts(contracts){
    var contractsList = [];
    for( let contract of contracts){
      contractsList.push({ id: contract.value, value: contract.cont_name, code: contract.cont_code });
    }
    return contractsList;
  }

  setFilterContracts( filter :DashboardFilterComponent ,contracts){
    filter.setData('contract', this.parseContracts(contracts) );
  }


  ngOnInit() {    

  }




  onPrintGraphic(graphicName){
    var f = null;

    if(graphicName=='TuvSemanalPlagas'){
      f = this.chartFilters['plagueTuv'];  
    }else if(graphicName=="TuvSemanal"){
      f = this.chartFilters['weeklyTuv'];  
    }else if(graphicName=="TuvMensual"){
      f = this.chartFilters['monthlyTuv'];  
    }else if(graphicName=="RoedoresActividadDispositivos"){
      f = this.chartFilters['deviceActivity'];  
    }else if(graphicName=="RoedoresCaptura"){
      f = this.chartFilters['capture'];  
    }else if(graphicName=="RoedoresConsumo"){
      f = this.chartFilters['consumption'];  
    }else if(graphicName=="MstSemanalPlagas"){
      f = this.chartFilters['plagueMst']; 
    }else if(graphicName=="MstSemanal"){
      f = this.chartFilters['weeklyMst']; 
    }else if(graphicName=="MstMensual"){
      f = this.chartFilters['monthlyMst'];
    }else if(graphicName=="RtuSemanalPlagas"){
      f = this.chartFilters['plagueRtu']; 
    }else if(graphicName=="RtuSemanal"){
      f = this.chartFilters['weeklyRtu']; 
    }else if(graphicName=="RtuMensual"){
      f = this.chartFilters['monthlyRtu'];
    }

    console.log('typo de grafico.............'+graphicName)

    $(".btn-print").prop('disabled',true);
    this.notify.info('Espere mientras se genera su documento...',{timeout:2500})
    this.printService.printDocument('graphic', {
        graphic : graphicName,
        years : f.years,
        months : f.months,
        contIds : f.contracts,
        userId : this.user_id,
    });
    
  }


  

//Combo chart Tuv
  loadTuvSaturationByPlagueChart(container = null, filters = null){
    var f = filters!=null ? filters : this.chartFilters['plagueTuv'];
    container = container!=null ? container : this.tuvSaturationChartContainer;
    var chart = this.createComboChartGroupStackHorizontal(container,'tuv','',['col-lg-12'])
    this.dashboard.getTuvSaturationBySpeciesGroup(f.years,f.months,f.contracts).subscribe(
      data => {
        container.clear();
        this.titles.tuvSaturationByPlague = data['title'];
        $.each(data['chart'],(month:any,chartData)=>{
          var chart = this.createComboChartGroupStackHorizontal(container,'tuv',month,['col-lg-12'])
          if(data['maxValue'] > 5) chart.style.xScaleMax = data['maxValue'];
          chart.style.view[1] = data['nBars'][month]!=0 ? (data['nBars'][month]*20) + 55 : 100;
          if(filters!=null){
            chart.style.view[0] = 600;
          }
          chart.setDataSource(chartData);
        });
        if(filters!=null){
          this.modalViewCommentDash.content.loading = false;
        }
        if(filters!=null) this.modalViewCommentDash.content.refresh();

      }, error =>{
        this.notify.warning('Error al cargar informacion de saturacion TUV. No se cargó el grafico "Saturacion TUV".');
      }
    );

    //Si se aplican filtros externos (modal) no se refrescan tareas
    if(filters!=null) return;
    this.loadTuvSaturationByPlagueTasks();
  }



  //Comentarios Combo CHart Tuv
  loadTuvSaturationByPlagueTasks(){
    this.loadingCommentTUVSemanaPlaga = 0;
    let f = this.chartFilters['plagueTuv'];
    this.dashboard.listCommentDashboard('gra-tuv-saturacion-semana-plaga',this.user_id,f.years,f.months,f.contracts).subscribe(
      dato=>{   
        this.listCommentTUVSemanaPlaga = dato['datos']; 
        this.countTUVSemanaPlaga = dato['count'];    
        this.loadingCommentTUVSemanaPlaga = 1;     
        if(this.listCommentTUVSemanaPlaga.length == 0){ this.loadingCommentTUVSemanaPlaga = 2;  }
    },error=>{
      console.log(error);
      this.loadingCommentTUVSemanaPlaga = 3;
      this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })
  }


  //graficos Tuv semanal
  loadWeeklyTuvChart(container=null, filters=null){
    let f = filters!=null ? filters : this.chartFilters['weeklyTuv'];
    container = container!=null ? container : this.tuvWeeklySaturationContainer;    
    var chart = this.createGroupedVerticalChart(container,'tuv','',['col-lg-12']);
    this.dashboard.flt_tuv_semanal(this.user_id,f.years,f.months,f.contracts).subscribe(
      data=>{
        this.titles.weeklyTuv = data['title'];
          chart.setDataSource(data['ngx-chart']);   
          if(filters!=null){
            chart.style.view[0] = 600; 
            this.modalViewCommentDash.content.loading = false;
            this.modalViewCommentDash.content.refresh();
          } 
              
      }, error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    });  

    //Si se aplican filtros externos (modal) no se actualizan comentarios
    if(filters!=null) return;
    this.loadWeeklyTuvTasks();
  }


  //tareas Tuv semanal
  loadWeeklyTuvTasks(){
    this.loadingCommentTUVSemanal = 0;
    let f = this.chartFilters['weeklyTuv'];
    this.dashboard.listCommentDashboard('gra-tuv-promedio-saturacion-semanal',this.user_id,f.years,f.months,f.contracts).subscribe(
      dato=>{   

        this.listCommentTUVSemanal = dato['datos']; 
        this.countTUVSemanal = dato['count'];     
        this.loadingCommentTUVSemanal = 1;     
        if(this.listCommentTUVSemanal.length == 0){ this.loadingCommentTUVSemanal = 2;  }
                           
    },error=>{
      console.log(error);
      this.loadingCommentTUVSemanal = 3;
      this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    }) 
  }

    //grafico Tuv mensual
  loadMonthlyTuvChart(container = null , filters = null){
    let f = filters!=null ? filters : this.chartFilters['monthlyTuv'];
    container = container!=null ? container : this.tuvMonthlySaturationContainer;
    var chart = this.createLineChart(container,'tuv','',['col-lg-12']);
    chart.style.showLegend = true;

    let displayedColumns = ['mes','Enero','Febrero','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
    var monthlyTuvTable = this.createTable(container,'',[],['col-lg-12']);
    // monthlyTuvTable.columnReferences = {'año':'year-legend-consumption'};
    monthlyTuvTable.displayAllColumns = true;
    monthlyTuvTable.paddingLeft = '80px';
    monthlyTuvTable.paddingRight = '170px';
    monthlyTuvTable.fontSize = 10;

    this.dashboard.flt_tuv_mensual(this.user_id,f.years,f.months,f.contracts).subscribe(
        data => {
          monthlyTuvTable.setDataSource(data['table']);

          this.titles.monthlyTuv = data['title'];
          chart.setDataSource(data['ngx-chart']);
          if(filters!=null){
            chart.style.view[0] = 600; 
            this.modalViewCommentDash.content.loading = false;
            this.modalViewCommentDash.content.refresh();
          } 
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  



    //Si se aplican filtros externos (modal) no se actualizan comentarios
    if(filters!=null) return;
    this.loadMonthlyTuvTasks();
  }

  //tareas Tuv mensual
  loadMonthlyTuvTasks(){
    this.loadingCommentTUVMensual = 0;
    let f = this.chartFilters['monthlyTuv'];
    this.dashboard.listCommentDashboard('gra-tuv-promedio-saturacion-mensual',this.user_id,f.years,f.months,f.contracts).subscribe(
      dato=>{
        this.listCommentTUVMensual = dato['datos']; 
        this.countTUVMensual = dato['count'];    
        this.loadingCommentTUVMensual = 1;     
        if(this.listCommentTUVMensual.length == 0){ this.loadingCommentTUVMensual = 2;  }
                           
    },error=>{
      console.log(error);
      this.loadingCommentTUVMensual = 3;
      this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    }) 
  }


  


//grafico Dispositivos Actividad Roedores
  loadDeviceActivityChart(container=null,filters=null){
    var customColors = [
      {'name':'Sin Actividad','value':'#7859EB'}
      , {'name':'Sin Acceso','value':'#FB886F'}
      , {'name':'Deteriorada','value':'#F7FB7B'}
      , {'name':'Consumo','value':'#78D35F'}
      , {'name':'Captura','value':'#C98ECF'}
    ];

    let f = filters!=null ? filters : this.chartFilters['deviceActivity'];
    container = container!=null ? container : this.deviceActivityContainer;

    var exteriorChart = this.createStackedVerticalChart(container,'dispositivos','1.- Perimetro Terreno Exterior',['col-lg-12'])
    var structuralChart = this.createStackedVerticalChart(container,'dispositivos','2.- Perimetro Estructural',['col-lg-12'])
    var interiorChart = this.createStackedVerticalChart(container,'dispositivos','3.- Interior',['col-lg-12'])
    exteriorChart.style.customColors = customColors;
    structuralChart.style.customColors = customColors;
    interiorChart.style.customColors = customColors;

    this.deviceCharts = [exteriorChart,structuralChart,interiorChart];
    this.maxDeviceValue = 0;

    this.dashboard.flt_dashboard_act_roedores(this.user_id,'interior',f.years,f.months,f.contracts).subscribe(
        dato=>{                
          this.titles.deviceActivity = dato['title'];
          interiorChart.setDataSource(dato['actRoedores']);
          this.setMaxDeviceValue(dato['maxValue']);

          if(filters!=null) {
            interiorChart.style.view[0] = 600;
            this.modalViewCommentDash.content.loading = false;
            this.modalViewCommentDash.content.updateDeviceActivityValues(dato,'interior');
            this.modalViewCommentDash.content.refresh();
            
          }
                            
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    }); 

    this.dashboard.flt_dashboard_act_roedores(this.user_id,'estructural',f.years,f.months,f.contracts).subscribe(
      dato=>{         
        this.titles.deviceActivity = dato['title'];            
        structuralChart.setDataSource(dato['actRoedores']);
        this.setMaxDeviceValue(dato['maxValue']);

        if(filters!=null) {
          structuralChart.style.view[0] = 600;
          this.modalViewCommentDash.content.loading = false;
          this.modalViewCommentDash.content.updateDeviceActivityValues(dato,'estructural');
          this.modalViewCommentDash.content.refresh();
        }
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    });

    this.dashboard.flt_dashboard_act_roedores(this.user_id,'exterior',f.years,f.months,f.contracts).subscribe(
      dato=>{     
        this.titles.deviceActivity = dato['title'];                
          exteriorChart.setDataSource(dato['actRoedores']);   
          this.setMaxDeviceValue(dato['maxValue']);

          if(filters!=null) {
            exteriorChart.style.view[0] = 600;
            this.modalViewCommentDash.content.loading = false;
            this.modalViewCommentDash.content.updateDeviceActivityValues(dato,'exterior');
            this.modalViewCommentDash.content.refresh();
          }
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    }); 

    //Si se aplican filtros externos (modal) no se refrescan tareas
    if(filters!=null) return;
    this.loadDeviceActivityTasks();
  }

//comentarios Dispositivos Actividad Roedores
  loadDeviceActivityTasks(){
    this.loadingCommentActiRoeMesZona = 0;
    let f = this.chartFilters['deviceActivity'];
    this.dashboard.listCommentDashboard('gra-roedor-actividad-mes-zona',this.user_id,f.years,f.months,f.contracts).subscribe(
      dato=>{   

        this.listCommentActiRoeMesZona = dato['datos']; 
        this.countActiRoeMesZona = dato['count'];
        this.loadingCommentActiRoeMesZona = 1;     
        if(this.listCommentActiRoeMesZona.length == 0){ this.loadingCommentActiRoeMesZona = 2;  }              
                           
    },error=>{
      console.log(error);
      this.loadingCommentActiRoeMesZona = 3;
      this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  
  }



//Grafico captura dispositivos Roedores
loadCaptureChart(container=null,filters=null){
  let f = filters!=null ? filters : this.chartFilters['capture'];
  console.log('imprimo xxxxxxxxxxxxx')
  console.log(this.chartFilters['capture'])
  container = container!=null ? container : this.captureContainer;
  var captureChart = this.createLineChart(container,'dispositivos','',['col-lg-12']);

  if(filters==null){
    var captureTable = this.createTable(container,'',[],['col-lg-12']);
    captureTable.columnReferences = {'año':'year-legend-capture'};
    captureTable.displayAllColumns = true;
    captureTable.paddingLeft = '80px';
    captureTable.paddingRight = '170px';
    captureTable.fontSize = 10;
  }

  this.dashboard.flt_dashboard_captura(this.user_id,f.years,f.months,f.contracts).subscribe(
    
    data => {
      this.titles.capture = data['title'];     

      captureChart.setDataSource(data['captura']);

      if(filters==null){
        captureTable.setDataSource(data['table']);
        setTimeout(()=>{
          $(".year-legend-capture-rm").css("display","none");
          $(".year-legend-capture-0").html("<div class='year-legend-capture-rm' style=\"margin-top:5px; border-radius:3px; float:left; width:15px; height:15px;background:#f00;\"></div>&nbsp;"+$(".year-legend-capture-0").html());
          $(".year-legend-capture-1").html("<div class='year-legend-capture-rm' style=\"margin-top:5px; border-radius:3px; float:left; width:15px; height:15px;background:#00f;\"></div>&nbsp;"+$(".year-legend-capture-1").html());
        },2000);
      }else{
        captureChart.style.legend = true;
        captureChart.style.view[0] = 600;
        this.modalViewCommentDash.content.loading = false;
        this.modalViewCommentDash.content.refresh();
      }
    }, error => {
      console.log('GET_DASHBOARD_CAPTURA ERROR :',error);
    }
  )

  //Si se aplican filtros externos (modal) no se refrescan tareas
  if(filters!=null) return;
    this.loadCaptureTasks();
}


//tareas captura dispositivos Roedores
loadCaptureTasks(){
  this.loadingCommentCompAnualCapturaMes = 0;
  let f = this.chartFilters['capture'];
  this.dashboard.listCommentDashboard('gra-roedor-captura-mes',this.user_id,f.years,f.months,f.contracts).subscribe(
    dato=>{   

      this.listCommentCompAnualCapturaMes = dato['datos']; 
      this.countCompAnualCapturaMes = dato['count'];  
      this.loadingCommentCompAnualCapturaMes = 1;     
      if(this.listCommentCompAnualCapturaMes.length == 0){ this.loadingCommentCompAnualCapturaMes = 2;  }
                         
  },error=>{
    console.log(error);
    this.loadingCommentCompAnualCapturaMes = 3;
    this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
  }) 
}


//Grafico Consumo dispositivos Roedores
loadConsumptionChart(container=null,filters=null){
  let f = filters!=null ? filters : this.chartFilters['consumption'];
  container = container!=null ? container : this.consumptionContainer;
  var consumptionChart = this.createLineChart(container,'dispositivos','',['col-lg-12']);

  if(filters==null){
    let displayedColumns = ['mes','Enero','Febrero','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
    var consumptionTable = this.createTable(container,'',[],['col-lg-12']);
    consumptionTable.columnReferences = {'año':'year-legend-consumption'};
    consumptionTable.displayAllColumns = true;
    consumptionTable.paddingLeft = '80px';
    consumptionTable.paddingRight = '170px';
    consumptionTable.fontSize = 10;
  }
  //f.years = [2023];
  console.log('filtros enviados  año '+f.years+'  mes  '+f.months)
  this.dashboard.flt_dashboard_consumo(this.user_id,f.years,f.months,f.contracts).subscribe(
    data => {
      this.titles.consumption = data['title'];     
      consumptionChart.setDataSource(data['consumo']);
      if(filters==null){
        consumptionTable.setDataSource(data['table']);
        setTimeout(()=>{
          $(".year-legend-consumption-rm").css("display","none");
          $(".year-legend-consumption-0").html("<div class='year-legend-consumption-rm' style=\"margin-top:5px; border-radius:3px; float:left; width:15px; height:15px;background:#f00;\"></div>&nbsp;"+$(".year-legend-consumption-0").html());
          $(".year-legend-consumption-1").html("<div class='year-legend-consumption-rm' style=\"margin-top:5px; border-radius:3px; float:left; width:15px; height:15px;background:#00f;\"></div>&nbsp;"+$(".year-legend-consumption-1").html());
        },2000);
      }else{
        consumptionChart.style.legend = true;
        consumptionChart.style.view[0] = 600;
        this.modalViewCommentDash.content.loading = false;
        this.modalViewCommentDash.content.refresh();
      }
      
    }, error => {
      console.log('GET_DASHBOARD_CONSUMO error : ',error);
    }
    
  );

  //Si se aplican filtros externos (modal) no se refrescan tareas
  if(filters!=null) return;
    this.loadConsumptionTasks();
}

//tareas Consumo dispositivos Roedores
loadConsumptionTasks(){
  this.loadingCommentCompAnualConsumoMes = 0;
  let f = this.chartFilters['consumption'];
  this.dashboard.listCommentDashboard('gra-roedor-consumo-mes',this.user_id,f.years,f.months,f.contracts).subscribe(
    dato=>{   

      this.listCommentCompAnualConsumoMes = dato['datos']; 
      this.countCompAnualConsumoMes = dato['count'];   
      this.loadingCommentCompAnualConsumoMes = 1;     
      if(this.listCommentCompAnualConsumoMes.length == 0){ this.loadingCommentCompAnualConsumoMes = 2;  }             
                        
  },error=>{
    console.log(error);
    this.loadingCommentCompAnualConsumoMes = 3;
    this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
  }) 
}





  /**
   * Dada una actualizacion en el componente de filtros, actualiza las variables locales.
   * Si el cambio en los filtros ocurren en el contrato, se actualizan todos los graficos 
   * mediante updateContracts(), sino, se actualiza solo el grafico correspondiente al 
   * filtro que lanza el evento
   * @param chartGroup : string - indica el grafico o grupo de graficos que lanza el evento
   * @param event : object - indica el estado actual del filtro que lanzó el evento
   * @return none
   */
  updateFilters(chartGroup,event){
    console.log('recarga de filtros...............')

    this.chartFilters[chartGroup] = event.filters;
    if( event.changes.contracts ){
      this.aplyFiltersToAllCharts();
    }else{
      if(chartGroup=='weeklyTuv'){
        this.tuvWeeklySaturationContainer.clear(); 
        this.loadWeeklyTuvChart();
      } 
      if(chartGroup=='monthlyTuv'){
        this.tuvMonthlySaturationContainer.clear(); 
        this.loadMonthlyTuvChart();
      }
      if(chartGroup=='plagueTuv'){
        this.tuvSaturationChartContainer.clear(); 
        this.loadTuvSaturationByPlagueChart();
      }
      if(chartGroup=='deviceActivity'){
        this.deviceActivityContainer.clear(); 
        this.loadDeviceActivityChart();
      }

      if(chartGroup=='weeklyMst'){
        this.FeromonaMstWeeklySaturationContainer.clear(); 
        this.loadWeeklyFeromonaMstChart();
      } 
      if(chartGroup=='monthlyMst'){
        this.FeromonaMstMonthlySaturationContainer.clear();
        this.loadMonthlyFeromonaMstChart();
      }
      if(chartGroup=='plagueMst'){
        this.FeromonasMstSaturationChartContainer.clear(); 
        this.loadFeromonasMstByPlagueChart();
      }

      if(chartGroup=='weeklyRtu'){
        this.FeromonaRtuWeeklySaturationContainer.clear(); 
        this.loadWeeklyFeromonaRtuChart();
      } 
      if(chartGroup=='monthlyRtu'){
        this.FeromonaRtuMonthlySaturationContainer.clear(); 
        this.loadMonthlyFeromonaRtuChart();
      }
      if(chartGroup=='plagueRtu'){
        this.FeromonasRtuSaturationChartContainer.clear(); 
        this.loadFeromonasRtuByPlagueChart();
      }

      if(chartGroup=='capture'){
        this.captureContainer.clear(); 
        this.loadCaptureChart();
      }
      
      if(chartGroup=='consumption'){
        this.consumptionContainer.clear(); 
        this.loadConsumptionChart();
      }
    }
  } 
  

  updateFilterSearch(filter,search){
    if( filter != 'weeklyTuv' ) this.weeklyTuvFilter.filterContracts(search);
    if( filter != 'monthlyTuv' ) this.monthlyTuvFilter.filterContracts(search);
    if( filter != 'deviceActivity' ) this.deviceActivityFilter.filterContracts(search);
    if( filter != 'plagueTuv' ) this.plagueTuvFilter.filterContracts(search);
    if( filter != 'capture' ) this.captureFilter.filterContracts(search);
    if( filter != 'consumption' ) this.consumptionFilter.filterContracts(search);
  
    if( filter != 'weeklyMst' ) this.weeklyMstFilter.filterContracts(search);
    if( filter != 'monthlyMst' ) this.monthlyMstFilter.filterContracts(search);
    if( filter != 'plagueMst' ) this.plagueMstFilter.filterContracts(search);
   
    if( filter != 'weeklyRtu' ) this.weeklyRtuFilter.filterContracts(search);
    if( filter != 'monthlyRtu' ) this.monthlyRtuFilter.filterContracts(search);
    if( filter != 'plagueRtu' ) this.plagueRtuFilter.filterContracts(search);
  
  }

  isArrayEmpty( toCheck ){
    if(Array.isArray(toCheck)){
      if( toCheck.length>0 ){
        return false;
      }
    } 
    return true;
  }

    /**
   * Dada una actualizacion en el componente de filtros de tareas, actualiza las variables 
   * locales y luego actualiza la tabla mediante loadTasks()
   * @param event : object - indica el estado actual del filtro
   * @return none
   */
  updateTaskFilter(event){
    this.taskOptions = event.filters;
    this.loadTasks(1);
  }



    /**
   * Establece la opcion seleccionada en todos los filtros de graficos.
   * Luego llama a la funcion aplyFiltersToAllCharts() para actualizar los resultados
   * en consecuencia con el nuevo contrato seleccionado
   * @param contracts int contrato seleccionado
   * @return none
   */
  updateContracts(contracts, type = 'SET_SELECTED' ){
    for( var key in this.chartFilters ){
      this.chartFilters[key]['contracts'] = contracts;
    }
    console.log('actualiza filtros  '+type);
    console.log(contracts);
    if( type == 'SET_DEFAULT' ){
      this.weeklyTuvFilter.setDefaultContract(contracts);
      this.monthlyTuvFilter.setDefaultContract(contracts);
      this.deviceActivityFilter.setDefaultContract(contracts);
      this.plagueTuvFilter.setDefaultContract(contracts);
      this.captureFilter.setDefaultContract(contracts);
      this.consumptionFilter.setDefaultContract(contracts);
      this.plagueMstFilter.setDefaultContract(contracts);
      this.weeklyMstFilter.setDefaultContract(contracts);
      this.monthlyMstFilter.setDefaultContract(contracts);
      this.plagueRtuFilter.setDefaultContract(contracts);
      this.weeklyRtuFilter.setDefaultContract(contracts);
      this.monthlyRtuFilter.setDefaultContract(contracts);
    }else{
      this.weeklyTuvFilter.setSelectedContract(contracts);
      this.monthlyTuvFilter.setSelectedContract(contracts);
      this.deviceActivityFilter.setSelectedContract(contracts);
      this.plagueTuvFilter.setSelectedContract(contracts);
      this.captureFilter.setSelectedContract(contracts);
      this.consumptionFilter.setSelectedContract(contracts);
      this.plagueMstFilter.setDefaultContract(contracts);
      this.weeklyMstFilter.setDefaultContract(contracts);
      this.monthlyMstFilter.setDefaultContract(contracts);
      this.plagueRtuFilter.setDefaultContract(contracts);
      this.weeklyRtuFilter.setDefaultContract(contracts);
      this.monthlyRtuFilter.setDefaultContract(contracts);
    }
  }


  clearReferences(){
    this.consumptionContainer.clear();
    this.captureContainer.clear();
    this.tuvSaturationChartContainer.clear();
    this.tuvMonthlySaturationContainer.clear();
    this.tuvWeeklySaturationContainer.clear();
    this.deviceActivityContainer.clear();
  }


  getContractNameById(contractId){
    var text = 'Ninguno';
    if( !(contractId > 0) ) return text;
    this.contratosTodos.map((c)=>{
      if( c.value == contractId ) text = c.cont_name;
    });
    return text;
  }

  // @HostListener('complete', ['$event']) 
  onCompleteTask(event) {
  }

  setMaxDeviceValue(newValue)
  {
    if( newValue > this.maxDeviceValue ) this.maxDeviceValue = newValue;
    
    for( var i = 0; i<this.deviceCharts.length; i++){
      let component = this.deviceCharts[i];
      component.style.yScaleMax = this.maxDeviceValue;
    }
  }



  onChangeFilter(event)
  {
    if( event.eventTrigger == 'contract' ){
      this.updateContracts(event.filters.contracts);
    }
  }

  loadFeromonasMstByPlagueChart(container = null, filters = null){

    var f = filters!=null ? filters : this.chartFilters['plagueMst'];
    container = container!=null ? container : this.FeromonasMstSaturationChartContainer;
    var chart = this.createComboChartGroupStackHorizontal(container,'tuv','',['col-lg-12'])

    console.log(chart);
  
    
    this.dashboard.getFeromonasMstBySpeciesGroup(f.years,f.months,f.contracts).subscribe(
      data => {
        container.clear();
        this.titles.feromonasMSTByPlague = data['title'];

        console.log('feromonas ........................................................')
        console.log(data);
        console.log(data['chart']);
        
        $.each(data['chart'],(month:any,chartData)=>{
          console.log('recorro datos feromonas.................')
          console.log(chartData)
          var chart = this.createComboChartGroupStackHorizontal(container,'tuv',month,['col-lg-12'])
          if(data['maxValue'] > 5) chart.style.xScaleMax = data['maxValue'];
          chart.style.view[1] = data['nBars'][month]!=0 ? (data['nBars'][month]*20) + 55 : 100;
          if(filters!=null){
            chart.style.view[0] = 600;
          }
          chart.setDataSource(chartData);
        });
        if(filters!=null){
          this.modalViewCommentDash.content.loading = false;
        }
        if(filters!=null) this.modalViewCommentDash.content.refresh();
      
      }, error =>{
        this.notify.warning('Error al cargar informacion de Feromonas MST. No se cargó el grafico "Feromonas MST".');
      }
    );

    //Si se aplican filtros externos (modal) no se refrescan tareas
    if(filters!=null) return;
    this.loadFeromonaMstByPlagueTasks();
  }

  loadWeeklyFeromonaMstChart(container=null, filters=null){
    let f = filters!=null ? filters : this.chartFilters['weeklyMst'];
    container = container!=null ? container : this.FeromonaMstWeeklySaturationContainer;    
    var chart = this.createGroupedVerticalChart(container,'tuv','',['col-lg-12']);
    this.dashboard.flt_feromona_mst_semanal(this.user_id,f.years,f.months,f.contracts).subscribe(
      data=>{
        this.titles.feromonasMSTweekly = data['title'];
          chart.setDataSource(data['ngx-chart']);   
          if(filters!=null){
            chart.style.view[0] = 600; 
            this.modalViewCommentDash.content.loading = false;
            this.modalViewCommentDash.content.refresh();
          } 
              
      }, error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    });  

    //Si se aplican filtros externos (modal) no se actualizan comentarios
    if(filters!=null) return;
    this.loadWeeklyFeromonaMstTasks();
  }


  loadMonthlyFeromonaMstChart(container = null , filters = null){
    let f = filters!=null ? filters : this.chartFilters['monthlyMst'];
    container = container!=null ? container : this.FeromonaMstMonthlySaturationContainer;
    var chart = this.createLineChart(container,'tuv','',['col-lg-12']);
    chart.style.showLegend = true;

    let displayedColumns = ['mes','Enero','Febrero','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
    var monthlyTuvTable = this.createTable(container,'',[],['col-lg-12']);
    // monthlyTuvTable.columnReferences = {'año':'year-legend-consumption'};
    monthlyTuvTable.displayAllColumns = true;
    monthlyTuvTable.paddingLeft = '80px';
    monthlyTuvTable.paddingRight = '170px';
    monthlyTuvTable.fontSize = 10;

    this.dashboard.flt_feromona_mst_mensual(this.user_id,f.years,f.months,f.contracts).subscribe(
        data => {
          monthlyTuvTable.setDataSource(data['table']);

          this.titles.feromonasMSTmonthly = data['title'];
          chart.setDataSource(data['ngx-chart']);
          if(filters!=null){
            chart.style.view[0] = 600; 
            this.modalViewCommentDash.content.loading = false;
            this.modalViewCommentDash.content.refresh();
          } 
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  

    //Si se aplican filtros externos (modal) no se actualizan comentarios
    if(filters!=null) return;
    this.loadMonthlyFeromonaMstTasks();
  }

  loadFeromonasRtuByPlagueChart(container = null, filters = null){

    var f = filters!=null ? filters : this.chartFilters['plagueRtu'];
    container = container!=null ? container : this.FeromonasRtuSaturationChartContainer;
    var chart = this.createComboChartGroupStackHorizontal(container,'tuv','',['col-lg-12'])

    console.log(chart);
    this.dashboard.getFeromonasRtuBySpeciesGroup(f.years,f.months,f.contracts).subscribe(
      data => {
        container.clear();
        this.titles.feromonasRTUByPlague = data['title'];

        console.log('feromonas ........................................................')
        console.log(data);
        console.log(data['chart']);
        
        $.each(data['chart'],(month:any,chartData)=>{
          console.log('recorro datos feromonas.................')
          console.log(chartData)
          var chart = this.createComboChartGroupStackHorizontal(container,'tuv',month,['col-lg-12'])
          if(data['maxValue'] > 5) chart.style.xScaleMax = data['maxValue'];
          chart.style.view[1] = data['nBars'][month]!=0 ? (data['nBars'][month]*20) + 55 : 100;
          if(filters!=null){
            chart.style.view[0] = 600;
          }
          chart.setDataSource(chartData);
        });
        if(filters!=null){
          this.modalViewCommentDash.content.loading = false;
        }
        if(filters!=null) this.modalViewCommentDash.content.refresh();
      
      }, error =>{
        this.notify.warning('Error al cargar informacion de saturacion Feromonas Rtu. No se cargó el grafico "Feromonas RTU".');
      }
    );
    //Si se aplican filtros externos (modal) no se refrescan tareas
    if(filters!=null) return;
    this.loadFeromonaRtuByPlagueTasks();
  }

  loadWeeklyFeromonaRtuChart(container=null, filters=null){
    let f = filters!=null ? filters : this.chartFilters['weeklyRtu'];
    container = container!=null ? container : this.FeromonaRtuWeeklySaturationContainer;    
    var chart = this.createGroupedVerticalChart(container,'tuv','',['col-lg-12']);
    this.dashboard.flt_feromona_rtu_semanal(this.user_id,f.years,f.months,f.contracts).subscribe(
      data=>{
        this.titles.feromonasRTUweekly = data['title'];
          chart.setDataSource(data['ngx-chart']);   
          if(filters!=null){
            chart.style.view[0] = 600; 
            this.modalViewCommentDash.content.loading = false;
            this.modalViewCommentDash.content.refresh();
          } 
              
      }, error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    });  

    //Si se aplican filtros externos (modal) no se actualizan comentarios
    if(filters!=null) return;
    this.loadWeeklyFeromonaRtuTasks();
  }

  loadMonthlyFeromonaRtuChart(container = null , filters = null){
    let f = filters!=null ? filters : this.chartFilters['monthlyRtu'];
    container = container!=null ? container : this.FeromonaRtuMonthlySaturationContainer;
    var chart = this.createLineChart(container,'tuv','',['col-lg-12']);
    chart.style.showLegend = true;

    let displayedColumns = ['mes','Enero','Febrero','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
    var monthlyTuvTable = this.createTable(container,'',[],['col-lg-12']);
    // monthlyTuvTable.columnReferences = {'año':'year-legend-consumption'};
    monthlyTuvTable.displayAllColumns = true;
    monthlyTuvTable.paddingLeft = '80px';
    monthlyTuvTable.paddingRight = '170px';
    monthlyTuvTable.fontSize = 10;

    this.dashboard.flt_feromona_rtu_mensual(this.user_id,f.years,f.months,f.contracts).subscribe(
        data => {
          monthlyTuvTable.setDataSource(data['table']);

          this.titles.feromonasRTUmonthly = data['title'];
          chart.setDataSource(data['ngx-chart']);
          if(filters!=null){
            chart.style.view[0] = 600; 
            this.modalViewCommentDash.content.loading = false;
            this.modalViewCommentDash.content.refresh();
          } 
      },error=>{
        console.log(error);
        this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })  
    //Si se aplican filtros externos (modal) no se actualizan comentarios
    if(filters!=null) return;
    this.loadMonthlyFeromonaRtuTasks();
  }

  //Comentarios Combo CHart Tuv
  loadFeromonaRtuByPlagueTasks(){
    this.loadingCommentFeromonaRtuPlaga = 0;
    let f = this.chartFilters['plagueRtu'];
    this.dashboard.listCommentDashboard('gra-feromona-rtu-plaga-trampa-semanal',this.user_id,f.years,f.months,f.contracts).subscribe(
      dato=>{   
        this.listCommentFeromonaRtuPlaga = dato['datos']; 
        this.countFeromonaRtuPlaga = dato['count'];    
        this.loadingCommentFeromonaRtuPlaga = 1;     
        if(this.listCommentFeromonaRtuPlaga.length == 0){ this.loadingCommentFeromonaRtuPlaga = 2;  }
    },error=>{
      console.log(error);
      this.loadingCommentFeromonaRtuPlaga = 3;
      this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })
  }

  loadWeeklyFeromonaRtuTasks(){
    this.loadingCommentFeromonaRtuSemanal = 0;
    let f = this.chartFilters['weeklyRtu'];
    this.dashboard.listCommentDashboard('gra-feromona-rtu-promedio-plaga-semanal',this.user_id,f.years,f.months,f.contracts).subscribe(
      dato=>{   
        this.listCommentFeromonaRtuSemanal = dato['datos']; 
        this.countFeromonaRtuSemanal = dato['count'];    
        this.loadingCommentFeromonaRtuSemanal = 1;     
        if(this.listCommentFeromonaRtuSemanal.length == 0){ this.loadingCommentFeromonaRtuSemanal = 2;  }
    },error=>{
      console.log(error);
      this.loadingCommentFeromonaRtuSemanal = 3;
      this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })
  }
  
  loadMonthlyFeromonaRtuTasks(){
    this.loadingCommentTUVSemanaPlaga = 0;
    let f = this.chartFilters['monthlyRtu'];
    this.dashboard.listCommentDashboard('gra-feromona-rtu-promedio-plaga-mensual',this.user_id,f.years,f.months,f.contracts).subscribe(
      dato=>{   
        this.listCommentFeromonaRtuMensual = dato['datos']; 
        this.countFeromonaRtuMensual = dato['count'];    
        this.loadingCommentFeromonaRtuMensual = 1;
             
        if(this.listCommentFeromonaRtuMensual.length == 0){ this.loadingCommentFeromonaRtuMensual = 2;  }
    },error=>{
      console.log(error);
      this.loadingCommentFeromonaRtuMensual = 3;
      this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })
  }
 
  loadFeromonaMstByPlagueTasks(){
    this.loadingCommentFeromonaMstPlaga = 0;
    let f = this.chartFilters['plagueMst'];
    this.dashboard.listCommentDashboard('gra-feromona-mst-plaga-trampa-semanal',this.user_id,f.years,f.months,f.contracts).subscribe(
      dato=>{   
        this.listCommentFeromonaMstPlaga = dato['datos']; 
        this.countFeromonaMstPlaga = dato['count'];    
        this.loadingCommentFeromonaMstPlaga = 1;     
        if(this.listCommentFeromonaMstPlaga.length == 0){ this.loadingCommentFeromonaMstPlaga = 2;  }
    },error=>{
      console.log(error);
      this.loadingCommentFeromonaMstPlaga = 3;
      this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })
  }

  
  loadWeeklyFeromonaMstTasks(){
    this.loadingCommentFeromonaMstSemanal = 0;
    let f = this.chartFilters['weeklyRtu'];
    this.dashboard.listCommentDashboard('gra-feromona-mst-promedio-plaga-semanal',this.user_id,f.years,f.months,f.contracts).subscribe(
      dato=>{   
        this.listCommentFeromonaMstSemanal = dato['datos']; 
        this.countFeromonaMstSemanal = dato['count'];    
        this.loadingCommentFeromonaMstSemanal = 1;     
        if(this.listCommentFeromonaMstSemanal.length == 0){ this.loadingCommentFeromonaMstSemanal = 2;  }
    },error=>{
      console.log(error);
      this.loadingCommentFeromonaMstSemanal = 3;
      this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })
  }

  loadMonthlyFeromonaMstTasks(){
    this.loadingCommentFeromonaMstMensual = 0;
    let f = this.chartFilters['monthlyMst'];
    this.dashboard.listCommentDashboard('gra-feromona-mst-promedio-plaga-mensual',this.user_id,f.years,f.months,f.contracts).subscribe(
      dato=>{   
        this.listCommentFeromonaMstMensual = dato['datos']; 
        this.countFeromonaMstMensual = dato['count'];    
        this.loadingCommentFeromonaMstMensual = 1;     
        if(this.listCommentFeromonaMstMensual.length == 0){ this.loadingCommentFeromonaMstMensual = 2;  }
    },error=>{
      console.log(error);
      this.loadingCommentFeromonaMstMensual = 3;
      this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});      
    })
  }


  
  //edicion rtu
  editCommentRTUSemanaPlaga(anio,mes,id,comentario,cont_id,template: TemplateRef<any>){
    //taskForm
    console.log(this.config)
    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);
    console.log('este es el comentrio...............'+comentario)
   
    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    task.comentario = comentario;
    var filter = {
      years : anio
      , months : [this.getNumberMonth(mes)]
      , contracts : cont_id
    }
    this.modalViewCommentDash.content.setType("edit");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskType('RSemanalPlaga');
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.modalViewCommentDash.content.loading = false;
      this.taskContainer = ref;
      if (this.taskContainer) {
        this.loadFeromonasRtuByPlagueChart(this.taskContainer,filter)
      }
    })
    this.id_table_to_edit = id;
    console.log(this.tmService)
    console.log(this.modalViewCommentDash)
  }

  editCommentRTUSemanal(anio,mes,id,comentario,cont_id,template: TemplateRef<any>){
    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);
    
    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    task.comentario = comentario;
    var filter = {
      years : anio
      , months : [this.getNumberMonth(mes)]
      , contracts : cont_id
    }
    this.modalViewCommentDash.content.setType("edit");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskType('RtuSemanal');
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.modalViewCommentDash.content.loading = false;
      this.taskContainer = ref;
      if (this.taskContainer) {
        this.loadWeeklyFeromonaRtuChart(this.taskContainer,filter);
      }
    })
    this.id_table_to_edit = id;

  }

  editCommentRTUMensual(anio,mes,id,comentario,cont_id,template: TemplateRef<any>){
    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);
    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    task.comentario = comentario;
    var filter = {
      years : [anio]
      , months : [this.getNumberMonth(mes)]
      , contracts : cont_id
    }
    this.modalViewCommentDash.content.setType("edit");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskType('RtuMensual');
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.modalViewCommentDash.content.loading = false;
      this.taskContainer = ref;
      if (this.taskContainer) {
        this.loadMonthlyFeromonaRtuChart(this.taskContainer,filter);
     
      }
    })
    this.id_table_to_edit = id;
  }




  //edicion mst
  editCommentMSTSemanaPlaga(anio,mes,id,comentario,cont_id,template: TemplateRef<any>){
    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);

    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    task.comentario = comentario;
   
    var filter = {
      years : anio
      , months : [this.getNumberMonth(mes)]
      , contracts : cont_id
    }
    this.modalViewCommentDash.content.setType("edit");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskType('MstSemanalPlaga');
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.modalViewCommentDash.content.loading = false;
      this.taskContainer = ref;
      if (this.taskContainer) {
        
        this.loadFeromonasMstByPlagueChart(this.taskContainer,filter);
      }
    })

    this.id_table_to_edit = id;
  }

  editCommentMSTSemanal(anio,mes,id,comentario,cont_id,template: TemplateRef<any>){
    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);

    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    task.comentario = comentario;
    var filter = {
      years : anio
      , months : [this.getNumberMonth(mes)]
      , contracts : cont_id
    }
    this.modalViewCommentDash.content.setType("edit");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskType('MstSemanal');
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.modalViewCommentDash.content.loading = false;
      this.taskContainer = ref;
      if (this.taskContainer) {
        this.loadWeeklyFeromonaMstChart(this.taskContainer,filter);
      }
    })
    this.id_table_to_edit = id;

  }

  editCommentMSTMensual(anio,mes,id,comentario,cont_id,template: TemplateRef<any>){
    console.log('edit comments mst mensual........................')
    this.modalViewCommentDash = this.modalService.show(TaskModalComponent, this.config);
    this.tmService = new TaskModalService(this);
    let task = new Task();
    task.anio = anio;
    task.mes = mes;
    task.comentario = comentario;
    var filter = {
      years : [anio]
      , months : [this.getNumberMonth(mes)]
      , contracts : cont_id
    }
    this.modalViewCommentDash.content.setType("edit");
    this.modalViewCommentDash.content.setTask(task);
    this.modalViewCommentDash.content.setTaskType('MstMensual');
    this.modalViewCommentDash.content.setTaskService(this.tmService);
    this.modalViewCommentDash.content.chartRefSubject.subscribe((ref) => {
      this.modalViewCommentDash.content.loading = false;
      this.taskContainer = ref;
      if (this.taskContainer) {
        this.loadMonthlyFeromonaMstChart(this.taskContainer,filter);
      }
    })
    this.id_table_to_edit = id;
  }


  
  editarComentarioRTUSemanaPlaga(task: Task){  
    console.log('guardo edicion rtu plaga..........................')
    console.log(task)  
    console.log(task.comentario)
    if(this.validateFormTaskRTUSemanaPlaga(task) == true){
      this.dashboard.editCommentRTUSemanaPlaga(this.user_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 
            //actualiza tareas
            this.loadFeromonaRtuByPlagueTasks();  
            this.modalViewCommentDash.hide();
     
        },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      }) 
    }else{
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.tmService.formError();
    }
  }

  editarComentarioRTUSemanal(task){
    if(this.validateFormTaskRTUSemanal(task) == true){
      this.dashboard.editCommentRTUSemanal(this.user_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 
  
            this.loadWeeklyFeromonaRtuTasks();          
            this.modalViewCommentDash.hide();
                     
        },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      })
    }else{
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.tmService.formError();
    } 
  }

  editarComentarioRTUMensual(task){
    if(this.validateFormTaskRTUMensual(task) == true){
      this.dashboard.editCommentRTUMensual(this.user_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{
            this.loadMonthlyFeromonaRtuTasks();          
            this.modalViewCommentDash.hide();
                     
        },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      }) 
    }else{
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.tmService.formError();
    }
  }

    
  editarComentarioMSTSemanaPlaga(task: Task){    
    if(this.validateFormTaskMSTSemanaPlaga(task) == true){
      this.dashboard.editCommentMSTSemanaPlaga(this.user_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 
            //actualiza tareas
            this.loadFeromonaMstByPlagueTasks();  
            this.modalViewCommentDash.hide();
     
        },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      }) 
    }else{
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.tmService.formError();
    }
  }

  editarComentarioMSTSemanal(task){
    if(this.validateFormTaskMSTSemanal(task) == true){
      this.dashboard.editCommentMSTSemanal(this.user_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{ 
  
            this.loadWeeklyFeromonaMstTasks();          
            this.modalViewCommentDash.hide();
                     
        },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      })
    }else{
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.tmService.formError();
    } 
  }

  editarComentarioMSTMensual(task){
    console.log('envio a res.................................')
    if(this.validateFormTaskMSTMensual(task) == true){
      this.dashboard.editCommentMSTMensual(this.user_id,this.id_table_to_edit,task.comentario).subscribe(
          dato=>{
            this.loadMonthlyFeromonaMstTasks();          
            this.modalViewCommentDash.hide();
                     
        },error=>{
          console.log(error);
          this.tmService.formError();
          this.notify.warning("Error al editar el comentario, Intente mas tarde",{timeout: 5000,});      
      }) 
    }else{
      this.notify.warning("Existen campos vacios en el formulario, se deben llenar todos los campos",{timeout: 5000,}); 
      this.tmService.formError();
    }

  }

  validateFormTaskRTUSemanaPlaga(task){
    if(task.comentario === '' || task.comentario === undefined || task.comentario==null){
      return false;
    }else{
      return true;
    }
  }

  validateFormTaskRTUSemanal(task){
    if(task.comentario === '' || task.comentario === undefined || task.comentario==null){
      return false;
    }else{
      return true;
    }
  }

  validateFormTaskRTUMensual(task){
    if(task.comentario === '' || task.comentario === undefined || task.comentario==null){
      return false;
    }else{
      return true;
    }
  }


  validateFormTaskMSTSemanaPlaga(task){
    if(task.comentario === '' || task.comentario === undefined || task.comentario === null){
      return false;
    }else{
      return true;
    }
  }

  validateFormTaskMSTSemanal(task){
    if(task.comentario === '' || task.comentario === undefined || task.comentario==null){
      return false;
    }else{
      return true;
    }
  }


  validateFormTaskMSTMensual(task){
    if(task.comentario === '' || task.comentario === undefined || task.comentario==null){
      return false;
    }else{
      return true;
    }
  }

}