import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JarwisService } from 'src/app/services/jarwis.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-response-reset',
  templateUrl: './response-reset.component.html',
  styleUrls: ['./response-reset.component.css']
})
export class ResponseResetComponent implements OnInit {

  public error = [];
  public userEmailError = null;
  public form = {
    user_email: null,
    user_password: null,
    user_password_confirmation: null,
    resetToken: null
  };

  constructor(
    private route: ActivatedRoute,
    private Jarwis: JarwisService,
    private router: Router,
    private notify: SnotifyService
  ) {
    route.queryParams.subscribe(params => {
      this.form.resetToken = params['token']
    });
  }

  ngOnInit() {
  }

  onSubmit(){
    this.userEmailError = null;
    this.error = [];
    this.notify.info('Espere un momento...', {timeout: 3000});
    this.Jarwis.cambiarPassword(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(data){
    this.notify.success(data.data);
    this.router.navigateByUrl('/login');
  }

  handleError(error){
    this.notify.error('Hay errores en el formulario');
    
    if(error.error.errors){
      this.error = error.error.errors;
    } else{
      this.userEmailError = error.error.error;
    }
  }

}
