import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-modal',
  templateUrl: './contract-modal.component.html',
  styleUrls: ['./contract-modal.component.css']
})
export class ContractModalComponent implements OnInit {


  public modalVisible = true;


  constructor() { }

  ngOnInit() {
  }

  // importar funcion renderDocuments para renderizado del modal.

}
