import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import * as L from 'leaflet';
import { icon, Marker } from 'leaflet';
import { MapMarkerService } from '../../../services/map/map-marker.service';
import 'leaflet-sidebar-v2';
import '../../../../../node_modules/leaflet-polylinedecorator/dist/leaflet.polylineDecorator.js';
import '../../../../../node_modules/leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import '../../../../../node_modules/leaflet-control-window/src/L.Control.Window.js';
import 'leaflet-control-window';
import '../../../../../node_modules/leaflet-easybutton/src/easy-button';
import 'src/assets/google-mutant/google-mutant.js';

@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {
    // private map;  

    @ViewChild('map', {read:L.Map}) map :L.Map;
    // @ViewChild('customPane') customPane :ElementRef;

    markersLayer = {};
    modal;

    options = {
        layers: [
            // L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 20, attribution: '...' })
        ],
        zoom: 8,
        center: L.latLng(-33,-70)
    };

    @Output('ready') readyEvent = new EventEmitter;

    sidebar :L.Control.Sidebar = null;
    googleMutant = null; 

    constructor(private markerService :MapMarkerService) { }

    ngAfterViewInit() {
        this.readyEvent.emit()
    }


    initSideBar()
    {
        this.sidebar = L.control.sidebar({
            autopan: false,       // whether to maintain the centered map point when opening the sidebar
            closeButton: true,    // whether t add a close button to the panes
            container: 'sidebar', // the DOM container or #ID of a predefined sidebar container that should be used
            position: 'left',     // left or right
        }).addTo(this.map);
    }

    public addButton(icon,callback)
    {
        console.log('estoy creando el boton',icon,callback);
        return L.easyButton( icon,  callback ).addTo(this.map);
    }

    addStateButton(states)
    {
        return L.easyButton( states ).addTo(this.map);
    }

    addPanel(panelContent)
    {
        var _this = this;
        this.sidebar.addPanel(panelContent);
    }

    public setMarkers(markers: any[])
    {
        for(let m of markers){
            m.addTo(this.map);
        }
    }

    addPolyline( positionList, color = 'grey' )
    {
        var polyline = L.polyline(positionList, {color: color}).addTo(this.map);
        return polyline;
        // zoom the map to the polyline
        // this.map.fitBounds(polyline.getBounds());
    }

    startMap(map)
    {
        this.map = map;

        this.googleMutant = L.gridLayer.googleMutant({
            type: 'terrain'	// valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
        }).addTo(map);

        L.control.fullscreen({
            position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, defaut topleft
            title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
            titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
            content: null, // change the content of the button, can be HTML, default null
            forceSeparateButton: true, // force seperate button to detach from zoom buttons, default false
            pseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
            //fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
        }).addTo(this.map);

        this.map.on('enterFullscreen', () => map.invalidateSize());
        this.map.on('exitFullscreen', () => map.invalidateSize());

        // this.addButton();

        var _this = this;
        this.addStateButton({
            states : [{
                stateName: 'google-terrain',
                icon: 'fa fa-map',
                title: "Vista Actual: Terreno\nClick para cambiar a vista Satelital",
                onClick: function(btn){
                    _this.changeMapType('satellite');
                    btn.state('google-satellite');
                }
            } , {
                stateName: 'google-satellite',
                icon: 'fa fa-map',
                title: "Vista Actual: Satelital\nClick para cambiar a vista de Caminos",
                onClick: function(btn){
                    _this.changeMapType('roadmap');
                    btn.state('google-roadmap');
                }
            } , {
                stateName: 'google-roadmap',
                icon: 'fa fa-map',
                title: "Vista Actual: Caminos\nClick para cambiar a vista Hibrida",
                onClick: function(btn){
                    _this.changeMapType('hybrid');
                    btn.state('google-hybrid');
                }
            } , {
                stateName: 'google-hybrid',
                icon: 'fa fa-map',
                title: "Vista Actual: Hibrida\nClick para cambiar a vista de Terreno",
                onClick: function(btn){
                    _this.changeMapType('terrain');
                    btn.state('google-terrain');
                }
            }]
        });
    }

    getMap()
    {
        return this.map;
    }

    hideSidebar()
    {
        this.sidebar.removeFrom(this.map);
    }

    showSidebar()
    {
        this.sidebar.addTo(this.map);    }

    showModal( options : L.Control.WindowOptions ){

        let modalOptions : L.Control.WindowOptions = {
            element: 'map',
            className: 'control-window',
            visible: false,
            title: undefined,
            closeButton: true,
            content: undefined,
            prompt: undefined,
            maxWidth: 1000,
            modal: true,
            position: 'top'
        };
        
        Object.assign(modalOptions,options);

        this.modal =  L.control.window(this.map,modalOptions).show();
        return this.modal;
    }

    updateModalContent(content){
        this.modal.content(content);
    }

    changeMapType(_type)
    {
        this.googleMutant.removeFrom(this.map);
        this.googleMutant = L.gridLayer.googleMutant({ type: _type }).addTo(this.map);
    }

}
