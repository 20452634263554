import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, ComponentFactoryResolver, Optional, Inject } from '@angular/core';
import { AssetsMapService } from './../../../services/assets-map.service';
import * as L from 'leaflet';
import { ServiceModalComponent } from './../../service-modal/service-modal.component';
import { BsModalService } from 'ngx-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'app-asset-marker',
  templateUrl: './asset-marker.component.html',
  styleUrls: ['./asset-marker.component.css']
})
export class AssetMarkerComponent implements AfterViewInit {
  
    private marker//: L.Marker;
    private asset : any = null;
    private map;

    // mapWrapper;

    icons = {
        green: L.divIcon({
            html: '<i style="color:green; font-size:25px;" class="fas fa-map-pin"></i>'
            , iconSize    : [20, 20]
            , className   : 'asset-marker-active'
        })
        // , greenWithFocus: L.divIcon({
        //     html: '<i style="color:green; font-size:35px;" class="fas fa-map-pin"></i>'
        //     , iconSize    : [20, 20]
        //     , className   : 'asset-marker-active'
        // })
        , red: L.divIcon({
            html: '<i style="color:red;font-size:25px;" class="fas fa-map-pin"></i>'
            , iconSize    : [20, 20]
            , className   : 'asset-marker-inactive'
        })
        // , redWithFocus: L.divIcon({
        //     html: '<i style="color:red;font-size:35px;" class="fas fa-map-pin"></i>'
        //     , iconSize    : [20, 20]
        //     , className   : 'asset-marker-inactive'
        // })        
        , gray: L.divIcon({
            html: '<i style="color:gray;font-size:25px;" class="fas fa-map-pin"></i>'
            , iconSize    : [20, 20]
            , className   : 'asset-marker-inactive'
        })
        // , grayWithFocus: L.divIcon({
        //     html: '<i style="color:red;font-size:35px;" class="fas fa-map-pin"></i>'
        //     , iconSize    : [20, 20]
        //     , className   : 'asset-marker-inactive'
        // })
    }

    @Output() serviceInfoRequested = new EventEmitter();

    // @ViewChild('serviceModal') modal : ServiceModalComponent;


    constructor( @Inject(AssetsMapService) private assetsMapService :AssetsMapService, 
                /* @Inject(ComponentFactoryResolver) private factory :ComponentFactoryResolver */
                private modal :ServiceModalComponent  )
    {
    }

    ngAfterViewInit()
    {
    }

    addTo(map: L.Map)
    {
        this.map = map;
        this.marker.addTo(map);
    }

    setAsset(asset)
    {
        this.asset = asset;
    }

    getAsset()
    {
        return this.asset;
    }

    initMarker()
    {
        
        this.marker = L.marker(
            [ parseFloat(this.asset.latitude), parseFloat(this.asset.longitude) ]
            , { icon: this.getIcon() }
        );
    }

    public getIcon() :any
    {
        var icon;
        switch (this.asset.status.toLowerCase()) {
            case 'realizado':
                icon = this.icons.green;
                break;
            case 'no realizado':
                icon = this.icons.red;
                break;
            default : 
                icon = this.icons.gray;
        }
        // this.asset.status=='Activo' ? this.icons.active : this.icons.inactive
        return icon;
    }

    setPopup()
    {
        let popup = L.popup({maxWidth: 600}).setContent( this.getPopup() );
        this.marker.bindPopup( popup );
        // this.marker.getPopup().maxWidth = 600;
        // console.log('binding popup',this.getPopup(),this.marker)
    }

    update(data)
    {
        this.asset.lastServiceDate = data.date;
        this.asset.status = data.status;
        this.marker.setIcon( this.getIcon() );
        var position = [parseFloat(data.latitude), parseFloat(data.longitude)];
        this.marker.setLatLng(position);
    }


    

    public build()
    {
        this.initMarker();
        this.setPopup();
        this.bindEvents();
    }

    private bindEvents()
    {
        var _this = this;
        this.marker.on('popupopen',function(){
            if( !_this.areServicesLoaded() ){
                _this.assetsMapService.getAssetServices(_this.asset.id).subscribe(
                    data => {
                        _this.asset.services = data['services'];
                        _this.marker.setPopupContent( _this.getPopup(data['services']) );
                        _this.bindPopupBtnEvents();
                    }
                    , error => {
    
                    }
                )
            }else{
                _this.bindPopupBtnEvents();
            }
        });

        this.marker.on('mouseover',function(){ 
            _this.marker.valueOf()._icon.style.transform += ' scale(1.3)';
        });
    
        this.marker.on('mouseout',function(){
            var newStyleTransform = _this.marker.valueOf()._icon.style.transform.replace(' scale(1.3)','');
             _this.marker.valueOf()._icon.style.transform = newStyleTransform;
        });
    }

    bindPopupBtnEvents()
    {
        var _this = this;
        setTimeout(function(){
            // var btns = document.getElementsByClassName('btn-service-info');
            // console.log('bindPopupBtnEvents',btns,btns.length);
            $(".btn-service-info").each(function(index,item){
                $(item).on('click',function(){
                    let data = { 
                        serviceAssetId: this.dataset.id,
                        assetId: this.dataset.assetId
                    }
                    // _this.serviceInfoRequested.emit(data);
                    _this.showServiceModal(this.dataset.id);
                })
            })
            // for( let i = 0; i<btns.length; i++ ){
            //     console.log('iterando elementos',btns[i]);
            //     btns[i].addEventListener('click',function(btn){
            //         console.log('id',this.dataset.id,_this.asset);
            //         let data = { 
            //             serviceAssetId: this.dataset.id,
            //             assetId: this.dataset.assetId
            //         }
            //         // _this.serviceInfoRequested.emit(data);
            //         _this.showServiceModal(this.dataset.id);
            //     });
            // }
        },500);
    }

    centerMapOnMe()
    {
        let map = this.marker._map;
        const position = this.marker.getLatLng();
        map.setView(position,15);
        this.marker.openPopup();
    }
    // marker.bindPopup( this.popupService.makeAssetPopup(c.properties) );
    // marker.addTo(map);

    remove()
    {
        this.map = null;
        this.marker.remove();
    }

    areServicesLoaded()
    {
        return Array.isArray(this.asset.services);
    }

    showServiceModal(id)
    {
        this.modal.show(id);
    }


    getPopup( previousServices = null )
    {
        let servicesHTML = this.getPopupServicesHTML(previousServices);
        let infoHTML = this.getPopupInfoHTML();

        let ariaExpanded = previousServices==null ? "false" : "true" ;
        let serviceCollapseShow = previousServices==null ? "" : "show" ;

        var popup = `   <div classs="row"
                            <div class="col-lg-12 d-flex justify-content-center">
                                <h5>CODIGO: <strong>${ this.asset.name }</strong> <h5>
                            </div>
                        </div>
                        <div id="accordion-${ this.asset.id }">
                            <div class="card">
                                <div class="card-header" id="heading-info">
                                    <button class="btn btn-link" 
                                            data-toggle="collapse" 
                                            data-target="#popup-info-${ this.asset.id }"
                                            aria-expanded="true" 
                                            aria-controls="popup-info-${ this.asset.id }">
                                        Información
                                    </button>
                                </div>
                                <div id="popup-info-${ this.asset.id }" class="collapse show" aria-labelledby="heading-info" data-parent="#accordion-${ this.asset.id }">
                                    <table class="table-hover table-condensed table-striped table-bordered">
                                        <tbody>
                                            ${ infoHTML }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading-services">
                                    <button class="btn btn-link" 
                                            data-toggle="collapse" 
                                            data-target="#popup-services-${ this.asset.id }"
                                            aria-expanded="${ ariaExpanded }" 
                                            aria-controls="popup-services-${ this.asset.id }">
                                        Ultimos Servicios
                                    </button>
                                </div>
                                <div id="popup-services-${ this.asset.id }" 
                                    class="collapse ${ serviceCollapseShow }" 
                                    aria-labelledby="heading-services" 
                                    data-parent="#accordion-${ this.asset.id }">
                                    ${ servicesHTML }
                                </div>
                        </div>`;
                        
        return popup;
    }

    getPopupInfoHTML()
    {
        let propertyHTML =  this.asset.subProperty==null ? '' : `
        <div>Propiedad : ${ this.asset.property }</div>
        <div>Sub propiedad : ${ this.asset.subProperty }</div>`;
        var bodyElements = {
            Propiedad : this.asset.subProperty==null ? null : this.asset.property,
            "Sub propiedad" : this.asset.subProperty,
            Ubicacion : this.asset.contractLocation,
            Cliente        : this.asset.client,
            Grupo          : this.asset.group,
            Categoria      : this.asset.category,
            Localizacion   : this.asset.location,
            Estado         : this.asset.status,
            Instalado      : this.asset.installed
        };

        var infoHTML = '';
        for( let title in bodyElements ){
            let value = bodyElements[title];
            if( value != null ){
                infoHTML += `<tr>
                                <td style="padding-left:15px">${ title }</td>
                                <td align="center">${ value }</td>
                            </tr>`;
            }
        }
        return infoHTML;
    }


    getPopupServicesHTML(previousServices)
    {
        if( previousServices!=null ){
            var tableBodyHTML = '';
            var noServicesHTML = '';
            for(let service of previousServices){
                tableBodyHTML += `<tr>
                                <td align="center">${ service.date }</td>
                                <td align="center">${ service.status }</td>
                                <td align="center">${ service.name }</td>
                                <td align="center">
                                    <i class="fas fa-info-circle btn-service-info" style="cursor:pointer;" data-id="${ service.id }" data-asset-id="${ this.asset.id }"></i>
                                </td>
                            </tr>`;
            }

            if( previousServices.length==0 ) noServicesHTML = '<div class="col-lg-12 d-flex justify-content-center">Sin servicios previos.</div>';

            var tableHTML = `   <table class="table-hover table-condensed table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="text-align:center;">Fecha</th>
                                            <th style="text-align:center;">Estado</th>
                                            <th style="text-align:center;">Servicio</th>
                                            <th style="text-align:center;">Info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        ${tableBodyHTML}
                                    </tbody>
                                </table>
                                ${noServicesHTML} `;
        }
        else
        {
            // margin-top: 10px; margin-bottom: 10px;  margin-left: 200;  margin-right: 200px;
            var tableHTML = `   <div  style="text-align: center; margin-top: 10px; margin-bottom: 10px;" >
                                    <span   class="spinner-border spinner-border-sm" 
                                            role="status" 
                                            aria-hidden="true"></span>
                                    &nbsp;Cargando Servicios...
                                </div>`;
        }
        return tableHTML;
    }





}
