import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'headerNormalizer'
})
export class HeaderNormalizerPipe implements PipeTransform {

  transform(value: string,type = 'ufl',split = '_'): string {
    var originalWords = value.split(split);
    var finalWords = [];
    let finalHeader : string;
    $.each(originalWords,function(i,word){
      let newWord = "";
      if(type=='ufl' /*Uppercase First Letter*/ ){
        newWord = word[0].toUpperCase() +  
        word.slice(1); 
      }else if(type=='u' /*Uppercase All*/){
        newWord = word.toUpperCase();
      }else if(type=='l' /*Lowercase All*/){
        newWord = word.toLowerCase();
      }
      finalWords.push(newWord);
    });
    finalHeader = finalWords.join(' ');
    return finalHeader;
  }


}
