import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';


const segtyId: number = 0;

@Injectable({
  providedIn: 'root'
})
export class ReloadComponentsService {

  private segtyID$ = new BehaviorSubject<number>(segtyId);
  constructor() { }

  get updatedSegtyID$(): Observable<number> {
    return this.segtyID$.asObservable();
  }

  setSegtyID$(segtyID: number): void {
    console.log(`setSegtyID$ ${segtyID}`);
    this.segtyID$.next(segtyID);
  }



}
