import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: decodeURI(JSON.parse(localStorage.getItem("style")).footer.toString()).toString(),
  styles: [JSON.parse(localStorage.getItem("style")).footercss.toString()]

})
export class FooterComponent implements OnInit {

  constructor() {
  	

   }

  ngOnInit() {


  	
  }

}
