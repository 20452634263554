import { Component, OnInit, ChangeDetectorRef, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { PrintService } from 'src/app/services/print.service';
import { ActivatedRoute } from '@angular/router';
import { PlanSvgComponent } from '../plan-svg/plan-svg.component';


@Component({
  selector: 'app-print-plan',
  templateUrl: './print-plan.component.html',
  styleUrls: ['./print-plan.component.css']
})
export class PrintPlanComponent implements OnInit
{
   vars ;
   @ViewChild('planSVG',{read:PlanSvgComponent}) planSVG :PlanSvgComponent;


   constructor(
      private printService: PrintService,
      public cDetector: ChangeDetectorRef,
      private route: ActivatedRoute,

   ) {
      // console.log('construyendo print plan')
      this.vars = JSON.parse(this.route.snapshot.params['printParams']);
   }

   ngOnInit() {
      // console.log('print init SVG');
      this.planSVG.initSVG(this.vars);
      this.planSVG.readyEvent.subscribe(()=>{
         this.print(1000);
      });
      // this.print(5000);
      // {
      //    contract: this.vars.contractId,
      //    serviceGroupType : this.vars.serviceGroupType,
      //    print : true,
      //    blueprint : this.
      // }
   }

   print(time = 3500) {
      this.cDetector.detectChanges();
      setTimeout(() => {
         // console.log(this.dataSourceInterior,this.gType,this.gName);
         // console.log('timeout');
         this.printService.onDataReady();
         
      }, time);
   }
   
}
