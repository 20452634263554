import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { DataTableComponent } from '../../dashboard/data-table/data-table.component';
import { SearchInputComponent } from '../search-input/search-input.component';


@Component({
    selector: 'SearchableTable',
    templateUrl: './searchable-table.component.html',
    styleUrls: ['./searchable-table.component.css']
})
export class SearchableTableComponent implements OnInit {

    originalData;
    displayedData;

    @Input('inputSize') inputSize = 50;

    @Output('clickOnCell') clickOnCellEvent = new EventEmitter;

    @ViewChild('input') private input :SearchInputComponent;
    @ViewChild('table') public table :DataTableComponent;


    constructor() { }

    ngOnInit() {
    }


    onSearch(event){
        console.log('onSEARCH => ',event);
        this.displayedData = this.filter(event.search);
        this.table.setDataSource( this.displayedData );
    }

    filter(search){
        console.log('filter search',search);
        var matchedRows = [];
        for( let row of this.originalData ){
            console.log('ROW',row)
            var match = false;
            for( let column in row ){
                let cellValue = row[column];
                console.log('cellValue',cellValue)
                if( cellValue.toLowerCase().includes(search) )   match = true;
            }
            console.log('match',match);
            if( match ) matchedRows.push(row);
        }
        console.log('matchedRows',matchedRows)
        return matchedRows;
    }

    setDataSource(dataSource)
    {
        this.originalData = dataSource;
        this.displayedData = dataSource;
        this.table.setDataSource(dataSource);
    }

    getDataTable()
    {
        return this.table;
    }

    clickOnCell(event)
    {
        console.log('SearchableTable::clickOnCell',event);
        this.clickOnCellEvent.emit(event)
    }
}
