import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  constructor() { }

  format(date:Date,format :string = 'y-m-d') : string
  {
    let year = date.getFullYear().toString();
    let month = ( date.getMonth() + 1 ).toString().padStart(2,'0');
    let day = date.getDate().toString().padStart(2,'0');
    return format.replace('y',year)
                  .replace('m',month)
                  .replace('d',day)
  }
}
