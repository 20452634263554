import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';

@Component({
  selector: 'app-contrato',
  templateUrl: './contrato.component.html',
  styleUrls: ['./contrato.component.css']
})
export class ContratoComponent implements OnInit {
  public cust_id;
  public cont_id;

  constructor(
    private activatedRoute: ActivatedRoute,
    private valPermiso: ValidaPermisosService
    ) {
    this.activatedRoute.params.subscribe(
      params => this.cargarDatos(params)
    );
   }

  ngOnInit() {
  }

  cargarDatos(data){
    //estos son los datos de la barra
    this.cust_id = data.cust_id;
    this.cont_id = data.cont_id;

    console.log(`'datos de la barra: ${this.cust_id} ${this.cont_id}`);

  }

  validaPermisos(permiso){
    // console.log(`validaPermisos(permiso): ${permiso}`);
    return this.valPermiso.validaPermisos(permiso);
  }

}
