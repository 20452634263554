import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'svg-marker',
  templateUrl: './svg-marker.component.html',
  styleUrls: ['./svg-marker.component.css']
})
export class SvgMarkerComponent implements OnInit {


    @Output('click') clickEvent = new EventEmitter;
    @Input() color = 'red';
    @Input() data = {
        id : 0,
        x : 0,
        y : 0,
    }

    @Input() title = '';

    constructor() { }


    ngOnInit() {

    }

    onClick(event){
        console.log(event);
        alert('click en marcador de planos');
        this.clickEvent.emit({ event: event, data: this.data })
    }

}
