import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BaseControlComponent } from './../base-control/base-control.component';
import { ControlWrapperComponent } from '../control-wrapper/control-wrapper.component';
import { MatInput } from '@angular/material'
import {MatButtonModule} from '@angular/material/button'; 
import {MatIconModule} from '@angular/material/icon'; 


@Component({
    selector: 'app-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.css']
})
export class TextControl extends BaseControlComponent implements OnInit
{
    constructor() { 
        super();
    }

    @Input('clearBtn') showClearBtn :boolean = true;

    ngOnInit() {
    }

    onKeyup(event)
    {
        this.data.value = event.target.value;
        this.changeEvent.emit({event:event,control:this.data});
    }

    onClear(event)
    {
        this.data.value = '';
        this.changeEvent.emit({event:event,control:this.data});
    }
  

}
