import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientsOptionsService {

  baseUrl: string;

  constructor(private http:HttpClient) { 
  	this.baseUrl = environment.apiUrl;
  }

  get_options(clie_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_clients_opt',{clie_id: clie_id,'token':sessionStorage.getItem('token')});       
  }

  
}
