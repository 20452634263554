import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  baseUrl: string;
  //http://api.movilform.com/api/login
  //http://localhost/Backend/backend/public/api/login
  private iss = {
    login: environment.apiUrl+environment.apiEnd+'/login',
  }
  //http://api.movilform.com/
  //http://localhost/Backend/backend/public/
  constructor(private http:HttpClient) { this.baseUrl = environment.apiUrl; }

  handle(token){
    this.set(token);
  }

  set(token){
    sessionStorage.setItem('token', token);
  }

  get(){
    return sessionStorage.getItem('token');
  }

  remove(){
    sessionStorage.removeItem('token');
  }
  verify(): Observable<any>{
    return this.http.post<any>(this.baseUrl+"api/verify",{'token':sessionStorage.getItem('token')},{headers: new HttpHeaders({
        
        'Content-type': 'application/json',
        'Accept': 'application/json',

      })});

  }

  isValid(){
    const token = this.get();

    if(token){
      const payload = this.payload(token);
      if(payload){
        return (Object.values(this.iss).indexOf(payload.iss) > -1) ? true : false;
      }
    }
    return false;
  }

  payload(token){
    const payload =  token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload){
    return JSON.parse(atob(payload));
  }

  loggedIn(){
    if(sessionStorage.getItem('auth')=='1'){
      return true;
    }
    else{
      return false;
    }
    return this.isValid();
  }
}
