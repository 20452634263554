import { Component, OnInit, EventEmitter, Output, ViewChild, AfterViewInit, ViewContainerRef } from '@angular/core';
import { DataTableComponent } from './../../dashboard/data-table/data-table.component';
import { SearchableTableComponent } from './../../common/searchable-table/searchable-table.component';
import { ControlFactoryService } from 'src/app/services/control-factory.service';
import { AppInjector } from 'src/app/services/app-injector.service';
import { Control } from 'src/app/models/control.model';
import { AssetsMapService } from 'src/app/services/assets-map.service';
import { MapComponent } from '../map/map.component';
import { DateFormatService } from 'src/app/services/date-format.service';





@Component({
    selector: 'LeafletAssetPanel',
    templateUrl: './asset-panel.component.html',
    styleUrls: ['./asset-panel.component.css']
})



export class AssetPanelComponent implements AfterViewInit {

    @Output() selectedAsset = new EventEmitter();
    @Output() ready = new EventEmitter();
    @ViewChild('filtersContainer', {read:ViewContainerRef} ) filtersContainer :ViewContainerRef;
    @ViewChild('table', {read:SearchableTableComponent} ) sTable :SearchableTableComponent;

    markers = [];
    private controlFactory :ControlFactoryService;
    private assetMapService :AssetsMapService;
    controls = [];
    mapWrapper :MapComponent;

    // indica si se ha agregado la correccion css para 
    // el correcto despliegue de los mat-select
    selectorsFixed = false;
    dateFormatter: DateFormatService;
    loading = false;
    searchedDate = '';
    
    constructor()
    {
        this.controlFactory = AppInjector.get(ControlFactoryService);
        this.assetMapService = AppInjector.get(AssetsMapService);
        this.dateFormatter = AppInjector.get(DateFormatService);
    }
    

    ngAfterViewInit(): void 
    {
        this.ready.emit();
        this.sTable.table.isHoverEnabled = true;
        this.sTable.table.customRowTitle = 'Click para centrar el mapa en este Articulo';
    }

    onSelectedAsset(event)
    {
        this.selectedAsset.emit(event);
    }


    setMarkers(markers)
    {
        this.markers = markers;
        let rows = this.markersToTableData(markers);
        this.updateTable(rows);
    }

    refreshMarkers()
    {
        this.assetMapService.getUpdatedAssets(this.markers,this.searchedDate ).subscribe(
            data => {
                this.updateMarkers(data['assets']);
            }
        )
    }

    markersToTableData(markers)
    {
        var rows = [];
        for(let marker of markers){
            if (marker.map==null) continue;
            let a = marker.getAsset();
            rows.push({
                'articulo'          : a.name
                , 'ultimo_servicio' : a.lastServiceDate
            });
        }
        return rows;
    }

    setMap(map)
    {
        this.mapWrapper = map;
    }

    updateTable(tableData)
    {
        this.sTable.table.showAllColumns();
        this.sTable.setDataSource(tableData);
        this.sTable.table.hideFrame();
    }

    getDOM()
    {
        return document.getElementById('assetPanel');
    }

    onMarkerRowClicked(event)
    {
        let marker = this.findMarkerByName( event.row.articulo );
        marker.centerMapOnMe();
    }


    findMarkerByName(seekedName)
    {
        var seekedMarker = null;
        for(let marker of this.markers){
            let a = marker.getAsset();
            if( a.name == seekedName ){
                seekedMarker = marker;
                break;    
            }
        }
        return seekedMarker;
    }


    clearMarkers()
    {
        for(let marker of this.markers){
            marker.remove();
        }
        this.markers = [];
    }

    initFilters() :void
    {
        this.filtersContainer.clear();
        this.selectorsFixed = false;
        let options = this.getFilterOptions();
        let controls = [
            new Control({
                id: 1
                , label: "Fecha Servicio"
                , tag: "lastServiceDate"
                , value: ''
                , type: "DATE"
                , options : `` 
            }),
            new Control({
                id: 1
                , label: "Mostrar Todos"
                , tag: "showAll"
                , value: true
                , type: "CHECKBOX"
                , options : `` 
            }),
            new Control({
                id: 1
                , label: "Estado Articulo"
                , tag: "status"
                , value: true
                , type: "CHECKBOX"
                , options : { color:'red' }
            }),
            new Control({ 
                id: 1
                , label: "Grupo"
                , tag: "group"
                , value: ""
                , type: "MULTISELECT"
                , options : { data: options['group'] }
            }),
            new Control({ 
                id: 1
                , label: "Propiedad"
                , tag: "property"
                , value: ""
                , type: "MULTISELECT"
                , options : { data: options['property'] }
            }),
            new Control({ 
                id: 1
                , label: "Categoria"
                , tag: "category"
                , value: ""
                , type: "MULTISELECT"
                , options : { data: options['category'] }
            }),
            new Control({ 
                id: 1
                , label: "Cliente"
                , tag: "client"
                , value: ""
                , type: "MULTISELECT"
                , options : { data: options['client'] }
            }),
            new Control({ 
                id: 1
                , label: "Localización"
                , tag: "location"
                , value: ""
                , type: "MULTISELECT"
                , options : { data: options['location'] }
            }),
            new Control({ 
                id: 1
                , label: "Sub-propiedad"
                , tag: "subProperty"
                , value: ""
                , type: "MULTISELECT"
                , options : { data: options['subProperty'] }
            })
        ];


        this.controls = [];
        for(let filterSchema of controls){
            var control = this.controlFactory.insertControl(this.filtersContainer,filterSchema);
            if(control.data.type == "DATE"){
                control.setDisplay("block");
                control.disable();
            }
            control.addClass("col-lg-6");
            this.bindFilterEvents(control);
            this.controls.push(control);
        }
    }

    bindFilterEvents(control)
    {
        if( ['SELECT','MULTISELECT','DATE'].includes(control.data.type) ){
            var _this = this;
            control.toggleEvent.subscribe(event => {
                if( !_this.selectorsFixed ){
                    $(".cdk-overlay-container").css("z-index",999999);
                    _this.selectorsFixed = true;
                }
            });

            // control.changeEvent.subscribe(event => {
            //     console.log('changeevent form panel',event);
            // });
        }
        if( control.data.type == 'CHECKBOX'){
            if(control.data.tag=='showAll')
            control.changeEvent.subscribe(event => {
                if( true == event.control.value ){
                    this.disableDateControl();
                }else{
                    this.enableDateControl()
                }
            });
        }
    }

    disableDateControl()
    {
        for(let control of this.controls){
            if( control.data.tag =='lastServiceDate'){
                control.disable();
                control.clear();
            }
        }
    }

    enableDateControl()
    {
        for(let control of this.controls){
            if( control.data.tag =='lastServiceDate'){
                control.enable();
            }
        }
    }



    onClickApplyFilters()
    {
        var filters = [];
        for(let control of this.controls){
            filters[ control.data.tag ] =  control.innerValue;
        }
        if (filters['lastServiceDate']!=this.searchedDate) {
            this.searchedDate = filters['lastServiceDate'].trim();
            this.loading = true;
            this.assetMapService.getUpdatedAssets(this.markers,filters['lastServiceDate'].trim() ).subscribe(
                data => {
                    
                    this.updateMarkers(data['assets']);
                    this.applyFilters(filters);
                    this.loading = false;
                }
            )
        } else {
            this.applyFilters(filters);
        }
    }

    updateMarkers (assets: any)
    {
        this.markers.forEach( marker => {
            var update = assets.filter(a=> a.id==marker.getAsset().id);
            // marker.remove();
            if (update.length > 0) {3
                marker.update(update[0]);
            }
        });
    }

    onClickRemoveFilters(){
        for( let marker of this.markers ){
            this.enableMarker(marker);
        }
        for( let control of this.controls ){
            if( control.data.type=='CHECKBOX' ){
                control.toDefault();
            }else{
                control.clear();
                if(control.data.type=='DATE') control.disable()
            }
        }
    }

    applyFilters (filters)
    {
        for(let marker of this.markers){
            this.applyFitlersToMarker(marker,filters);
        }
        this.updateTable( this.markersToTableData(this.markers) );
    }

    applyFitlersToMarker(marker,filters)
    {
        var showMarker = true;

        for( let [attribute,filterValue] of Object.entries(filters) ){
            if( !marker.getAsset().hasOwnProperty(attribute) ){
                continue;
            }
            //SELECTS
            if( filterValue instanceof Array ){
                if( filterValue.length>0 ){
                    var match = false;
                    for(let value of filterValue ){
                        if( marker.getAsset()[attribute]==value ){
                            match = true;
                        }
                    }
                    if( !match ) showMarker = false;
                }
            }
            //CHECKBOX STATUS
            if( attribute == 'status' ){
                if( filterValue==false ){
                    if( marker.getAsset().status!='Realizado' ) showMarker = false;
                }
            }
            //DATE
            if( attribute == 'lastServiceDate' ){
                if( filterValue!='' ){
                    let assetDate = this.dateFormatter.format( new Date(marker.getAsset().lastServiceDate) ) ;
                    if( assetDate > filterValue ){
                        showMarker = false;
                    }
                }
            }
        }
        if( showMarker ){
            this.enableMarker(marker);
        }else{
            this.disableMarker(marker);
        }
    }

    disableMarker(marker)
    {
        marker.remove();
    }

    enableMarker(marker)
    {
        marker.addTo(this.mapWrapper.map);
    }

    getFilterOptions() :Object
    {
        var options = {
            group: [],
            category: [],
            location: [],
            property: [],
            client: [],
            subProperty: []
        }
        for(let marker of this.markers){
            let asset = marker.getAsset();
            Object.entries(options).forEach(([currentOption, collection]) => {
                if( !collection.includes(asset[currentOption]) && asset[currentOption]!=null ){
                    options[currentOption].push(asset[currentOption]);
                }
            });
        }
        return options;
    }
    
}
