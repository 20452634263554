import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StylesService {
	baseUrl: string;
  constructor(private http:HttpClient) { 

  	this.baseUrl = environment.apiUrl;

  }

  getStyle(num):Observable<string>{

  	return this.http.post(this.baseUrl+"getStyle",{id:num},{responseType: 'text'});
  }
}
