import { Component, TemplateRef, OnInit, ViewChild } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import * as moment from 'moment';
import * as $ from 'jquery';
import { ContractDocsService } from '../../services/contract-docs.service';
import { ServiceModalComponent } from '../service-modal/service-modal.component';
import { environment } from '../../../environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ReloadComponentsService } from '../../services/reload-components.service';

@Component({
  selector: 'app-contract-docs',
  templateUrl: './contract-docs.component.html',
  styleUrls: ['./contract-docs.component.css']
})
export class ContractDocsComponent implements OnInit {
  public compId: number;
  public contId: number;
  public segtyId: number;
  public selectedDocument: any;
  public contractDocs;
  public contractDocsAll;
  public filteredDocs;
  public files;
  public urlS3;

  // variable para el array de permisos relacionados al modulo de contratos. (cargar en el constructor)
  documentsPermissions: any;
  public modalVisible = false;
  public pagina = 1;
  public porPagina = 5;
  public showInput = false;
  public params = {
    title: '',
    description: '',
    type: '',
    date: '',
    contractId: '',
    segmentId: '',
    docId: '',
    docUrl: '',
  };
  public docsTypes: any;
  public docsSubTypes: any;


  public selectedDocType: any;
  public SDT = 'seleccione';
  type: number;
  subType: string;
  public docName: string;
  public reposEnable = 2;
  public newRepoName: string;

  private docsPerms;

  myDropDown: string;

  public form = {
    'user_id': '',
    'docName': '',
    'contId': 0,
    'segtyId': 0,
    'clieId': 0,
    'resutyId': 0
  };
  public enableUpload = false;
  public reposTypes;
  @ViewChild(ServiceModalComponent) serviceModal: ServiceModalComponent;
  modalRef?: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private contService: ContractDocsService,
    private notify: SnotifyService,
    private reloadService: ReloadComponentsService
  ) {
    this.chargeLocalStorageVars();
    this.loadPermissions();
    this.getDocsOfContract();
    this.getDocumentsTypes();
    console.log('CONSTRUCTOR DE DOCUMENTOS.');
  }




  chargeLocalStorageVars(): void {
    this.compId = Number(localStorage.getItem('comp_id'));
    this.contId = Number(localStorage.getItem('cont_id'));
    // this.segtyId = Number(localStorage.getItem('segtyId'));
    // this.segtyId = service.blabla


    this.urlS3 = environment.s3Url;
    this.contractDocs = [];
    this.reposTypes = [
      'Documentos Legales',
      'Documentos Trabajador',
      'Todos'
    ];
  }


  loadPermissions(): void {
    const userPerms = JSON.parse(localStorage.getItem('perms'));
    const filteredPerms = userPerms.filter(perm => perm.modu_id === 67);
    if (!filteredPerms.length) {
      this.docsPerms = [];
      this.notify.error('No tiene permisos para este modulo', { timeout: 0 });
    } else {
      this.docsPerms = filteredPerms;
    }
  }


  ngOnInit() {
  }

  // variable con el valor de la ultima linea de negocio seleccionada!
  segtyID$ = this.reloadService.updatedSegtyID$.subscribe(segtyID => {
    this.segtyId = segtyID;
    return segtyID;
  });

  handleInputVisible() {
    this.showInput = !this.showInput;
  }

  seeDoc(doc) {
    window.open(`${this.urlS3}${doc.repos_route}`, '_blank');
  }


  chargeFile(event) {
    this.files = event.target.files;
    this.enableUpload = true;
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }

  renderEditModal(template: TemplateRef<any>, doc) {
    this.selectedDocument = doc;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
  }


  closeModal(modalRef) {
    modalRef.hide();
    this.form.docName = '';
    this.showInput = false;
    this.type = 0;
    this.subType = '';
    this.files = null;
  }


  closeUpdateModal(modalRef) {
    modalRef.hide();
    this.selectedDocument = null;
    this.reposEnable = 2;
    this.newRepoName = '';
  }




  async uploadDocument() {
    if (this.showInput) {
      this.docName = this.form.docName;
    }
    const userId = JSON.parse(localStorage.getItem('userData')).user_id;
    const formData = new FormData();
    formData.append('file', this.files[0]);
    this.showInput ? formData.append('docName', this.docName) : null;
    formData.append('contId', this.contId.toString());
    formData.append('compId', this.compId.toString());
    formData.append('segtyId', this.segtyId.toString());
    formData.append('clieId', JSON.parse(localStorage.getItem('userData')).clie_id);
    formData.append('retyId', this.type.toString());
    formData.append('resutyId', this.subType);
    formData.append('userId', userId);

    await this.contService.uploadDocument(formData).subscribe(data => {
      this.notify.success('Archivo subido correctamente.', { timeout: 2000 });

      this.showInput = false;
      this.form.docName = '';

      this.modalRef.hide();
      this.type = 0;
      this.subType = '';
      this.files = null;
      setTimeout(() => {
        this.getDocsOfContract();
      }, 3000);
    }, error => {
      console.log(error);
      if (error.status == 400) {
        this.notify.confirm('Ya existe un archivo con el mismo nombre. ¿desea reemplazarlo?', {
          timeout: 0,
          closeOnClick: false,
          pauseOnHover: true,
          buttons: [
            {
              text: 'Aceptar',
              action: (toast) => {
                this.notify.remove(toast.id);
                this.contService.overrideDocumentS3(formData).subscribe(data => {
                  this.notify.success('Archivo actualizado correctamente.', { timeout: 2000 });
                  this.modalRef.hide();
                  this.type = 0;
                  this.subType = '';
                  setTimeout(() => {
                    this.getDocsOfContract();
                  }, 3000);
                }, error => {
                  console.log(error);
                  this.notify.error('Error al subir el archivo.', { timeout: 2000 });
                });
              }
            },
            {
              text: 'Rechazar',
              action: (toast) => {
                this.notify.remove(toast.id);
              }
            },
          ]
        });
      } else {
        this.notify.error('Error al subir el archivo.');
        return;
      }
    });
    this.form.docName = '';
    this.notify.info('Buscando documentos...', { timeout: 3000 });
    this.enableUpload = false;
  }

  async editDocument() {
    const reposId = this.selectedDocument.repos_id;
    const name = this.newRepoName;
    const enable = this.reposEnable;
    const reposRoute = this.selectedDocument.repos_route;
    const reposActualName = this.selectedDocument.repos_name;
    const userId = JSON.parse(localStorage.getItem('userData')).user_id;
    const resutyId = this.selectedDocument.resuty_id;

    await this.contService.updateDocumentName(reposId, name, enable, reposActualName, reposRoute, userId,resutyId).subscribe(data => {
      this.notify.success('Documento actualizado correctamente.', { timeout: 2000 });
      this.modalRef.hide();
      this.selectedDocument = null;
      this.newRepoName = '';
      this.reposEnable = 2;
      setTimeout(() => {
        this.modalRef.hide();
        this.getDocsOfContract();
      }, 3000);
    }, error => {
      if (error.status == 409) {
        this.notify.warning('Ya existe un documento con el mismo nombre.', { timeout: 2000 });
        return;
      }
      if (error.status == 400) {
        this.notify.warning('Debe indicar un nuevo nombre y/o actulizar el estado', { timeout: 2000 });
        return;
      }
      console.log(error);
      this.notify.error('Error al actualizar el documento.', { timeout: 2000 });
    });

  }


  getDocsOfContract() {
    console.log(`getDocsOfContract()`);
    this.notify.info('Buscando documentos...', { timeout: 4000 });
    console.log(`this.segtyID$ = ${this.segtyID$}`);

    this.contractDocs = this.contService.getDocumentsByContractId(this.contId, this.segtyId, this.docsPerms).subscribe(
      data => {
        if (data && data.length > 0) {
          this.contractDocsAll = data;
          this.contractDocs = this.contractDocsAll;
        } else {
          this.contractDocs = [];
          this.notify.info('No se encontraron documentos para este contrato', { timeout: 2000 });
        }
      }, err => {
        this.notify.error('Error al recuperar los documentos', { timeout: 2000 });
        console.log(err);
      });
  }

  getDocumentsTypes() {
    this.docsTypes = this.contService.getDocumentTypes(this.compId).subscribe(
      data => {
        this.docsTypes = data;
      }, err => {
        console.log(err);
        this.notify.error('Error al obtener tipos de documentos', { timeout: 2000 });
      });
  }



  // funcion para cargar los subtipos de documento
  getDocumentsSubTypes() {
    this.docsSubTypes = this.contService.getDocumentSubTypes(this.subType).subscribe(
      data => {
        this.docsSubTypes = data;
      }, err => {
        console.log(err);
        this.docsSubTypes = [];
        this.notify.error('Error al obtener subtipos de documentos', { timeout: 2000 });
      });
  }


  handleTypeSelected(event) {
    this.notify.info('Buscando subtipos de documentos...', { timeout: 2000 });
    this.docsSubTypes = this.contService.getDocumentSubTypes(event.target.value).subscribe(
      data => {
        this.docsSubTypes = data;
      }, err => {
        console.log(err);
        this.docsSubTypes = [];
        this.notify.error('Error al obtener subtipos de documentos', { timeout: 2000 });
      });
  }


  handleSubTypeSelected(event) {
    this.subType = event.target.value;
  }




  onChangeofOptions(event) {
    this.type = event.target.value;
  }

  change(event) {
    this.selectedDocType = event;
  }

  checkPermission(action) {
    const userPerms = JSON.parse(localStorage.getItem('perms'));
    let hasPerm = false;
    userPerms.forEach(perm => {
      if (perm.perm_key === action) {
        hasPerm = true;
      }
    });
    return hasPerm;
  }


  checkSubType() {
    console.log('checkSubType');
    console.log(this.subType);
  }




  filtrar(event){
    var filter = event;
    this.contractDocs = this.contractDocsAll.filter(function (doc) {
      return (
        doc.repos_name.toLowerCase().includes(filter.toLowerCase()) 
      );
    });
  }

}
