import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FormsService {

    baseUrl: string;

    constructor(private http:HttpClient) { 
        this.baseUrl = environment.apiUrl+'api/';
    }



    getFormByServiceAssetId(id): Observable <any[]>{
        return this.http.post<any[]>(this.baseUrl+'forms/getByServiceAsset',{servicesAssetsId: id, 'token':sessionStorage.getItem('token')});
    }
}
