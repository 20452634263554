import { Component, OnInit, Input, Output, HostBinding, NgZone, ChangeDetectorRef } from '@angular/core';
import { EventEmitter } from 'events';
import { ChartInterface } from 'src/app/interfaces/chart-interface';
import {MatCardModule} from '@angular/material/card';

@Component({
  selector: 'app-grouped-vertical-chart',
  templateUrl: './grouped-vertical-chart.component.html',
  styleUrls: ['./grouped-vertical-chart.component.css']
})
export class GroupedVerticalChartComponent implements OnInit {

  public title = "";
  public dataSource : any = [];

  public index : number;
  public fingerprint : string;
  public selfRef: GroupedVerticalChartComponent;
  public isLoading = true;
  public style : any;
  public chartStyles = {
    'tuv' : {
      view : [1030, 400]
      , scheme :  {domain: ['#7859EB','#FB886F','#F7FB7B','#78D35F','#C98ECF','#7FD9F7','#F2B25F','#B2EA70']}
      , legend :  true
      , legendTitle : 'Mes'
      , xAxis : true
      , yAxis : true
      , showYAxisLabel : true
      , yAxisLabel : ''
      , barPadding : 2
      , xScaleMax : 100
      , showDataLabel : false
    }
  };
  public type : string;
  isDataValid = true;
 
  @HostBinding('class.col-md-4') isColMd4 : boolean;
  @HostBinding('class.col-lg-4') isColLg4 : boolean;
  @HostBinding('class.col-md-6') isColMd6 : boolean;
  @HostBinding('class.col-lg-6') isColLg6 : boolean;
  @HostBinding('class.col-md-8') isColMd8 : boolean;
  @HostBinding('class.col-lg-8') isColLg8 : boolean;
  @HostBinding('class.col-md-12') isColMd12 : boolean;
  @HostBinding('class.col-lg-12') isColLg12 : boolean;


  //interface for Parent-Child interaction
  public parentReference : ChartInterface;
/* 
  _time;
  get time() { return this._time; }
 */

  constructor(/* public zone : NgZone,  */private detector : ChangeDetectorRef ) {
    this.fingerprint = Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);
  }

  // @Input('type') type : string; 
  // @Output() onLoad = new EventEmitter();


  ngOnInit() {
  }


  yAxisTickFormatting = (value: number) => {
    if (Math.floor(value) !== value) {
      return '';
    }
    return value;
  }


  setClasses(classes){
    if( !Array.isArray(classes) ) return false;
    if(classes.includes('col-lg-4')) this.isColLg4 = true;
    if(classes.includes('col-md-4')) this.isColMd4 = true;
    if(classes.includes('col-lg-6')) this.isColLg6 = true;
    if(classes.includes('col-md-6')) this.isColMd6 = true;
    if(classes.includes('col-lg-8')) this.isColLg8 = true;
    if(classes.includes('col-md-8')) this.isColMd8 = true;
    if(classes.includes('col-lg-12')) this.isColLg12 = true;
    if(classes.includes('col-md-12')) this.isColMd12 = true;
  }

  setDataSource(dataSource){
    this.isLoading = false;
    this.dataSource = dataSource;
    this.validateData();
  }

  setType(type){
    this.type = type;
    this.style = this.chartStyles[type];
  }

  setCustomStyle(style){
    this.style = style; 
  }

  validateData(){
    var data = this.dataSource;
    var barCount = 0;
    data.map((group)=>{
      group.series.map((bar)=>{
        if(bar.value>0) barCount++;
      });
    });
    this.isDataValid = barCount>0;
  }
}
