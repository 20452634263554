import { Component, OnInit } from '@angular/core';
import { RepositoriesService } from "../../services/repositories.service";
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { ClientsOptionsService } from '../../services/clients-options.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {report} from "../../services/report";
import {TokenService} from "../../services/token.service";
import { SnotifyService } from 'ng-snotify';
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment';
import * as $ from 'jquery';
import { AnimationStyleMetadata } from '@angular/animations';

@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.css'],
	styles: [JSON.parse(localStorage.getItem("style")).img.toString(),JSON.parse(localStorage.getItem("style")).menu.toString() , JSON.parse(localStorage.getItem("style")).bootstrap.toString()],
})
export class ReportComponent implements OnInit {

	public str1= '<iframe class="embed-responsive-item" src="';
	public str2= 'http://mf4desa.movilform.com/files/route_pdf/';
	public str3= '"></iframe>';
	public name:report[];
	public elemact;
	public btype=0;
	public id;
	public subid;
	public doc;
	public URL="";
	public type="TODOS";
	public subtype=null;
	public subtypeflag=0;
	public numero="";
	public cargando=3;
	public aux=[""];
	public aux2;
	public selector;
	public selectoraux;
	public sup=5;
	public inf=0;
	public disp=0;
	public pagact=1;
	public paginas=0;
	public elementos=5;
	public rutas= new Object();
	public URL2;
	public URLs=[];
	public link;
	public a;
	public valor="";
	public compid="";
	public fbusq=0;
	public selectedDate;
	public selectedDate2;
	public show="Seleccione Fecha";
	public show2="Seleccione Fecha";
	public TODOS="";
	public calact=0;
	public calact2=0;
	public error=0;
	public cont_id="-1";
	public modulo="";
	public modulocont="";
	public wcodigo=-1;
  	public wname=null;
  	public flagwork=0;
  	public modalimage;
  	public cust_id="-1";

  	public clie_id=JSON.parse(localStorage.getItem('userData')).clie_id;
  	public timezone;

	public typeIsEmpty=true;
	  
	public tableIsEmpty:boolean = false;
	public tableIsLoading:boolean = false;
	public currentSort = 'ASC';
	public allRows: any = [];

	constructor(
		private valPermiso: ValidaPermisosService,
		private route:Router,
		private clie_opt:ClientsOptionsService,
		private datos:RepositoriesService, 
		private http:HttpClient,
		private notify: SnotifyService, 
		private servprueba: TokenService) {

		moment().locale('es');		
		this.modulo=this.route.url.split('/')[(this.route.url.split('/').length)-1];
		console.log(this.route.url.split('/').length);
		console.log(this.modulo);
		this.cont_id=this.route.url.split('/')[4];
		console.log(this.cont_id);
		if(this.cont_id==undefined){

		}

		if(this.cont_id==undefined && this.modulo!="reporte"){
			this.cust_id=this.modulo;
			this.modulo="reporte_c";
			this.cont_id="-2";
		}
		
		this.datos.getType(this.modulo).subscribe(dato=>{

			this.id=dato.rety_name;
			if(this.id == ''){
				this.typeIsEmpty=true;
				this.notify.warning("No se encontraron tipos de documentos",{timeout: 5000,});
			}else{

				var index = this.id.map(function(d) { return d['rety_name']; }).indexOf('Todos');
				this.id[index].rety_name="Todos";
				this.TODOS=this.id[index].rety_id;
				this.type=this.TODOS;
				console.log(dato);
				console.log(this.id);
				var index = this.id.map(function(d) { return d['rety_name']; }).indexOf('Todos');
				this.TODOS=this.id[index].rety_id;
				this.type=this.TODOS;

				this.typeIsEmpty=false;

			}			
			
			this.compid=dato.comp_id[0].comp_id;
			this.pagact=1;
			this.clie_opt.get_options(this.clie_id).subscribe(datos=>{
				this.timezone = datos['app_timezone']; 
				
				this.tableIsLoading = true;
				this.tableIsEmpty = false;
				console.log(`MODULE: ${this.modulo}`);

				const object = {
					modulo: this.modulo,
					comp_id: this.compid,
					clie_id: this.clie_id,
					cont_id: this.cont_id,
					cust_id: this.cust_id,
					rety_id: this.type,
				}

				console.log('OBJETO CON DATOS PARA PEDIR REPOS');
				console.info(object);


				this.datos.getAllDoc(this.modulo,this.compid,"1900-01-01 00:00:00","2100-01-01 00:00:00",this.cont_id,this.cust_id,this.timezone).subscribe(datos=>{
					this.tableIsLoading = false;
					if (dato.length==0)   this.tableIsEmpty = true;  

					this.doc=datos;
					this.fbusq=1;
					this.btype=1;
					this.disp=1;
					console.log(this.doc);
					this.paginacion(datos);
				},
				  error=>{console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
			    })
			},
			  error=>{console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
		    })
		},
		error=>{console.log(error);this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});

		})
	}


	changeImage(arg){
		this.modalimage=arg;
		console.log(this.modalimage);
	}


	tshow(arg){
		if(arg==1){

			this.show=this.selectedDate;
			this.calact=1;
			this.calact2=1;
		}
		if(arg==2){
			this.show2=this.selectedDate2;
			this.calact=1;
			this.calact2=1;
		}
		if(arg==0){
			this.show="Seleccione Fecha";
			this.show2="Seleccione Fecha";
			this.calact=1;
			this.calact2=1;
		}
	}


	change(type){
		console.log(type);
		this.flagwork=0;
		this.subtype=undefined;
		this.subtypeflag=0;
		this.datos.getSubType(type).subscribe(data=>{
			console.log(data);
			this.subid=data;
			this.subtypeflag=1;
			
		});
	}

	fecha(){
		console.log(this.selectedDate);
	}
	tipo(arg,arg2,arg3=null,arg4=null){
		let auxn=this.wname;
		
		
		if(false){
			this.notify.warning("Ingrese Subtipo de documento");
		}else{
			if(this.selectedDate===undefined || this.selectedDate2===undefined){
				this.tipo2(arg,arg2,"1900-01-01 00:00:00","2100-01-01 00:00:00",null,auxn);
			}
			else{				
				this.tipo2(arg,arg2,this.selectedDate,this.selectedDate2,null,auxn);
			}
		}
		
	}
	tipo2(arg,arg2,t1=null,t2=null,code=null,name=null){
		
		if(arg==this.TODOS){
			this.fbusq=0;
			this.pagact=1;

			this.tableIsLoading = true;
			this.tableIsEmpty = false;
		
			this.datos.getAllDoc(this.modulo,this.compid,t1,t2,this.cont_id,this.cust_id,this.timezone).subscribe(dato=>{
				this.tableIsLoading = false;
				if (dato.length==0)   this.tableIsEmpty = true;  

				if (dato.length==0) {
					this.notify.info('Sin documentos');
				}
				this.doc=dato;
				this.disp=1;
				this.numero="";
				console.log(this.doc);
				this.paginacion(dato);
				this.fbusq=1;
			},error=>{console.log(error);	
				this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
				this.disp=1;
				this.fbusq=1;
				this.numero="";
			})
		}

		else{
			this.fbusq=0;
			this.pagact=1;
			
			this.tableIsLoading = true;
			this.tableIsEmpty = false;
			
			this.datos.getList(this.modulo,arg,arg2,this.compid,t1,t2,this.cont_id,code,name,this.cust_id,this.timezone).subscribe(d=>{
				var dato:any = d;
				this.tableIsLoading = false;
				if (dato.length==0)   this.tableIsEmpty = true; 
				
				this.doc=dato;
				this.disp=1;
				this.numero="";
				this.wname=null;
				this.wcodigo=-1;
				this.paginacion(dato);
				this.fbusq=1;
				this.flagwork=1;
				console.log(this.doc);
			},error=>{	console.log(error);	
				this.wname=null;
				this.wcodigo=-1;
				this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
				this.disp=1;
				this.fbusq=1;
				this.numero="";
				this.flagwork=1;
			})
		}
	}

	
	
	paginacion(arg:any){
		// this.currentSort = 'ASC';
		
		// //ordernamiento ascendente por nombre de archivo 
		// arg.sort(function(a, b) {
		// 	if (a.repos_name < b.repos_name) return -1;
		// 	if (a.repos_name > b.repos_name) return 1;
		// 	return 0;
		// });


		this.aux2=(arg.length/this.elementos);
		
		this.aux=this.aux2.toString().split(".");
		
		if(this.aux[1]===undefined){
			this.paginas=Number(this.aux[0]);

		}
		else{
			this.paginas=Number(this.aux[0])+1;
		}
		this.allRows = arg;
		this.elemact=arg.slice(this.elementos*(this.pagact-1),(this.pagact*this.elementos));
		
		this.selectoraux=Array.from({length: Number(this.paginas)}, (v, k) => k+1);
		this.selector=this.selectoraux.slice(this.inf,this.sup);
	}
	dev(){
		this.paginacion(this.doc);
		if(this.sup>this.selectoraux[this.selectoraux.length-1]){
			
			this.inf=this.selectoraux[this.selectoraux.length-1]-5;
			this.sup=this.selectoraux[this.selectoraux.length-1];

		}
		if(this.inf<0){
			
			this.inf=0;
			this.sup=5;
		}
		
		this.sum(0);
	}
	pag(arg){

		this.disp=0;
		this.pagact=Number(arg); 
		this.inf=this.pagact-3;
		this.sup=this.pagact+2;
		if(this.sup>this.selectoraux[this.selectoraux.length-1]){
			this.inf=this.selectoraux[this.selectoraux.length-1]-5;
			this.sup=this.selectoraux[this.selectoraux.length-1];

		}
		if(this.inf<0){
			this.inf=0;
			this.sup=5;
		}
		
		this.paginacion(this.doc);
		this.disp=1;
	}
	sum(arg){
		
		this.disp=0;
		this.pagact=this.pagact+arg;
		
		if(this.pagact<1){
			this.pagact=1;
		}
		if(this.pagact>this.selectoraux[this.selectoraux.length-1]){
			this.pagact=this.selectoraux[this.selectoraux.length-1];
		}
		
		if(this.selector[this.selector.length-1]!=this.selectoraux[this.selectoraux.length-1] && arg==1 && this.pagact==this.selector[3]){
			this.inf=this.inf+arg;
			this.sup=this.sup+arg;

		}
		if((this.selector[0]!=1 && arg==-1) && this.pagact==this.selector[1]){

			this.inf=this.inf+arg;
			this.sup=this.sup+arg;
		}
		
		

		this.paginacion(this.doc);
		
		this.disp=1;

	}
	upload(){
		this.cargando=3;
		this.disp=2;
	}

	checktype(){ return this.type; }
	changeCode(code){ this.wcodigo=code; }
	changeName(name){ this.wname=name; }

	eliminarRepositories(repos_id,repos_route){
		console.log("repositories id eliminar: "+  repos_id );
		
		
		this.datos.delete_repositories(repos_id,repos_route).subscribe(data=>{},error=>{console.log(error)});
		this.fbusq=0;
		this.pagact=1;

		this.tableIsLoading = true;
		this.tableIsEmpty = false;
		
		this.datos.getAllDoc(this.modulo,this.compid,"1900-01-01 00:00:00","2100-01-01 00:00:00",this.cont_id,this.cust_id,this.timezone).subscribe(dato=>{
			
			this.tableIsLoading = false;
			if (dato.length==0)   this.tableIsEmpty = true;

			this.doc=dato;
			this.disp=1;
			this.numero="";
			console.log(this.doc);
			this.paginacion(dato);
			this.fbusq=1;
		},error=>{console.log(error);	
			this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
			this.disp=1;
			this.fbusq=1;
			this.numero="";
		})
	}

	desactivarRepositories(repos_id){
		console.log("repositories id desactivar: "+  repos_id);
		this.datos.disable_repositories(repos_id).subscribe(data=>{},error=>{console.log(error)});
		this.fbusq=0;
		this.pagact=1;

		this.tableIsLoading = true;
		this.tableIsEmpty = false;
		this.datos.getAllDoc(this.modulo,this.compid,"1900-01-01 00:00:00","2100-01-01 00:00:00",this.cont_id,this.cust_id,this.timezone).subscribe(dato=>{
			
			this.tableIsLoading = false;
			if (dato.length==0)   this.tableIsEmpty = true; 
			
			this.doc=dato;
			this.disp=1;
			this.numero="";
			console.log(this.doc);
			this.paginacion(dato);
			this.fbusq=1;
		},error=>{console.log(error);	
			this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
			this.disp=1;
			this.fbusq=1;
			this.numero="";
		})
		
	}

	reload_component(){
		this.tableIsLoading = true;
		this.tableIsEmpty = false;

		this.datos.getAllDoc(this.modulo,this.compid,"1900-01-01 00:00:00","2100-01-01 00:00:00",this.cont_id,this.cust_id,this.timezone).subscribe(dato=>{

			this.tableIsLoading = false;
			if (dato.length==0)   this.tableIsEmpty = true;   

			this.doc=dato;
			this.disp=1;
			this.numero="";
			console.log(this.doc);
			this.paginacion(dato);
			this.fbusq=1;
		},error=>{console.log(error);	
			this.notify.warning("Conexion no disponible, Intente mas tarde",{timeout: 5000,});
			this.disp=1;
			this.fbusq=1;
			this.numero="";
		})
	}

	validaPermisos(permiso){
		console.log(`validaPermisos ${permiso}`);
    	return this.valPermiso.validaPermisos(permiso);
	 }
	 
	sortTable()
	{
		
		// console.log('sortTable',this.currentSort,this.allRows);
		if (this.currentSort=='ASC') {
			this.currentSort = 'DESC';
			//ordernamiento descendente por nombre de archivo 
			this.allRows.sort(function(a, b) {
				if (a.repos_name > b.repos_name) return -1;
				if (a.repos_name < b.repos_name) return 1;
				return 0;
			});
		}else {
			this.currentSort = 'ASC';
			//ordernamiento asc por nombre de archivo
			this.allRows.sort(function(a, b) {
				if (a.repos_name < b.repos_name) return -1;
				if (a.repos_name > b.repos_name) return 1;
				return 0;
			});
		}
		this.paginacion(this.allRows);
	}

	ngOnInit() {}



}
