import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class JarwisService {

  private baseUrl = environment.apiUrl+environment.apiEnd;
  //http://api.movilform.com/api
  //http://localhost/Backend/backend/public/api

  constructor(private http:HttpClient) { }

  login(data){
    return this.http.post(`${this.baseUrl}/login`, data);
  }

  sendPasswordResetLink(data){
    return this.http.post(`${this.baseUrl}/sendPasswordResetLink`, data);
  }

  cambiarPassword(data){
    return this.http.post(`${this.baseUrl}/resetPassword`, data);
  }

  modificaPassword(data){
    return this.http.post(`${this.baseUrl}/modificaPassword`, data);
  }

  obtenerDatosMenu(data){
    return this.http.post(`${this.baseUrl}/datosMenu`, data);
  }

  obtenerContratos(data){
    return this.http.post(`${this.baseUrl}/contratos`, data);
  }

  obtenerPermisos(data){
    return this.http.post(`${this.baseUrl}/permisos`, data);
  }

  obtenerDatosLineaNegocio(data){
    return this.http.post(`${this.baseUrl}/datosLineaNegocio`, data);
  }

  obtenerSubmenuLineaNegocio(data){
    return this.http.post(`${this.baseUrl}/subMenuLineaNegocio`, data);
  }
}
