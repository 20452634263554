import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';
import { SnotifyService } from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class BeforeLoginService implements CanActivate{
  
  canActivate(reoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> |
  Promise<boolean> {
    console.log("en before");
    this.Token.verify().subscribe(data=>{
      console.log("pasa");
      sessionStorage.setItem('auth','1');
      this.router.navigate(['/home']);
      
    },
    error=>{
      console.log(error);
      if(error.status==401){
        

        sessionStorage.setItem('auth','0');
      }
    });

    if(!this.Token.loggedIn()){
      return true;
    } else{
      //redirecciona a home si está logeado y quiere ingresar a pagina que no requiere estar logeado
      if(sessionStorage.getItem('auth')=='1'){
      
      }
      return false;
    }
  }

  constructor(
    private Token: TokenService,
    private router: Router,
    private notify: SnotifyService
  ) { }
}
