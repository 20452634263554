import { Component, OnInit, ElementRef, ViewChild, Renderer2, ViewContainerRef, ComponentFactoryResolver, HostListener, AfterViewInit } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { ContractPlanService } from 'src/app/services/contract-plan.service';
import { SvgLoaderComponent } from '../common/svg-loader/svg-loader.component';
import { SvgMarkerComponent } from '../common/svg-marker/svg-marker.component';
import { Router } from '@angular/router';
import { SearchableTableComponent } from '../common/searchable-table/searchable-table.component';
import { AssetModalComponent } from './asset-modal/asset-modal.component';
import { PrintService } from 'src/app/services/print.service';
import { PlanSvgComponent } from './plan-svg/plan-svg.component';

import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from "pdfmake/build/pdfmake";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

// import { InlineSVGModule } from 'ng-inline-svg';
// import { HttpClientModule } from '@angular/common/http';

@Component({
    selector: 'ContractPlan',
    templateUrl: './contract-plan.component.html',
    styleUrls: ['./contract-plan.component.css']
})
export class ContractPlanComponent implements OnInit
{
    // @ViewChild('dataContainer') dataContainer: ElementRef;
    // @ViewChild('container',{read:ViewContainerRef}) container: ViewContainerRef;
    @ViewChild('table',{read:SearchableTableComponent}) table: SearchableTableComponent; //wrapper de Datatable con buscador
    @ViewChild('modal',{read:AssetModalComponent}) modal: AssetModalComponent;
    @ViewChild('planSVG',{read:PlanSvgComponent}) planSVG :PlanSvgComponent;





    constructor( 
        private iconReg:SvgIconRegistryService,
        private planService :ContractPlanService,
        private renderer:Renderer2,
        private factory :ComponentFactoryResolver,
        private route:Router,
        public printService : PrintService,
    ){
    }

	assetLoaded = false;
	stage = 'table';
    blueprint = null;
    downloadBtnDisabled = true;

    ngOnInit() 
    {
        this.configTable();
        this.initContractsTable();    
	}
    
    configTable()
    {
        let table = this.table.getDataTable();
        table.isHoverEnabled = true;
        table.setHoverColors('#007dc5','white')
        // table.hoverColor = '#007dc5';
        // table.hoverTextColor = 'white';
    }

    @HostListener('click',['$event']) onClick(event){
        if(event.target.classList.contains('svgAssetMarker')){
            this.getAssetInfo( event.target.dataset.id );
        }
    }

    @HostListener('mouseover',['$event']) onHover(event){
        if(event.target.classList.contains('svgAssetMarker')){
        }
    }

    @HostListener('mouseout',['$event']) onBlur(event){
        if(event.target.classList.contains('svgAssetMarker')){
        }
    }


	
	initContractsTable()
	{
		this.switchStage('table');
		var contractId = this.route.url.split('/')[4];
        var serviceGroupTypeId = localStorage.getItem('segty');
		this.planService.getPlans(contractId,serviceGroupTypeId).subscribe(
			data => {
				this.table.getDataTable().setHeaders(['folio','codigo','nombre','fecha_creacion','version','ultima_actualizacion','profesional_responsable']);
				this.table.setDataSource(data['plans']);
			}, error => {
                console.log('fallo carga de planos => ',error)
			}
		)
    }
    


	switchStage(stage)
	{
		this.stage = stage;
	}



	getAssetInfo(assetId)
	{
        this.modal.show();
      //   alert('buscando asset'+assetId);
        this.planService.getAssetInfo(assetId).subscribe(
            data =>  {
                this.modal.setData(data);
            }, error => {
                console.log('ERROR mientras se obtenia informacion del articulo => ',error)
            }
        );
	}
	
	tableClicked(event)
	{
		this.initContractPlan(event.row);
	}

    enableDownloadButton(){ this.downloadBtnDisabled = false; }
    disableDownloadButton(){ this.downloadBtnDisabled = true;}
    


    initContractPlan(data)
    {
        this.disableDownloadButton();
        this.blueprint = data.id;
        this.assetLoaded = false;
        // this.loadSvgFromHtml('test',this.svg);
        var contractId = this.route.url.split('/')[4];
        var customerId = this.route.url.split('/')[2];
        var serviceGroupType = localStorage.getItem('segty');
      //   alert('contract = ' + contractId + '\ncustomer => ' + customerId + '\nLinea de negocio => '+serviceGroupType);
        // contractId = '2358438';
        this.stage = 'plan';

        this.planSVG.errorEvent.subscribe(()=>{ this.back(); });
        this.planSVG.readyEvent.subscribe(()=>{ this.enableDownloadButton() });
        this.planSVG.initSVG({
            serviceGroupType: serviceGroupType,
            contract: contractId,
            blueprint: data.id
        });
    }

    back()
    {
        this.stage = 'table';
        this.planSVG.clearSvg();    
    }

	
    public downloadSVG()
    {
        this.planSVG.downlaod();
        // // pdfMake.vfs = pdfFonts.pdfMake.vfs;
        // let docDefinition = {
        //     content: [{svg: $(".contractSketch")[0].innerHTML}]/* 'some test text' */ //$(".contractSketch")[0].innerHTML
        // };
        // console.log( $(".contractSketch")[0].innerHTML );
        // let pidief = pdfMake.createPdf(docDefinition)
        // console.log(pdfFonts.pdfMake.vfs)
        // console.log('pidief',pidief)
        // pidief.vfs = pdfFonts.pdfMake.vfs;
        // pidief.download();
        // var contractId = this.route.url.split('/')[4];
        // var serviceGroupType = localStorage.getItem('segty');
        // // contractId = '2358438';
        // // this.printService.printDocument('plan', {contract: contractId, serviceGroupType: serviceGroupType,blueprint:this.blueprint,print:true});
    }




}
