import { Component, OnInit } from '@angular/core';
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  styles:[JSON.parse(localStorage.getItem("style")).menu.toString()]
})
export class HomeComponent implements OnInit {

  public imagenes = []
  constructor(private valPermiso: ValidaPermisosService) {
    console.log(localStorage.getItem('token'));
    this.cargaImagenes();
  }
  
  cargaImagenes(){
    if(JSON.parse(localStorage.getItem('style')).imagenesCarrusel == undefined){
      this.imagenes = [];
    }else{
      this.imagenes = JSON.parse(JSON.parse(localStorage.getItem('style')).imagenesCarrusel.replace(/'/gi, "\""));
    }
  }

  cambiaImagen(){
    this.cargaImagenes();
  }

  ngOnInit() {}

  validaPermisos(permiso){
    return this.valPermiso.validaPermisos(permiso);
  }
}
