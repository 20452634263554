import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { AppInjector } from 'src/app/services/app-injector.service';
// import { AngularSvgPanZoom } from 'angular-svg-pan-zoom/dist/angular-svg-pan-zoom.js';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as SvgPanZoom from 'svg-pan-zoom';

@Component({
    selector: 'app-svg-loader',
    templateUrl: './svg-loader.component.html',
    styleUrls: ['./svg-loader.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgLoaderComponent implements OnInit, AfterViewInit {

    @Input('svg') svg;
    @Input('svgName') svgName;
    @Input('loadMode') loadMode = 'html';
    @Input('zoom') zoom = true;
    @Input('height') height :number = 500;
    @Input('width') width :number = 1000;
    @Input('border') border :boolean = true;

    @Output('ready') readyEvent = new EventEmitter();


    iconReg :SvgIconRegistryService;
    safeHTML :SafeHtml;
    // detector :ChangeDetectorRef
    sanitizer: DomSanitizer;
    svgId = 'svg-'+Date.now()
    svgBox = 1000;

    testHTML;
    @ViewChild('svgContainer') svgContainer :ElementRef;
    
    // @HostBinding('style.position') = "ab";

    constructor(public detector :ChangeDetectorRef)
    {
        this.iconReg = AppInjector.get(SvgIconRegistryService);
        this.sanitizer = AppInjector.get(DomSanitizer);
        // this.detector = AppInjector.get(ChangeDetectorRef);
    }

    ngAfterViewInit(){
        this.updateSvgDimentions();
        if ( this.zoom ) this.initZoomPanel();
    }


    ngOnInit() 
    {
        // if( this.loadMode == 'html' )
        // {
        //     this.iconReg.addSvg(this.svgName,this.svg);
        // }
        // else if ( this.loadMode == 'url' )
        // {
        //     this.iconReg.loadSvg(this.svgName,this.svg);
        // }
        
        this.safeHTML = this.sanitizer.bypassSecurityTrustHtml(this.svg);
    }

    updateSvgDimentions()
    {
        this.detector.detectChanges();
        let svg :any= document.getElementById(this.svgId);
        let bBox :any = svg.getBBox();
        this.svgBox = bBox.width>bBox.height ? bBox.width : bBox.height;
        this.detector.detectChanges();
        this.readyEvent.emit({bBox:bBox});
    }

    initZoomPanel()
    {
        let zoomPanTrigger = SvgPanZoom("#svgWrapper", {
            viewportSelector: '.svg-pan-zoom_viewport',
            zoomEnabled: true,
            controlIconsEnabled: true,
            center: true,
            fit: true,
            contain: true,
            zoomScaleSensitivity: 0.5
        });
    }

}
