import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentosEmpresasService {

  baseUrl: string;	

	constructor(private http:HttpClient) {

		this.baseUrl = environment.apiUrl;
		//http://localhost/Backend/backend/public/ --> pruebas locales
		//http://api.movilform.com/
	}

	get_emp(repos_table,repos_table_id,comp_id,t1=null,t2=null,timezone): Observable<any[]>{
		
		return this.http.post<any[]>(this.baseUrl+"get_docEmp",{'repos_table':repos_table ,'repos_table_id':repos_table_id,'comp_id':comp_id,'t1':t1,'t2':t2,'token':localStorage.getItem('token'),timezone:timezone},
		{
			headers: new HttpHeaders({
				'Access-Control-Allow-Origin': '*',
				'Content-type': 'application/json',
				'Accept': 'application/json',

			})
		});
	}

	getListFilter(repos_table,repos_table_id,tipo,subtipo,comp_id,t1,t2,timezone): Observable<number>{		
		return this.http.post<number>(this.baseUrl+"get_docEmpFilter",{repos_table:repos_table,repos_table_id:repos_table_id,type:tipo,subtype:subtipo,comp_id:comp_id,t1:t1,t2:t2,token:localStorage.getItem('token'),timezone:timezone},
		{
			headers: new HttpHeaders({
				'Access-Control-Allow-Origin': '*',
				'Content-type': 'application/json',
				'Accept': 'application/json',

			})
		});

	}
}
