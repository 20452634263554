import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl, MatInputModule } from '@angular/material'; //
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { FusionChartsModule } from 'angular-fusioncharts';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { JarwisService } from './services/jarwis.service';
import { ContractDocsService } from './services/contract-docs.service';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { AfterLoginService } from './services/after-login.service';
import { BeforeLoginService } from './services/before-login.service';
import { MenuComponent } from './components/menu/menu.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { UserDataService } from './services/user-data.service';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { ResetPasswordComponent } from './components/password/reset-password/reset-password.component';
import { CarruselComponent } from './components/home/carrusel/carrusel.component';
import { HoldingComponent } from './components/holding/holding.component';
import { GraficoPastelComponent } from './components/graficos/grafico-pastel/grafico-pastel.component';
import { BuscadorComponent } from './components/holding/buscador/buscador.component';
import { GraficoBarraComponent } from './components/graficos/grafico-barra/grafico-barra.component';
import { ReportComponent } from './components/report/report.component';
import { PluginsComponent } from './components/plugins/plugins.component';
import { SafePipe } from './pipes/safe.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DlDateTimePickerDateModule } from 'angular-bootstrap-datetimepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap';
// Load FusionCharts
import * as FusionCharts from 'fusioncharts';
// Load Charts module
import * as Charts from 'fusioncharts/fusioncharts.charts';
// Load fusion theme
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { ContratoComponent } from './components/contrato/contrato.component';
import { MenuHorizontalComponent } from './components/menu-horizontal/menu-horizontal.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ModificarCarruselComponent } from './components/home/modificar-carrusel/modificar-carrusel.component';
import { NotaVentaComponent } from './components/nota-venta/nota-venta.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { VisitasComponent } from './components/visitas/visitas.component';
import { getSpanishPaginatorIntl } from './mat-paginator-spanish';
import { MatSelectModule } from '@angular/material/select';
import { ReportePresenciaPlagasComponent } from './components/dashboard_html/rentokil/reporte-presencia-plagas/reporte-presencia-plagas.component';
// import { GoogleChartsModule } from 'angular-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CadenaAuditoriaExternaComponent } from './components/dashboard_html/rentokil/cadena-auditoria-externa/cadena-auditoria-externa.component';
import { SaturacionTuvComponent } from './components/dashboard_html/rentokil/saturacion-tuv/saturacion-tuv.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PrintLayoutComponent } from './components/print-layout/print-layout.component';
import { PrintService } from './services/print.service';
import { PrintDashboardComponent } from './components/dashboard_html/print_dashboard/printDashboard.component';
import { RecommendationService } from "./services/recommendation.service";
import { RecommendationComponent } from './components/recommendation/recommendation.component';
import { RecommendationFormComponent } from './components/recommendation/recommendation-form/recommendation-form.component';


import { VerticalChartComponent } from './components/dashboard/charts/vertical-chart/vertical-chart.component';
import { DataTableComponent } from './components/dashboard/data-table/data-table.component';
import { MatCardModule } from '@angular/material/card';
import { HorizontalChartComponent } from './components/dashboard/charts/horizontal-chart/horizontal-chart.component';
import { HeaderNormalizerPipe } from './pipes/header-normalizer.pipe';
import { LineChartComponent } from './components/dashboard/charts/line-chart/line-chart.component';
import { TableAndChartHandlerComponent } from './components/dashboard/table-and-chart-handler/table-and-chart-handler.component';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { TaskModalComponent } from './components/dashboard_html/task-modal/task-modal.component';
import { GroupedStackedHorizontalComboComponent } from './components/dashboard/charts/combo-charts/grouped-stacked-horizontal-combo/grouped-stacked-horizontal-combo.component';
import { StackgroupHorizontalChartComponent } from './components/dashboard/charts/stackgroup-horizontal-chart/stackgroup-horizontal-chart.component';
import { ComboSeriesHorizontalComponent } from './components/dashboard/charts/combo-charts/grouped-stacked-horizontal-combo/combo-series-horizontal.component';
import { GroupedVerticalChartComponent } from './components/dashboard/charts/grouped-vertical-chart/grouped-vertical-chart.component';
import { DashboardFilterComponent } from 'src/app/components/dashboard_html/rentokil/saturacion-tuv/dashboard-filter/dashboard-filter.component';
import { StackedVerticalChartComponent } from './components/dashboard/charts/stacked-vertical-chart/stacked-vertical-chart.component';
import { MapComponent } from './components/leaflet/map/map.component';
import { AssetsMapComponent } from './components/assets-map/assets-map.component';
import { MapMarkerService } from './services/map/map-marker.service';
import { MapPopUpService } from './services/map/map-pop-up.service';
import { AssetMarkerComponent } from './components/leaflet/asset-marker/asset-marker.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AssetPanelComponent } from './components/leaflet/asset-panel/asset-panel.component';
import { TrackingPanelComponent } from './components/leaflet/tracking-panel/tracking-panel.component';
import { TrackingMarkerComponent } from './components/leaflet/tracking-marker/tracking-marker.component';
import { ServiceModalComponent } from './components/service-modal/service-modal.component';
import { SearchInputComponent } from './components/common/search-input/search-input.component';
import { SearchableTableComponent } from './components/common/searchable-table/searchable-table.component';
import { ControlFactoryComponent } from './components/common/controls/control-factory/control-factory.component';
import { ControlWrapperComponent } from './components/common/controls/control-wrapper/control-wrapper.component';
import { Injector } from '@angular/core';
import { setAppInjector } from './services/app-injector.service';
import { TextControl } from './components/common/controls/text/text.component';
import { DateControl } from './components/common/controls/date/date.component';
import { SelectControl } from './components/common/controls/select/select.component';
import { CheckboxControl } from './components/common/controls/checkbox/checkbox.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'; 
import { ChangeDetectorRef } from '@angular/core';
import { DateFormatService } from './services/date-format.service';
import { ContractPlanComponent } from './components/contract-plan/contract-plan.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ContractPlanService } from './services/contract-plan.service';
import { SvgLoaderComponent } from './components/common/svg-loader/svg-loader.component';
import { SvgMarkerComponent } from './components/common/svg-marker/svg-marker.component';
import { AssetModalComponent } from './components/contract-plan/asset-modal/asset-modal.component';
import { PlanSvgComponent } from './components/contract-plan/plan-svg/plan-svg.component';
import { PrintPlanComponent } from './components/contract-plan/print-plan/print-plan.component';
import { AssetsComponent } from './components/assets/assets.component';
import { ModulesService } from './services/modules.service';
import { MatButtonModule } from '@angular/material/button'; 


// Add dependencies to FusionChartsModule
import { MatDatepickerModule } from '@angular/material/datepicker'; 
import { DatepickerComponent } from 'src/app/components/controls/datepicker/datepicker';
import { MatRadioModule } from '@angular/material';
import { MatBadgeModule } from '@angular/material';
import { AssetsFilterComponent } from './components/assets/assets-filter/assets-filter.component';
import { SearchControl } from './components/common/controls/search/search.component';

// Add dependencies to FusionChartsModule
FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme)


import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ContractDocsComponent } from './components/contract-docs/contract-docs.component';
import { ContractModalComponent } from './components/contract-docs/contract-modal/contract-modal.component';
import { ReloadDirective } from './directives/reload.directive';
import { MassUploadComponent } from './components/mass-upload/mass-upload.component'; 


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    RequestResetComponent,
    ResponseResetComponent,
    ResetPasswordComponent,
    CarruselComponent,
    HoldingComponent,
    GraficoPastelComponent,
    BuscadorComponent,
    ContratoComponent,
    GraficoBarraComponent,
    MenuHorizontalComponent,
    ReportComponent,
    PluginsComponent,
    SafePipe,
    FileUploadComponent,
    DashboardComponent,
    ModificarCarruselComponent,
    NotaVentaComponent,
    VisitasComponent,
    ReportePresenciaPlagasComponent,
    CadenaAuditoriaExternaComponent,
    SaturacionTuvComponent,
    PrintLayoutComponent,
    PrintDashboardComponent,
    RecommendationComponent,
    HeaderNormalizerPipe,
    RecommendationFormComponent,
    VerticalChartComponent,
    DataTableComponent,
    HorizontalChartComponent,
    LineChartComponent,
    TableAndChartHandlerComponent,
    TaskModalComponent,
    GroupedStackedHorizontalComboComponent,
    StackgroupHorizontalChartComponent,
    ComboSeriesHorizontalComponent,
    GroupedVerticalChartComponent,
    DashboardFilterComponent,
    StackedVerticalChartComponent,
    DatepickerComponent,
    MapComponent,
    AssetsMapComponent,
    AssetMarkerComponent,
    AssetPanelComponent,
    TrackingPanelComponent,
    TrackingMarkerComponent,
    ServiceModalComponent,
    SearchInputComponent,
    SearchableTableComponent,
    ControlWrapperComponent,
    TextControl,
    DateControl,
    SelectControl,
    CheckboxControl,
    ContractPlanComponent,
    SvgLoaderComponent,
    SvgMarkerComponent,
    AssetModalComponent,
    PlanSvgComponent,
    PrintPlanComponent,
    AssetsComponent,
    AssetsFilterComponent,
    SearchControl,
    ContractDocsComponent,
    ContractModalComponent,
    ReloadDirective,
    MassUploadComponent,
  ]
  , imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SnotifyModule,
    FusionChartsModule,
    NgxPaginationModule,
    ModalModule.forRoot(),
    DlDateTimePickerDateModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    MatSelectModule,
    // GoogleChartsModule.forRoot(),
    NgxChartsModule,
    TabsModule.forRoot(),
    MatExpansionModule,
    AlertModule.forRoot(),
    MatCardModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatBadgeModule,
    LeafletModule.forRoot(),
    MatSlideToggleModule,
    MatDatepickerModule,
    AngularSvgIconModule.forRoot(),
    MatButtonModule,
    MatAutocompleteModule
  ]
  , providers: [
    JarwisService, 
    ContractDocsService,
    TokenService, 
    AuthService, 
    AfterLoginService, 
    BeforeLoginService, 
    UserDataService, 
    PrintService,
    RecommendationService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults }, 
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() }, 
    SnotifyService,
    BsModalRef,
    MapMarkerService,
    MapPopUpService,
    DateFormatService,
    ContractPlanService,
    ModulesService
  ]
  , bootstrap: [ AppComponent ]
  , entryComponents : [ 
    VerticalChartComponent,
    DataTableComponent,
    HorizontalChartComponent,
    LineChartComponent,
    TaskModalComponent,
    StackgroupHorizontalChartComponent,
    GroupedVerticalChartComponent,
    StackedVerticalChartComponent,
    ServiceModalComponent,
    AssetMarkerComponent,
    TextControl,
    SelectControl,
    DateControl,
    CheckboxControl,
    SearchControl,
    SvgLoaderComponent,
    SvgMarkerComponent,
    AssetsMapComponent
  ]
})
export class AppModule 
{ 
  constructor(injector: Injector) {
    setAppInjector(injector);
  } 
}
