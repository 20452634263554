import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';
import { MapPopUpService } from './map-pop-up.service'

@Injectable({
    providedIn: 'root'
})
export class MapMarkerService {

    capitals :any = {
        features : [
            {
                type: "Feature"
                , geometry : {
                    type: "Point"
                    , coordinates: [125.6,10.1]
                }
                , properties: {
                    name : "ISLAS DINAGAT"
                    , state : 'Kambodia'
                    , population : 'uno que otro'
                }
            }
            ,             {
                type: "Feature"
                , geometry : {
                    type: "Point"
                    , coordinates: [-70.6506,-33.4372]
                }
                , properties: {
                    name : "Santiago"
                    , state : 'Region Metropolitana'
                    , population : 'caleta'
                }
            }
        ]
    }; //string = '/assets/data/usa-capitals.geojson';

    constructor(private http: HttpClient,private popupService: MapPopUpService) {

    }



    makeCapitalMarkers(map): void {
        // this.http.get(this.capitals).subscribe((res: any) => {
        for (const c of this.capitals.features) {
            const lat = c.geometry.coordinates[0];
            const lon = c.geometry.coordinates[1];
            const marker = L.circleMarker([lon, lat]
                // ,{
                //     icon: L.divIcon({
                //         html: '<i class="fas fa-toilet"></i>'
                //         , className : 'dummy'
                //     })
                // }
            );
            marker.bindPopup( this.popupService.makeAssetPopup(c.properties) );
            marker.addTo(map);
        }
        // });
    }
}
