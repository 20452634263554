import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NotaVentaService {

  baseUrl: string;

  constructor(private http:HttpClient) {

  	this.baseUrl = environment.apiUrl;
   }

   get_nv(acti_id): Observable <any[]>{
   	return this.http.post<any[]>(this.baseUrl+'listado_nv',{acti_id: acti_id,'token':sessionStorage.getItem('token')});       
   }

   get_activities(clie_id,cont_id,segty_id): Observable <any[]>{
     return this.http.post<any[]>(this.baseUrl+'get_activities',{clie_id: clie_id,cont_id:cont_id,segty_id:segty_id,'token':sessionStorage.getItem('token')});       
   }

   nv_filter(acti_id,status,rety_name,fechaINI,fechaFIN): Observable <any[]>{
   	return this.http.post<any[]>(this.baseUrl+'NVFilters',{acti_id:acti_id,status:status,rety_name:rety_name,fechaINI:fechaINI,fechaFIN:fechaFIN,'token':sessionStorage.getItem('token')});
   }

   getTypeReport(): Observable<any>{
    return this.http.post<any>(this.baseUrl+"list_rety",{clie_id: JSON.parse(sessionStorage.getItem('userData')).clie_id,'token':sessionStorage.getItem('token')},
    {
      headers: new HttpHeaders({
        
      })
    });
  }

  // subida de archivos s3
 postUrl(arg,arg1,arg2,arg3,arg5=null): Observable<any>{

    return this.http.post<any>(this.baseUrl+"archivo_U",{'url':arg,'name':arg1,'type':arg2,'compid':arg3,'token':sessionStorage.getItem('token'),'work_code':arg5},
    {
      headers: new HttpHeaders({
        
      })
    });


  }

  postFile(fileToUpload: FormData): Observable<any> {
    const endpoint = this.baseUrl+'archivo';
    const formData: FormData = new FormData();
    console.log(formData);
    
    return this.http.post<any>(endpoint,fileToUpload,
    {
      headers: new HttpHeaders({
        

      })
    });

  }   

  get_files_s3(id,segty_id): Observable<any>{
    return this.http.post<any>(this.baseUrl+"get_files_s3",{id:id,segty_id:segty_id,'token':sessionStorage.getItem('token')});
  }

  getSubType(rety_id): Observable<any>{
    return this.http.post<any>(this.baseUrl+"reporte_subtype",{'rety_id':rety_id,'token':sessionStorage.getItem('token')},
    {
      headers: new HttpHeaders({
        
        'Content-type': 'application/json',
        'Accept': 'application/json',

      })
    });
  }

  postContid(cont_id,id): Observable<any>{

    return this.http.post<any>(this.baseUrl+"archivo_C",{'id':id ,'cont_id': cont_id,'token':sessionStorage.getItem('token')},
    {
      headers: new HttpHeaders({
        
        'Content-type': 'application/json',
        'Accept': 'application/json',

      })
    });

  }
  postDesc(arg,arg2):Observable<any>{

    return this.http.post<any>(this.baseUrl+"archivo_D",{'id':arg2,'token':sessionStorage.getItem('token'),'desc':arg},
    {
      headers: new HttpHeaders({
        
      })
    });

  }
}
