import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { SnotifyService } from 'ng-snotify';
import { JarwisService } from 'src/app/services/jarwis.service';
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service';
import { ModulesService } from 'src/app/services/modules.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const demoData = [
  {
    "cust_id": 573,
    "comp_id": 1,
    "cust_code": "82623500-4",
    "cust_name": "IDEAL S.A.",
    "cust_address": null,
    "cust_phone": null,
    "cust_email": null,
    "cust_comercial_activity": null,
    "cust_region": null,
    "cust_commune": null,
    "cust_city": null,
    "cust_date_creation": null,
    "cust_fantasy_name": null,
    "cust_custom1": null,
    "cust_custom2": null,
    "cust_custom3": null,
    "cust_active": 1
  },
  {
    "cust_id": 573,
    "comp_id": 1,
    "cust_code": "6448854-4",
    "cust_name": "Pancho Demo 2",
    "cust_address": null,
    "cust_phone": null,
    "cust_email": null,
    "cust_comercial_activity": null,
    "cust_region": null,
    "cust_commune": null,
    "cust_city": null,
    "cust_date_creation": null,
    "cust_fantasy_name": null,
    "cust_custom1": null,
    "cust_custom2": null,
    "cust_custom3": null,
    "cust_active": 1
  },
  {
    "cust_id": 573,
    "comp_id": 1,
    "cust_code": "6448854-4",
    "cust_name": "Terminator",
    "cust_address": null,
    "cust_phone": null,
    "cust_email": null,
    "cust_comercial_activity": null,
    "cust_region": null,
    "cust_commune": null,
    "cust_city": null,
    "cust_date_creation": null,
    "cust_fantasy_name": null,
    "cust_custom1": null,
    "cust_custom2": null,
    "cust_custom3": null,
    "cust_active": 1
  },
  {
    "cust_id": 4385,
    "comp_id": 1,
    "cust_code": "6448854-4",
    "cust_name": "Los Simpson",
    "cust_address": null,
    "cust_phone": null,
    "cust_email": null,
    "cust_comercial_activity": null,
    "cust_region": null,
    "cust_commune": null,
    "cust_city": null,
    "cust_date_creation": null,
    "cust_fantasy_name": null,
    "cust_custom1": null,
    "cust_custom2": null,
    "cust_custom3": null,
    "cust_active": 1
  },
  {
    "cust_id": 4684,
    "comp_id": 1,
    "cust_code": "79864744-5",
    "cust_name": "Futurama",
    "cust_address": null,
    "cust_phone": null,
    "cust_email": null,
    "cust_comercial_activity": null,
    "cust_region": null,
    "cust_commune": null,
    "cust_city": null,
    "cust_date_creation": null,
    "cust_fantasy_name": null,
    "cust_custom1": null,
    "cust_custom2": null,
    "cust_custom3": null,
    "cust_active": 1
  }
]




@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  styles: [JSON.parse(sessionStorage.getItem("style")).img.toString(), JSON.parse(sessionStorage.getItem("style")).menu.toString(), JSON.parse(sessionStorage.getItem("style")).bootstrap.toString()],


})
export class MenuComponent implements OnInit {

  private datosUsuario = this.userData.get();
  public datosMenu = [];
  public logoEmpresa = 'assets/notification_bg.jpg';
  public modules: [] = [];
  public datosMenuFiltrados = [];
  public filesUrl;
  public tutorialVideoVisible;
  public tutorialVideoUrl: SafeResourceUrl;
  modalRef?: BsModalRef;


  constructor(
    private Auth: AuthService,
    private router: Router,
    private Token: TokenService,
    private userData: UserDataService,
    private notify: SnotifyService,
    private Jarwis: JarwisService,
    private valPermiso: ValidaPermisosService,
    private modulesService: ModulesService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer
  ) {
    console.log(this.userData.get());
  }
  reporte() {
    console.log("click");
    console.log(this.router.url);
  }


  ngOnInit() {
    this.Jarwis.obtenerDatosMenu(this.datosUsuario).subscribe(
      data => { this.salvaDatos(data) }
    );
    this.modulesService.getModules().subscribe(data => { this.modules = data['modules']; })
    let clieOptions = this.userData.getClientOptions();
    this.filesUrl = clieOptions.portal_document_link;
    const { portal_tutorial_link } = clieOptions;
    if (portal_tutorial_link.length > 0) {
      console.log(this.tutorialVideoUrl);
      this.tutorialVideoVisible = true;
      this.tutorialVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(portal_tutorial_link);
    } else {
      this.tutorialVideoVisible = false;
    }

  }




  openExternalFilesUrl() {
    window.open(this.filesUrl, "_blank");
  }

  salvaDatos(data) {
    this.datosMenu = data.data;
    this.datosMenuFiltrados = data.data;
    // this.datosMenu = demoData;
    // this.datosMenuFiltrados = demoData;
  }

  autorizado(permiso) {
    return this.valPermiso.validaPermisos(permiso);
  }

  logout(event: MouseEvent) {
    event.preventDefault();
    this.notify.confirm('¿Está seguro de cerrar la sesión?', {
      timeout: 10000,
      buttons: [
        {
          text: 'Si',
          action: toaster => {
            this.notify.success('Sesion cerrada Correctamente');
            this.Token.remove(),
              this.userData.remove(),
              this.userData.removePermisos(),
              this.Auth.changeAuthStatus(false),
              this.router.navigateByUrl('/login'),
              this.notify.remove(toaster.id)
          }
        },
        {
          text: 'No'
        }
      ]
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  filtrarClientes(event) {
    event.stopPropagation();
    var search = event.target.value;
    if (search == '') {
      this.datosMenuFiltrados = this.datosMenu;
    }
    this.datosMenuFiltrados = this.datosMenu.filter(function (x) {
      return (
        x.cust_name.toLowerCase().includes(search.trim().toLowerCase()) 
      );
    });
  }

  checkPermission(action) {
    const userPerms = JSON.parse(sessionStorage.getItem('perms'));
    let hasPerm = false;
    userPerms.forEach(perm => {
      if (perm.perm_key === action) {
        hasPerm = true;
      }
    });
    return hasPerm;
  }

}
