import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { RecommendationService } from './../../../services/recommendation.service'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { NgForm } from '@angular/forms'
import { SnotifyService } from 'ng-snotify'
import { ValidaPermisosService } from 'src/app/services/valida-permisos.service'

@Component({
  selector: 'app-recommendation-form',
  templateUrl: './recommendation-form.component.html',
  styleUrls: ['./recommendation-form.component.css'],
})
export class RecommendationFormComponent implements OnInit {
  @Input() recommendationData: any

  // declare ng-template #newDeb
  @ViewChild('template') template: ElementRef
  private modal
  public isLoaded = false
  public modalConfig = {
    backdrop: true, //Fondo oscuro
    ignoreBackdropClick: true, //para que no se cierre al clickear fuera del formulario
    class: 'modal-lg',
    keyboard: false, //para que no se cierre al apretar escape
  }
  public images = []
  private contractId = null
  commentCollapseArrow = 'up'
  formSegty: string = '';

  @Output() complete = new EventEmitter()

  constructor(
    private rService: RecommendationService,
    private modalService: BsModalService,
    private notify: SnotifyService,
    private validationService: ValidaPermisosService,
  ) {}

  loadForm(info: any) {
    this.isLoaded = false
    this.contractId = info.contractId
    this.rService
      .getRecommendation(info.formId, info.recommendationId)
      .subscribe(
        (data) => {
          this.recommendationData = data
          this.isLoaded = true
          this.openModal()
          ;(<any>$("[data-toggle='toggle']")).bootstrapToggle('on')
        },
        (error) => {
          this.notify.error(
            'No se pudo obtener la informacion del Factor de Riesgo. Intentelo nuevamente.',
          )
        },
      )
  }

  actionText() {
    return this.recommendationData.action == 'create' ? 'Crear' : 'Ver'
  }

  isNew() {
    return this.recommendationData.action == 'create'
  }

  selectData(input) {
    if (input.type == 'SELECT') {
      return JSON.parse(input.options).data
    }
    if (input.type == 'RADIO') {
      return JSON.parse(input.options).labels
    }
  }

  accept(form: NgForm) {
    $('.btn-save')
      .html(`<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Guardando...`)
    $('.btn-save').prop('disabled', true)
    var formData = new FormData()
    var rData: any

    if (this.isNew()) {
      // revisar esta validacion
      //Crear recomendacion
      if (!this.validateForm(form)) {
        $('.btn-save').html('Guardar')
        $('.btn-save').prop('disabled', false)
        return false
      }

      rData = []
      $.each(this.recommendationData.inputs, (i, v) => {
        if (v.tag in form.value) {
          if (v.type == 'CAMERA') {
            $.each(this.images, (index, image) => {
              formData.append(`${v.tag}[${index}]`, image, image.name)
            })
          } else {
            rData.push({
              id: v.id,
              tag: v.tag,
              value: form.value[v.tag],
            })
          }
        }
      })
    } else {
      // aqui se esta editando la recomendacion, es decir añadiendo comentario.
      //Comentario
      if (form.value['recommendationComment'].trim() == '') {
        $('.btn-save').html('Guardar')
        $('.btn-save').prop('disabled', false)
        this.notify.error('Debe agregar un comentario para guardar.', {
          timeout: 3000,
        })
        return false
      }
      console.log('enviando nuevo comentario', form)
      rData = [
        { tag: 'comentario', value: form.value['recommendationComment'] },
        {
          tag: 'nombre_responsable',
          value: form.value['recommendationNombreResponsable'],
        },
        {
          tag: 'estado_recomendacion',
          value: $("[data-toggle='toggle'").is(':checked'),
        },
      ]
      $.each(this.images, (index, image) => {
        formData.append('image', image, image.name)
      })
    }

    formData.append('contractId', this.contractId)
    formData.append('formId', this.recommendationData.formId)
    formData.append('segtyId', localStorage.getItem('segtyId'))
    formData.append(
      'recommendationId',
      this.recommendationData.recommendationId,
    )
    formData.append('data', JSON.stringify(rData))
    this.rService.saveRecommendation(formData).subscribe(
      (data) => {
        $('.btn-save').html('Guardar')
        if (!data['error']) {
          let mensaje = this.isNew()
            ? 'Se guardó su recomendacion'
            : 'Se guardó su comentario'
          this.notify.success(mensaje)
          this.images = []
          this.complete.emit()
          this.modal.hide()
        }
      },
      (error) => {
        $('.btn-save').prop('disabled', false).html('Guardar')
        this.notify.error(
          'Error al guardar el mensaje, intentelo nuevamente.',
          { timeout: 3000 },
        )
      },
    )
  }

  detectFiles(event) {
    try {
      let files = event.target.files
      console.log(files)
    } catch (error) {
      console.log(error)
    }
  }

  getControlValue(control) {
    var text = ''
    $.each(this.recommendationData.values, (i, v) => {
      if ('foda_' + control.tag == i) {
        text = v
      }
    })
    return text
  }

  validateForm(form: NgForm) {
    var ok = true
    $.each(form.value, (i, v) => {
      var required = true
      if (v == '') {
        var fieldName = ''

        //se busca las opciones del control
        $.each(this.recommendationData.inputs, (ii, vv) => {
          if (vv.tag == i) {
            fieldName = vv.label
            if (['CAMERA', 'GALLERYIMAGE'].includes(vv.type)) {
              required = false
            } else {
              ok = false
            }
          }
        })

        if (required) {
          this.notify.warning(`El campo [${fieldName}] se encuentra vacio`)
          return false
        }
      }
    })
    return ok
  }

  cancel() {
    this.images = []
    this.modal.hide()
  }

  openModal() {
    const csSgtyId = localStorage.getItem('segtyId');
    if(csSgtyId == '3'){
      this.formSegty = 'Servicios MIP';
    } else if(csSgtyId == '83'){
      this.formSegty = 'Servicios Control Biologico';
    }
    this.modal = this.modalService.show(this.template, this.modalConfig)
    setTimeout(() => {
      let btn: any = $("[data-toggle='toggle']")
      btn.bootstrapToggle('on')
    }, 300)
  }

  ngOnInit() {
  }

  relatedSelectChange(controlId) {
    $.each(this.recommendationData.inputs, (index, input) => {
      try {
        var controlOptions = JSON.parse(input.options)
        if ('reference' in controlOptions) {
          if (controlOptions.reference == controlId) {
            $(`#control-${input.id}`).html('')
            let blankOption = new Option('- Seleccione una opcion -', '')
            // blankOption.setAttribute('disabled','');
            $(`#control-${input.id}`).append(blankOption)
            $.each(controlOptions.data, (ii, value) => {
              if (
                value[1] == $(`#control-${controlId} option:selected`).text()
              ) {
                var newOpt = new Option(value[0], value[0])
                $(`#control-${input.id}`).append(newOpt)
              }
            })
          }
        }
      } catch (error) {}
    })
  }

  checkPermission(permiso) {
    return this.validationService.validaPermisos(permiso)
  }

  isClosed() {
    return (
      this.recommendationData.values.foda_serv_estado.toLowerCase() == 'cerrado'
    )
  }

  imageUploaderChange(fileInputEvent: any) {
    if (this.isNew()) {
      if (this.images.length > 1) {
        this.notify.warning('Se pueden adjuntar un maximo de 2 imagenes.')
        return false
      }
    } else {
      if (this.images.length > 0) {
        this.notify.warning(
          'Se pueden adjuntar solo una imagen por comentario.',
        )
        return false
      }
    }
    this.images.push(fileInputEvent.target.files[0])
    this.refreshImages()
    return true
  }

  removeImage(index) {
    this.images.splice(index, 1)
    this.refreshImages()
    return true
  }

  refreshImages() {
    var html = ''
    if (this.images.length > 0) {
      $.each(this.images, (i, v) => {
        html += ` <div class="alert alert-secondary alert-dismissible fade show d-inline" role="alert">
                    <strong style="font-size:11px">${v.name}</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" data-image-id="${i}">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>`
      })
    } else {
      html = 'Sin imagenes...'
    }
    $('.div-images').html(html)
    var _this = this
    $('[data-image-id]').on('click', function () {
      _this.removeImage($(this).data('image-id'))
    })
  }

  loadRecomendationImage(id, error = false) {
    var img = $(`img[data-reco-img-id=${id}]`)
    $(`button[data-reco-img-id=${id}]`).addClass('hidden')
    if (error) {
      let alt = img.attr('alt')
      img
        .parent()
        .html(
          `<span class="glyphicon glyphicon-alert" style="color:red"></span> ${alt}`,
        )
    } else {
      img.fadeIn(1500)
    }
  }

  isValidControl(control: any) {
    var validControls = ['TEXT', 'SELECT', 'CAMERA', 'RADIO', 'GALLERY']
    if (validControls.includes(control.type)) return true
    return false
  }

  commentCollapseToggle() {
    this.commentCollapseArrow =
      this.commentCollapseArrow == 'up' ? 'down' : 'up'
    $('.comment-collapse-btn').html(
      `<i class="fa fa-chevron-${this.commentCollapseArrow}"></i> Comentarios Previos (${this.recommendationData.comments.length})`,
    )
  }
}
