import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AssetService
{
	baseUrl: string;

    constructor(private http:HttpClient) { 
        this.baseUrl = environment.apiUrl+'api/';
    }

    getTuvDetail(id)
	{
		return this.http.post<any[]>(this.baseUrl+'assets/getTuvDetail',{id: id, 'token':localStorage.getItem('token')});
	}
}
