import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy
} from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition
} from "@angular/animations";
import { formatLabel } from "@swimlane/ngx-charts";
// import { calculateViewDimensions } from 'common/view-dimensions.helper';

@Component({
  selector: "g[ngx-combo-series-horizontal]",
  template: `
    <!-- STACKED -->
    <svg:g
    *ngFor="let stack of bars; let index = index; trackBy: trackBy"
    [attr.transform]="groupTransform(stack)"
    >
    <!-- 
    [@animationState]="'active'" [attr.transform]="groupTransform(stack)"
    -->

      <!-- BARRA -->
      <svg:g ngx-charts-bar
        *ngFor="let bar of stack; trackBy: trackBy"
        [width]="bar.width"
        [height]="bar.height"
        [x]="bar.x"
        [y]="bar.y"
        [fill]="bar.color"
        [data]="bar.data"
        [orientation]="'horizontal'"
        [roundEdges]="roundEdges"
        ngx-tooltip
        [tooltipPlacement]="'top'"
        [tooltipType]="'tooltip'"
        [tooltipTitle]="bar.tooltipText"
        [animations]="false"
      ></svg:g>
      <!-- /BARRA -->

      <svg:g *ngIf="showDataLabel">

          <!-- LABEL CON TOTAL -->
          <svg:g
              ngx-charts-bar-label
              *ngFor="let b of barsForDataLabels; let i = index; trackBy: trackDataLabelBy"
              [barX]="b.x"
              [barY]="b.y"
              [barWidth]="b.width"
              [barHeight]="b.height"
              [value]="b.text"
              [orientation]="'horizontal'"
              (dimensionsChanged)="dataLabelWidthChanged.emit({ size: $event, index: i })"
          />

          <!-- [valueFormatting]="dataLabelFormattin -->
      </svg:g>
    </svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("animationState", [
      transition("* => void", [
        style({
          opacity: 1,
          transform: "*"
        }),
        animate(500, style({ opacity: 0, transform: "scale(0)" }))
      ])
    ])
  ]
})



/*
  * Esta clase controla el despliegue de las barras al interior de un group, 
  * es decir, recibe mediante la variable [series] toda la data de un group
  * y lo itera dentro de stacked, para luego volver a iterarlo y crear la barra.
  * Esto se hace mediante la funcion update() para lluego verse reflejado en el
  * html mediante las directivas *ngFor()
*/
export class ComboSeriesHorizontalComponent implements OnChanges {

  groupTransform(group) {
    var _this = this;
    return "translate(0, " + _this.yScale(group.name) + ")";
  };
  

  @Input() dims;
  @Input() type = "stacked";
  @Input() series;
  @Input() seriesLine;
  @Input() xScale;
  @Input() yScale;
  @Input() colors;
  @Input() tooltipDisabled: boolean = false;
  @Input() gradient: boolean;
  @Input() activeEntries: any[];
  @Input() seriesName: string;
  @Input() animations: boolean = true;
  @Input() noBarWhenZero: boolean = true;
  @Input() bandWidth;
  @Input() roundEdges = false;
  @Input() innerScale;
  @Input() groupName : string;
  @Input() groupPadding;
  @Input() stackPadding;
  @Input() barHeight;
  @Input() results;
  @Input() showDataLabel:boolean = false;

  @Output() select = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() deactivate = new EventEmitter();
  @Output() bandwidth = new EventEmitter();
  @Output() dataLabelWidthChanged = new EventEmitter();

  bars: any;
  x: any;
  y: any;
  barsForDataLabels = []; //almacena la data de los label que se muestran al final de la barra

  ngOnChanges(changes): void {
    this.update();
  }

  /* 
    * El metodo se activa luego de cada cambio en la data.
    * Calcula las posiciones, largos, colores y tooltips de cada una de las barras a desplegar dentro de un barGroup
  */
  update(): void {

    let width;
    if (this.series.length) {
      width = this.bandWidth;
      this.bandwidth.emit(width);
    }

    
    let total;
    if (this.type === "normalized") {
      total = this.series.map(d => d.value).reduce((sum, d) => sum + d, 0);
    }
    
    this.bars = this.series.map((stack, index) => {

      //puntero que refleja la cantidad acumulada en la barra en el eje x
      var d0 = 0;

      //determina alto de cada una de las barras dentro de un determinado grupo
      var finalHeight = this.series.length > 0 
                        ? (this.barHeight/this.series.length) - (this.stackPadding/2) - 1
                        : 0;

      var stackedBars = stack.series.map((d,i)=>{
        let value = d.value;
        const label = d.name;
        const formattedLabel = formatLabel(label);
        const roundEdges = this.type === "standard";
  
        const bar: any = {
          value,
          label,
          roundEdges,
          data: d,
          width,
          formattedLabel,
          height: 0,
          x: 0,
          y: 0
        };
  

        /*
          * Se calcula la posicion, largo y ancho de cada barra
        */

        const offset0 = d0; //valor inicial de la barra
        const offset1 = offset0 + value; //valor final de la barra
        d0 += value; //se actualiza el acumulador
        bar.width = this.xScale(offset1) - this.xScale(offset0); //ancho en pixels
        bar.height = finalHeight;
        bar.x = this.xScale(offset0); /* posicion x inicial barra (en pixels) */
        bar.y = this.innerScale(stack.name); /* posicion y inicial barra (en pixels) */
        bar.offset0 = offset0; 
        bar.offset1 = offset1;

  
  
        //colors
        if (this.colors.scaleType === "ordinal") {
          bar.color = this.colors.getColor(label);
        } else {
          if (this.type === "standard") {
            bar.color = this.colors.getColor(value);
            bar.gradientStops = this.colors.getLinearGradientStops(value);
          } else {
            bar.color = this.colors.getColor(bar.offset1);
            bar.gradientStops = this.colors.getLinearGradientStops(
              bar.offset1,
              bar.offset0
            );
          }
        }
        // --colors
  
  
        //tooltip
        bar.tooltipText = `
          <span class="tooltip-label">${this.groupName} • ${stack.name} • ${d.name}</span>
          <span class="tooltip-val">${value.toLocaleString()}</span>
        `;
        // --tooltip

        return bar;
      });


      //Muestra un label al final de la barra que indica nombre del Stacked y cantidad total
      if( this.showDataLabel==true ){
        this.barsForDataLabels = [];
        
        this.series.map((stack)=>{

          var section:any = {};
          section.series = stack.series;
          var total = stack.series.map(function (d) { return d.value; }).reduce(function (sum, d) { return ( sum + d ); }, 0);
          
          /*
            * Se recorta el nombre del stacked para prevenir que se superponga sobre el legendField.
            *
            * Se considera tambien la cantidad de caracteres del total a mostrar para restringir
            * el nombre de forma dinámica
          */
          let trimmedName = (stack.name.length + total.toLocaleString().length) > 17
                            ? stack.name.slice( 0 , 20 - total.toLocaleString().length  ) + '...'
                            : stack.name;
          section.text = trimmedName +' ('+total+')';

          section.x = this.xScale(total); //se ubica al final de la barra
          section.y = this.innerScale(stack.name) +2; 
          section.width = 5;
          section.height = this.series.length>0 ? (this.barHeight/this.series.length) - (this.stackPadding/2) : 0;
          this.barsForDataLabels.push(section);

        });

      }


      return stackedBars;
    });
  }






  
  isActive(entry): boolean {
    if (!this.activeEntries) return false;
    const item = this.activeEntries.find(d => {
      return entry.name === d.name && entry.series === d.series;
    });
    return item !== undefined;
  }

  onClick(data): void {
    this.select.emit(data);
  }

  trackBy(index, bar): string {
    return bar.label;
  }

}
