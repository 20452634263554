import { Component, OnInit, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { StylesService } from 'src/app/services/styles.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-modificar-carrusel',
  templateUrl: './modificar-carrusel.component.html',
  styleUrls: ['./modificar-carrusel.component.css'],
  styles:[JSON.parse(localStorage.getItem("style")).menu.toString()]
})

export class ModificarCarruselComponent implements OnInit {
  
  public modalRef: BsModalRef;
  public config = {
    backdrop: true , //Fondo oscuro 
    ignoreBackdropClick: true, //para que no se cierre al clickear fuera del formulario 
    class: 'modal-lg',
    keyboard: false //para que no se cierre al apretar escape 
  };
  private imagenes = [];
  private nuevaImagen = {
    'nombre': null,
    'ruta': null,
    'url': null,
  };
  public imagen = null;
  public error;
  public portal = localStorage.getItem('actual');
  public URL;
  public File;
  public baseUrl;
  
  id_unico = null;
  
  @Output()
  cambia = new EventEmitter(); //con esto activaré el metodo para que cambien las imagenes del carrusel en home

  constructor(
    private modalService: BsModalService,
    private httpClient: HttpClient,
    private notify: SnotifyService,
    private style: StylesService
  ){ 
    this.baseUrl=environment.apiUrl;
    if(JSON.parse(localStorage.getItem('style')).imagenesCarrusel == undefined){
      this.imagenes = [];
    }else{
      this.imagenes = JSON.parse(JSON.parse(localStorage.getItem('style')).imagenesCarrusel.replace(/'/gi, "\""));
    }
  }
  
  ngOnInit() {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  closeModal(){
    this.notify.info("Guardando cambios realizados", {timeout: 5000});
    this.modificaStyle(); //Modifico el style en localStorage
    this.modalRef.hide();
  }

  modificaStyle(){
    this.style.getStyle(localStorage.getItem('actual')).subscribe(
      data => {
        var nuevoStyle = JSON.parse(data);
        localStorage.setItem('style', nuevoStyle[0].por_style);
        this.cambia.emit(); //Activa el metodo en home
        this.notify.success("Cambios Guardados", {timeout: 5000});
      }
    );
  }
  HandleFile(event){

    this.File=event;
    console.log(this.URL);

  }



  uploadFile(){
    this.id_unico = new Date().getTime();
    this.notify.info("Espere un momento...", {timeout: 5000});
    this.error = null;
    let elem = this.File.target;
    let imagen = elem.files[0];
    let extensiones = /(.jpg|.jpeg|.png)$/i;

    //valida archivo subido tenga las extensiones anteriores
    if(!extensiones.exec(imagen.name)){
      this.notify.error('La imagen seleccionada no es del tipo .jpeg/.jpg/.png/', {timeout: 5000});
      this.error = 'Archivo no permitido';
      return false;
    }
    
    this.agregarImagen(imagen);
    
    if(elem.files.length > 0){
      let formData = new FormData();
      formData.append('myfile', imagen);
      formData.append('imagenes', JSON.stringify(this.imagenes).replace(/"/gi, "\'"));
      formData.append('portal', this.portal);
      formData.append('id_unico', this.id_unico);
      formData.append('url', this.URL);


      this.sendFile(formData).subscribe(
        (response) => {
          this.notify.success(response.toString(), {timeout: 5000})
        },
        error => this.notify.error('Ha ocurrido un error, no se guardó la imagen', {timeout: 5000}));
    }
  }

  eliminarImagen(index){
    this.notify.info("Espere un momento...", {timeout: 5000});
    let imagenBorrada = this.imagenes[index];
    this.imagenes.splice(index, 1);
    this.deleteFile(imagenBorrada, JSON.stringify(this.imagenes).replace(/"/gi, "\'")).subscribe(
      (response) => {
        this.notify.success(response.toString(), {timeout: 5000})
      });
  }

  agregarImagen(imagen) {
    this.nuevaImagen.ruta= 'Imagenes/Carrusel/'+ this.id_unico + '-' + imagen.name;
    this.nuevaImagen.nombre= imagen.name;
    this.nuevaImagen.url=this.URL;
    this.imagenes.push(this.nuevaImagen);
    this.nuevaImagen = {'nombre': null,'ruta': null, 'url':null};
    this.imagen = null;
  }

  sendFile(formData: any){
    let url = `${this.baseUrl}api/subirImagen`;
    return this.httpClient.post(
      url,
      formData)
  }

  deleteFile(formData: any, imagenes){
    let url = `${this.baseUrl}api/borrarImagen`;
    return this.httpClient.post(
      url,
      [formData, imagenes, this.portal])
  }
}
