import { Component } from '@angular/core';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { UserDataService } from './services/user-data.service';

import {StylesService} from './services/styles.service';
import * as moment from 'moment';

import * as $ from 'jquery';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  styles:[JSON.parse(sessionStorage.getItem("style")).app_img_movile.toString()]
})
export class AppComponent {
  title = 'frontend';
  img="";

  ver(){
    if(sessionStorage.getItem('auth')=='1'){
      return true;
    }
    else{
      return false;
    }
  }

  estaLogeado(){
    var datos = this. userData.get();
    if(datos){
      return true
    }
    return false;
  }

  constructor(private style:StylesService, private userData: UserDataService) {
    this.img=(JSON.parse(sessionStorage.getItem("style")).loginback.toString());
   
  }
  ngOnInit() {
    moment.locale('es');
   
  	var div = $("<div />", {
    html: '&shy;<style>' + JSON.parse(sessionStorage.getItem("style")).font.toString() + '</style>'
  }).appendTo("body");
  }
}
