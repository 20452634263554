import { Component, OnInit,ComponentFactoryResolver, ViewContainerRef  } from '@angular/core';
import { VerticalChartComponent } from '../charts/vertical-chart/vertical-chart.component';
import { DataTableComponent } from './../data-table/data-table.component';
import { ChartInterface } from 'src/app/interfaces/chart-interface';
import { HorizontalChartComponent } from '../charts/horizontal-chart/horizontal-chart.component';
import { LineChartComponent } from '../charts/line-chart/line-chart.component';
import { GroupedVerticalChartComponent } from '../charts/grouped-vertical-chart/grouped-vertical-chart.component';
import { StackedVerticalChartComponent } from '../charts/stacked-vertical-chart/stacked-vertical-chart.component';
import { StackgroupHorizontalChartComponent } from '../charts/stackgroup-horizontal-chart/stackgroup-horizontal-chart.component';

@Component({
  selector: 'app-table-and-chart-handler',
  templateUrl: './table-and-chart-handler.component.html',
  styleUrls: ['./table-and-chart-handler.component.css']
})

export class TableAndChartHandlerComponent implements OnInit,ChartInterface {

  protected componentIndex = 0;
  protected references : any = [];
  protected chartReferences : any = [];
  protected tableReferences : any = [];
  constructor( protected cfResolver : ComponentFactoryResolver ) {
  }

  ngOnInit() {
  }

  remove(){}

  testFunction(){
  }

  protected createTable(container : ViewContainerRef, title : string = '',columnsToDisplay : string[] | boolean, classes : any[] = [],customReferencesContainer : any = null){
    let tableFactory = this.cfResolver.resolveComponentFactory(DataTableComponent);
    let tableReference = container.createComponent(tableFactory);
    let currentTable = tableReference.instance;
    currentTable.selfRef = currentTable;
    currentTable.index = ++this.componentIndex;
    currentTable.isLoading = true;
    currentTable.title = title;
    if( typeof columnsToDisplay == 'boolean' ) currentTable.displayAllColumns = columnsToDisplay;
    else currentTable.setHeaders(columnsToDisplay);
    currentTable.setClasses(classes);
    this.tableReferences.push(tableReference);
    this.references.push(tableReference);
    if(customReferencesContainer!=null) customReferencesContainer.push(tableReference);
    return currentTable;
  }



  private createChart(chart:any,container : ViewContainerRef,type : string = '', title : string = '', classes : string[] = [],customReferencesContainer : any = null ){
    let chartFactory = this.cfResolver.resolveComponentFactory(chart);
    let chartReference = container.createComponent(chartFactory);
    let currentChart :any = chartReference.instance;
    currentChart.selfRef = currentChart;
    currentChart.index = ++this.componentIndex;
    currentChart.parentReference = this;
    currentChart.setType(type);
    currentChart.title = title;
    currentChart.isLoading = true;
    currentChart.setClasses(classes);
    this.chartReferences.push(chartReference);
    this.references.push(chartReference);
    if(customReferencesContainer!=null) customReferencesContainer.push(chartReference);
    return currentChart;
  }

  protected createHorizontalChart(container : ViewContainerRef,type : string = '', title : string = '', classes : string[] = [],customReferencesContainer : any = null ){
    return this.createChart(HorizontalChartComponent,container,type,title,classes,customReferencesContainer);
  }

  protected createGroupedVerticalChart(container :ViewContainerRef, type :string = '', title :string = '' , classes :any[] = [] ,customReferencesContainer : any = null ){
    return this.createChart(GroupedVerticalChartComponent,container,type,title,classes,customReferencesContainer);
  }

  protected createStackedVerticalChart(container :ViewContainerRef, type :string = '', title :string = '' , classes :any[] = [], customReferencesContainer : any = null  ){
    return this.createChart(StackedVerticalChartComponent,container,type,title,classes,customReferencesContainer);
  }

  protected createComboChartGroupStackHorizontal(container :ViewContainerRef, type :string = '', title :string = '' , classes :any[] = [] ,customReferencesContainer : any = null  ){
    return this.createChart(StackgroupHorizontalChartComponent,container,type,title,classes,customReferencesContainer);
  }

  protected createVerticalChart(container :ViewContainerRef, type :string = '', title :string = '' , classes :any[] = [],customReferencesContainer : any = null ){
    return this.createChart(VerticalChartComponent,container,type,title,classes,customReferencesContainer);
  }

  protected createLineChart(container :ViewContainerRef, type :string = '', title :string = '' , classes :any[] = [],customReferencesContainer : any = null ){
    return this.createChart(LineChartComponent,container,type,title,classes,customReferencesContainer);
  }






}
