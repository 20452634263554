import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BaseControlComponent } from '../base-control/base-control.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'SelectControl',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})

export class SelectControl extends BaseControlComponent implements OnInit {

	@Output('toggle') toggleEvent = new EventEmitter;
	formControl = null;

	constructor() 
	{
		super();
	}

	ngOnInit() {
	}


	onOpenOptions(event){
		// event.controlData = this.data;
		this.toggleEvent.emit({event : event,control:this.data});
	}
	
	onSelectionChange(event){
		this.updateValue(event.value);
		this.changeEvent.emit({event:event,control:this.data});
	}


	updateValue(value)
	{
		this.innerValue = value;
		if( this.data.type=='MULTISELECT' ){
			var newValue = '';
			for( let i = 0; i < this.innerValue.length; i++ ){
				newValue += this.innerValue[i];
				if( i < this.innerValue.length-1 ) newValue += ',';
			}
		}else{
			const newValue = this.innerValue + '';
		}
		this.data.value = newValue;
	}

	setData(data)
	{
		this.data = data;
		if( data.type=='MULTISELECT' && this.formControl==null ){
			this.formControl = new FormControl();
		}
		this.setDefaultValue(this.stringToArray(data.value));
		this.toDefault();
	}

	stringToArray(stringValue){
		let array = [];
		let options = stringValue.trim().split(',');
		for(let option of options){
			if(option.trim()!=''){
				array.push(option.trim()); 
			}
		}
		return array;
	}

	toDefault()
	{
		this.updateValue(this.defaultValue);
	}

	clear()
	{
		this.updateValue([]);
		this.formControl.setValue([]);
	}





}
